/* eslint-disable react/require-default-props */
import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../../globals/models/components/dataTable';
import {
  acceptDeclineMatching,
  startSubsetOrBiddingProcess,
} from '../../../../store/actions/creators/dataTable';
import { ProposalI } from '../../../../globals/models/data/article.model';

const GeneralIcon = ({
  tipText,
  faIcon,
  hoverClassName,
  _startSubsetOrBiddingProcess,
  modalType,
  row,
  _acceptMatching,
  proposal,
  actionButtonClassName,
  onClick,
}: GeneralIconProps) => {
  return (
    <Tooltip
      title={tipText}
      placement="top-end"
      className={`general-hover-effect hover:${hoverClassName}`}
    >
      <span>
        <button
          type="button"
          className={`${actionButtonClassName} ${
            faIcon.iconName === 'exclamation-circle' ? 'cursor-default' : ''
          }`}
          onClick={() => {
            if (modalType === DataTableModalOptions.ARTICLE_SUBSET) {
              _startSubsetOrBiddingProcess(row, modalType, 'SUBSET_TEXT');
            }

            if (modalType === DataTableModalOptions.ARTICLE_BID) {
              _startSubsetOrBiddingProcess(row, modalType, 'ARTICLE_BID_TITLE');
            }

            if (modalType === DataTableModalOptions.ACCEPT_MATCHING) {
              _acceptMatching(row, proposal as ProposalI, 'CONFIRMED');
            }

            if (modalType === DataTableModalOptions.DECLINE_MATCHING) {
              _acceptMatching(row, proposal as ProposalI, 'DECLINED');
            }

            if (onClick) {
              onClick();
            }
          }}
          disabled={modalType === DataTableModalOptions.DEFAULT}
        >
          <FontAwesomeIcon icon={faIcon} />
        </button>
      </span>
    </Tooltip>
  );
};

interface GeneralIconProps {
  tipText: string;
  faIcon: IconDefinition;
  hoverClassName: string;
  actionButtonClassName?: string;

  _startSubsetOrBiddingProcess: (
    article: ArticleOfferI,
    page: DataTableModalOptions,
    title: string | null,
  ) => {
    type: string;
    payload: { article: ArticleOfferI; page: DataTableModalOptions };
  };

  modalType: DataTableModalOptions;
  row: ArticleOfferI;

  _acceptMatching: (
    value: ArticleOfferI,
    proposal: ProposalI,
    status: 'CONFIRMED' | 'DECLINED',
  ) => Promise<{
    type: string;
    payload: { article: ArticleOfferI; proposal: ProposalI } | string;
  }>;

  proposal?: ProposalI;

  onClick?: () => void;
}

export default connect(null, {
  _startSubsetOrBiddingProcess: startSubsetOrBiddingProcess,
  _acceptMatching: acceptDeclineMatching,
})(GeneralIcon);
