import { combineReducers } from 'redux';
import { registrationReducer } from './registration';
import { dataTableReducer } from './dataTable';
import { dataTableShellReducer } from './dataTableShell';
import { sidebarReducer } from './sidebar';
import { articleEditorReducer } from './articleEditor';
import { profileReducer } from './profile';
import { shoppingCartReducer } from './shoppingCart';
import { adminCompaniesReducer } from './admin/companies';
import initialState from '../initialState';
import { RegistrationActionTypes } from '../actions/types/registration';
import { modalsReducer } from './modals';
import { msv3Reducer } from './MSV3';

const appReducer = combineReducers({
  registrationState: registrationReducer,
  dataTableState: dataTableReducer,
  dataTableShellState: dataTableShellReducer,
  sidebarState: sidebarReducer,
  articleEditorState: articleEditorReducer,
  profileState: profileReducer,
  shoppingCartState: shoppingCartReducer,
  adminAreaState: combineReducers({
    companies: adminCompaniesReducer,
  }),
  modalsState: modalsReducer,
  msv3State: msv3Reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === RegistrationActionTypes.LOGOUT) {
    return appReducer(initialState, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
