import { LOCALES } from './constantChoices';
import i18n from '../../i18n';
import { ShippingCostsHandlerOptions } from '../../globals/models/data/article.model';

/**
 * Static translations
 * t('MASS_IMPORT.LABELS.COUNTRY_LOCATION')
 * t('MASS_IMPORT.LABELS.SHIPPING_COSTS_HANDLER')
 */

export enum ShippingCostHandlerMap {
  ICH = ShippingCostsHandlerOptions.CREATOR,
  PARTNER = ShippingCostsHandlerOptions.PARTNER,
  NOT_DEFINED = ShippingCostsHandlerOptions.NOT_DEFINED,
}

export const MASS_IMPORT_POSSIBLE_FIELDS = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'QUANTITY_TEXT',
  },
  {
    key: 'min_amount',
    value: 'MINIMUM_PURCHASE_QUANTITY_TEXT',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'MASS_IMPORT.LABELS.PRICE',
  },
  {
    key: 'discount_aep_cleaned',
    value: 'ARTICLE_EDITOR_DISCOUNT_PREMIUM_AEK',
  },
  {
    key: 'charge',
    value: 'TABLE_COLUMN_BATCH',
  },
  {
    key: 'verfallsdatum',
    value: 'MASS_IMPORT_EXPIRATION_DATE_FIELD',
  },
  {
    key: 'minPriceActive',
    value: 'MASS_IMPORT_PRICE_PROPOSAL_CHECKBOX',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: 'MASS_IMPORT.LABELS.MIN_PRICE',
  },
  {
    key: 'period_of_validity',
    value: 'ARTICLE_EDITOR_TENDER_EXP_DATE',
  },
  {
    key: 'status_description',
    value: 'ARTICLE_EDITOR_STATUS_DESCRIPTION',
  },
  {
    key: 'payment_deadline',
    value: 'MASS_IMPORT_SELECT_PAYMENT',
  },
  {
    key: 'article_location_country',
    value: 'MASS_IMPORT.LABELS.COUNTRY_LOCATION',
  },
  {
    key: 'shipping_costs_carrier',
    value: 'MASS_IMPORT.LABELS.SHIPPING_COSTS_HANDLER',
  },
];

export const MASS_IMPORT_MM_DISABLED_CHECK = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'QUANTITY_TEXT',
  },
  {
    key: 'min_amount',
    value: '',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'MASS_IMPORT.LABELS.PRICE',
  },
  {
    key: 'discount_aep_cleaned',
    value: '',
  },
  {
    key: 'charge',
    value: 'TABLE_COLUMN_BATCH',
  },
  {
    key: 'verfallsdatum',
    value: 'MASS_IMPORT_EXPIRATION_DATE_FIELD',
  },
  {
    key: 'minPriceActive',
    value: '',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: '',
  },
  {
    key: 'period_of_validity',
    value: '',
  },
  {
    key: 'status_description',
    value: '',
  },
  {
    key: 'payment_deadline',
    value: '',
  },
  {
    key: 'article_location_country',
    value: '',
  },
  {
    key: 'shipping_costs_carrier',
    value: '',
  },
];

export const MASS_IMPORT_MM_POSSIBLE_FIELDS = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'Verfügbar',
  },
  {
    key: 'min_amount',
    value: '',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'EK',
  },
  {
    key: 'discount_aep_cleaned',
    value: '',
  },
  {
    key: 'charge',
    value: 'Charge',
  },
  {
    key: 'verfallsdatum',
    value: 'Verfall',
  },
  {
    key: 'minPriceActive',
    value: '',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: '',
  },
  {
    key: 'period_of_validity',
    value: '',
  },
  {
    key: 'status_description',
    value: '',
  },
  {
    key: 'payment_deadline',
    value: '',
  },
  {
    key: 'article_location_country',
    value: '',
  },
  {
    key: 'shipping_costs_carrier',
    value: '',
  },
];

export const MASS_IMPORT_MM_WITHOUT_CHARGE_POSSIBLE_FIELDS = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'Verfügbar',
  },
  {
    key: 'min_amount',
    value: '',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'Wert EK',
  },
  {
    key: 'discount_aep_cleaned',
    value: '',
  },
  {
    key: 'charge',
    value: '',
  },
  {
    key: 'verfallsdatum',
    value: '',
  },
  {
    key: 'minPriceActive',
    value: '',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: '',
  },
  {
    key: 'period_of_validity',
    value: '',
  },
  {
    key: 'status_description',
    value: '',
  },
  {
    key: 'payment_deadline',
    value: '',
  },
  {
    key: 'article_location_country',
    value: '',
  },
  {
    key: 'shipping_costs_carrier',
    value: '',
  },
];

export const MASS_IMPORT_MM_WITH_CHARGE_AND_STOCKYARD_POSSIBLE_FIELDS = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'Verfügbar',
  },
  {
    key: 'min_amount',
    value: '',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'Wert EK',
  },
  {
    key: 'discount_aep_cleaned',
    value: '',
  },
  {
    key: 'charge',
    value: '',
  },
  {
    key: 'verfallsdatum',
    value: '',
  },
  {
    key: 'minPriceActive',
    value: '',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: '',
  },
  {
    key: 'period_of_validity',
    value: '',
  },
  {
    key: 'status_description',
    value: '',
  },
  {
    key: 'payment_deadline',
    value: '',
  },
  {
    key: 'article_location_country',
    value: '',
  },
  {
    key: 'shipping_costs_carrier',
    value: '',
  },
];

export const MASS_IMPORT_MM_WITH_CHARGE_AND_GOODS_RECEIVED_POSSIBLE_FIELDS = [
  {
    key: 'product_pzn',
    value: 'PZN',
  },
  {
    key: 'available_amount',
    value: 'Verfügbar',
  },
  {
    key: 'min_amount',
    value: '',
  },
  {
    key: 'price_per_piece_cleaned',
    value: 'Wert EK',
  },
  {
    key: 'discount_aep_cleaned',
    value: '',
  },
  {
    key: 'charge',
    value: 'Charge',
  },
  {
    key: 'verfallsdatum',
    value: 'Verfall',
  },
  {
    key: 'minPriceActive',
    value: '',
  },
  {
    key: 'best_offer_price_per_piece_cleaned',
    value: '',
  },
  {
    key: 'period_of_validity',
    value: '',
  },
  {
    key: 'status_description',
    value: '',
  },
  {
    key: 'payment_deadline',
    value: '',
  },
  {
    key: 'article_location_country',
    value: '',
  },
  {
    key: 'shipping_costs_carrier',
    value: '',
  },
];

const MINumbersFormatChoices = LOCALES.map((el) => el.text);

export const MassImportExpDateFormats = Object.freeze({
  'MM/yyyy': 'MM/yyyy',
  'MM.yyyy': 'MM.yyyy',
  'MM-yyyy': 'MM-yyyy',
  'dd.MM.yyyy': 'dd.MM.yyyy',
  REL_DATE: i18n.t('MASS_IMPORT.LABELS.RELATIVE_DATE_IN_MONTHS'),
});

export const MASS_IMPORT_DATA_FORMATS = {
  verfallsdatum: [
    'dd.MM.yyyy',
    'MM/yyyy',
    'MM.yyyy',
    'MM-yyyy',
    i18n.t('MASS_IMPORT.LABELS.RELATIVE_DATE_IN_MONTHS'),
  ],
  period_of_validity: [
    'dd/MM/yyyy',
    'dd.MM.yyyy',
    'dd-MM-yyyy',
    i18n.t('MASS_IMPORT.LABELS.RELATIVE_DATE_IN_MONTHS'),
  ],
  price_per_piece_cleaned: MINumbersFormatChoices,
  discount_aep_cleaned: MINumbersFormatChoices,
  best_offer_price_per_piece_cleaned: MINumbersFormatChoices,
} as { [key: string]: string[] };

export enum MassImportSteps {
  FILE_UPLOAD = 0,
  DB_FIELDS_MATCHING = 1,
  DATA_CONFIRMATION = 2,
  CHECKOUT_ADDRESSES = 3,
}

export enum ArticleEditorSteps {
  PZN_SEARCH = 0,
  ARTICLE_INFORMATION = 1,
  ARTICLE_STATUS = 2,
  CHECKOUT_ADDRESSES = 3,
}

/**
 * Static translations
 * t('ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_OWN')
 * t('ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_ALL')
 * t('ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_ALL')
 * t('ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_OWN')
 * t('ARTICLE_EDITOR.LABELS.PARTNER_MY_OFFER')
 * t('ARTICLE_EDITOR.LABELS.PARTNER_ALL_OFFERS')
 * t('ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_ALL')
 * t('ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_OWN')
 * t('ARTICLE_EDITOR.LABELS.NOT_DEFINED')
 */

const getCreatorShippingHandler = () => {
  switch (window.location.pathname) {
    case '/application/offers/own':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_OWN';

    case '/application/offers/all':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_ALL';

    case '/application/search/all':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_ALL';

    case '/application/search/own':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_OWN';

    case '/application/search/-/create':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_OWN';

    case '/application/offers/-/create':
      return 'ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_OWN';

    case (
      window.location.pathname.match(
        '/application/offers/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_OWN';

    case (
      window.location.pathname.match(
        '/application/search/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'ARTICLE_EDITOR.LABELS.CREATOR_PURCHASES_OWN';

    default:
      return 'ARTICLE_EDITOR.LABELS.NOT_DEFINED';
  }
};

const getPartnerShippingHandler = () => {
  switch (window.location.pathname) {
    case '/application/offers/own':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_MY_OFFER';

    case '/application/offers/all':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_ALL_OFFERS';

    case '/application/search/all':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_ALL';

    case '/application/search/own':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_OWN';

    case '/application/search/-/create':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_OWN';

    case '/application/offers/-/create':
      return 'ARTICLE_EDITOR.LABELS.PARTNER_MY_OFFER';

    case (
      window.location.pathname.match(
        '/application/offers/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'ARTICLE_EDITOR.LABELS.PARTNER_MY_OFFER';

    case (
      window.location.pathname.match(
        '/application/search/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'ARTICLE_EDITOR.LABELS.PARTNER_PURCHASE_OWN';

    default:
      return 'ARTICLE_EDITOR.LABELS.NOT_DEFINED';
  }
};

export const ShippingCostOptions: {
  value: ShippingCostsHandlerOptions | undefined;
  text: string;
}[] = [
  {
    value: ShippingCostsHandlerOptions.NOT_DEFINED,
    text: 'ARTICLE_EDITOR.LABELS.NOT_DEFINED',
  },
  {
    value: ShippingCostsHandlerOptions.CREATOR,
    text: getCreatorShippingHandler(),
  },
  {
    value: ShippingCostsHandlerOptions.PARTNER,
    text: getPartnerShippingHandler(),
  },
];

export const MASS_IMPORT_MM_WERNECKE_POSSIBLE_FIELDS = [
  {
    key: 'wernecke_0',
    value: '---',
  },
  {
    key: 'wernecke_1',
    value: 'Ohne Chargen',
  },
  {
    key: 'wernecke_2',
    value: 'Mit Chargen kumuliert',
  },
  {
    key: 'wernece_3',
    value: 'Mit Charge und Lagerplatz',
  },
  {
    key: 'wernecke_4',
    value: 'Mit Chargen und Wareneingängen',
  },
];

export const ShippingCostOptionsProfileSetting: {
  value: ShippingCostsHandlerOptions | undefined;
  text: string;
}[] = [
  {
    value: ShippingCostsHandlerOptions.NOT_DEFINED,
    text: 'ARTICLE_EDITOR.LABELS.NOT_DEFINED',
  },
  {
    value: ShippingCostsHandlerOptions.CREATOR,
    text: 'ARTICLE_EDITOR.LABELS.CREATOR_OFFERS_OWN',
  },
  {
    value: ShippingCostsHandlerOptions.PARTNER,
    text: 'ARTICLE_EDITOR.LABELS.PARTNER_MY_OFFER',
  },
];

export const ShippingCostOptionsCreatorPartner: {
  value: ShippingCostsHandlerOptions | undefined;
  text: string;
}[] = [
  {
    value: ShippingCostsHandlerOptions.NOT_DEFINED,
    text: 'ARTICLE_EDITOR.LABELS.NOT_DEFINED',
  },
  {
    value: ShippingCostsHandlerOptions.CREATOR,
    text: 'ARTICLE_EDITOR.LABELS.CREATOR',
  },
  {
    value: ShippingCostsHandlerOptions.PARTNER,
    text: 'ARTICLE_EDITOR.LABELS.PARTNER',
  },
];
