/* eslint-disable @typescript-eslint/indent,no-nested-ternary,react/require-default-props */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../globals/models/components/dataTable';
import SubsetModal from './SubsetModal';
import {
  setIsTableModalLoading,
  setTableModal,
} from '../../../store/actions/creators/dataTable';
import BidModal from './BidModal';
import DeleteArticleModal from './DeleteArticleModal';
import OpenProposalModal from './OpenProposalsModal';
import CompletedProposalsModal from './CompletedProposals';
import MSV3PZNDeliverySuggestionsModal from './MSV3/MSV3PZNDeliverySuggestionsModal';
import MSV3CheckoutModal from './MSV3/checkout/MSV3CheckoutModal';

// Static translation keys

/**
 * t('SHOPPING_CART_SUBMIT_SALES')
 * t('SHOPPING_CART_SUBMIT_PURCHASES')
 * t('ARTICLE_BID_TITLE')
 * t('SUBSET_TEXT')
 * t('DELETE_ARTICLE_MODAL_TITLE')
 * t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.TITLE')
 * t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.CHECKOUT_TITLE')
 */

const TableModal = ({
  currentModalPage,
  _setIsTableModalLoading,
  _setTableModal,
  title,
  currentModalArticle,
  maxWidth,
}: TableModalProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const activeColor = path.includes('offers')
    ? 'text-primary'
    : path.includes('cart')
    ? 'text-primary'
    : 'text-green-600';

  const articleType = path.includes('offers')
    ? 'OFFER_TEXT'
    : 'PURCHASE_REQUEST_TEXT';

  // function to handle the state of the material dialog
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    _setIsTableModalLoading(false);
    _setTableModal(null, null);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
      <div className="w-full">
        <div className="grid grid-cols-2 border-b mr-1">
          <p
            className={`py-4 pl-4 font-bold md:text-xl whitespace-no-wrap ${activeColor}`}
          >
            {t(title as string, {
              productName: currentModalArticle?.attributes.product_name,
              productType:
                articleType === 'OFFER_TEXT'
                  ? t('OFFER_TEXT')
                  : t('PURCHASE_REQUEST_TEXT'),
            })}
          </p>
          <p className="text-left relative pt-2">
            <button
              type="button"
              className="focus:outline-none absolute right-0"
              onClick={handleClose}
            >
              &#10060;
            </button>
          </p>
        </div>
        {currentModalPage === DataTableModalOptions.ARTICLE_SUBSET && (
          <SubsetModal />
        )}
        {currentModalPage === DataTableModalOptions.ARTICLE_BID && <BidModal />}
        {currentModalPage === DataTableModalOptions.DELETE_ARTICLE && (
          <DeleteArticleModal />
        )}
        {currentModalPage === DataTableModalOptions.OPEN_PROPOSALS && (
          <OpenProposalModal articleOffer={currentModalArticle} />
        )}
        {currentModalPage === DataTableModalOptions.COMPLETED_PROPOSALS && (
          <CompletedProposalsModal
            articleOffer={currentModalArticle}
            closeModal={handleClose}
          />
        )}
        {currentModalPage === DataTableModalOptions.MSV3_PZN_AVAILABILITY && (
          <MSV3PZNDeliverySuggestionsModal
            articleOffer={currentModalArticle as ArticleOfferI}
            closeModal={handleClose}
          />
        )}
        {currentModalPage === DataTableModalOptions.MSV3_CHECKOUT && (
          <MSV3CheckoutModal
            articleOffer={currentModalArticle as ArticleOfferI}
            closeModal={handleClose}
          />
        )}
      </div>
    </Dialog>
  );
};

interface TableModalProps {
  currentModalPage: DataTableModalOptions | null;
  _setIsTableModalLoading: (
    value: boolean,
  ) => { type: string; payload: boolean };
  _setTableModal: (
    value: DataTableModalOptions | null,
    title: string | null,
  ) => {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  };
  title: string | null;
  currentModalArticle: ArticleOfferI | null;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const mapStateToProps = ({
  dataTableState: {
    currentModal: { article, page, title, maxWidth },
  },
}: StoreI) => ({
  currentModalArticle: article,
  currentModalPage: page,
  title,
  maxWidth,
});

export default connect(mapStateToProps, {
  _setIsTableModalLoading: setIsTableModalLoading,
  _setTableModal: setTableModal,
})(TableModal);
