export enum ArticleEditorActionTypes {
  GET_ARTICLES_SUGGESTIONS_BY_PZN = 'Article-Editor/GET_ARTICLES_SUGGESTIONS_BY_PZN',
  SET_IS_ARTICLE_CREATED = 'Article-Editor/SET_IS_ARTICLE_CREATED',
  EDITOR_ERROR = 'Article-Editor/EDITOR_ERROR',
  SET_ARTICLE_TO_EDIT = 'Article-Editor/SET_ARTICLE_TO_EDIT',
  SET_EDIT_MODE = 'Article-Editor/SET_EDIT_MODE',
  SET_IS_LOADING = 'Article-Editor/SET_IS_LOADING',
  UPLOAD_MASS_IMPORT_FILE = 'Article-Editor/UPLOAD_MASS_IMPORT_FILE',
  UPLOAD_PRODUCT_FINDER_IMPORT_FILE = 'Article-Editor/UPLOAD_PRODUCT_FINDER_IMPORT_FILE',
  SET_IS_LOADING_ARTICLE_SUGGESTIONS = 'Article-Editor/SET_IS_LOADING_ARTICLE_SUGGESTIONS',
  UPLOAD_MASS_IMPORT_DATA = 'Article-Editor/UPLOAD_MASS_IMPORT_DATA',
  SET_DATA_UPLOAD_STATUS = 'Article-Editor/SET_DATA_UPLOAD_STATUS',
  GET_ARTICLE_MATCHING = 'Article-Editor/GET_ARTICLE_MATCHING',
  SET_IS_GETTING_ARTICLE_MATCHING = 'Article-Editor/SET_IS_GETTING_ARTICLE_MATCHING',
  ADD_MSV3_DELIVERABLES = 'Article-Editor/ADD_MSV3_DELIVERABLES',
}
