export function roundToFixedWithLocale(
  value: number,
  decimals = 2,
  locale = 'en',
) {
  return value.toLocaleString(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}

export function round(value: number, decimals = 2) {
  const roundedValue = parseFloat(`${value}`).toFixed(decimals);
  return Number(roundedValue) === 0 ? 0 : (roundedValue as any);
}

export function cleanFormattedNumber(value: string, locale: string) {
  const cleanSuffixes = (valueToClean: string) =>
    valueToClean.replace(/[ €]/g, '').replace(/[ %]/g, '');

  switch (locale) {
    case 'en':
      return cleanSuffixes(String(value).replace(/[,]/g, ''));

    case 'de':
      return cleanSuffixes(
        String(value).replace(/[.]/g, '').replace(/[,]/g, '.'),
      );

    default:
      return cleanSuffixes(String(value).replace(/[,]/g, ''));
  }
}
