import { Reducer } from 'redux';
import { SidebarState } from '../../globals/models/redux';
import initialState from '../initialState';
import { SidebarActions } from '../../globals/models/actions/sidebar';
import { SidebarActionTypes } from '../actions/types/sidebar';

export const sidebarReducer: Reducer<SidebarState, SidebarActions> = (
  state = initialState.sidebarState,
  action,
) => {
  switch (action.type) {
    case SidebarActionTypes.SET_FILTER_STRING:
      return {
        ...state,
        filterString: action.payload,
      };
    default:
      return state;
  }
};
