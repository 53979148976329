/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';
import { TRAMEDIX_PHONE_NUMBER } from '../../shared/constants/constantChoices';

const Privacy = () => {
  return (
    <div className="container mx-auto px-10 lg:px-0">
      <div className="py-6">
        <p className="font-semibold text-3xl  text-primary">
          Datenschutzerklärung
        </p>
        <p className="text-2xl font-light">Zuletzt geändert: 22.03.2018</p>
      </div>
      <div className="px-6">
        <h2 className="text-2xl pt-4 font-bold pb-4">
          1. Datenschutz auf einen Blick
        </h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">Allgemeine Hinweise</h3>
        <p className="pb-2">
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Datenerfassung auf unserer Website
        </h3>
        <p className="pb-2">
          <strong>
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </strong>
        </p>
        <p className="pb-2">
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen.
        </p>
        <p className="pb-2">
          <strong>Wie erfassen wir Ihre Daten?</strong>
        </p>
        <p className="pb-2">
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p className="pb-2">
          Andere Daten werden automatisch beim Besuch der Website durch unsere
          IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
          betreten.
        </p>
        <p className="pb-2">
          <strong>Wofür nutzen wir Ihre Daten?</strong>
        </p>
        <p className="pb-2">
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <p className="pb-2">
          <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
        </p>
        <p className="pb-2">
          Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
          oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
          Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
          ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Analyse-Tools und Tools von Drittanbietern
        </h3>
        <p className="pb-2">
          Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit Cookies und mit
          sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
          erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
          zurückverfolgt werden. Sie können dieser Analyse widersprechen oder
          sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
          Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
        </p>
        <p className="pb-2">
          Sie können dieser Analyse widersprechen. Über die
          Widerspruchsmöglichkeiten werden wir Sie in dieser
          Datenschutzerklärung informieren.
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">
          2. Allgemeine Hinweise und Pflichtinformationen
        </h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">Datenschutz</h3>
        <p className="pb-2">
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p className="pb-2">
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p className="pb-2">
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Hinweis zur verantwortlichen Stelle
        </h3>
        <p className="pb-2">
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
        </p>
        <p className="pb-2">
          TRAMEDIX UG (haftungsbeschränkt)
          <br /> Goldberger Str. 81a
          <br /> 18273 Güstrow
        </p>

        <p className="pb-2">
          Telefon: {TRAMEDIX_PHONE_NUMBER}
          <br /> E-Mail: technik@tramedix.com
        </p>
        <p className="pb-2">
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h3>
        <p className="pb-2">
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </h3>
        <p className="pb-2">
          Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
          Landesdatenschutzbeauftragte des Bundeslandes, in dem unser
          Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten
          sowie deren Kontaktdaten können folgendem Link entnommen werden:
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Recht auf Datenübertragbarkeit
        </h3>
        <p className="pb-2">
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          SSL- bzw. TLS-Verschlüsselung
        </h3>
        <p className="pb-2">
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von &rdquo;http://&rdquo; auf
          &rdquo;https://&rdquo; wechselt und an dem Schloss-Symbol in Ihrer
          Browserzeile.
        </p>
        <p className="pb-2">
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Verschlüsselter Zahlungsverkehr auf dieser Website
        </h3>
        <p className="pb-2">
          Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
          Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei
          Einzugsermächtigung) zu übermitteln, werden diese Daten zur
          Zahlungsabwicklung benötigt.
        </p>
        <p className="pb-2">
          Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
          Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte
          SSL- bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von &rdquo;http://&rdquo; auf
          &rdquo;https://&rdquo; wechselt und an dem Schloss-Symbol in Ihrer
          Browserzeile.
        </p>
        <p className="pb-2">
          Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Auskunft, Sperrung, Löschung
        </h3>
        <p className="pb-2">
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
          oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Widerspruch gegen Werbe-Mails
        </h3>
        <p className="pb-2">
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
          Werbung und Informationsmaterialien wird hiermit widersprochen. Die
          Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
          Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
          Spam-E-Mails, vor.
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">
          3. Datenschutzbeauftragter
        </h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Gesetzlich vorgeschriebener Datenschutzbeauftragter
        </h3>
        <p className="pb-2">
          Wir haben für unser Unternehmen einen Datenschutzbeauftragten
          bestellt.
        </p>
        <p className="pb-2">
          Axel Lehmann
          <br /> TRAMEDIX UG (haftungsbeschränkt)
          <br /> Goldberger Str. 81A
          <br /> 18273 Güstrow
        </p>

        <p className="pb-2">
          Telefon: {TRAMEDIX_PHONE_NUMBER}
          <br /> E-Mail: a.lehmann@tramedix.com
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">
          4. Datenerfassung auf unserer Website
        </h2>
        <h3>Cookies</h3>
        <p className="pb-2">
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert.
        </p>
        <p className="pb-2">
          Die meisten der von uns verwendeten Cookies sind so genannte
          “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
          gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis
          Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
          beim nächsten Besuch wiederzuerkennen.
        </p>
        <p className="pb-2">
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browser
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <p className="pb-2">
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
          werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung
          seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
          Surfverhaltens) gespeichert werden, werden diese in dieser
          Datenschutzerklärung gesondert behandelt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Server-Log-Dateien</h3>
        <p className="pb-2">
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>
        <ul className="pb-2 pl-6">
          <li>
            <strong>&gt; &nbsp;</strong>Browsertyp und Browserversion
          </li>
          <li>
            <strong>&gt; &nbsp;</strong>verwendetes Betriebssystem
          </li>
          <li>
            {' '}
            <strong>&gt; &nbsp;</strong>Referrer URL
          </li>
          <li>
            <strong>&gt; &nbsp;</strong>Hostname des zugreifenden Rechners
          </li>
          <li>
            <strong>&gt; &nbsp;</strong>Uhrzeit der Serveranfrage
          </li>
          <li>
            <strong>&gt; &nbsp;</strong>IP-Adresse
          </li>
        </ul>
        <p className="pb-2">
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.
        </p>
        <p className="pb-2">
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Kontaktformular</h3>
        <p className="pb-2">
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
        </p>
        <p className="pb-2">
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen.
          Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
          Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <p className="pb-2">
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
          – bleiben unberührt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Registrierung auf dieser Website
        </h3>
        <p className="pb-2">
          Sie können sich auf unserer Website registrieren, um zusätzliche
          Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
          verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
          Dienstes, für den Sie sich registriert haben. Die bei der
          Registrierung abgefragten Pflichtangaben müssen vollständig angegeben
          werden. Anderenfalls werden wir die Registrierung ablehnen.
        </p>
        <p className="pb-2">
          Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch
          notwendigen Änderungen nutzen wir die bei der Registrierung angegebene
          E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
        </p>
        <p className="pb-2">
          Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie
          können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu
          reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
          der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p className="pb-2">
          Die bei der Registrierung erfassten Daten werden von uns gespeichert,
          solange Sie auf unserer Website registriert sind und werden
          anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
          unberührt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Verarbeiten von Daten (Kunden- und Vertragsdaten)
        </h3>
        <p className="pb-2">
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit
          sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt
          auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
          Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
          gestattet. Personenbezogene Daten über die Inanspruchnahme unserer
          Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir
          nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme
          des Dienstes zu ermöglichen oder abzurechnen.
        </p>
        <p className="pb-2">
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
          Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
          Aufbewahrungsfristen bleiben unberührt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Datenübermittlung bei Vertragsschluss für Online-Shops, Händler und
          Warenversand
        </h3>
        <p className="pb-2">
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
          im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der
          Lieferung der Ware betrauten Unternehmen oder das mit der
          Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
          Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
          Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
          Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
          Werbung, erfolgt nicht.
        </p>
        <p className="pb-2">
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Datenübermittlung bei Vertragsschluss für Dienstleistungen und
          digitale Inhalte
        </h3>
        <p className="pb-2">
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies
          im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
          Zahlungsabwicklung beauftragte Kreditinstitut.
        </p>
        <p className="pb-2">
          Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann,
          wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine
          Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa
          zu Zwecken der Werbung, erfolgt nicht.
        </p>
        <p className="pb-2">
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">
          5. Analyse Tools und Werbung
        </h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">Google Analytics</h3>
        <p className="pb-2">
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA.
        </p>
        <p className="pb-2">
          Google Analytics verwendet so genannte &rdquo;Cookies&rdquo;. Das sind
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert.
        </p>
        <p className="pb-2">
          Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
          Webangebot als auch seine Werbung zu optimieren.
        </p>
        <p className="pb-2">
          <strong>Browser Plugin</strong>
        </p>
        <p className="pb-2">
          Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch den Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>
        <p className="pb-2">
          <strong>Widerspruch gegen Datenerfassung</strong>
        </p>
        <p className="pb-2">
          Sie können die Erfassung Ihrer Daten durch Google Analytics
          verhindern, indem Sie auf folgenden Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
          Besuchen dieser Website verhindert:
          <a href="#">Google Analytics deaktivieren</a>.
        </p>
        <p className="pb-2">
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
          finden Sie in der Datenschutzerklärung von Google:
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <p className="pb-2">
          <strong>Auftragsdatenverarbeitung</strong>
        </p>
        <p className="pb-2">
          Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung
          abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbehörden bei der Nutzung von Google Analytics vollständig
          um.
        </p>
        <p className="pb-2">
          <strong>Demografische Merkmale bei Google Analytics</strong>
        </p>
        <p className="pb-2">
          Diese Website nutzt die Funktion “demografische Merkmale” von Google
          Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu
          Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese
          Daten stammen aus interessenbezogener Werbung von Google sowie aus
          Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten
          Person zugeordnet werden. Sie können diese Funktion jederzeit über die
          Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
          Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch
          gegen Datenerfassung” dargestellt generell untersagen.
        </p>

        <h3 className="text-2xl pt-4 font-bold pb-4">Google AdSense</h3>
        <p className="pb-2">
          Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
          Werbeanzeigen der Google Inc. (&rdquo;Google&rdquo;). Anbieter ist die
          Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p className="pb-2">
          Google AdSense verwendet sogenannte &rdquo;Cookies&rdquo;,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website ermöglichen. Google AdSense
          verwendet auch so genannte Web Beacons (unsichtbare Grafiken). Durch
          diese Web Beacons können Informationen wie der Besucherverkehr auf
          diesen Seiten ausgewertet werden.
        </p>
        <p className="pb-2">
          Die durch Cookies und Web Beacons erzeugten Informationen über die
          Benutzung dieser Website (einschließlich Ihrer IP-Adresse) und
          Auslieferung von Werbeformaten werden an einen Server von Google in
          den USA übertragen und dort gespeichert. Diese Informationen können
          von Google an Vertragspartner von Google weiter gegeben werden. Google
          wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten
          Daten zusammenführen.
        </p>
        <p className="pb-2">
          Die Speicherung von AdSense-Cookies erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
          Webangebot als auch seine Werbung zu optimieren.
        </p>
        <p className="pb-2">
          Sie können die Installation der Cookies durch eine entsprechende
          Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website voll umfänglich nutzen können. Durch die
          Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
          Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
          Weise und zu dem zuvor benannten Zweck einverstanden.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Google Analytics Remarketing
        </h3>
        <p className="pb-2">
          Unsere Websites nutzen die Funktionen von Google Analytics Remarketing
          in Verbindung mit den geräteübergreifenden Funktionen von Google
          AdWords und Google DoubleClick. Anbieter ist die Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA.
        </p>
        <p className="pb-2">
          Diese Funktion ermöglicht es die mit Google Analytics Remarketing
          erstellten Werbe-Zielgruppen mit den geräteübergreifenden Funktionen
          von Google AdWords und Google DoubleClick zu verknüpfen. Auf diese
          Weise können interessenbezogene, personalisierte Werbebotschaften, die
          in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
          Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem anderen
          Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.
        </p>
        <p className="pb-2">
          Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu
          diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto.
          Auf diese Weise können auf jedem Endgerät auf dem Sie sich mit Ihrem
          Google-Konto anmelden, dieselben personalisierten Werbebotschaften
          geschaltet werden.
        </p>
        <p className="pb-2">
          Zur Unterstützung dieser Funktion erfasst Google Analytics
          google-authentifizierte IDs der Nutzer, die vorübergehend mit unseren
          Google-Analytics-Daten verknüpft werden, um Zielgruppen für die
          geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.
        </p>
        <p className="pb-2">
          Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft
          widersprechen, indem Sie personalisierte Werbung in Ihrem Google-Konto
          deaktivieren; folgen Sie hierzu diesem Link:
          <a
            href="https://www.google.com/settings/ads/onweb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/settings/ads/onweb/
          </a>
          .
        </p>
        <p className="pb-2">
          Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt
          ausschließlich auf Grundlage Ihrer Einwilligung, die Sie bei Google
          abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei
          Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto
          zusammengeführt werden (z.B. weil Sie kein Google-Konto haben oder der
          Zusammenführung widersprochen haben) beruht die Erfassung der Daten
          auf Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich
          daraus, dass der Websitebetreiber ein Interesse an der anonymisierten
          Analyse der Websitebesucher zu Werbezwecken hat.
        </p>
        <p className="pb-2">
          Weitergehende Informationen und die Datenschutzbestimmungen finden Sie
          in der Datenschutzerklärung von Google unter:
          <a
            href="https://www.google.com/policies/technologies/ads/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/policies/technologies/ads/
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">
          Google AdWords und Google Conversion-Tracking
        </h3>
        <p className="pb-2">
          Diese Website verwendet Google AdWords. AdWords ist ein
          Online-Werbeprogramm der Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, United States (“Google”).
        </p>
        <p className="pb-2">
          Im Rahmen von Google AdWords nutzen wir das so genannte
          Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige
          klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei
          Cookies handelt es sich um kleine Textdateien, die der
          Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies
          verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
          persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte
          Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können
          Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat
          und zu dieser Seite weitergeleitet wurde.
        </p>
        <p className="pb-2">
          Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies
          können nicht über die Websites von AdWords-Kunden nachverfolgt werden.
          Die mithilfe des Conversion-Cookies eingeholten Informationen dienen
          dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
          für Conversion-Tracking entschieden haben. Die Kunden erfahren die
          Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
          einer mit einem Conversion-Tracking-Tag versehenen Seite
          weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit
          denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am
          Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen,
          indem Sie das Cookie des Google Conversion-Trackings über ihren
          Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie
          werden sodann nicht in die Conversion-Tracking Statistiken
          aufgenommen.
        </p>
        <p className="pb-2">
          Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von
          Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
          Webangebot als auch seine Werbung zu optimieren.
        </p>
        <p className="pb-2">
          Mehr Informationen zu Google AdWords und Google Conversion-Tracking
          finden Sie in den Datenschutzbestimmungen von Google:
          <a
            href="https://www.google.de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.de/policies/privacy/
          </a>
          .
        </p>
        <p className="pb-2">
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browser
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">6. Newsletter</h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">Newsletterdaten</h3>
        <p className="pb-2">
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen,
          welche uns die Überprüfung gestatten, dass Sie der Inhaber der
          angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
          einverstanden sind. Weitere Daten werden nicht bzw. nur auf
          freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
          für den Versand der angeforderten Informationen und geben diese nicht
          an Dritte weiter.
        </p>
        <p className="pb-2">
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
          Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
          Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
          Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
          Newsletters können Sie jederzeit widerrufen, etwa über den
          &rdquo;Austragen&rdquo;-Link im Newsletter. Die Rechtmäßigkeit der
          bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
          unberührt.
        </p>
        <p className="pb-2">
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
          gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten,
          die zu anderen Zwecken bei uns gespeichert wurden (z.B.
          E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">CleverReach</h3>
        <p className="pb-2">
          Diese Website nutzt CleverReach für den Versand von Newslettern.
          Anbieter ist die CleverReach GmbH & Co. KG, Mühlenstr. 43, 26180
          Rastede. CleverReach ist ein Dienst, mit dem der Newsletterversand
          organisiert und analysiert werden kann. Die von Ihnen zwecks
          Newsletterbezug eingegebenen Daten (z.B. E-Mail-Adresse) werden auf
          den Servern von CleverReach in Deutschland bzw. Irland gespeichert.
        </p>
        <p className="pb-2">
          Unsere mit CleverReach versandten Newsletter ermöglichen uns die
          Analyse des Verhaltens der Newsletterempfänger. Hierbei kann u. a.
          analysiert werden, wie viele Empfänger die Newsletternachricht
          geöffnet haben und wie oft welcher Link im Newsletter angeklickt
          wurde. Mit Hilfe des sogenannten Conversion-Trackings kann außerdem
          analysiert werden, ob nach Anklicken des Links im Newsletter eine
          vorab definierte Aktion (z.B. Kauf eines Produkts auf unserer Website)
          erfolgt ist. Weitere Informationen zur Datenanalyse durch
          CleverReach-Newsletter erhalten Sie unter:
          <a
            href="https://www.cleverreach.com/de/funktionen/reporting-und-tracking/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cleverreach.com/de/funktionen/reporting-und-tracking/
          </a>
          .
        </p>
        <p className="pb-2">
          Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6
          Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit
          widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit
          der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
          unberührt.
        </p>
        <p className="pb-2">
          Wenn Sie keine Analyse durch CleverReach wollen, müssen Sie den
          Newsletter abbestellen. Hierfür stellen wir in jeder
          Newsletternachricht einen entsprechenden Link zur Verfügung. Des
          Weiteren können Sie den Newsletter auch direkt auf der Website
          abbestellen.
        </p>
        <p className="pb-2">
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter
          gespeichert und nach der Abbestellung des Newsletters sowohl von
          unseren Servern als auch von den Servern von CleverReach gelöscht.
          Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B.
          E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
        </p>
        <p className="pb-2">
          Näheres entnehmen Sie den Datenschutzbestimmungen von CleverReach
          unter:
          <a
            href="https://www.cleverreach.com/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cleverreach.com/de/datenschutz/
          </a>
          .
        </p>
        <p className="pb-2">
          <strong>
            Abschluss eines Vertrags über Auftragsdatenverarbeitung
          </strong>
        </p>
        <p className="pb-2">
          Wir haben mit CleverReach einen Vertrag zur Auftragsdatenverarbeitung
          abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbehörden bei der Nutzung von CleverReach vollständig um.
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">7. Plugins und Tools</h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">YouTube</h3>
        <p className="pb-2">
          Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
          Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno,
          CA 94066, USA.
        </p>
        <p className="pb-2">
          Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
          besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt.
          Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
          besucht haben.
        </p>
        <p className="pb-2">
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
          YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
          zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen.
        </p>
        <p className="pb-2">
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
        </p>
        <p className="pb-2">
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von YouTube unter:
          <a
            href="https://www.google.de/intl/de/policies/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.de/intl/de/policies/privacy
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Vimeo</h3>
        <p className="pb-2">
          Unsere Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
          Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
        </p>
        <p className="pb-2">
          Wenn Sie eine unserer mit einem Vimeo-Plugin ausgestatteten Seiten
          besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
          Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
          besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch
          dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei
          Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den
          Vimeo-Server in den USA übermittelt.
        </p>
        <p className="pb-2">
          Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie
          Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen.
          Dies können Sie verhindern, indem Sie sich aus Ihrem Vimeo-Account
          ausloggen.
        </p>
        <p className="pb-2">
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Vimeo unter:
          <a
            href="https://vimeo.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://vimeo.com/privacy
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Google Web Fonts</h3>
        <p className="pb-2">
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </p>
        <p className="pb-2">
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
          Die Nutzung von Google Web Fonts erfolgt im Interesse einer
          einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar.
        </p>
        <p className="pb-2">
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>
        <p className="pb-2">
          Weitere Informationen zu Google Web Fonts finden Sie unter
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{' '}
          und in der Datenschutzerklärung von Google:
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Google Maps</h3>
        <p className="pb-2">
          Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
          ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
          94043, USA.
        </p>
        <p className="pb-2">
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
          Adresse zu speichern. Diese Informationen werden in der Regel an einen
          Server von Google in den USA übertragen und dort gespeichert. Der
          Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
        </p>
        <p className="pb-2">
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar.
        </p>
        <p className="pb-2">
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Google:
          <a
            href="https://www.google.de/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.de/intl/de/policies/privacy/
          </a>
          .
        </p>
        <h2 className="text-2xl pt-4 font-bold pb-4">8. Zahlungsanbieter</h2>
        <h3 className="text-2xl pt-4 font-bold pb-4">PayPal</h3>
        <p className="pb-2">
          Auf unserer Website bieten wir u.a. die Bezahlung via PayPal an.
          Anbieter dieses Zahlungsdienstes ist die PayPal (Europe) S.à.r.l. et
          Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (im Folgenden
          “PayPal”).
        </p>
        <p className="pb-2">
          Wenn Sie die Bezahlung via PayPal auswählen, werden die von Ihnen
          eingegebenen Zahlungsdaten an PayPal übermittelt.
        </p>
        <p className="pb-2">
          Die Übermittlung Ihrer Daten an PayPal erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
          (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die
          Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu
          widerrufen. Ein Widerruf wirkt sich auf die Wirksamkeit von in der
          Vergangenheit liegenden Datenverarbeitungsvorgängen nicht aus.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Klarna</h3>
        <p className="pb-2">
          Auf unserer Website bieten wir u.a. die Bezahlung mit den Diensten von
          Klarna an. Anbieter ist die Klarna AB, Sveavägen 46, 111 34 Stockholm,
          Schweden (im Folgenden “Klarna”).
        </p>
        <p className="pb-2">
          Klarna bietet verschiedene Zahlungsoptionen an (z.B. Ratenkauf). Wenn
          Sie sich für die Bezahlung mit Klarna entscheiden
          (Klarna-Checkout-Lösung), wird Klarna verschiedene personenbezogene
          Daten von Ihnen erheben. Details hierzu können Sie in der
          Datenschutzerklärung von Klarna unter folgendem Link nachlesen:
          <a
            href="https://www.klarna.com/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.klarna.com/de/datenschutz/
          </a>
          .
        </p>
        <p className="pb-2">
          Klarna nutzt Cookies, um die Verwendung der Klarna-Checkout-Lösung zu
          optimieren. Die Optimierung der Checkout-Lösung stellt ein
          berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
          Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert
          werden und keinen Schaden anrichten. Sie verbleiben auf Ihrem Endgerät
          bis Sie sie löschen. Details zum Einsatz von Klarna-Cookies entnehmen
          Sie folgendem Link:
          <a
            href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf
          </a>
          .
        </p>
        <p className="pb-2">
          Die Übermittlung Ihrer Daten an Klarna erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b DSGVO
          (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die
          Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu
          widerrufen. Ein Widerruf wirkt sich auf die Wirksamkeit von in der
          Vergangenheit liegenden Datenverarbeitungsvorgängen nicht aus.
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Sofortüberweisung</h3>
        <p className="pb-2">
          Auf unserer Website bieten wir u.a. die Bezahlung mittels
          “Sofortüberweisung” an. Anbieter dieses Zahlungsdienstes ist die
          Sofort GmbH, Theresienhöhe 12, 80339 München (im Folgenden “Sofort
          GmbH”).
        </p>
        <p className="pb-2">
          Mit Hilfe des Verfahrens “Sofortüberweisung” erhalten wir in Echtzeit
          eine Zahlungsbestätigung von der Sofort GmbH und können unverzüglich
          mit der Erfüllung unserer Verbindlichkeiten beginnen.
        </p>
        <p className="pb-2">
          Wenn Sie sich für die Zahlungsart “Sofortüberweisung” entschieden
          haben, übermitteln Sie die PIN und eine gültige TAN an die Sofort
          GmbH, mit der diese sich in Ihr Online-Banking-Konto einloggen kann.
          Sofort GmbH überprüft nach dem Einloggen automatisch Ihren Kontostand
          und führt die Überweisung an uns mit Hilfe der von Ihnen übermittelten
          TAN durch. Anschließend übermittelt sie uns unverzüglich eine
          Transaktionsbestätigung. Nach dem Einloggen werden außerdem Ihre
          Umsätze, der Kreditrahmen des Dispokredits und das Vorhandensein
          anderer Konten sowie deren Bestände automatisiert geprüft.
        </p>
        <p className="pb-2">
          Neben der PIN und der TAN werden auch die von Ihnen eingegebenen
          Zahlungsdaten sowie Daten zu Ihrer Person an die Sofort GmbH
          übermittelt. Bei den Daten zu Ihrer Person handelt es sich um Vor- und
          Nachname, Adresse, Telefonnummer(n), Email-Adresse, IP-Adresse und
          ggf. weitere zur Zahlungsabwicklung erforderliche Daten. Die
          Übermittlung dieser Daten ist notwendig, um Ihre Identität
          zweifelsfrei zu festzustellen und Betrugsversuchen vorzubeugen.
        </p>
        <p className="pb-2">
          Die Übermittlung Ihrer Daten an die Sofort GmbH erfolgt auf Grundlage
          von Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) und Art. 6 Abs. 1 lit. b
          DSGVO (Verarbeitung zur Erfüllung eines Vertrags). Sie haben die
          Möglichkeit, Ihre Einwilligung zur Datenverarbeitung jederzeit zu
          widerrufen. Ein Widerruf wirkt sich auf die Wirksamkeit von in der
          Vergangenheit liegenden Datenverarbeitungsvorgängen nicht aus.
        </p>
        <p className="pb-2">
          Details zur Zahlung mit Sofortüberweisung entnehmen Sie folgenden
          Links:
          <a
            href="https://www.sofort.de/datenschutz.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.sofort.de/datenschutz.html
          </a>{' '}
          und
          <a
            href="https://www.klarna.com/sofort/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.klarna.com/sofort/
          </a>
          .
        </p>
        <h3 className="text-2xl pt-4 font-bold pb-4">Paydirect</h3>
        <p className="pb-2">
          Auf unserer Website bieten wir u.a. die Bezahlung mittels Paydirekt
          an. Anbieter dieses Zahlungsdienstes ist die Paydirekt GmbH, Hamburger
          Allee 26-28, 60486 Frankfurt am Main, Deutschland (im Folgenden
          “Paydirekt”).
        </p>
        <p className="pb-2">
          Wenn Sie die Bezahlung mittels Paydirekt ausführen, erhebt Paydirekt
          verschiedene Transaktionsdaten und leitet diese an die Bank weiter,
          bei der Sie mit Paydirekt registriert sind. Neben den für die Zahlung
          erforderlichen Daten erhebt Paydirekt im Rahmen der
          Transaktionsabwicklung ggf. weitere Daten wie z.B. Lieferadresse oder
          einzelne Positionen im Warenkorb.
        </p>
        <p className="pb-2">
          Paydirect authentifiziert die Transaktion anschließend mit Hilfe des
          bei der Bank hierfür hinterlegten Authentifizierungsverfahrens.
          Anschließend wird der Zahlbetrag von Ihrem Konto auf unser Konto
          überwiesen. Weder wir noch Dritte haben Zugriff auf Ihre Kontodaten.
        </p>
        <p className="pb-2">
          Details zur Zahlung mit Paydirekt entnehmen Sie den AGB und den
          Datenschutzbestimmungen von Paydirekt unter:
          <a
            href="https://www.paydirekt.de/agb/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.paydirekt.de/agb/index.html
          </a>
          .
        </p>

        <h3 className="text-2xl pt-4 font-bold pb-4">Smartlook</h3>
        <p className="pb-2">
          Diese Website nutzt Funktionen des Webanalysedienstes Smartlook.
          Anbieter ist die Smartlook, Lidicka 2030/20 602 00 Brno, Czech
          Republic (European Union).
        </p>
        <p className="pb-2">
          Sie können die Erfassung Ihrer Daten durch Smartlook verhindern, indem
          Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt,
          der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website
          verhindert:
          <a
            href="https://www.smartlook.com/de/opt-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            Smartlook deaktivieren
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default GeneralLayout(Privacy);
