import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto pb-10 md:px-20 px-10 min-h-screen-80">
      <h1 className="font-bold text-primary text-3xl py-4">
        {t('PRICING_LIST')}
      </h1>
      <hr />
      <table className="w-full border table-auto mt-10">
        <tbody>
          <tr className="h-10 font-bold">
            <td className="border-r pl-2">
              {t('PRICING_LIST_BASIC_FUNCTION')}
            </td>
            <td className="pl-2" />
          </tr>
          <tr className="bg-grey-2 py-4 h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_USER_ACCOUNT')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_OFFER_POST')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_OFFER_CHANGE')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">
              {t('PRICING_LIST_BID_SUBMISSION')}
            </td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_BID_REJECTION')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">
              {t('PRICING_LIST_BID_AND_PRICE_SUBMISSION')}
            </td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">
              {t('PRICING_LIST_BIDDER_TRANSACTION')}
            </td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10 opacity-90">
            <td className="border-r pl-2 pr-2 lg:whitespace-no-wrap">
              {t('PRICING_LIST_ADVERTISER_TRANSACTION_LABEL')}
            </td>
            <td className="pl-2">
              {t('PRICING_LIST_ADVERTISER_TRANSACTION_VALUE')}
            </td>
          </tr>
          <tr className="bg-grey-2 h-10 font-bold">
            <td className="border-r pl-2">
              {t('PRICING_LIST_INDIVIDUALIZATION')}
            </td>
            <td className="pl-2" />
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_WHITELIST')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_BLACKLIST')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">{t('PRICING_LIST_FAVORITES')}</td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10 font-bold">
            <td className="border-r pl-2">{t('PRICING_LIST_MSV3_ORDER')}</td>
            <td className="pl-2" />
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">
              {t('PRICING_LIST_ORDER_BY_MSV3_INTERFACE')}
            </td>
            <td className="pl-2">{t('PRICING_LIST_MSV3_ORDER_PRICE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">
              <s>{t('PRICING_LIST_MSV3_AUTOMATIC')}</s>
            </td>
            <td className="pl-2">
              <s>{t('PRICING_LIST_MSV3_AUTOMATIC_PRICE')}</s>
            </td>
          </tr>
          <tr className="h-10 font-bold">
            <td className="border-r pl-2">{t('PRICING_LIST_SALES_SUPPORT')}</td>
            <td className="pl-2" />
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">
              {t('PRICING_LIST_PRODUCT_FINDER')}
            </td>
            <td className="pl-2">{t('PRICING_LIST_CHARGE_FREE')}</td>
          </tr>
          <tr className="h-10 font-bold">
            <td className="border-r pl-2">{t('PRICING_LIST_FINE_PRINT')}</td>
            <td className="pl-2" />
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">
              {t('PRICING_TERM_OF_NOTICE_LABEL')}
            </td>
            <td className="pl-2">{t('PRICING_TERM_OF_NOTICE_VALUE')}</td>
          </tr>
          <tr className="h-10">
            <td className="border-r pl-2">
              {t('PRICING_PAYMENT_TERMS_LABEL')}
            </td>
            <td className="pl-2">{t('PRICING_PAYMENT_TERMS_VALUE')}</td>
          </tr>
          <tr className="bg-grey-2 h-10">
            <td className="border-r pl-2">
              {t('PRICING_PAYMENT_METHOD_LABEL')}
            </td>
            <td className="pl-2">{t('PRICING_PAYMENT_METHOD_VALUE')}</td>
          </tr>
        </tbody>
      </table>
      <h3 className="pt-10 font-bold opacity-90">{t('PRICING_WARNING')}</h3>
      <p className="pt-2 font-light text-sm">{t('PRICE_STATUS')}</p>
    </div>
  );
};

export default GeneralLayout(Pricing);
