import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PasswordResetFieldsState,
  RegistrationFormType,
} from '../../../globals/models/components/registrationForms';
import TextInput from '../../Shared/layouts/TextInput';
import { InputChangeState } from '../../../globals/models/components/textInput';
import useEnterKeyListener from '../../Shared/hooks/useEnterKeyListener';
import { passwordResetValidationSchema } from '../../../shared/validators/registration.schemas';

export const passwordResetInitialValues = {
  email: '',
};

const PasswordResetFields = ({
  initialValues,
  onChange,
  handleSubmit,
  handleNavigation,
}: PasswordResetFieldsProps) => {
  const { t } = useTranslation();
  useEnterKeyListener({
    querySelectorToExecuteClick: '#pwd-reset-submit-button',
  });

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validateOnChange
      initialValues={initialValues}
      validationSchema={passwordResetValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
        } = props;

        const isFormTouched = Object.values(touched).length > 0;
        const isFormValid =
          isValid && Object.values(errors).length === 0 && isFormTouched;

        return (
          <div>
            <div className="pb-8">
              <TextInput
                forText="email"
                isRequired
                labelKey={t('REGISTRATION_FORM_LOGIN_EMAIL_TEXT')}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.email && errors.email
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.email}
              />
            </div>
            <div className="grid grid-cols-2">
              <p className="md:mt-0 mt-4">
                <button
                  type="button"
                  className="text-green-600 font-bold hover:underline pt-2 focus:outline-none"
                  onClick={() => {
                    handleNavigation();
                    onChange(passwordResetInitialValues);
                  }}
                >
                  {t('PASSWORD_RESET_SWITCH_BACK')}
                </button>
              </p>
              <p className="text-right md:mt-0 mt-6">
                <button
                  id="pwd-reset-submit-button"
                  type="button"
                  className={`text-white text-lg p-2 rounded-sm px-4 ${
                    isFormValid
                      ? 'cursor-pointer bg-green-600'
                      : 'cursor-not-allowed bg-green-400'
                  }`}
                  onClick={isFormValid ? () => handleSubmit(values) : undefined}
                >
                  {t('PASSWORD_RESET_BUTTON_TEXT')}
                </button>
              </p>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface PasswordResetFieldsProps {
  initialValues: PasswordResetFieldsState;
  onChange: (values: PasswordResetFieldsState) => void;
  handleSubmit: (fields: PasswordResetFieldsState) => void;
  handleNavigation: (to?: RegistrationFormType | null) => void;
}

export default PasswordResetFields;
