import React, { ComponentType } from 'react';

function Msv3Layout(Component: ComponentType<any>) {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    return (
      <>
        <main className="general-layout">
          <Component {...props} />
        </main>
      </>
    );
  };
}

export default Msv3Layout;
