/* eslint-disable react/display-name */
import React, { ComponentType } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const RecaptchaProvider = (Component: ComponentType<any>) => (props: any) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    language="en"
    scriptProps={{
      async: true,
      defer: true,
      appendTo: 'body',
    }}
  >
    <Component {...props} />
  </GoogleReCaptchaProvider>
);

export default RecaptchaProvider;
