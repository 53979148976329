import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';
import MSV3DeliveryAmountPicker from './MSV3DeliveryAmountPicker';
import { ArticleOfferI } from '../../../../../globals/models/components/dataTable';
import { MSV3CheckoutSteps } from '../../../../../shared/constants/dataTable';
import CheckboxInput from '../../../../Shared/layouts/CheckBoxInput';

const DeliveryDetails: React.FC<DeliveryDetailsProps> = ({
  data,
  setDesiredAmounts,
  desiredAmounts,
  articleOffer,
  currentPageLimitMeta,
  metaOffset,
  errorMessages,
  currentStep,
  goNext,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  // eslint-disable-next-line
  let data_ =
    data.length !== 0
      ? data.slice(metaOffset, metaOffset + currentPageLimitMeta)
      : [];

  if (currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING) {
    data_ = data_.filter(
      (el) =>
        desiredAmounts[el.providerId].touched &&
        desiredAmounts[el.providerId].amount !== '0',
    );
  }

  const [hasChecked, setHasChecked] = useState(false);
  const [disable, setDisabled] = useState(false);

  const proceedButtonDisabledState = !(
    (hasChecked || Object.values(errorMessages).some((el) => Boolean(el))) &&
    data_.some((el) => desiredAmounts[el.providerId].amount !== '0')
  );

  return (
    <>
      <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-sm m-6 hide-scrollbar modal-list-table">
        <span className="opacity-0">&nbsp;</span>
        <table
          className="min-w-full divide-y divide-gray-200 table-fixed border"
          key={Math.random()}
        >
          <thead className="bg-grey-light">
            <tr>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
              >
                {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.PROVIDER NAME')}
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
              >
                PZN
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
              >
                {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DELIVERABLE_AMOUNT')}
              </th>
              <th
                scope="col"
                className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
              >
                {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DESIRED_AMOUNT')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data_.map((el, index) =>
              el.deliverableAmount ? (
                <tr
                  key={Math.random()}
                  className="odd:bg-grey-2 text-gray-800 text-base h-10"
                >
                  <td className="text-center whitespace-no-wrap">
                    {el.partnerName}
                  </td>
                  <td className="text-center whitespace-no-wrap">
                    {articleOffer.attributes.pzn}
                  </td>
                  <td className="text-center whitespace-no-wrap">
                    {el.deliverableAmount
                      ? el.deliverableAmount.toLocaleString(i18n.language)
                      : ''}
                  </td>

                  <td className="text-center whitespace-no-wrap">
                    <MSV3DeliveryAmountPicker
                      key={Math.random()}
                      emitNextValue={(value) => {
                        setDesiredAmounts({
                          ...desiredAmounts,
                          [el.providerId]: {
                            amount: value,
                            pzn: articleOffer.attributes.pzn,
                            deliverableAmount: el.deliverableAmount as number,
                            touched: Boolean(value),
                          },
                        });
                      }}
                      initialValue={desiredAmounts[el.providerId].amount}
                      errorMessage={errorMessages[el.providerId]}
                      disabled={
                        currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING
                      }
                    />
                  </td>
                </tr>
              ) : (
                ''
              ),
            )}
          </tbody>
        </table>
        <div className="mt-6">
          <CheckboxInput
            labelText={t('MSV3.CHECKOUT.APPROVE_CHECKBOX')}
            onChange={(value) => {
              setHasChecked(value);
            }}
          />
        </div>
        {/* <div className="mt-6"> */}
        {/*  {t(' In welchem Intervall soll beim Großhändler geprüft werden?')} */}
        {/* </div> */}
        {/* <div className="grid grid-cols-2 mt-2"> */}
        {/*  <Select */}
        {/*    id="article-status" */}
        {/*    className="w-full h-8 bg-white border-1 border-gray-400 rounded-sm px-1 input" */}
        {/*    disableUnderline */}
        {/*    native */}
        {/*    disabled */}
        {/*    defaultValue={INTERVAL[0].submitValue} */}
        {/*    // onChange={} */}
        {/*    // value={inputsState.article_location_country} */}
        {/*  > */}
        {/*    {INTERVAL.map((el) => ( */}
        {/*      <option */}
        {/*        value={el.submitValue} */}
        {/*        key={el.submitValue} */}
        {/*        className="cursor-pointer hover:bg-grey-4 px-2" */}
        {/*      > */}
        {/*        {t(el.displayValue)} */}
        {/*      </option> */}
        {/*    ))} */}
        {/*  </Select> */}
        {/* </div> */}
        <div className="text-right mt-10">
          <Tooltip
            title={
              proceedButtonDisabledState
                ? (t('MSV3.CHECKOUT.TIPS.BUY_BUTTON') as string)
                : ''
            }
          >
            <button
              type="button"
              className={`button ${
                proceedButtonDisabledState
                  ? 'c-no-drop bg-primary-disabled'
                  : ''
              }`}
              onClick={() => {
                if (!proceedButtonDisabledState) {
                  goNext();
                }
                if (currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING) {
                  setDisabled(true);
                }
              }}
              disabled={disable}
            >
              {currentStep === MSV3CheckoutSteps.PREFERRED_AMOUNTS &&
                t('MSV3.CHECKOUT.PROCEED_BUTTON')}
              {currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING &&
                t('MSV3.CHECKOUT.BUY_NOW_BUTTON')}
              {isLoading && (
                <CircularProgress
                  style={{ color: 'white' }}
                  size={15}
                  className="ml-2"
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      {data.length === 0 && (
        <div className="w-full h-10 flex flex-row justify-center whitespace-no-wrap">
          <div>
            {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.NO_DELIVERABLES')}
          </div>
        </div>
      )}
    </>
  );
};

interface DeliveryDetailsProps {
  data: {
    partnerName: string;
    deliverableAmount: number;
    providerId: string;
  }[];
  setDesiredAmounts: (nextValue: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount: number;
      touched: boolean;
    };
  }) => void;
  desiredAmounts: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount: number;
      touched: boolean;
    };
  };
  articleOffer: ArticleOfferI;
  metaOffset: number;
  errorMessages: {
    [key: string]: string | undefined;
  };
  currentPageLimitMeta: number;
  currentStep: MSV3CheckoutSteps;
  goNext: () => void;
  isLoading: boolean;
}

export default DeliveryDetails;
