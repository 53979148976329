/* eslint-disable @typescript-eslint/indent,no-case-declarations */
import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  CreateAddressActionI,
  DeleteAddressActionI,
  EditAddressActionI,
  GetAddressesActionI,
  GetUnreadMessagesNumberActionI,
  GetUserMessagesActionI,
  GetUserProfileActionI,
  GetUserSettingsActionI,
  ProfileActions,
  ProfileErrorActionI,
  SetAddressEditorIsLoadingActionI,
  SetIsMessageReadActionI,
  SetMessagesListIsLoadingActionI,
  SetMSV3DailyLimitActionI,
  SetUserSettingsActionI,
} from '../../globals/models/actions/profile';
import { ProfileState } from '../../globals/models/redux';
import { ProfileActionTypes } from '../actions/types/profile';
import { AddressType } from '../../globals/models/components/addresses';

export const profileReducer: Reducer<ProfileState, ProfileActions> = (
  state = initialState.profileState,
  action,
) => {
  switch (action.type) {
    case ProfileActionTypes.GET_ADDRESSES:
      return {
        ...state,
        addresses: {
          ...(action as GetAddressesActionI).payload,
          isLayoutLoading: false,
          isEditorLoading: false,
        },
      };

    case ProfileActionTypes.PROFILE_ERROR:
      console.log(action.payload);
      // toast.error(i18next.t((action as ProfileErrorActionI).payload));
      return {
        ...state,
        errorMessage: (action as ProfileErrorActionI).payload,
        addresses: {
          ...state.addresses,
          isLayoutLoading: false,
          isEditorLoading: false,
        },
        isLoadingMessages: false,
      };

    case ProfileActionTypes.CREATE_ADDRESS:
      if (
        (action as CreateAddressActionI).payload.type === AddressType.DELIVERY
      ) {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            delivery: [...state.addresses.delivery, action.payload.address],
            isEditorLoading: false,
            isLayoutLoading: false,
          },
        };
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing: [...state.addresses.billing, action.payload.address],
          isEditorLoading: false,
          isLayoutLoading: false,
        },
      };

    case ProfileActionTypes.EDIT_ADDRESS:
      if (
        (action as EditAddressActionI).payload.type === AddressType.DELIVERY
      ) {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            delivery: [
              ...state.addresses.delivery.filter(
                (el) => el.id !== action.payload.address.id,
              ),
              action.payload.address,
            ],
            isEditorLoading: false,
            isLayoutLoading: false,
          },
        };
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing: [
            ...state.addresses.billing.filter(
              (el) => el.id !== action.payload.address.id,
            ),
            action.payload.address,
          ],
          isEditorLoading: false,
          isLayoutLoading: false,
        },
      };

    case ProfileActionTypes.DELETE_ADDRESS:
      if (
        (action as DeleteAddressActionI).payload.type === AddressType.DELIVERY
      ) {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            delivery: [
              ...state.addresses.delivery.filter(
                (el) => el.id !== action.payload.address.id,
              ),
            ],
            isEditorLoading: false,
            isLayoutLoading: false,
          },
        };
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing: [
            ...state.addresses.billing.filter(
              (el) => el.id !== action.payload.address.id,
            ),
          ],
          isEditorLoading: false,
          isLayoutLoading: false,
        },
      };

    case ProfileActionTypes.SET_ADDRESS_EDITOR_IS_LOADING:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isEditorLoading: (action as SetAddressEditorIsLoadingActionI).payload,
        },
      };

    case ProfileActionTypes.SET_IS_PROFILE_LOADING:
      return { ...state, isLoading: false };

    case ProfileActionTypes.GET_USER_DETAILS:
      return {
        ...state,
        userProfile: (action as GetUserProfileActionI).payload,
        isLoading: false,
      };

    case ProfileActionTypes.SET_MSV3_DAILY_LIMIT:
      return {
        ...state,
        userProfile: (action as SetMSV3DailyLimitActionI).payload,
      };

    case ProfileActionTypes.GET_USER_MESSAGES:
      return {
        ...state,
        messages: (action as GetUserMessagesActionI).payload,
        isLoadingMessages: false,
      };

    case ProfileActionTypes.SET_MESSAGES_LIST_IS_LOADING:
      return {
        ...state,
        isLoadingMessages: (action as SetMessagesListIsLoadingActionI).payload,
      };

    case ProfileActionTypes.SET_IS_MESSAGE_READ:
      const selectedMessageIndex = (action as SetIsMessageReadActionI).payload.messagesRecords.findIndex(
        (el) =>
          Number(el.id) ===
          (action as SetIsMessageReadActionI).payload.messageId,
      );

      const selectedMessage = (action as SetIsMessageReadActionI).payload
        .messagesRecords[selectedMessageIndex as number];

      const newMessagesRecords = (action as SetIsMessageReadActionI).payload
        .messagesRecords;

      newMessagesRecords[selectedMessageIndex] = {
        ...selectedMessage,
        attributes: {
          ...selectedMessage.attributes,
          unread: false,
        },
      };

      return {
        ...state,
        messages: {
          ...state.messages,
          data: newMessagesRecords,
        },
      };

    case ProfileActionTypes.GET_UNREAD_MESSAGES_NUMBER:
      return {
        ...state,
        messagesNumber: (action as GetUnreadMessagesNumberActionI).payload,
      };

    case ProfileActionTypes.GET_USER_SETTINGS:
      return {
        ...state,
        profileSettings: (action as GetUserSettingsActionI).payload,
      };

    case ProfileActionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        profileSettings: (action as SetUserSettingsActionI).payload,
      };

    default:
      return state;
  }
};
