/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import banner from '../../../../assets/img/Tramedix_Logo.png';
import { toggleRegistrationForm } from '../../../../store/actions/creators/registration';
import { RegistrationState } from '../../../../globals/models/redux';
import { RegistrationFormType } from '../../../../globals/models/components/registrationForms';
import { TRAMEDIX_PHONE_NUMBER } from '../../../../shared/constants/constantChoices';
import { User } from '../../../../globals/models/data/user.model';
import showLicenseInvalidOrExpiredToast from '../../../../shared/helpers/showInvalidOrExpiredLicenseToast';

interface HeaderProps {
  _toggleRegistrationForm: (
    formMeta: RegistrationState['form'],
  ) => { type: string } | undefined;
  currentUser: User | null;
}

const HeaderContent = ({
  _toggleRegistrationForm,
  currentUser,
}: HeaderProps) => {
  const { t } = useTranslation();
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <header>
      <div className="lg:h-14 relative flex flex-wrap items-center justify-between navbar-expand-lg bg-white text-primary lg:text-lg">
        <div className="w-full flex flex-wrap justify-between md:h-full">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start py-4">
            <Link
              to="/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 "
              onClick={() => {
                showLicenseInvalidOrExpiredToast(currentUser);
              }}
            >
              <img src={banner} alt="banner" className="h-8 ml-4" />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none mr-2"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <nav
            className={`lg:flex flex-grow items-center md:h-full ${
              navbarOpen ? ' flex' : ' hidden'
            }`}
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto md:h-full">
              <li className="nav-item">
                <span className="flex flex-col items-center justify-center h-full px-4 lg:py-0 py-1">
                  {TRAMEDIX_PHONE_NUMBER}
                </span>
              </li>
              <li className="nav-item">
                <a
                  className="flex flex-col items-center justify-center hover:opacity-75 h-full px-4 lg:py-0 py-1"
                  href="mailto:info@tramedix.de"
                >
                  <span className="w-full">info@tramedix.de</span>
                </a>
              </li>
              <li className="nav-item">
                <button
                  onClick={() => {
                    _toggleRegistrationForm({
                      type: RegistrationFormType.LOGIN,
                    });
                  }}
                  type="button"
                  className="flex flex-col items-center items-center justify-center hover:opacity-75 h-full pl-4 pr-8 lg:py-0 py-1"
                >
                  <span>
                    <FontAwesomeIcon icon={faUser} /> {t('LOGIN_TEXT')}
                  </span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  id="signupButton"
                  onClick={() => {
                    _toggleRegistrationForm({
                      type: RegistrationFormType.SIGNUP,
                    });
                  }}
                  type="button"
                  className="flex flex-col items-center justify-center hover:opacity-75 lg:bg-primary lg:text-white h-full lg:px-6 px-4 lg:py-0 py-1"
                >
                  {t('SIGNUP_TEXT')}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({ registrationState: { currentUser } }: StoreI) => ({
  currentUser,
});

export default connect(mapStateToProps, {
  _toggleRegistrationForm: toggleRegistrationForm,
})(HeaderContent);
