/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';
import axios, { CancelTokenSource } from 'axios';
import {
  ArticleOfferI,
  DataTableColumnsConfigI,
  DataTableModalOptions,
  DataTablePageE,
  PaginatedArticles,
} from '../../../globals/models/components/dataTable';
import {
  AcceptOrDeclineMatchingActionI,
  AddArticleToShoppingCartActionI,
  DeleteArticleActionI,
  DeleteRelationshipActionI,
  GetSortRefActionI,
  GetTableColumnsConfigActionI,
  GetTransactionsNumberActionI,
  ResetTableActionI,
  SetArticlesActionI,
  SetIsAddingToShoppingCartActionI,
  SetIsContentLoadingActionI,
  SetIsCurrentModalLoadingActionI,
  SetPageSizeActionI,
  SetRelationshipActionI,
  SetSelectedRowsActionI,
  SetSortRefActionI,
  SetTableModalActionI,
  StartSubsetOrBidActionI,
} from '../../../globals/models/actions/dataTable';
import server from '../../../services/axios.service';
import i18next from '../../../i18n';

import { DataTableActionTypes } from '../types/dataTable';
import { DEFAULT_COLUMNS_CONFIG } from '../../../shared/constants/dataTable';
import store from '../../index';
import { ProposalI } from '../../../globals/models/data/article.model';
import { DataTableShellActionTypes } from '../types/dataTableShell';
import { ShoppingCartActionTypes } from '../types/shoppingCart';
import { getMSV3PznSuggestions } from './MSV3';
import { Relationship } from '../../../globals/models/data/relationship';
import { MSV3pznReqObjectI } from '../../../globals/models/data/msv3.model';

/**
 * A wrapper for the display text of column names
 * @param {Array} configuration - The configuration to wrap
 */
const wrapConfigWithDisplayText = (
  configuration: DataTableColumnsConfigI[],
) => {
  return DEFAULT_COLUMNS_CONFIG.map((el) => ({
    ...el,
    ...configuration.find((config) => config.config_id === el.config_id),
  }));
};

export const getDataTableColumnsConfig: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: DataTableColumnsConfigI[] | string }>,
  { type: string; payload: DataTableColumnsConfigI[] | string },
  number,
  GetTableColumnsConfigActionI
>> = (userId: number) => async (dispatch: Dispatch) => {
  try {
    const {
      data: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: { data_table },
      },
    } = await server.get(`/api/v1/users/${userId}/configs/`, {
      headers: {
        Authorization: `Bearer ${
          store.getState().registrationState.tokens.access
        }`,
      },
    });
    return dispatch({
      type: DataTableActionTypes.GET_COLUMNS_CONFIG,
      payload:
        data_table.length !== 0
          ? wrapConfigWithDisplayText(data_table as DataTableColumnsConfigI[])
          : DEFAULT_COLUMNS_CONFIG,
    });
  } catch (e) {
    dispatch({
      type: DataTableActionTypes.GET_COLUMNS_CONFIG,
      payload: DEFAULT_COLUMNS_CONFIG,
    });
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('GET_COLUMNS_CONFIG_ERROR'),
    });
  }
};

export const setDataTableColumnsConfig: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: DataTableColumnsConfigI[] }>,
  { type: string; payload: DataTableColumnsConfigI[] },
  any,
  GetTableColumnsConfigActionI
>> = (nextConfig: DataTableColumnsConfigI[], userId: number) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: DataTableActionTypes.GET_COLUMNS_CONFIG,
    payload: wrapConfigWithDisplayText(nextConfig),
  });
  dispatch({
    type: DataTableActionTypes.SET_IS_LAYOUT_LOADING,
    payload: true,
  });
  dispatch({
    type: DataTableActionTypes.SET_IS_CONTENT_LOADING,
    payload: true,
  });
  dispatch({
    type: ShoppingCartActionTypes.SET_IS_LAYOUT_LOADING,
    payload: true,
  });

  try {
    await server.put(`/api/v1/users/${userId}/configs/`, {
      config: nextConfig,
    });

    dispatch({
      type: DataTableActionTypes.SET_IS_LAYOUT_LOADING,
      payload: false,
    });
    dispatch({
      type: ShoppingCartActionTypes.SET_IS_LAYOUT_LOADING,
      payload: false,
    });
    return dispatch({
      type: DataTableActionTypes.SET_IS_CONTENT_LOADING,
      payload: false,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('SET_COLUMNS_CONFIG_ERROR'),
    });
  }
};

const getArticlesRequestTokenSource: CancelTokenSource[] = [];
let msv3CancelToken: CancelTokenSource | null = null;

export const cancelLastGetArticlesRequest = () => {
  getArticlesRequestTokenSource.forEach((el) => {
    el.cancel();
    getArticlesRequestTokenSource.shift();
  });

  if (msv3CancelToken) {
    msv3CancelToken.cancel('CAMCEL_REQUEST');
    msv3CancelToken = null;
  }
};

export const getArticles: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: PaginatedArticles }>,
  { type: string; payload: PaginatedArticles },
  any,
  SetArticlesActionI
>> = (
  queryParams: string,
  currentDTPage: DataTablePageE,
  shouldSetLayout?: boolean,
) => async (dispatch: Dispatch) => {
  const getUrlSuffix = () => {
    if (
      currentDTPage === DataTablePageE.OFFERS ||
      currentDTPage === DataTablePageE.SEARCH
    ) {
      return 'articles';
    }
    return 'purchase-requests';
  };

  const getQuerySuffix = () => {
    if (
      currentDTPage === DataTablePageE.OFFERS ||
      currentDTPage === DataTablePageE.SEARCH
    ) {
      return 'filter[user_data]=True&filter[is_active]=False';
    }
    return 'filter[user_data]=False&filter[is_active]=True';
  };

  const tokenSource = axios.CancelToken.source();

  getArticlesRequestTokenSource.push(tokenSource);

  try {
    const { data } = await server.get<PaginatedArticles>(
      `/api/v1/${getUrlSuffix()}/?${queryParams}&${getQuerySuffix()}`,
      {
        headers: {
          Authorization: `Bearer ${
            store.getState().registrationState.tokens.access
          }`,
        },
        cancelToken: tokenSource.token,
      },
    );

    if (shouldSetLayout) {
      dispatch({
        type: DataTableActionTypes.SET_IS_LAYOUT_LOADING,
        payload: false,
      });
    }

    dispatch({
      type: DataTableShellActionTypes.SET_TABLE_PAGE_INDEX,
      payload: Math.floor(
        Number(data.meta?.pagination.offset) /
          Number(data.meta?.pagination.limit),
      ),
    });

    getArticlesRequestTokenSource.shift();

    msv3CancelToken = axios.CancelToken.source();

    if (data && data.data.length !== 0) {
      const pznList: MSV3pznReqObjectI[] = data.data.map((el) => ({
        amount: el.attributes.piece_amount,
        pzn: el.attributes.pzn,
        name: el.attributes.product_name,
      }));
      getMSV3PznSuggestions(
        pznList,
        false,
        msv3CancelToken.token,
      )(dispatch)
        .then(() => {
          msv3CancelToken = null;
        })
        .catch(() => {
          msv3CancelToken = null;
        });
    }

    return dispatch({
      type: DataTableActionTypes.SET_ARTICLES,
      payload: data,
    });
  } catch (e) {
    if (e.constructor.name !== 'Cancel') {
      return dispatch({
        type: DataTableActionTypes.TABLE_ERROR,
        payload: i18next.t('GET_ARTICLES_ERROR'),
      });
    }

    return {
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('GET_ARTICLES_ERROR'),
    };
  }
};

export const setIsContentLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsContentLoadingActionI
>> = (isLoading: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_IS_CONTENT_LOADING,
    payload: isLoading,
  });
};

export const setIsLayoutLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsContentLoadingActionI
>> = (isLoading: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_IS_LAYOUT_LOADING,
    payload: isLoading,
  });
};

export const setPageSize: ActionCreator<ThunkAction<
  { type: string; payload: number },
  { type: string; payload: number },
  boolean,
  SetPageSizeActionI
>> = (pageSize: number) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_PAGE_SIZE,
    payload: pageSize,
  });
};

export const setArticles: ActionCreator<ThunkAction<
  { type: string; payload: PaginatedArticles },
  { type: string; payload: PaginatedArticles },
  ArticleOfferI[],
  SetArticlesActionI
>> = (data: ArticleOfferI[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_PAGE_SIZE,
    payload: { data },
  });
};

const wrapArticleDataFromProposalData = (
  attributes: any,
  type: string,
  proposalId: number,
) => {
  // eslint-disable-next-line no-param-reassign
  const returnValue = {
    type,
    id: attributes.article_id,
    attributes: {
      ...attributes,
      product_name: attributes.product_data.name,
      pzn: attributes.product_data.pzn,
      options_type: attributes.proposal_status,
      piece_amount: attributes.new_piece_amount, // TODO: Alter after the endpoint has been refactored
      price: attributes.new_price_per_piece, // TODO: Alter after the endpoint has been refactored
    },
    proposal: { id: proposalId },
  } as any;
  returnValue.attributes.calculated_aek_discount = undefined; // TODO: Remove after the endpoint has been refactored min_piece_amount
  returnValue.attributes.min_piece_amount = undefined; // TODO: Remove after the endpoint has been refactored
  delete returnValue.attributes.product_data;
  return returnValue;
};

const getShoppingCartData = async () => {
  const {
    data: {
      data: {
        attributes: { buying_proposals, selling_proposals },
      },
    },
  } = await server.get('/checkout/shopping-cart/');
  const buyingProposals = await Promise.all(
    buying_proposals.map(async (proposalId: number) => {
      const {
        data: {
          data: { attributes, type, id },
        },
      } = await server.get(`/checkout/proposal/${proposalId}/`);
      return wrapArticleDataFromProposalData(attributes, type, Number(id));
    }),
  );
  const sellingProposals = await Promise.all(
    selling_proposals.map(async (proposalId: number) => {
      const {
        data: {
          data: { attributes, type, id },
        },
      } = await server.get(`/checkout/proposal/${proposalId}/`);
      return wrapArticleDataFromProposalData(attributes, type, Number(id));
    }),
  );

  return { buyingProposals, sellingProposals };
};

export const getShoppingCartDetails: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: PaginatedArticles | string }>,
  { type: string; payload: PaginatedArticles | string },
  null,
  SetArticlesActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const { buyingProposals, sellingProposals } = await getShoppingCartData();

    dispatch({
      type: DataTableActionTypes.SET_IS_LAYOUT_LOADING,
      payload: false,
    });

    return dispatch({
      type: DataTableActionTypes.SET_ARTICLES,
      payload: {
        data: [],
        shoppingCart: { buyingProposals, sellingProposals },
      } as PaginatedArticles,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('SHOPPING_CART_DATA_LOADING_ERROR'),
    });
  }
};

export const resetDataTable: ActionCreator<ThunkAction<
  { type: string },
  { type: string },
  null,
  ResetTableActionI
>> = () => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.RESET_TABLE,
  });
};

export const addItemsToShoppingCart: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleOfferI | string }>,
  { type: string; payload: ArticleOfferI | string },
  ArticleOfferI,
  AddArticleToShoppingCartActionI
>> = (rows: ArticleOfferI[]) => async (dispatch: Dispatch) => {
  try {
    await server.post('/checkout/proposal/mass-options/', {
      data: rows.map((row) => ({ id: row.id })),
    });
    return dispatch({
      type: DataTableActionTypes.ADD_ITEM_TO_SHOPPING_CART,
      payload: rows.map((row) => row),
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('ADD_ITEM_TO_SHOPPING_CART_ERROR'),
    });
  }
};

export const setIsAddingToShoppingCart: ActionCreator<ThunkAction<
  { type: string; payload: { value: boolean; iconRef: number } },
  { type: string; payload: { value: boolean; iconRef: number } },
  any,
  SetIsAddingToShoppingCartActionI
>> = (value: boolean, iconRef: number) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_IS_ADDING_TO_SHOPPING_CART,
    payload: { value, iconRef },
  });
};

export const getTransactionsNumber: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: number }>,
  { type: string; payload: number },
  null,
  GetTransactionsNumberActionI
>> = () => async (dispatch: Dispatch) => {
  const { sellingProposals, buyingProposals } = await getShoppingCartData();
  return dispatch({
    type: DataTableActionTypes.GET_TRANSACTIONS_NUMBER,
    payload: sellingProposals.length + buyingProposals.length,
  });
};

export const startSubsetOrBiddingProcess: ActionCreator<ThunkAction<
  {
    type: string;
    payload: {
      article: ArticleOfferI;
      page: DataTableModalOptions;
      title: string | null;
    };
  },
  {
    type: string;
    payload: {
      article: ArticleOfferI;
      page: DataTableModalOptions;
      title: string | null;
    };
  },
  any,
  StartSubsetOrBidActionI
>> = (
  article: ArticleOfferI,
  page: DataTableModalOptions,
  title: string | null,
) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.START_SUBSET_OR_BID,
    payload: { article, page, title },
  });
};

export const submitASubset: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload: { article: ArticleOfferI } | string;
  }>,
  {
    type: string;
    payload: { article: ArticleOfferI } | string;
  },
  any,
  StartSubsetOrBidActionI
>> = (
  article: ArticleOfferI,
  chosen_quantity: number,
  chosen_price?: number,
) => async (dispatch: Dispatch) => {
  try {
    const {
      attributes: { apu_discount, aep_discount, price },
      id,
    } = article;

    await server.post('/checkout/proposal/', {
      data: {
        attributes: {
          aep_discount,
          apu_discount,
          piece_amount: chosen_quantity,
          article_id: id,
          price_per_piece: chosen_price || price,
          origin_article_id: null,
        },
        type: 'ProposalView',
      },
    });
    return dispatch({
      type: DataTableActionTypes.SUBMIT_SUBSET_OR_BID,
      payload: article,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('SUBSET_SUBMISSION_ERROR'),
    });
  }
};

export const setIsTableModalLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsCurrentModalLoadingActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_IS_CURRENT_MODAL_LOADING,
    payload: value,
  });
};

export const setTableModal: ActionCreator<ThunkAction<
  {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  },
  {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  },
  any,
  SetTableModalActionI
>> = (
  page: DataTableModalOptions | null,
  title: string,
  article?: ArticleOfferI,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_CURRENT_MODAL,
    payload: { page, title, article, maxWidth },
  });
};

export const submitABid: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload: { article: ArticleOfferI } | string;
  } | void>,
  { type: string; payload: PaginatedArticles },
  any,
  StartSubsetOrBidActionI
>> = (
  article: ArticleOfferI,
  chosenData: {
    articleSubset: string;
    unitPrice: string;
    totalPrice: string;
    aepDiscount: string;
    apuDiscount: string;
  },
  // eslint-disable-next-line consistent-return
) => async (dispatch: Dispatch) => {
  try {
    const {
      data: {
        data: {
          attributes: {
            status,
            additional_informations: { remaining_tries },
          },
        },
      },
    } = await server.post('/checkout/proposal/', {
      data: {
        attributes: {
          aep_discount: chosenData.aepDiscount,
          apu_discount: chosenData.apuDiscount,
          piece_amount: chosenData.articleSubset,
          article_id: article.id,
          price_per_piece: chosenData.unitPrice,
          origin_article_id: null,
        },
        type: 'ProposalView',
      },
    });

    if (status === 'DECLINED' || status === 'LIMIT_REACHED') {
      if (Number(remaining_tries) === 0) {
        toast.error(i18next.t('BID_SUBMISSION_PRICE_ERROR_LAST_TRY'), {
          autoClose: false,
        });
        dispatch({
          type: DataTableActionTypes.SUBMIT_BID_DECLINED,
          payload: {
            ...article,
            attributes: {
              ...article.attributes,
              best_offer_limit_reached: true,
            },
          } as ArticleOfferI,
        });
      } else {
        toast.warn(
          i18next.t('BID_SUBMISSION_PRICE_ERROR_FIRST_TRY', {
            remainingTries: remaining_tries,
          }),
          { autoClose: false },
        );
        dispatch({
          type: DataTableActionTypes.SET_IS_CURRENT_MODAL_LOADING,
          payload: false,
        });
      }
    } else {
      return dispatch({
        type: DataTableActionTypes.SUBMIT_SUBSET_OR_BID,
        payload: article,
      });
    }
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('BID_SUBMISSION_ERROR'),
    });
  }
};

export const setSelectedRows: ActionCreator<ThunkAction<
  { type: string; payload: ArticleOfferI[] },
  { type: string; payload: ArticleOfferI[] },
  ArticleOfferI[],
  SetSelectedRowsActionI
>> = (value: ArticleOfferI[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_SELECTED_ROWS,
    payload: value,
  });
};

export const deleteArticle: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleOfferI[] | string }>,
  { type: string; payload: ArticleOfferI[] },
  ArticleOfferI,
  DeleteArticleActionI
>> = (value: ArticleOfferI[]) => async (dispatch: Dispatch) => {
  try {
    await Promise.all(
      value.map(async (articleOffer) => {
        await server.delete(`/api/v1/articles/${articleOffer.id}/`);
      }),
    );
    return dispatch({
      type: DataTableActionTypes.DELETE_ARTICLE,
      payload: value,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const acceptDeclineMatching: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | {
          article: ArticleOfferI;
          proposal: ProposalI;
          status: 'CONFIRMED' | 'DECLINED';
        }
      | string;
  }>,
  {
    type: string;
    payload:
      | {
          article: ArticleOfferI;
          proposal: ProposalI;
          status: 'CONFIRMED' | 'DECLINED';
        }
      | string;
  },
  any,
  AcceptOrDeclineMatchingActionI
>> = (
  value: ArticleOfferI,
  proposal: ProposalI,
  status: 'CONFIRMED' | 'DECLINED',
) => async (dispatch: Dispatch) => {
  try {
    await server.patch(`/checkout/proposal/${proposal.proposal_id}/`, {
      data: {
        type: 'ProposalView',
        attributes: {
          event_type: 'change_status',
          new_status: status,
        },
      },
    });

    return dispatch({
      type: DataTableActionTypes.ACCEPT_OR_DECLINE_MATCHING,
      payload: { article: value, proposal, status },
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const getRelationships: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: Relationship[] }>,
  { type: string; payload: Relationship[] },
  any,
  SetRelationshipActionI
>> = (value: Relationship) => async (dispatch: Dispatch) => {
  const tokenSource = axios.CancelToken.source();
  let path = '';
  if (window.location.pathname.includes('blacklist')) {
    path = 'blacklist-all';
  }
  if (window.location.pathname.includes('whitelist')) {
    path = 'whitelist-all';
  }
  if (window.location.pathname.includes('favorites')) {
    path = 'all-favorites';
  }

  getArticlesRequestTokenSource.push(tokenSource);

  try {
    const { data } = await server.get(`/api/v1/relation/${path}`, {
      headers: {
        Authorization: `Bearer ${
          store.getState().registrationState.tokens.access
        }`,
      },
      cancelToken: tokenSource.token,
    });

    return dispatch({
      type: DataTableActionTypes.SET_RELATIONSHIPS,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: i18next.t('GET_RELATIONSHIP_ERROR'),
    });
  }
};

export const deleteRelationship: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ReadonlySet<number> | string }>,
  { type: string; payload: ReadonlySet<number> },
  any,
  DeleteRelationshipActionI
>> = (value: ReadonlySet<number>) => async (dispatch: Dispatch) => {
  try {
    const rel2 = Array.from(value.values());
    let path = '';
    if (window.location.pathname.includes('blacklist')) {
      path = 'blacklist';
    }
    if (window.location.pathname.includes('whitelist')) {
      path = 'whitelist';
    }
    if (window.location.pathname.includes('favorites')) {
      path = 'favorites';
    }
    await Promise.all(
      rel2.map(async (relationship) => {
        await server.delete(`/api/v1/relation/${relationship}/delete-${path}/`);
      }),
    );
    return dispatch({
      type: DataTableActionTypes.DELETE_RELATIONSHIPS,
      payload: value,
    });
  } catch (e) {
    return dispatch({
      type: DataTableActionTypes.TABLE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setSortRef: ActionCreator<ThunkAction<
  { type: string; payload: { id: string; desc: boolean } },
  { type: string; payload: { id: string; desc: boolean } },
  boolean,
  SetSortRefActionI
>> = (sort: { id: string; desc: boolean }) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.SET_SORT_REF,
    payload: sort,
  });
};

export const getSortRef: ActionCreator<ThunkAction<
  { type: string; payload: { id: string; desc: boolean } },
  { type: string; payload: { id: string; desc: boolean } },
  null,
  GetSortRefActionI
>> = (sort: { id: string; desc: boolean }) => (dispatch: Dispatch) => {
  return dispatch({
    type: DataTableActionTypes.GET_SORT_REF,
    payload: sort,
  });
};
