import React from 'react';
import './App.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import numeral from 'numeral';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import Routes from './routes';

import i18next from './i18n';
import store from './store';

// Date pickers locales
registerLocale('de', de);
setDefaultLocale('en');

// Numeral locales
// Inspired from http://numeraljs.com/#locales
numeral.locale(i18next.language === 'en' ? 'en-gb' : i18next.language);

function App() {
  return (
    <Provider store={store}>
      <CssBaseline />
      <Routes />
      <ToastContainer
        hideProgressBar
        closeButton={false}
        position="bottom-left"
        className="rounded"
      />
    </Provider>
  );
}

export default App;
