/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import CompanyDataFields from './CompanyDataFields';
import CompanyApprovalsFields from './CompanyApprovalsFields';
import {
  CompanyApprovalsFieldsState,
  CompanyDataFieldsState,
  companyRegistrationInitialValues,
  CompanyRegistrationState,
  userSignupFieldsInitialValues,
  UserSignupFieldsState,
} from '../../../globals/models/components/registrationForms';
import useEnterKeyListener from '../../Shared/hooks/useEnterKeyListener';
import { RegistrationState } from '../../../globals/models/redux';
import {
  setErrorMessage,
  setIsLoading,
  signup,
  toggleRegistrationForm,
} from '../../../store/actions/creators/registration';
import banner from '../../../assets/img/Tramedix_Logo.png';
import UserSignupFields from './UserSignupFields';
import { TRAMEDIX_PHONE_NUMBER } from '../../../shared/constants/constantChoices';

// Static translation keys

/**
 * t('REGISTRATION_FORM_ERROR_COMPANY_NAME_SHORT')
 * t('REGISTRATION_FORM_ERROR_COMPANY_NAME_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_FIRST_NAME_SHORT')
 * t('REGISTRATION_FORM_ERROR_FIRST_NAME_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_EMAIL_INVALID')
 * t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_PHONE_NUMBER_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_LAST_NAME_SHORT')
 * t('REGISTRATION_FORM_ERROR_LAST_NAME_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_PRIVACY_POLICY_REQUIRED')
 * t('SIGNUP_SUBMISSION_WAIT_MESSAGE')
 */

function getStepContent(
  stepIndex: number,
  t: (key: string) => string,
  handleDataFieldsChange: (value: CompanyDataFieldsState) => void,
  handleApprovalsFieldsChange: (value: CompanyApprovalsFieldsState) => void,
  setIsFormValid: (isValid: boolean) => void,
  fields: { user: UserSignupFieldsState; company: CompanyRegistrationState },
  successMessage: string | null,
  handleUserSignupSubmit: (fields: UserSignupFieldsState) => void,
  isEditMode: boolean,
  handleNext: () => void,
  setActiveStep: (step: number) => void,
  setIsEditMode: (mode: boolean) => void,
) {
  switch (stepIndex) {
    case 0:
      return (
        <UserSignupFields
          handleSubmit={handleUserSignupSubmit}
          initialValues={fields.user}
          _isEditMode={isEditMode}
        />
      );
    case 1:
      return (
        <CompanyDataFields
          onChange={handleDataFieldsChange}
          value={fields.company.dataFields}
          handleNext={handleNext}
          handleBack={setActiveStep}
          setIsEditMode={setIsEditMode}
        />
      );
    case 2:
      return (
        <CompanyApprovalsFields
          onChange={handleApprovalsFieldsChange}
          value={fields.company.approvalsFields}
          handleBack={setActiveStep}
          handleNext={handleNext}
        />
      );
    default:
      return <p className="pb-4">{t(successMessage as string)}</p>;
  }
}

interface CompanyRegistrationProps {
  successMessage: string | null;
  _toggleRegistrationForm: (
    formMeta: RegistrationState['form'],
  ) => { type: string } | undefined;
  _setErrorMessage: (
    errorMessage: string | null,
  ) => { type: string; payload: string | null };
  _signup: (fields: {
    signup: UserSignupFieldsState;
    company: CompanyRegistrationState;
  }) => Promise<{ type: string; payload: string | null }>;
  errorMessage: string | null;
  isLoading: boolean;
  _setIsLoading: (isLoading: boolean) => { type: string; payload: boolean };
}

const Signup = ({
  successMessage: _storeSuccessMessage,
  _signup,
  _toggleRegistrationForm,
  _setErrorMessage,
  errorMessage,
  isLoading,
  _setIsLoading,
}: CompanyRegistrationProps) => {
  const { t } = useTranslation();
  useEnterKeyListener({
    querySelectorToExecuteClick: '#company-reg-next-button',
  });

  // Functions needed for the stepper
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    t('REGISTRATION_FORM_SIGNUP_TITLE'),
    t('COMPANY_REGISTRATION_MODAL_STEP_1'),
    t('COMPANY_REGISTRATION_MODAL_STEP_2'),
  ];

  const [, setIsFormValid] = useState(false);

  // function to handle the state of the material dialog
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    _toggleRegistrationForm({ type: null });
    _setErrorMessage(null);
    setOpen(false);
  };

  // state management for the data to submit
  const [fields, setFields] = useState({
    user: userSignupFieldsInitialValues,
    company: companyRegistrationInitialValues,
  });
  const [successMessage, setSuccessMessage] = useState(
    'SIGNUP_SUBMISSION_WAIT_MESSAGE',
  );

  const [isEditMode, setIsEditMode] = useState(false);

  if (_storeSuccessMessage && _storeSuccessMessage !== successMessage) {
    setSuccessMessage(_storeSuccessMessage);
  }

  if (errorMessage === t('SIGNUP_FORM_ERROR_USED_EMAIL')) {
    setTimeout(() => {
      setIsEditMode(true);
      setActiveStep(0);
    }, 1000);
  }

  const handleNext = () => {
    switch (activeStep) {
      case 1:
        setActiveStep(2);
        setIsFormValid(false);
        break;
      case 2:
        _setIsLoading(true);
        _signup({ signup: fields.user, company: fields.company });
        setActiveStep(3);
        break;
      case 3:
        handleClose();
        break;
      default:
        setIsFormValid(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="md:fixed md:top-1/2 top-0 md:left-1/2 left-0 md:transform md:-translate-x-1/2 md:-translate-y-1/2 z-75">
        <div className="bg-white pt-2 rounded-md text-primary md:text-base text-sm border-t-2 border-white">
          <div className="text-right px-2 py-0">
            <button
              type="button"
              className="focus:outline-none right-0"
              onClick={handleClose}
            >
              &#10060;
            </button>
          </div>
          <div className="px-10 auth__container">
            <div className="md:flex border-b border-primary pb-1">
              <p className="flex-none">
                <img src={banner} alt="" className="h-6" />
              </p>
              <p className="md:text-right md:flex-grow text-xs sm:text-sm md:text-base md:pt-0 pt-2">
                <span className="">
                  <FontAwesomeIcon icon={faPhone} /> {TRAMEDIX_PHONE_NUMBER}
                </span>
                <a
                  className="md:pl-4 pl-1 whitespace-no-wrap"
                  href="mailto:info@tramedix.de"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> info@tramedix.de
                </a>
              </p>
            </div>
            {isLoading && <LinearProgress />}
            <div className="pt-6 text-sm md:text-base">
              <>
                <h1 className="inline text-primary font-bold md:text-xl text-center">
                  {t('SIGNUP_TEXT')}
                </h1>
                <span className="text-sm text-gray-500">
                  (<span className="text-red-400">*</span>{' '}
                  <span className="text-xs sm:text-xs md:text-sm">
                    = {t('REQUIRED_FIELD_TEXT')}
                  </span>
                  )
                </span>
              </>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label} className="hidden md:block">
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                {getStepContent(
                  activeStep,
                  t,
                  (value) => {
                    setFields({
                      ...fields,
                      company: {
                        ...fields.company,
                        dataFields: value,
                      },
                    });
                  },
                  (value) => {
                    setFields({
                      ...fields,
                      company: {
                        ...fields.company,
                        approvalsFields: value,
                      },
                    });
                  },
                  setIsFormValid,
                  fields,
                  successMessage,
                  (userSignupFields) => {
                    setFields({ ...fields, user: userSignupFields });
                    setActiveStep(1);
                  },
                  isEditMode,
                  handleNext,
                  setActiveStep,
                  setIsEditMode,
                )}
              </>
              {activeStep === 3 && (
                <p className="text-right w-full">
                  <button
                    className="button focus:outline-none cursor-pointer bg-green-600"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    {t('CLOSE_TEXT')}
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = ({
  registrationState: { message, errorMessage, isLoading },
}: StoreI) => ({
  successMessage: message,
  errorMessage,
  isLoading,
});

export default connect(mapStateToProps, {
  _toggleRegistrationForm: toggleRegistrationForm,
  _setErrorMessage: setErrorMessage,
  _signup: signup,
  _setIsLoading: setIsLoading,
})(Signup);
