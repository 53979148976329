/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faMapMarkerAlt,
  faUserAlt,
  faSignOutAlt,
  faBuilding,
  faHandsHelping,
  faBan,
  faPlusCircle,
  faStar,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from '../../../../store/actions/creators/registration';
import { User, UserRole } from '../../../../globals/models/data/user.model';
import showLicenseInvalidOrExpiredToast from '../../../../shared/helpers/showInvalidOrExpiredLicenseToast';
import { cancelLastGetArticlesRequest } from '../../../../store/actions/creators/dataTable';

const HeaderProfile = ({
  handleClose,
  currentUser,
  _logout,
}: HeaderProfileProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  return (
    <div className="block mt-2 w-56 bg-white text-left rounded-md text-gray-700 left-0 top-1 text-sm ">
      {currentUser?.role !== UserRole.ADMIN && (
        <>
          {pathname !== '/-/profile/licenses' && (
            <Link
              className="block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light"
              to="/-/profile/licenses"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faBuilding} className="mr-1" />{' '}
              {t('SHELL.NAVIGATION.LICENCES_BUTTON')}
            </Link>
          )}
          {pathname !== '/-/profile/addresses' && (
            <Link
              className="block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light"
              to="/-/profile/addresses"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />{' '}
              {t('SHELL.NAVIGATION.ADDRESSES_BUTTON')}
            </Link>
          )}
          {pathname !== '/-/profile' && (
            <Link
              className="block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light"
              to="/-/profile"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faUserAlt} className="mr-1" />{' '}
              {t('SHELL.NAVIGATION.ACCOUNT_BUTTON')}
            </Link>
          )}
          {pathname !== '/-/profile/msv3' && (
            <Link
              className="block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light"
              to="/-/profile/msv3"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faHandsHelping} className="mr-1" /> MSV3
            </Link>
          )}
          {pathname !== '/-/profile/blacklist' && (
            <Link
              className={`block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light ${
                currentUser?.consulting ? 'settings' : ''
              }`}
              to="/-/profile/blacklist"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faBan} className="mr-1" /> Blacklist
            </Link>
          )}
          {pathname !== '/-/profile/whitelist' && (
            <Link
              className={`block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light ${
                currentUser?.consulting ? 'settings' : ''
              }`}
              to="/-/profile/whitelist"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="mr-1" /> Whitelist
            </Link>
          )}
          {pathname !== '/-/profile/favorites' && (
            <Link
              className={`block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light ${
                currentUser?.consulting ? 'settings' : ''
              }`}
              to="/-/profile/favorites"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faStar} className="mr-1" /> Favoriten
            </Link>
          )}
          {pathname !== '/-/profile/settings' && (
            <Link
              className={`block px-4 py-2 hover:text-primary cursor-pointer border-gray-400 border-solid hover:bg-grey-light ${
                currentUser?.consulting ? 'settings' : ''
              }`}
              to="/-/profile/settings"
              onClick={() => {
                cancelLastGetArticlesRequest();
                showLicenseInvalidOrExpiredToast(currentUser);
                handleClose();
              }}
            >
              <FontAwesomeIcon icon={faCog} className="mr-1" /> {t('SETTINGS')}
            </Link>
          )}
        </>
      )}

      <span
        className="block px-4 py-2 border-t-1 border-gray-400 border-solid hover:text-primary cursor-pointer hover:bg-grey-light"
        onClick={() => {
          cancelLastGetArticlesRequest();
          _logout();
        }}
        role="button"
        id="logoutButton"
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />{' '}
        {t('SHELL.NAVIGATION.LOG_OUT_BUTTON')}
      </span>
    </div>
  );
};

interface HeaderProfileProps {
  handleClose: () => void;
  currentUser: User | null;
  _logout: () => void;
}

const mapStateToProps = ({ registrationState: { currentUser } }: StoreI) => ({
  currentUser,
});

export default connect(mapStateToProps, {
  _logout: logout,
})(HeaderProfile);
