/* eslint-disable no-underscore-dangle,@typescript-eslint/naming-convention */
import axios, { AxiosInstance } from 'axios';
import store from '../store';
import { RegistrationActionTypes } from '../store/actions/types/registration';
import {
  decodeUser,
  setCurrentUserWithDispatch,
  storeTokensInLocalStorage,
  storeTokensInSessionStorage,
} from '../shared/utils/redux.utils';

type RefreshWaiter = {
  resolve: (accessToken: string) => void;
  reject: (err: Error) => void;
};

const unprotectedUrls = [
  '/api/v1/auth/login/',
  '/api/v1/auth/register/',
  '/api/v1/auth/reset-password/',
  '/api/v1/auth/reset-password/confirm/',
  '/api/v1/auth/recaptcha/',
];

class Server {
  private readonly apiHandler: AxiosInstance;

  private isRefreshing = false;

  private refreshQueue = new Array<RefreshWaiter>();

  private retries = 1;

  constructor(
    baseUrl = process.env.REACT_APP_BACKEND_URL,
    additionalAxiosHeaders?: { [key: string]: any },
    additionalAxiosSettings?: { [key: string]: any },
  ) {
    this.apiHandler = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization:
          store.getState().registrationState.tokens.access !== '' &&
          `Bearer ${store.getState().registrationState.tokens.access}`,
        'Content-Type': 'application/json',
        ...additionalAxiosHeaders,
      },
      ...additionalAxiosSettings,
    });
  }

  private assignTokenInterceptor(
    tokenHeaderKey = 'Authorization',
    tokenBearerSubstring = 'Bearer ',
  ) {
    this.apiHandler.interceptors.request.use(
      (config) => {
        if (
          store.getState().registrationState.tokens.access !== '' &&
          !unprotectedUrls.includes(config.url as string)
        ) {
          // eslint-disable-next-line no-param-reassign
          config.headers[tokenHeaderKey] = `${tokenBearerSubstring}${
            store.getState().registrationState.tokens.access
          }`;
        }

        return config;
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  }

  getApiHandler(
    tokenHeaderKey = 'Authorization',
    tokenBearerSubstring = 'Bearer ',
  ) {
    this.assignTokenInterceptor(tokenHeaderKey, tokenBearerSubstring);
    this.apiHandler.interceptors.response.use(undefined, async (err) => {
      if (!err.response) {
        return Promise.reject(err);
      }

      const { config: orgConfig, response } = err;

      const { status } = response;

      if (!unprotectedUrls.includes(orgConfig.url)) {
        if (status === 401) {
          orgConfig._retry =
            typeof orgConfig._retry === 'undefined' ? 0 : orgConfig._retry + 1;

          if (orgConfig._retry === this.retries) {
            return Promise.reject(err);
          }

          if (!this.isRefreshing) {
            this.isRefreshing = true;
            try {
              // do what waiters are waiting for
              const { accessToken } = await this.refreshToken();
              this.refreshQueue.forEach((waiter: RefreshWaiter) => {
                waiter.resolve(accessToken);
              });
              this.refreshQueue = [];
              this.apiHandler.defaults.headers.Authorization = `Bearer ${accessToken}`;
              return this.apiHandler(orgConfig);
            } catch (e) {
              this.resetTokens(err);
            } finally {
              this.isRefreshing = false;
            }
          }

          return new Promise((resolve, reject) => {
            this.refreshQueue.push({
              resolve: (accessToken: string) => {
                this.apiHandler.defaults.headers.Authorization = `Bearer ${accessToken}`;
                return resolve(this.apiHandler(orgConfig));
              },
              reject: (error: Error) => {
                return reject(error);
              },
            });
          });
        }

        if (
          status === 403 &&
          store.getState().registrationState.currentUser &&
          !store.getState().registrationState.currentUser
            ?.isLicenseDueOrInvalid &&
          // TODO: Refactor using a general Exception Filter for api errors (probably in an interceptor ?)
          response.data &&
          response.data.errors &&
          response.data.errors[0] &&
          response.data.errors[0].detail ===
            'Expired or missing trading license.'
        ) {
          // refresh the tokens to update the previously stored ones
          try {
            const { accessToken } = await this.refreshToken();
            const user = decodeUser(accessToken);
            setCurrentUserWithDispatch(
              store.dispatch,
              user,
              true,
              store.getState().registrationState.tokensStorage,
            );
          } catch (e) {
            this.resetTokens(err);
          }
        }

        return Promise.reject(err);
      }
      return Promise.reject(err);
    });

    return this.apiHandler;
  }

  /** Resets the internal state of the auth tokens. and triggers a login form. */
  private resetTokens(err: any) {
    this.refreshQueue.forEach((v) => v.reject(err));
    this.refreshQueue = [];
    sessionStorage.setItem('redirect-path', window.location.pathname);
    this.apiHandler.defaults.headers.Authorization = undefined;
    store.dispatch({
      type: RegistrationActionTypes.TOGGLE_LOGIN_FORM,
      payload: true,
    });
    store.dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: {
        access: null,
        refresh: null,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshToken() {
    const { refresh } = store.getState().registrationState.tokens;
    if (!refresh) {
      throw new Error('Can not refresh without token.');
    }

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/refresh/`,
      {
        refresh,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const {
      data: {
        data: { refresh_token, access_token },
      },
    } = res;

    if (store.getState().registrationState.tokensStorage === 'localStorage') {
      storeTokensInLocalStorage(access_token, refresh_token);
    } else {
      storeTokensInSessionStorage(access_token, refresh_token);
    }

    store.dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: {
        access: access_token,
        refresh: refresh_token,
      },
    });

    return { accessToken: access_token, refreshToken: refresh_token };
  }
}

export const fileParserApiInstance = new Server(
  process.env.REACT_APP_MASS_IMPORT_PARSER_URL,
).getApiHandler();

export const msv3ApiInstance = new Server(
  process.env.REACT_APP_MSV3_URL,
).getApiHandler('Bearer', '');

export default new Server().getApiHandler();
