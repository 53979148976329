/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';
import { setIsTableModalLoading } from '../../../store/actions/creators/dataTable';
import CheckboxInput from '../../Shared/layouts/CheckBoxInput';
import { submitShoppingCart } from '../../../store/actions/creators/shoppingCart';
import { ModalsTypes } from '../../../globals/models/redux';
import { setCurrentModalType } from '../../../store/actions/creators/modals';
import CheckoutAddressesSelector from '../../Shared/layouts/CheckoutAddressesSelector';

const SubmitCartDataModal = ({
  _setIsTableModalLoading,
  isLoading,
  _submitShoppingCart,
  selectedRows,
  title,
  _setCurrentModalType,
}: SubsetModalProps) => {
  const { t } = useTranslation();

  const activeColor =
    title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
      ? 'text-primary'
      : 'text-green-600';

  const activeBgColor =
    title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
      ? 'bg-primary'
      : 'bg-green-600';

  const inactiveBgColor =
    title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
      ? 'bg-primary-disabled'
      : 'bg-green-400';

  // function to handle the state of the material dialog
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    _setIsTableModalLoading(false);
    _setCurrentModalType(null);
  };

  const [fields, setFields] = useState<{
    billingAddress: string;
    invoiceAddress: string;
    hasAcceptedTerms: boolean;
  }>({
    billingAddress: '',
    invoiceAddress: '',
    hasAcceptedTerms: false,
  });

  const isValid =
    title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
      ? fields?.billingAddress &&
        fields.invoiceAddress &&
        fields.hasAcceptedTerms
      : fields.hasAcceptedTerms;

  const hasAcceptedTermsCheckboxDisabledState =
    title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
      ? !fields.billingAddress || !fields.invoiceAddress
      : false;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm">
      <div className="w-full">
        <div className="grid grid-cols-2 border-b mr-1">
          <p
            className={`py-4 pl-4 font-bold text-xl whitespace-no-wrap ${activeColor}`}
          >
            {t(title as string)}
          </p>
          <p className="text-left relative pt-2">
            <button
              type="button"
              className="focus:outline-none absolute right-0"
              onClick={handleClose}
            >
              &#10060;
            </button>
          </p>
        </div>
        <div className="w-full">
          {title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES && (
            <CheckoutAddressesSelector
              addressCreationRedirectHandler={handleClose}
              disabled={isLoading}
              saveAddresses={({ billingAddress, invoiceAddress }) => {
                setFields({ ...fields, billingAddress, invoiceAddress });
              }}
            />
          )}
          <div className="px-4 mx-6 py-6 border my-4">
            <CheckboxInput
              labelText={t(
                'SHOPPING_CART.SUBMIT_MODAL.SHOPPING_CART_CONFIRMATION_TEXT',
              )}
              className="text-base pb-4"
              onChange={(value) => {
                setFields({
                  ...fields,
                  hasAcceptedTerms: value,
                });
              }}
              disabled={hasAcceptedTermsCheckboxDisabledState}
            />
            <p className="">{t('SHOPPING_CART_SUBMISSION_FIRST_CONDITION')}</p>
            <p className="font-light italic mt-2 border-l-4 pl-2">
              {t('SHOPPING_CART_SUBMISSION_NOTE')}
            </p>
          </div>
          <div className="w-full flex flex-row justify-between pt-4 border-t px-6">
            <button
              type="button"
              className="focus:outline-none button mr-4 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
              onClick={() => {
                _setIsTableModalLoading(false);
                _setCurrentModalType(null);
              }}
            >
              {t('CANCEL_TEXT')}
            </button>
            <button
              type="button"
              className={`focus:outline-none button ${
                isValid ? activeBgColor : `${inactiveBgColor} c-no-drop`
              }`}
              disabled={!isValid}
              onClick={() => {
                _setIsTableModalLoading(true);
                _submitShoppingCart(
                  selectedRows,
                  title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
                    ? // SWITCHED BECAUSE OF https://gitlab.com/tramedix/marketplace-react-frontend/-/issues/133#note_488538312
                      // TODO: Remove after comment's claim is fixed
                      'buying'
                    : 'selling',
                  title === ModalsTypes.SHOPPING_CART_SUBMIT_SALES
                    ? {
                        invoice_adress_id: fields.invoiceAddress,
                        delivery_adress_id: fields.billingAddress,
                      }
                    : undefined,
                ).then(() => {
                  handleClose();
                });
              }}
            >
              {t('CONFIRM_TEXT')}
              {isLoading && (
                <CircularProgress
                  style={{ color: 'white' }}
                  size={15}
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

interface SubsetModalProps {
  _setIsTableModalLoading: (
    value: boolean,
  ) => { type: string; payload: boolean };
  isLoading: boolean;
  _submitShoppingCart: (
    rows: ArticleOfferI[],
    proposalType: 'buying' | 'selling',
    addresses?: {
      delivery_adress_id: string;
      invoice_adress_id: string;
    },
  ) => Promise<{
    type: string;
    payload:
      | {
          rows: string[];
          proposalType: 'buying' | 'selling';
        }
      | string;
  }>;
  selectedRows: ArticleOfferI[];
  title: ModalsTypes | null;
  _setCurrentModalType: (
    type: ModalsTypes | null,
  ) => { type: string; payload: ModalsTypes | null };
}

const mapStateToProps = ({
  dataTableState: {
    currentModal: { isLoading },
  },
  modalsState: { selectedRows, type },
}: StoreI) => ({
  isLoading,
  selectedRows,
  title: type,
});

export default connect(mapStateToProps, {
  _setIsTableModalLoading: setIsTableModalLoading,
  _submitShoppingCart: submitShoppingCart,
  _setCurrentModalType: setCurrentModalType,
})(SubmitCartDataModal);
