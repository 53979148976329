/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import { useRouteMatch } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import NumberFormat from 'react-number-format';
import numeral from 'numeral';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';
import generatePaginationDetails from '../../../shared/utils/pagination';
import { round } from '../../../shared/utils/numbers.utils';
import SubtableIcons from '../content/rowActions/SubtableIcons';
import {
  ArticleOptionsTypes,
  ProposalI,
} from '../../../globals/models/data/article.model';
import i18next from '../../../i18n';
import { formatTableCellDisplay } from '../../../shared/utils/dataTable';

const OpenProposalsModal = ({ articleOffer }: OpenProposalsModalProps) => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();

  const data =
    articleOffer && articleOffer.attributes
      ? articleOffer.attributes.proposals
          .filter(
            (el) =>
              ![
                ArticleOptionsTypes.CONFIRMED,
                ArticleOptionsTypes.DECLINED,
              ].includes(el.status),
          )
          .map((proposal) => ({
            price: Number(proposal.price_per_piece),
            quantity: proposal.piece_amount,
            difference: round(
              round(Number(proposal.price_per_piece)) -
                round(Number(articleOffer.attributes.price)),
            ),
          }))
      : [];

  const possiblePageLimits = [10, 20, 50, 100, 200];
  const [currentPageMeta, setCurrentPageMeta] = useState(1);
  const [currentPageLimitMeta, setCurrentPageLimitMeta] = useState(
    possiblePageLimits[0],
  );
  const metaOffset = (currentPageMeta - 1) * currentPageLimitMeta;

  const { pages } = generatePaginationDetails(
    data.length,
    metaOffset,
    currentPageLimitMeta,
  );

  const articleType = path.includes('offers')
    ? 'OFFER_TEXT'
    : 'PURCHASE_REQUEST_TEXT';

  numeral.locale(i18n.language === 'en' ? 'en-gb' : 'de');

  return (
    <div className="h-screen-70 relative">
      <h1 className="text-center pt-6 text-md md:text-base font-bold">
        {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ARTICLE_INFO_SUB_TITLE')}
      </h1>
      <div className="p-6 overflow-x-scroll hide-scrollbar">
        {data.length !== 0 && (
          <table
            key={Math.random()}
            className="min-w-full divide-y divide-gray-200 table-fixed border rounded-sm text-md"
          >
            <tbody>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('TABLE_COLUMN_ARTICLE_NAME')}
                </th>
                <td className="font-bold text-md whitespace-no-wrap px-6">
                  {articleOffer?.attributes.product_name}
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('PZN')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  {articleOffer?.attributes.pzn}
                </td>
              </tr>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ORIGINAL_PRICE')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  <NumberFormat
                    value={articleOffer?.attributes.price}
                    thousandSeparator={i18next.language === 'de' ? '.' : ','}
                    decimalSeparator={i18next.language === 'de' ? ',' : '.'}
                    className="w-full text-black bg-grey-2 p-0 m-0 text-left"
                    suffix=" €"
                    disabled
                    decimalScale={2}
                  />
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.SOLD_ITEMS')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.SOLD_ITEMS_VALUE', {
                    soldItems: numeral(
                      articleOffer?.attributes.proposals
                        .map((proposal) => proposal.piece_amount)
                        .reduce(
                          (piece_amount1, piece_amount2) =>
                            piece_amount1 + piece_amount2,
                        ),
                    ).format(),
                    totalItems: numeral(
                      articleOffer?.attributes.piece_amount,
                    ).format(),
                  })}
                </td>
              </tr>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.AEK_DISCOUNT')}
                </th>
                <td className="whitespace-no-wrap px-6">
                  <NumberFormat
                    value={articleOffer?.attributes.aep_discount}
                    thousandSeparator={i18next.language === 'de' ? '.' : ','}
                    decimalSeparator={i18next.language === 'de' ? ',' : '.'}
                    className="inline text-black bg-grey-2 p-0 m-0"
                    suffix=" %"
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.PRICE_NEGOTIATION')}
                </th>
                <td className="whitespace-no-wrap px-6">
                  {articleOffer?.attributes.best_offer_possible
                    ? t('YES_TEXT')
                    : t('NO_TEXT')}
                </td>
              </tr>
              {/* TODO: Use commented code bellow to display the minimum price */}
              {/* <tr> */}
              {/*  <th */}
              {/*    scope="col" */}
              {/*    className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light" */}
              {/*  > */}
              {/*    {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.MIN_PRICE')} */}
              {/*  </th> */}
              {/*  <td className=" whitespace-no-wrap px-6">--- €</td> */}
              {/* </tr> */}
            </tbody>
          </table>
        )}
      </div>
      <h1 className="text-center pt-6 text-md md:text-base font-bold px-4">
        {t('DATA_TABLE.OPEN_PROPOSALS_MODAL.SUB_TITLE', {
          productType:
            articleType === 'OFFER_TEXT'
              ? t('OFFER_TEXT')
              : t('PURCHASE_REQUEST_TEXT'),
        })}
      </h1>
      <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-sm m-6 hide-scrollbar">
        <SwipeableViews axis="x" index={currentPageMeta} enableMouseEvents>
          <span className="opacity-0">&nbsp;</span>
          {new Array(pages).fill(null).map(() => (
            <table
              className="min-w-full divide-y divide-gray-200 table-fixed border"
              key={Math.random()}
            >
              <thead className="bg-grey-light">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/4 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.OPEN_PROPOSALS_MODAL.QUANTITY')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/4 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.OPEN_PROPOSALS_MODAL.PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/4 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.OPEN_PROPOSALS_MODAL.PRICE_DIFFERENCE')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/4 whitespace-no-wrap text-center"
                  >
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length !== 0 &&
                  data
                    .slice(metaOffset, metaOffset + currentPageLimitMeta)
                    .map((el, index) => (
                      <tr
                        key={Math.random()}
                        className="odd:bg-grey-2 text-gray-800 text-base"
                      >
                        <td className="text-center">
                          <NumberFormat
                            value={el.quantity}
                            thousandSeparator={
                              i18n.language === 'de' ? '.' : ','
                            }
                            decimalSeparator={
                              i18n.language === 'de' ? ',' : '.'
                            }
                            className={`w-full text-center text-black ${
                              index % 2 === 0 ? 'bg-grey-2' : 'bg-white'
                            } p-0 m-0`}
                            disabled
                          />
                        </td>
                        <td className="text-center whitespace-no-wrap">
                          {formatTableCellDisplay(el.price, 'price')} €
                        </td>
                        <td className="text-center font-bold text-lg whitespace-no-wrap">
                          {el.difference > 0
                            ? '+ '
                            : el.difference === 0
                            ? '+ - '
                            : ''}{' '}
                          {formatTableCellDisplay(el.difference, 'difference')}{' '}
                          €
                        </td>
                        <td className="flex py-2 px-4">
                          <SubtableIcons
                            row={articleOffer as ArticleOfferI}
                            proposal={
                              articleOffer?.attributes.proposals[
                                index
                              ] as ProposalI
                            }
                          />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          ))}
        </SwipeableViews>
      </div>
      {data.length === 0 && (
        <div className="w-full h-10 flex flex-row justify-center whitespace-no-wrap">
          <div>{t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.NO_PROPOSALS')}</div>
        </div>
      )}
      <div className="w-full flex flex-row justify-between mb-6 pl-4 px-4 overflow-x-scroll mt-4 w-full hide-scrollbar">
        <div className="flex">
          <div className="pt-2 whitespace-no-wrap mr-2">{`${t(
            'TABLE_ITEMS_PER_PAGE',
          )}`}</div>
          <Select
            name="timeslot"
            id="time-choices"
            className="border rounded-sm w-full h-8 mt-1 px-1"
            defaultValue={possiblePageLimits[0]}
            onChange={({ target: { value } }) => {
              setCurrentPageLimitMeta(value as number);
            }}
            value={currentPageLimitMeta}
            disableUnderline
            native
          >
            {possiblePageLimits.map((el) => (
              <option
                value={el}
                key={el}
                className="hover:bg-grey-4 cursor-pointer"
              >
                {el}
              </option>
            ))}
          </Select>
        </div>
        <div className="pt-2">
          <Pagination
            count={pages}
            onChange={(e, page) => {
              if (page !== currentPageMeta) {
                setCurrentPageMeta(page);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

interface OpenProposalsModalProps {
  articleOffer: ArticleOfferI | null;
}

export default OpenProposalsModal;
