/* eslint-disable @typescript-eslint/indent,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AddressI } from '../../../globals/models/data/address.model';
import { ProfileState } from '../../../globals/models/redux';
import { getUsersAddresses } from '../../../store/actions/creators/profile';

const CheckoutAddressesSelector = ({
  deliveryAddresses,
  billingAddresses,
  disabled,
  addressCreationRedirectHandler,
  saveAddresses,
  _getUsersAddresses,
}: CheckoutAddressesSelectorProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [fields, setFields] = useState({
    billingAddress: billingAddresses.length !== 0 ? billingAddresses[0].id : '',
    invoiceAddress:
      deliveryAddresses.length !== 0 ? deliveryAddresses[0].id : '',
  });

  useEffect(() => {
    if (
      !(fields.billingAddress && fields.invoiceAddress) &&
      billingAddresses.length !== 0 &&
      deliveryAddresses.length !== 0
    ) {
      setFields({
        billingAddress: billingAddresses[0].id,
        invoiceAddress: deliveryAddresses[0].id,
      });
    }
    // Never add fields or isValid as deps. It will lead to an infinite loop
  }, [deliveryAddresses.length, billingAddresses.length]);

  if (fields.billingAddress && fields.invoiceAddress && !isValid) {
    setIsValid(true);
    saveAddresses(fields);
  }

  if (!fields.billingAddress && !fields.invoiceAddress && isValid) {
    setIsValid(false);
    saveAddresses(fields);
  }

  useEffect(() => {
    _getUsersAddresses();
  }, [_getUsersAddresses]);

  return (
    <div className="px-6">
      {deliveryAddresses.length !== 0 ? (
        <>
          <div className="mt-6">
            <label
              htmlFor="delivery-address"
              className={`whitespace-nowrap ${
                disabled && 'c-no-drop text-gray-500'
              }`}
            >
              {t('SHOPPING_CART.SUBMIT_MODAL.SELECT_DELIVERY_ADRESS_TEXT')}
            </label>
            <Select
              id="delivery-address"
              className="w-full h-8 bg-white border-1 border-gray-400 rounded-sm px-1"
              disableUnderline
              disabled={disabled}
              value={fields.invoiceAddress}
              native
              onChange={(e) => {
                setFields({
                  ...fields,
                  invoiceAddress: e.target.value as string,
                });
                saveAddresses({
                  ...fields,
                  invoiceAddress: e.target.value as string,
                });
              }}
              defaultValue={
                deliveryAddresses.length !== 0
                  ? deliveryAddresses[0].id
                  : undefined
              }
            >
              {deliveryAddresses.map((el) => (
                <option
                  value={el.id}
                  key={`${el.street_number}${Math.random()}`}
                  className="cursor-pointer hover:bg-grey-4 px-2 text-sm"
                >
                  {el.street} {el.street_number}, {el.zipcode}, {el.city},{' '}
                  {el.country}
                </option>
              ))}
            </Select>
          </div>
        </>
      ) : (
        <button
          className="button mt-10"
          type="button"
          onClick={() => {
            addressCreationRedirectHandler();
            push('/-/profile/addresses?page=delivery');
          }}
        >
          {t('SHOPPING_CART.SUBMIT_MODAL.CREATE_DELIVERY_ADDRESS_TEXT')}
        </button>
      )}
      <div>
        {billingAddresses.length !== 0 ? (
          <>
            <div className="mt-6">
              <label
                htmlFor="billing-address"
                className={`whitespace-nowrap ${
                  disabled && 'c-no-drop text-gray-500'
                }`}
              >
                {t('SHOPPING_CART.SUBMIT_MODAL.SELECT_BILLING_ADDRESS_TEXT')}
              </label>
              <Select
                id="billing-address"
                className="w-full h-8 bg-white border-1 border-gray-400 rounded-sm px-1"
                disableUnderline
                value={fields.billingAddress}
                disabled={disabled}
                native
                onChange={(e) => {
                  setFields({
                    ...fields,
                    billingAddress: e.target.value as string,
                  });
                  saveAddresses({
                    ...fields,
                    billingAddress: e.target.value as string,
                  });
                }}
                defaultValue={
                  billingAddresses.length !== 0
                    ? billingAddresses[0].id
                    : undefined
                }
              >
                {billingAddresses.map((el) => (
                  <option
                    value={el.id}
                    key={`${el.street_number}${Math.random()}`}
                    className="cursor-pointer hover:bg-grey-4 px-2"
                  >
                    {el.street} {el.street_number}, {el.zipcode}, {el.city},{' '}
                    {el.country}
                  </option>
                ))}
              </Select>
            </div>
          </>
        ) : (
          <button
            className="button mt-10"
            type="button"
            onClick={() => {
              addressCreationRedirectHandler();
              push('/-/profile/addresses?page=billing');
            }}
          >
            {t('SHOPPING_CART.SUBMIT_MODAL.CREATE_BILLING_ADDRESS_TEXT')}
          </button>
        )}
      </div>
    </div>
  );
};

interface CheckoutAddressesSelectorProps {
  billingAddresses: AddressI[];
  deliveryAddresses: AddressI[];
  disabled: boolean;
  addressCreationRedirectHandler: () => void;
  saveAddresses: (addresses: {
    billingAddress: string;
    invoiceAddress: string;
  }) => void;
  _getUsersAddresses: () => Promise<{
    type: string;
    payload:
      | string
      | {
          billing: ProfileState['addresses']['billing'];
          delivery: ProfileState['addresses']['delivery'];
        };
  }>;
}

const mapStateToProps = ({
  profileState: {
    addresses: { billing, delivery },
  },
}: StoreI) => ({
  billingAddresses: billing,
  deliveryAddresses: delivery,
});

export default connect(mapStateToProps, {
  _getUsersAddresses: getUsersAddresses,
})(CheckoutAddressesSelector);
