/* eslint-disable @typescript-eslint/indent */
import { ReactNode } from 'react';
import { ArticleI } from '../data/article.model';
import { PaginatedItems } from '../data';

export type DataTableColumnsConfigI = {
  id?: string;
  config_id: string;
  isSelected: boolean;
  displayText?: any;
  accessor?: string;
  sortable?: boolean;
};

export interface DataTableContentWrapperPropsI {
  columnsList: TableContentPropsI['columns'];
  articles: TableContentPropsI['articles'];
  _getArticles: (
    queryParams: string,
    currentDTPage: DataTablePageE,
    shouldSetLayout?: boolean,
  ) => Promise<{ type: string; payload: PaginatedArticles }>;
  isLoading: boolean;
  _setPageSize: TableContentPropsI['_setPageSize'];
  pageSize: number;
  _setSelectedRows: (
    value: ArticleOfferI[],
  ) => { type: string; payload: ArticleOfferI[] };
  _setIsLoading: (payload: boolean) => { type: string; payload: boolean };
  currentPage: DataTablePageE;
  tablePageIndex?: number;
  parentContainerReference: HTMLDivElement;
  children?: ReactNode;
}

export interface TableContentPropsI {
  columns: {
    config_id: string;
    Header: string;
    accessor: string;
  }[];
  articles: PaginatedArticles;
  _getArticles: DataTableContentWrapperPropsI['_getArticles'];
  isLoading: boolean;
  _setPageSize: (pageSize: number) => { type: string; payload: number };
  pageSize: number;
  _setSelectedRows: (
    value: ArticleOfferI[],
  ) => { type: string; payload: ArticleOfferI[] };
  _setIsLoading: (payload: boolean) => { type: string; payload: boolean };
  currentPage: DataTablePageE;
  tablePageIndex?: number;
  parentContainerWidth?: number;
}

export interface ArticleOfferI {
  type: string;
  attributes: ArticleI;
  MSV3deliverables?: '<' | '>=' | null;
  MSV3Availabilities?: any[];
  id?: string;
  relationships?: {
    article_images: { data: []; meta: { count: number } };
    matches: {
      data: { type: string; id: string }[];
      meta: { count: number };
    };
    offer: {
      data: { type: string; id: string };
    };
    product_charge: { data: { type: string; id: string } };
  };
  proposal?: { id: number };
}

export type PaginatedArticles = PaginatedItems<ArticleOfferI>;

export enum DataTablePageE {
  OFFERS = 'OFFERS',
  SEARCH = 'SEARCH',
  BUY = 'BUY',
  DELIVER = 'DELIVER',
}

export interface PaginatorPropsI {
  canPreviousPage: boolean;
  previousPage: () => void;
  _getArticles: DataTableContentWrapperPropsI['_getArticles'];
  pageIndex: number;
  currentPageSize: number;
  _setIsLoading: (payload: boolean) => { type: string; payload: boolean };
  canNextPage: boolean;
  nextPage: () => void;
  pageOptionsLength: number;
  dTPageSize?: number;
  setDtPageSize: (pageSize: number) => void;
  setCurrentPageSize: TableContentPropsI['_setPageSize'];
  currentPage: DataTablePageE;
  sidebarFilterString: string | null;
  sort?: {
    id: string;
    desc: boolean;
  };
}

export enum DataTableShellTabOptionsE {
  MY_OFFERS = 'TABLE_NAV_MY_OFFERS',
  ALL_OFFERS = 'TABLE_NAV_ALL_OFFERS',
  MY_SEARCH = 'TABLE_NAV_MY_SEARCH',
  ALL_SEARCH = 'TABLE_NAV_ALL_SEARCH',
  CREATE_OFFER = 'TABLE_NAV_CREATE_OFFER',
  CREATE_PURCHASE_REQUEST = 'TABLE_NAV_CREATE_PURCHASE_REQUEST',
}

export enum DataTableModalOptions {
  ARTICLE_SUBSET = 'ARTICLE_SUBSET',
  ARTICLE_BID = 'ARTICLE_BID',
  DEFAULT = 'DEFAULT',
  DELETE_ARTICLE = 'DEFAULT',
  LQ = 'LQ',
  ACCEPT_MATCHING = 'ACCEPT_MATCHING',
  DECLINE_MATCHING = 'DECLINE_MATCHING',
  OPEN_PROPOSALS = 'OPEN_PROPOSALS',
  COMPLETED_PROPOSALS = 'COMPLETED_PROPOSALS',
  MSV3_PZN_AVAILABILITY = 'MSV3_PZN_AVAILABILITY',
  MSV3_CHECKOUT = 'MSV3_CHECKOUT',
}
