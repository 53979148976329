import * as Yup from 'yup';
import i18n from '../../i18n';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_INVALID'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')),
  password: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT'))
    .matches(
      new RegExp('.{8,128}'),
      i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID'),
    )
    .required(i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')),
});

export const passwordResetValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_INVALID'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')),
});

export const userSignupValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_COMPANY_NAME_SHORT'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_COMPANY_NAME_REQUIRED')),
  firstName: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_FIRST_NAME_SHORT'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_FIRST_NAME_REQUIRED')),
  email: Yup.string()
    .email(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_INVALID'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')),
  phoneNumber: Yup.string().required(
    i18n.t('REGISTRATION_FORM_ERROR_PHONE_NUMBER_REQUIRED'),
  ),
  lastName: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_LAST_NAME_SHORT'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_LAST_NAME_REQUIRED')),
  password: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT'))
    .matches(
      new RegExp('.{8,128}'),
      i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID'),
    )
    .required(i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')),
  confirmPassword: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT'))
    .matches(
      new RegExp('.{8,128}'),
      i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID'),
    )
    .test(
      'passwords-match',
      i18n.t('REGISTRATION.LABELS.CONFIRM_PASSWORD_FIELD_NO_MATCH'),
      // eslint-disable-next-line func-names
      function (value) {
        return this.parent.password === value;
      },
    )
    .required(i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')),
  hasAcceptedPrivacyPolicy: Yup.boolean().required(
    i18n.t('REGISTRATION_FORM_ERROR_PRIVACY_POLICY_REQUIRED'),
  ),
});

export const passwordResetConfirmationValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT'))
    .matches(
      new RegExp('.{8,128}'),
      i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID'),
    )
    .required(i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')),
  confirmPassword: Yup.string()
    .min(3, i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT'))
    .matches(
      new RegExp('.{8,128}'),
      i18n.t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID'),
    )
    .test(
      'passwords-match',
      i18n.t('PASSWORD_RESET.CONFIRMATION.CONFIRM_PASSWORD_NO_MATCH'),
      // eslint-disable-next-line func-names
      function (value) {
        return this.parent.newPassword === value;
      },
    )
    .required(),
});
