/* eslint-disable @typescript-eslint/indent */
import { Reducer } from 'redux';
import initialState from '../../initialState';

import {
  AdminCompaniesActions,
  GetAdminCompaniesActionI,
  GetAdminCompanyTransactionsActionI,
  GetAdminCompanyTransactionsToDownloadActionI,
  SetAdminBreadcrumbsActionI,
  SetCurrentlyDisplayedArticleActionI,
  SetGettingAdminCompanyTransactionsToDownloadActionI,
  SetIsAdminCompaniesLoadingActionI,
} from '../../../globals/models/actions/admin/companies';
import { AdminAreaState } from '../../../globals/models/redux';
import { AdminCompaniesActionTypes } from '../../actions/types/admin/companies';

export const adminCompaniesReducer: Reducer<
  AdminAreaState['companies'],
  AdminCompaniesActions
> = (state = initialState.adminAreaState.companies, action) => {
  switch (action.type) {
    case AdminCompaniesActionTypes.GET_ADMIN_COMPANIES:
      return {
        ...state,
        loadedData: (action as GetAdminCompaniesActionI).payload,
        isLoading: false,
      };

    case AdminCompaniesActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: (action as SetIsAdminCompaniesLoadingActionI).payload,
      };

    case AdminCompaniesActionTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: (action as SetAdminBreadcrumbsActionI).payload,
      };

    case AdminCompaniesActionTypes.SET_CURRENTLY_DISPLAYED_ARTICLE:
      return {
        ...state,
        currentlyDisplayedCompany: (action as SetCurrentlyDisplayedArticleActionI)
          .payload,
      };

    case AdminCompaniesActionTypes.GET_ADMIN_COMPANY_TRANSACTIONS:
      return {
        ...state,
        transactions: (action as GetAdminCompanyTransactionsActionI).payload,
        isLoading: false,
      };

    case AdminCompaniesActionTypes.GET_ADMIN_COMPANY_TRANSACTIONS_TO_DOWNLOAD:
      return {
        ...state,
        transactionsToDownload: (action as GetAdminCompanyTransactionsToDownloadActionI)
          .payload,
        isLoadingTransactionsToDownload: false,
      };

    case AdminCompaniesActionTypes.SET_IS_GETTING_ADMIN_COMPANY_TRANSACTIONS_TO_DOWNLOAD:
      return {
        ...state,
        isLoadingTransactionsToDownload: (action as SetGettingAdminCompanyTransactionsToDownloadActionI)
          .payload,
      };

    default:
      return state;
  }
};
