/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import {
  faDivide,
  faExclamationCircle,
  faHandshake,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import GeneralIcon from './GeneralIcon';
import ShoppingCartIcon from './ShoppingCartAction';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../../globals/models/components/dataTable';
import LQIcon from './LQIcon';

interface ActiveOfferIconsProps {
  row: ArticleOfferI;
}

const ActiveOfferIcons = ({ row }: ActiveOfferIconsProps) => {
  const { t } = useTranslation();
  return (
    <>
      {row.attributes.lq_url && <LQIcon row={row} />}
      {row.attributes.best_offer_limit_reached ? (
        <GeneralIcon
          tipText={t('BID_MODAL.LIMIT_REACHED_TEXT')}
          faIcon={faExclamationCircle}
          hoverClassName={`text-red-600 ml-2 ${
            !row.attributes.best_offer_possible && 'hidden'
          }`}
          modalType={DataTableModalOptions.DEFAULT}
          row={row}
        />
      ) : (
        <GeneralIcon
          tipText={t('Submit a bid')}
          faIcon={faHandshake}
          hoverClassName={`text-primary ml-2 ${
            !row.attributes.best_offer_possible && 'hidden'
          }`}
          row={row}
          modalType={DataTableModalOptions.ARTICLE_BID}
        />
      )}
      {row.attributes.min_piece_amount &&
        row.attributes.min_piece_amount < row.attributes.piece_amount && (
          <GeneralIcon
            tipText={t('Subset')}
            faIcon={faDivide}
            hoverClassName="text-primary ml-2"
            modalType={DataTableModalOptions.ARTICLE_SUBSET}
            row={row}
          />
        )}
      <ShoppingCartIcon tipText={t('ADD_TO_SHOPPING_CART_TEXT')} row={row} />
    </>
  );
};

export default ActiveOfferIcons;
