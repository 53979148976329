/**
 * Returns true if an array contains fully another array
 * @param {Array} source - the source array
 * @param {Array} target - the target array
 * @returns {boolean} -
 * * */
export const arrayContainsEvery = <T>(source: T[], target: T[]) =>
  target.every((v) => source.includes(v));

/**
 * Returns true if an array contains partially another array
 * @param {Array} source - the source array
 * @param {Array} target - the target array
 * @returns {boolean} -
 * * */
export const arrayContainsSome = <T>(source: T[], target: T[]) =>
  target.some((v) => source.includes(v));

/**
 * Returns true if an array contains duplicates
 * @param {Array} array - the source array
 */
export const arrayContainsDuplicates = <T>(array: T[]) =>
  new Set(array).size !== array.length;
