import * as Yup from 'yup';
import i18n from '../../i18n';

export const bidModalValidationSchema = (
  minimumAmount: number,
  pieceAmount: number,
) =>
  Yup.object().shape({
    articleSubset: Yup.number()
      .min(minimumAmount, i18n.t('SUBSET_SUBMISSION_ERROR_MIN_VALUE'))
      .max(pieceAmount, i18n.t('SUBSET_SUBMISSION_ERROR_MAX_VALUE'))
      .required(i18n.t('SUBSET_SUBMISSION_ERROR_REQUIRED_VALUE')),
  });

export const subsetModalValidationSchema = (
  minimumAmount: number,
  pieceAmount: number,
) =>
  Yup.object().shape({
    articleSubset: Yup.number()
      .min(minimumAmount, i18n.t('SUBSET_SUBMISSION_ERROR_MIN_VALUE'))
      .max(pieceAmount, i18n.t('SUBSET_SUBMISSION_ERROR_MAX_VALUE'))
      .required(i18n.t('SUBSET_SUBMISSION_ERROR_REQUIRED_VALUE')),
  });
