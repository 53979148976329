export const LOCALES = [
  { value: 'en', text: 'English' },
  { value: 'de', text: 'Deutsch' },
];

export const CONSULTATION_CHOICES = [
  {
    translationKey: 'HOME_PAGE_CONS_TIME_CHOICE_1',
    value: 'before_lunch',
  },
  {
    translationKey: 'HOME_PAGE_CONS_TIME_CHOICE_2',
    value: 'after_lunch',
  },
  {
    translationKey: 'HOME_PAGE_CONS_TIME_CHOICE_3',
    value: 'evening',
  },
];

export const TRAMEDIX_PHONE_NUMBER = '038 43 - 22 989 23-0';

export default { locales: LOCALES };

export const INTERVAL = [
  {
    displayValue: '15 min',
    submitValue: '15 min',
  },
  {
    displayValue: '30 min',
    submitValue: '30 min',
  },
  {
    displayValue: '1 h',
    submitValue: '1 h',
  },
  {
    displayValue: '2 h ',
    submitValue: '2 h',
  },
  {
    displayValue: '4 h ',
    submitValue: '4 h',
  },
  {
    displayValue: '12 h ',
    submitValue: '12 h',
  },
  {
    displayValue: '24 h ',
    submitValue: '24 h',
  },
];
