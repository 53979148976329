/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Breadcrumbs, PaginatedItems } from '../../../../globals/models/data';
import {
  Company,
  Transaction,
} from '../../../../globals/models/data/company.model';
import server from '../../../../services/axios.service';
import { ProfileActionTypes } from '../../types/profile';
import {
  GetAdminCompaniesActionI,
  GetAdminCompanyTransactionsActionI,
  GetAdminCompanyTransactionsToDownloadActionI,
  SetAdminBreadcrumbsActionI,
  SetCurrentlyDisplayedArticleActionI,
} from '../../../../globals/models/actions/admin/companies';
import { AdminCompaniesActionTypes } from '../../types/admin/companies';
import { getCompaniesListLimitBasedOnScreen } from '../../../../shared/utils/pagination';

export const getAdminCompanies: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload: PaginatedItems<Company> | string;
  }>,
  {
    type: string;
    payload: PaginatedItems<Company> | string;
  },
  string,
  GetAdminCompaniesActionI
>> = (offset?: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: AdminCompaniesActionTypes.SET_IS_LOADING,
      payload: true,
    });
    const { data } = await server.get(
      `/api/v1/companies?page[limit]=${getCompaniesListLimitBasedOnScreen()}${
        offset ? `&page[offset]=${offset}` : ''
      }`,
    );
    return dispatch({
      type: AdminCompaniesActionTypes.GET_ADMIN_COMPANIES,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setBreadcrumbs: ActionCreator<ThunkAction<
  {
    type: string;
    payload: Breadcrumbs;
  },
  {
    type: string;
    payload: Breadcrumbs;
  },
  Breadcrumbs,
  SetAdminBreadcrumbsActionI
>> = (breadcrumbs: Breadcrumbs) => (dispatch: Dispatch) => {
  return dispatch({
    type: AdminCompaniesActionTypes.SET_BREADCRUMBS,
    payload: breadcrumbs,
  });
};

export const setCurrentlyDisplayedArticle: ActionCreator<ThunkAction<
  {
    type: string;
    payload: {
      display:
        | 'licences'
        | 'offers_purchase_requests'
        | 'successful_transactions';
      data: Company;
    } | null;
  },
  {
    type: string;
    payload: {
      display:
        | 'licences'
        | 'offers_purchase_requests'
        | 'successful_transactions';
      data: Company;
    } | null;
  },
  Breadcrumbs,
  SetCurrentlyDisplayedArticleActionI
>> = (
  article: {
    display:
      | 'licences'
      | 'offers_purchase_requests'
      | 'successful_transactions';
    data: Company;
  } | null,
) => (dispatch: Dispatch) => {
  return dispatch({
    type: AdminCompaniesActionTypes.SET_CURRENTLY_DISPLAYED_ARTICLE,
    payload: article,
  });
};

export const getAdminCompanyTransactions: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | PaginatedItems<{
          type: string;
          id: string;
          attributes: {
            transaction: Transaction;
          };
        }>
      | string;
  }>,
  {
    type: string;
    payload:
      | PaginatedItems<{
          type: string;
          id: string;
          attributes: {
            transaction: Transaction;
          };
        }>
      | string;
  },
  null,
  | GetAdminCompanyTransactionsActionI
  | GetAdminCompanyTransactionsToDownloadActionI
>> = (offset?: number, startDate?: string, endDate?: string) => async (
  dispatch: Dispatch,
) => {
  try {
    let transactions: PaginatedItems<{
      type: string;
      id: string;
      attributes: {
        transaction: Transaction;
      };
    }> = { data: [] };

    let url = '/api/v1/transactions/';

    if (offset) {
      url += `?page[offset]=${offset}`;
    }

    if (startDate && endDate) {
      dispatch({
        type:
          AdminCompaniesActionTypes.SET_IS_GETTING_ADMIN_COMPANY_TRANSACTIONS_TO_DOWNLOAD,
        payload: true,
      });
      url += `?start_date=${startDate}&end_date=${endDate}`;
    } else {
      dispatch({
        type: AdminCompaniesActionTypes.SET_IS_LOADING,
        payload: true,
      });
    }

    const { data } = await server.get(url);

    transactions = data;

    transactions.data = transactions.data.map((el) => ({
      ...el,
      attributes: {
        transaction: { ...el.attributes.transaction, offerId: el.id },
      },
    }));

    return startDate && endDate
      ? dispatch({
          type:
            AdminCompaniesActionTypes.GET_ADMIN_COMPANY_TRANSACTIONS_TO_DOWNLOAD,
          payload: transactions,
        })
      : dispatch({
          type: AdminCompaniesActionTypes.GET_ADMIN_COMPANY_TRANSACTIONS,
          payload: transactions,
        });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};
