import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory } from 'react-router-dom';
import { RegistrationState } from '../../../globals/models/redux';
import { User } from '../../../globals/models/data/user.model';
import {
  LoginFieldsState,
  PasswordResetConfirmationFieldsState,
  passwordResetConfirmationInitialValues,
  PasswordResetFieldsState,
  RegistrationFieldsState,
  RegistrationFormType,
} from '../../../globals/models/components/registrationForms';
import PasswordResetFields, {
  passwordResetInitialValues,
} from './PasswordResetFields';
import LoginFields from './LoginFields';
import banner from '../../../assets/img/Tramedix_Logo.png';
import {
  completePasswordReset,
  initializePasswordReset,
  login,
  setErrorMessage,
  toggleRegistrationForm,
} from '../../../store/actions/creators/registration';
import { TRAMEDIX_PHONE_NUMBER } from '../../../shared/constants/constantChoices';
import PasswordResetConfirmation from './PasswordResetConfirmation';

const initialState = {
  passwordReset: passwordResetInitialValues,
};

// Static translation keys

/**
 * t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_SHORT')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_INVALID')
 * t('REGISTRATION_FORM_ERROR_PASSWORD_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')
 * t('REGISTRATION_FORM_ERROR_EMAIL_INVALID')
 * t('PASSWORD_RESET.SUCCESS_INIT_MESSAGE')
 * t('PASSWORD_RESET.CONFIRMATION.CONFIRM_PASSWORD_NO_MATCH')
 * t('PASSWORD_RESET.CONFIRMATION.SUCCESS_MESSAGE')
 * t('PASSWORD_RESET.ERRORS.EMAIL_NOT_FOUND')
 */

const LoginPasswordResetModal = ({
  formType,
  _toggleRegistrationForm,
  _login,
  _setErrorMessage,
  isLoading,
  _initializePasswordReset,
  _completePasswordReset,
  resetToken,
}: RegistrationProps) => {
  const { t } = useTranslation();

  const [fields, setFields] = useState(initialState);
  const history = useHistory();

  // function to control the appearance of the modal in the redux state
  const handleNavigation = (to: typeof formType = null) => {
    _setErrorMessage(null);
    if (to) {
      _toggleRegistrationForm({ type: to });
      return;
    }

    switch (formType) {
      case RegistrationFormType.SIGNUP:
        _toggleRegistrationForm({ type: RegistrationFormType.LOGIN });
        break;
      case RegistrationFormType.LOGIN:
        _toggleRegistrationForm({ type: RegistrationFormType.SIGNUP });
        break;
      case RegistrationFormType.PASSWORD_RESET:
        _toggleRegistrationForm({ type: RegistrationFormType.LOGIN });
        break;
      default:
        break;
    }
  };

  // function to handle the title display
  // eslint-disable-next-line consistent-return
  const getModalTitle = () => {
    switch (formType) {
      case RegistrationFormType.LOGIN:
        return t('REGISTRATION_FORM_LOGIN_TITLE');
      case RegistrationFormType.PASSWORD_RESET:
        return t('REGISTRATION_FORM_PASSWORD_RESET_TITLE');
      case RegistrationFormType.PASSWORD_RESET_CONFIRMATION:
        return t('PASSWORD_RESET.CONFIRMATION.MODAL_TITLE');
      default:
        break;
    }
  };

  // function to handle the state of the material dialog
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    _toggleRegistrationForm({ type: null });
    _setErrorMessage(null);
    history.replace({
      search: '',
    });
    setOpen(false);
  };

  // Function to handle the submission
  const handleSubmit = (fieldsData?: RegistrationFieldsState) => {
    switch (formType) {
      case RegistrationFormType.LOGIN:
        _login(fieldsData as LoginFieldsState);
        break;

      case RegistrationFormType.PASSWORD_RESET:
        _initializePasswordReset(
          (fieldsData as PasswordResetFieldsState).email,
        );
        break;

      case RegistrationFormType.PASSWORD_RESET_CONFIRMATION:
        _completePasswordReset(
          (fieldsData as PasswordResetConfirmationFieldsState).confirmPassword,
          resetToken as string,
        );
        break;

      default:
        break;
    }
  };

  // function to switch the modal body
  // eslint-disable-next-line consistent-return
  const getModalContentFields = () => {
    switch (formType) {
      case RegistrationFormType.LOGIN:
        return (
          <LoginFields
            handleSubmit={handleSubmit}
            handleNavigation={handleNavigation}
          />
        );
      case RegistrationFormType.PASSWORD_RESET:
        return (
          <PasswordResetFields
            handleNavigation={handleNavigation}
            handleSubmit={handleSubmit}
            onChange={(values) => {
              setFields({
                ...fields,
                passwordReset: values,
              });
            }}
            initialValues={fields.passwordReset}
          />
        );

      case RegistrationFormType.PASSWORD_RESET_CONFIRMATION:
        return (
          <PasswordResetConfirmation
            initialValues={passwordResetConfirmationInitialValues}
            handleSubmit={handleSubmit}
          />
        );
      default:
        break;
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="md:fixed md:top-1/2 top-0 md:left-1/2 left-0 md:transform md:-translate-x-1/2 md:-translate-y-1/2 z-75">
        <div className="bg-white pb-10 rounded-md text-primary md:text-base text-sm border-t-2 border-white">
          <div className="text-right px-2 pt-2 pb-4">
            <button
              type="button"
              className="focus:outline-none right-0"
              onClick={handleClose}
            >
              &#10060;
            </button>
          </div>
          <div className="px-10">
            <div className="md:flex border-b border-primary pb-1">
              <p className="flex-none">
                <img src={banner} alt="" className="h-6" />
              </p>
              <p className="md:text-right md:flex-grow text-xs sm:text-sm md:text-base md:pt-0 pt-2">
                <span className="">
                  <FontAwesomeIcon icon={faPhone} /> {TRAMEDIX_PHONE_NUMBER}
                </span>
                <a
                  className="md:pl-4 pl-1 whitespace-no-wrap"
                  href="mailto:info@tramedix.de"
                >
                  <FontAwesomeIcon icon={faEnvelope} /> info@tramedix.de
                </a>
              </p>
            </div>
            {isLoading && <LinearProgress />}
            <div className="pt-8 text-sm md:text-base">
              <div>
                <h1 className="inline text-primary font-bold md:text-xl">
                  {getModalTitle()}
                </h1>
                <span className="text-sm text-gray-500">
                  (<span className="text-red-400">*</span>{' '}
                  <span className="text-xs sm:text-sm md:text-base">
                    = {t('REQUIRED_FIELD_TEXT')}
                  </span>
                  )
                </span>
              </div>
              {getModalContentFields()}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

interface RegistrationProps {
  _toggleRegistrationForm: (
    formMeta: RegistrationState['form'],
  ) => { type: string } | undefined;

  formType: RegistrationFormType | null;
  _login: (formData: {
    email: string;
    password: string;
  }) => Promise<{ type: string; payload: User | string }>;

  _setErrorMessage: (
    errorMessage: string | null,
  ) => { type: string; payload: string | null };
  isLoading: boolean;

  _initializePasswordReset: (
    email: string,
  ) => Promise<{ type: string; payload?: string }>;

  _completePasswordReset: (
    password: string,
    token: string,
  ) => Promise<{ type: string; payload?: string }>;
  // eslint-disable-next-line react/require-default-props
  resetToken?: string;
}

const mapStateToProps = ({
  registrationState: {
    form: { type },
    isLoading,
    tokens: { passwordReset },
  },
}: StoreI) => ({
  formType: type,
  isLoading,
  resetToken: passwordReset,
});

export default connect(mapStateToProps, {
  _toggleRegistrationForm: toggleRegistrationForm,
  _login: login,
  _setErrorMessage: setErrorMessage,
  _initializePasswordReset: initializePasswordReset,
  _completePasswordReset: completePasswordReset,
})(LoginPasswordResetModal);
