/* eslint-disable react-hooks/rules-of-hooks, react/display-name */
import React, { ComponentType } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

function GeneralLayout(Component: ComponentType<any>) {
  return (props: any) => {
    return (
      <>
        <Header />
        <main className="general-layout">
          <Component {...props} />
        </main>
        <Footer />
      </>
    );
  };
}

export default GeneralLayout;
