export enum ShippingCostsHandlerOptions {
  NOT_DEFINED = 'NOT_DEFINED',
  CREATOR = 'CREATOR',
  PARTNER = 'PARTNER',
}

export enum ArticleOptionsTypes {
  IN_CHECKOUT = 'IN_CHECKOUT',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  RECIPIENT_DIFF_AMOUNT = 'RECIPIENT_DIFF_AMOUNT',
  IN_PROCESS = 'IN_PROCESS',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  EDITABLE = 'EDITABLE',
  FOREIGN_ARTICLE = 'FOREIGN_ARTICLE',
  PROPOSAL_EXISTS = 'PROPOSAL_EXISTS',
}

export interface ArticleI {
  aep: string;
  aep_discount: number;
  aep_marge: string | number;
  apu: string;
  apu_discount: number;
  article_status: string;
  avp: number;
  best_offer_possible: boolean;
  charge_number: number;
  expiration_date: string;
  end_period_of_validity: string;
  is_outdated: boolean;
  lq_url: string;
  matches_exist: boolean;
  min_piece_amount: number;
  options_type: string;
  payment_deadline: string;
  payment_deadline_label: string;
  proposals_exist: boolean;
  piece_amount: number;
  price: string;
  product_name: string;
  pzn: string;
  selling_date: string | null;
  trade_type: string;
  relative_exp_date: number;
  calculated_aek_discount: number;
  id: string;
  best_offer_limit_reached?: boolean;
  MSV3deliverables?: '<' | '>=' | null;
  offer?: {
    aep_discount: string;
    apu_discount: string;
    best_offer_aep_discount: string;
    best_offer_apu_discount: string;
    id: string;
    price: string;
    best_offer_auto_accept: string;
    package_size: string;
    status_description: string;
  };
  proposals: ProposalI[];
  own_proposals: ProposalI[];
  shipping_costs_carrier: ShippingCostsHandlerOptions;
  matchmaking: ArticleMatchingI[];
  article_location_country: string;
  favorite: boolean;
  MSV3Availabilities?: [];
  attributes?: {
    product_name: string;
  };
}

export interface MSV3AvailabilitiesI {
  amount_asked: number;
  amount_deliverable: number;
  desired_amount?: number;
  partner_name: string;
  pzn: string;
  delivery_parts: [];
  product_name: string;
  provider_id: string;
  id?: number;
  partners?: any[];
}

export interface ArticleSuggestionI {
  aep: string;
  apothekenpflichtig?: number;
  apu: number;
  avp: number;
  btm?: number;
  kennzeichen_arzneimittel?: number;
  kennzeichen_medizinprodukt?: number;
  mp31?: number;
  name: string;
  package_size: string;
  pzn: string;
  tfg?: number;
  verschreibungspflichtig?: number;
}

export interface ProposalI {
  aep?: number;
  aep_marge?: number;
  apu?: number;
  article_id?: string;
  charge_number?: string;
  selling_date?: string | null;
  proposal_id: number;
  offer: string;
  origin_offer: string;
  price_per_piece: string;
  piece_amount: number;
  recipient_piece_amount: number;
  aep_discount: string;
  apu_discount: string;
  status: ArticleOptionsTypes;
  completed_date: string;
  expiration_date: string;
  product_data?: {
    pzn: string;
    name: string;
  };
  trade_type: string;
}

export interface MassImportImportData {
  headers: string[];
  content: string[][];
  isMM: boolean;
}

export interface ProductFinderMassImportImportData {
  headers: string[];
  content: ProductFinderI[];
  isMM: boolean;
}

export interface ProductFinderI {
  position: number;
  pzn: string;
  product_name: string;
  size: number;
  amount: number;
  condition: string;
  negotiable: string;
  status: string;
  period_of_validity: string;
  period_of_offer: string;
  minimum_amount: number;
  place: string;
  shipping_cost: string;
  period_of_payment: string;
  0: number;
  1: string;
  2: string;
  3: number;
  4: number;
  5: string;
  6: string;
  7: string;
  8: string;
  9: string;
  10: number;
  11: string;
  12: string;
  13: string;
}

export interface ArticleMatchingI {
  article_uuid: string;
  name: string;
  amount_asked: number;
  amount_calculated: number;
  max_piece_amount: number;
  expiration_date: string;
  batch: string;
  matchmaking_score: number;
  price: number;
  best_offer_possible: boolean;
  subset_possible: boolean;
  min_piece_amount: number;
  options_type: ArticleOptionsTypes;
}
