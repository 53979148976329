import React from 'react';
import { useTranslation } from 'react-i18next';

const HomeActions = () => {
  const { t } = useTranslation();

  return (
    <div className="container ml-auto mr-auto px-5 lg:px-10">
      <div className="pb-20 text-white leading-8 md:grid block md:grid-cols-3 md:gap-10">
        <div className="card bg-primary p-8">
          <h1 className="card-header font-bold text-xl">{t('Sell')}</h1>
          <p className="text-lg card-content">
            {t('HOME_PAGE_SELL_ACTION_TEXT_1')}&nbsp;
            <strong>{t('HOME_PAGE_SELL_ACTION_BOLD_TEXT_1')}</strong>
            &nbsp;{t('HOME_PAGE_SELL_ACTION_TEXT_2')}
          </p>
        </div>
        <div className="card bg-secondary p-8">
          <h1 className="card-header font-bold text-xl">{t('Buy')}</h1>
          <p className="card-content text-lg">
            {t('HOME_PAGE_BUY_ACTION_TEXT_1')}&nbsp;
            <strong>{t('HOME_PAGE_BUY_ACTION_BOLD_TEXT_1')}</strong>
            &nbsp;{t('HOME_PAGE_BUY_ACTION_TEXT_2')}
          </p>
        </div>
        <div className="card bg-green-600 p-8">
          <h1 className="card-header font-bold text-xl">{t('Search')}</h1>
          <p className="card-content text-lg">
            {t('HOME_PAGE_SEARCH_ACTION_TEXT_1')}&nbsp;
            <strong>{t('HOME_PAGE_SEARCH_ACTION_BOLD_TEXT_1')}</strong>
            &nbsp;{t('HOME_PAGE_SEARCH_ACTION_TEXT_2')}
            &nbsp;<strong>{t('HOME_PAGE_SEARCH_ACTION_BOLD_TEXT_2')}</strong>
            &nbsp;{t('HOME_PAGE_SEARCH_ACTION_TEXT_3')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeActions;
