import { AddressI } from '../data/address.model';

export enum AddressType {
  BILLING = 'CompanyInvoiceAddress',
  DELIVERY = 'CompanyDeliveryAddress',
}

export type CreatedEditedDeletedAddress = {
  address: AddressI;
  type: AddressType;
};
