// eslint-disable-next-line import/prefer-default-export
export enum RegistrationActionTypes {
  TOGGLE_SIGNUP_FORM = 'Registration/TOGGLE_SIGNUP_FORM',
  TOGGLE_LOGIN_FORM = 'Registration/TOGGLE_LOGIN_FORM',
  TOGGLE_PASSWORD_RESET_FORM = 'Registration/TOGGLE_PASSWORD_RESET_FORM',
  TOGGLE_COMPANY_REGISTRATION_FORM = 'Registration/TOGGLE_COMPANY_REGISTRATION_FORM',
  HIDE_FORM = 'Registration/HIDE_FORM',
  SET_TOKENS_STORAGE = 'Registration/SET_ACCESS_TOKEN_STORAGE',
  SET_CURRENT_USER = 'Registration/SET_CURRENT_USER',
  REGISTRATION_ERROR = 'Registration/REGISTRATION_ERROR',
  SET_SIGNUP_SUBMISSION_MESSAGE = 'Registration/SET_SIGNUP_SUBMISSION_MESSAGE',
  SET_TOKENS_VALUE = 'Registration/SET_TOKENS_VALUE',
  SET_IS_LOADING = 'Registration/SET_IS_LOADING',
  PASSWORD_RESET_INIT = 'Registration/PASSWORD_RESET_INIT',
  PASSWORD_RESET_COMPLETE = 'Registration/PASSWORD_RESET_COMPLETE',
  SET_CURRENT_FORM_TYPE = 'Registration/SET_CURRENT_FORM',
  LOGOUT = 'Registration/LOGOUT',
}
