/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ArticleOfferI } from '../../../../../globals/models/components/dataTable';
import generatePaginationDetails from '../../../../../shared/utils/pagination';
import {
  MSV3ApiError,
  Msv3PartnerI,
  MSV3PZNAvailability,
  MSV3ServerResponse,
} from '../../../../../globals/models/data/msv3.model';
import MSV3ArticleInfoTable from '../MSV3ArticleInfoTable';
import DeliveryDetails from './DeliveryDetails';
import ModalsPaginator from '../../ModalsPaginator';
import { MSV3CheckoutSteps } from '../../../../../shared/constants/dataTable';
import { buyMsv3Items } from '../../../../../store/actions/creators/MSV3';
import MSV3CheckoutResultsDisplay from './MSV3CheckoutResultsDisplay';

const Step2Title: React.FC = () => {
  const [hasDisplayedOutline, setHasDisplayedOutline] = useState(true);
  const { t } = useTranslation();

  setTimeout(() => {
    setHasDisplayedOutline(false);
  }, 3000);
  return (
    <span className={`p-2 ${hasDisplayedOutline ? '?' : ''}`}>
      {t('MSV3.CHECKOUT.STEP2_TITLE')}
    </span>
  );
};

/**
 * Static translation
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_CONFIRMED')
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_DECLINED')
 * t('PROFILER_GET_ADDRESSES_ERROR')
 */

const MSV3CheckoutModal = ({
  articleOffer,
  closeModal,
  currentPZNAvailability,
  ownMsv3PartnersList,
  _buyMsv3Items,
  checkoutResult,
}: CompletedProposalsModalProps) => {
  const { t } = useTranslation();

  const data = currentPZNAvailability
    ? Object.keys(currentPZNAvailability).map((providerId) => {
        const results = currentPZNAvailability[providerId].find(
          (el) => el.pzn === articleOffer.attributes.pzn,
        );

        return {
          partnerName: ownMsv3PartnersList.find((el) => el.id === providerId)
            ?.name,
          deliverableAmount: results?.amount_deliverable,
          providerId: ownMsv3PartnersList.find((el) => el.id === providerId)
            ?.soapId as string,
        };
      })
    : [];

  const possiblePageLimits = [10, 20, 50, 100, 200];
  const [currentPageMeta, setCurrentPageMeta] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageLimitMeta, setCurrentPageLimitMeta] = useState(
    possiblePageLimits[0],
  );
  const metaOffset = (currentPageMeta - 1) * currentPageLimitMeta;

  const { pages } = generatePaginationDetails(
    data.length,
    metaOffset,
    currentPageLimitMeta,
  );

  const amountsInitialState: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount: number;
      touched: boolean;
    };
  } = {};

  if (data.length !== 0) {
    data
      .slice(metaOffset, metaOffset + currentPageLimitMeta)
      .forEach((value) => {
        amountsInitialState[value.providerId] = {
          amount: `${value.deliverableAmount}`,
          pzn: articleOffer.attributes.pzn,
          deliverableAmount: value.deliverableAmount as number,
          touched: true,
        };
      });
  }
  const [desiredAmounts, setDesiredAmounts] = useState(amountsInitialState);
  const [currentStep, setCurrentStep] = useState<MSV3CheckoutSteps>(
    MSV3CheckoutSteps.PREFERRED_AMOUNTS,
  );

  const errorMessages: { [key: string]: string | undefined } = {};

  // if (data.length !== 0) {
  //   data
  //     .slice(metaOffset, metaOffset + currentPageLimitMeta)
  //     .forEach((value) => {
  //       errorMessages[value.providerId] =
  //         Number(desiredAmounts[value.providerId].amount) >
  //         (value.deliverableAmount as number)
  //           ? t('MSV3.CHECKOUT.DESIRED_AMOUNT_ERROR', {
  //               value: value.deliverableAmount,
  //             })
  //           : undefined;
  //     });
  // }

  return (
    <div className="h-screen-70 relative">
      {currentStep !== MSV3CheckoutSteps.RESULTS_DISPLAY && (
        <>
          <h1 className="text-center pt-6 text-md text-base font-bold">
            {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ARTICLE_INFO_SUB_TITLE')}
          </h1>
          <MSV3ArticleInfoTable articleOffer={articleOffer} />
        </>
      )}
      <h1 className="text-center pt-6 text-md md:text-base font-bold px-4">
        {currentStep === MSV3CheckoutSteps.PREFERRED_AMOUNTS && (
          <div className="mt-6">
            <span className="text-primary text-lg">
              {t('MSV3.CHECKOUT.STEP1_TITLE')}
            </span>
            <br />
            <hr />
            <br />
            <span className="text-warning text-md">
              {t('MSV3.CHECKOUT.INFO')}
            </span>
          </div>
        )}
        <span className="text-primary text-lg">
          {currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING && (
            <Step2Title />
          )}

          {currentStep === MSV3CheckoutSteps.RESULTS_DISPLAY &&
            t('MSV3.CHECKOUT.STEP3_TITLE')}
        </span>
      </h1>
      {currentStep !== MSV3CheckoutSteps.RESULTS_DISPLAY && (
        <DeliveryDetails
          articleOffer={articleOffer}
          currentPageLimitMeta={currentPageLimitMeta}
          desiredAmounts={desiredAmounts}
          setDesiredAmounts={setDesiredAmounts}
          data={
            data as {
              partnerName: string;
              deliverableAmount: number;
              providerId: string;
              touched: boolean;
            }[]
          }
          errorMessages={errorMessages}
          metaOffset={metaOffset}
          currentStep={currentStep}
          goNext={() => {
            if (currentStep === MSV3CheckoutSteps.PREFERRED_AMOUNTS) {
              setCurrentStep(MSV3CheckoutSteps.CHECK_BEFORE_BUYING);
            }

            if (currentStep === MSV3CheckoutSteps.CHECK_BEFORE_BUYING) {
              setIsLoading(true);
              _buyMsv3Items(
                desiredAmounts,
                articleOffer.proposal?.id as number,
              ).then(({ payload }) => {
                if (typeof payload !== 'string') {
                  setCurrentStep(MSV3CheckoutSteps.RESULTS_DISPLAY);
                  setIsLoading(false);
                }
              });
            }
          }}
          isLoading={isLoading}
        />
      )}
      {currentStep !== MSV3CheckoutSteps.RESULTS_DISPLAY && (
        <ModalsPaginator
          currentPageLimitMeta={currentPageLimitMeta}
          currentPageMeta={currentPageMeta}
          setCurrentPageLimitMeta={setCurrentPageLimitMeta}
          setCurrentPageMeta={setCurrentPageMeta}
          pages={pages}
          possiblePageLimits={possiblePageLimits}
        />
      )}
      {currentStep === MSV3CheckoutSteps.RESULTS_DISPLAY && (
        <div className="px-6">
          <h2 className="font-semibold mb-2">
            {t('MSV3.CHECKOUT.SUCCESSFUL_TRANSACTIONS')}
          </h2>
          <MSV3CheckoutResultsDisplay
            data={Object.keys(checkoutResult.results).map(
              (errorProviderId) =>
                data.find(
                  (record) => record.providerId === errorProviderId,
                ) as {
                  partnerName: string;
                  deliverableAmount: number;
                  providerId: string;
                },
            )}
            desiredAmounts={desiredAmounts}
          />
          <h2 className="font-semibold mt-4 mb-2">
            {t('MSV3.CHECKOUT.UNSUCCESSFUL_TRANSACTIONS')}
          </h2>
          <MSV3CheckoutResultsDisplay
            data={Object.keys(checkoutResult.errors).map(
              (errorProviderId) =>
                data.find(
                  (record) => record.providerId === errorProviderId,
                ) as {
                  partnerName: string;
                  deliverableAmount: number;
                  providerId: string;
                },
            )}
            desiredAmounts={desiredAmounts}
          />
        </div>
      )}
      <div
        className={`text-right pt-1 ${
          currentStep === MSV3CheckoutSteps.RESULTS_DISPLAY &&
          'absolute w-full bottom-0'
        }`}
      >
        <button
          type="button"
          className="button m-4 ml-6 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
          onClick={() => closeModal()}
        >
          {t('CLOSE_TEXT')}
        </button>
      </div>
    </div>
  );
};

interface CompletedProposalsModalProps {
  articleOffer: ArticleOfferI;
  closeModal: () => void;
  currentPZNAvailability: MSV3PZNAvailability | null;
  ownMsv3PartnersList: Msv3PartnerI[];
  _buyMsv3Items: (
    data: {
      [key: string]: {
        amount: string;
        pzn: string;
        deliverableAmount: number;
        touched: boolean;
      };
    },
    proposalId: number,
  ) => Promise<{
    type: string;
    payload:
      | {
          results: ObjectT<MSV3ServerResponse>;
          errors: ObjectT<MSV3ApiError>;
        }
      | string;
  }>;

  checkoutResult: {
    results: ObjectT<MSV3ServerResponse>;
    errors: ObjectT<MSV3ApiError>;
  };
}

const mapStateToProps = ({
  msv3State: {
    currentPZNAvailability,
    partnersList: { own },
    checkoutResult,
  },
}: StoreI) => ({
  currentPZNAvailability,
  ownMsv3PartnersList: own,
  checkoutResult,
});

export default connect(mapStateToProps, {
  _buyMsv3Items: buyMsv3Items,
})(MSV3CheckoutModal);
