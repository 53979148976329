/* eslint-disable react/require-default-props,@typescript-eslint/indent */

import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faClock,
  faEdit,
  faHistory,
  faInfoCircle,
  faShoppingCart,
  faTimes,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import {
  ArticleI,
  ArticleOptionsTypes,
  ProposalI,
} from '../../../../globals/models/data/article.model';
import { offersAndSearchesLinks } from '../../../../shared/utils/dataTable';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../../globals/models/components/dataTable';
import GeneralIcon from './GeneralIcon';
import NotConfirmedIcons from './NotConfirmedIcons';
import ActiveOfferIcons from './ActiveOfferIcons';
import { setArticleToEdit } from '../../../../store/actions/creators/articleEditor';
import {
  removeItemFromShoppingCart,
  setIsAddingToShoppingCart,
} from '../../../../store/actions/creators/shoppingCart';
import { setTableModal } from '../../../../store/actions/creators/dataTable';

// Static translations
/**
 * t('DATA_TABLE.OPEN_PROPOSALS_MODAL.TITLE')
 * t('DATA_TABLE.DELETE_MODAL.TITLE')
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.TITLE')
 */

const RawActions = ({
  isShoppingCartView,
  _setArticleToEdit,
  _data,
  row,
  _removeItemFromShoppingCart,
  shoppingCartViewType,
  isLoading,
  _setIsAddingToShoppingCart,
  iconRef,
  _setTableModal,
}: RowActionsProps) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [removeFromCartIconRef] = useState(Math.random());

  const openProposals =
    pathname === '/cart'
      ? []
      : row.attributes.proposals.filter(
          (el: ProposalI) =>
            ![
              ArticleOptionsTypes.CONFIRMED,
              ArticleOptionsTypes.DECLINED,
            ].includes(el.status),
        );

  const completedProposals =
    pathname === '/cart'
      ? []
      : row.attributes.proposals.filter((el: ProposalI) =>
          [ArticleOptionsTypes.CONFIRMED].includes(el.status),
        );

  if (isShoppingCartView) {
    return (
      <button
        type="button"
        className="hover:text-red-600 -ml-2 general-hover-effect"
        onClick={() => {
          _setIsAddingToShoppingCart(true, removeFromCartIconRef);
          _removeItemFromShoppingCart(
            row,
            // SWITCHED BECAUSE OF https://gitlab.com/tramedix/marketplace-react-frontend/-/issues/133#note_488538312
            // TODO: Remove after comment's claim is fixed
            shoppingCartViewType === 'buying' ? 'selling' : 'buying',
          );
        }}
        disabled={isLoading}
      >
        {t('Remove')}
        {isLoading && iconRef === removeFromCartIconRef ? (
          <CircularProgress
            style={{ color: 'red' }}
            size={15}
            className="ml-2"
          />
        ) : (
          <FontAwesomeIcon icon={faTimes} className=" ml-2" />
        )}
      </button>
    );
  }

  // TODO: Use Is_new and edit_allowed after they are part of the payload
  if (
    offersAndSearchesLinks
      .filter((link) => link.includes('own'))
      .includes(pathname)
  ) {
    return (
      <>
        <Tooltip
          title={t('EDIT_TEXT') as string}
          placement="top-end"
          className="mr-2 cursor-pointer"
        >
          <button
            type="button"
            className="general-hover-effect"
            onClick={() => {
              _setArticleToEdit({
                ...row.attributes,
                id: row.id as string,
              }).then(
                ({
                  payload: {
                    offer: { id },
                  },
                }: any) => {
                  history.push(
                    `/application/${match.path.split('/')[2]}/${id}/edit`,
                  );
                },
              );
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </Tooltip>
        <Tooltip
          title={t('DELETE_TEXT') as string}
          placement="top-end"
          className="hover:text-red-600 cursor-pointer"
        >
          <button
            type="button"
            className="outline-none general-hover-effect"
            onClick={() => {
              _setTableModal(
                DataTableModalOptions.DELETE_ARTICLE,
                'DATA_TABLE.DELETE_MODAL.TITLE',
                row,
              );
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </Tooltip>
        <Tooltip
          title={
            completedProposals.length === 0
              ? (t(
                  'DATA_TABLE.COMPLETED_PROPOSALS_MODAL.TIPS.GOTO_BUTTON_DISABLED',
                ) as string)
              : (t(
                  'DATA_TABLE.COMPLETED_PROPOSALS_MODAL.TIPS.GOTO_BUTTON',
                ) as string)
          }
          placement="top-end"
          className={`${openProposals.length === 0 ? 'c-no-drop' : ''}`}
          arrow
        >
          <span>
            <button
              type="button"
              className={`outline-none general-hover-effect ${
                completedProposals.length === 0 ? 'c-no-drop text-grey-1' : ''
              }`}
              onClick={() => {
                if (completedProposals.length !== 0) {
                  _setTableModal(
                    DataTableModalOptions.COMPLETED_PROPOSALS,
                    'DATA_TABLE.COMPLETED_PROPOSALS_MODAL.TITLE',
                    row,
                    'md',
                  );
                }
              }}
            >
              <FontAwesomeIcon icon={faHistory} className="ml-2" />
            </button>
          </span>
        </Tooltip>
      </>
    );
  }

  switch (row.attributes.options_type as ArticleOptionsTypes) {
    case ArticleOptionsTypes.IN_CHECKOUT:
      return (
        <GeneralIcon
          tipText={t('ALREADY_IN_SHOPPING_CART_TEXT')}
          faIcon={faShoppingCart}
          hoverClassName="text-red-600 cursor-not-allowed"
          row={row}
          modalType={DataTableModalOptions.DEFAULT}
        />
      );
    case ArticleOptionsTypes.PROPOSAL_EXISTS:
      if (
        row.attributes.own_proposals.find(
          (proposal) => proposal.status === ArticleOptionsTypes.NOT_CONFIRMED,
        )
      ) {
        return (
          <GeneralIcon
            tipText={t('OFFER_MADE_TEXT')}
            faIcon={faInfoCircle}
            hoverClassName="text-red-600 cursor-not-allowed"
            row={row}
            modalType={DataTableModalOptions.DEFAULT}
          />
        );
      }
      return <ActiveOfferIcons row={row} />;
    case ArticleOptionsTypes.NOT_CONFIRMED:
      return <NotConfirmedIcons row={row} />;
    case ArticleOptionsTypes.IN_PROCESS:
      return (
        <GeneralIcon
          tipText={t('Waiting for confirmation')}
          faIcon={faClock}
          hoverClassName="text-red-600 cursor-not-allowed"
          row={row}
          modalType={DataTableModalOptions.DEFAULT}
        />
      );
    case ArticleOptionsTypes.CONFIRMED:
      return (
        <GeneralIcon
          tipText={t('Confirmed')}
          faIcon={faCheck}
          hoverClassName="text-red-600 cursor-not-allowed"
          row={row}
          modalType={DataTableModalOptions.DEFAULT}
        />
      );
    case ArticleOptionsTypes.DECLINED:
      return (
        <GeneralIcon
          tipText={t('Declined')}
          faIcon={faTimesCircle}
          hoverClassName="text-red-600 cursor-not-allowed"
          row={row}
          modalType={DataTableModalOptions.DEFAULT}
        />
      );
    case ArticleOptionsTypes.FOREIGN_ARTICLE:
      return <ActiveOfferIcons row={row} />;
    default:
      return <ActiveOfferIcons row={row} />;
  }
};

interface RowActionsProps {
  isShoppingCartView: boolean;
  _setArticleToEdit: (
    article: ArticleI,
  ) => Promise<{ type: string; payload: ArticleI }>;
  _data: ArticleOfferI[];
  row: ArticleOfferI;
  _removeItemFromShoppingCart: (
    row: ArticleOfferI,
    proposalType: 'buying' | 'selling',
  ) => Promise<{
    type: string;
    payload:
      | { article: ArticleOfferI; proposalType: 'buying' | 'selling' }
      | string;
  }>;
  shoppingCartViewType?: 'buying' | 'selling';
  isLoading: boolean;
  _setIsAddingToShoppingCart: (
    value: boolean,
    iconRef: number,
  ) => { type: string; payload: { value: boolean; iconRef: number } };
  iconRef: number | null;
  _setTableModal: (
    value: DataTableModalOptions | null,
    title: string | null,
    article?: ArticleOfferI,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  ) => {
    type: string;
    payload: {
      page: DataTableModalOptions | null;
      title: string | null;
      article?: ArticleOfferI;
    };
  };
}

const mapStateToProps = ({
  shoppingCartState: { loadingIconRef, isAddingToCart },
}: StoreI) => ({
  isLoading: isAddingToCart,
  iconRef: loadingIconRef,
});

export default connect(mapStateToProps, {
  _setArticleToEdit: setArticleToEdit,
  _removeItemFromShoppingCart: removeItemFromShoppingCart,
  _setIsAddingToShoppingCart: setIsAddingToShoppingCart,
  _setTableModal: setTableModal,
})(RawActions);
