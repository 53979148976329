import React from 'react';
import { connect } from 'react-redux';
import Shell from './Shell';
import HeaderContent from './HeaderContent';
import { setCurrentUser } from '../../../../store/actions/creators/registration';

interface HeaderProps {
  isAuth: boolean;
}

const Header = ({ isAuth }: HeaderProps) => {
  return isAuth ? <Shell /> : <HeaderContent />;
};

const mapStateToProps = ({ registrationState: { isAuth } }: StoreI) => ({
  isAuth,
});

export default connect(mapStateToProps, {
  _setCurrentUser: setCurrentUser,
})(Header);
