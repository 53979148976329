import React from 'react';

export enum InputChangeState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNTOUCHED = 'UNTOUCHED',
  TOUCHED = 'TOUCHED',
}

export enum TextInputType {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
}

export interface FormTextInputProps {
  labelKey: string | JSX.Element;
  forText: string;
  isRequired: boolean;
  classes?: {
    input?: string;
    label?: string;
    container?: string;
  };
  tabIndex?: number;
  type?: TextInputType;
  placeHolder?: string;
  onChange?: (
    value: string,
    event?: Event | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  value?: string;
  changeState?: InputChangeState;
  errorMessage?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  min?: number;
  disabled?: boolean;
  name?: string;
  isLoading?: boolean;
  subLabel?: string;
  pattern?: string;
}
