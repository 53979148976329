/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { Parser, transforms } from 'json2csv';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setCurrentModalType } from '../../../../store/actions/creators/modals';
import { ModalsTypes } from '../../../../globals/models/redux';
import { getAdminCompanyTransactions } from '../../../../store/actions/creators/admin/companies';
import { PaginatedItems } from '../../../../globals/models/data';
import { Transaction } from '../../../../globals/models/data/company.model';

const TransactionsExportModal = ({
  _setCurrentModal,
  _getAdminCompanyTransactions,
  isLoadingTransactionsToDownload,
  transactionsToDownload,
}: TransactionsExportModalProps) => {
  const { t, i18n } = useTranslation();

  // function to handle the state of the material dialog
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    _setCurrentModal(null);
  };

  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(
    new Date(Date.now() + 3600000 * 24 * 30),
  );

  const isValid = startDate && endDate && startDate < endDate;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <div className="px-4 pt-4 pb-2">
        <h2 className="text-base">
          {t('ADMIN.TRANSACTIONS.EXPORT_MODAL_LABEL')}
        </h2>
        <br />

        <div className="text-sm text-red-600">
          {!startDate && !endDate && t('ADMIN.TRANSACTIONS.NO_DATES_PROVIDED')}
          {startDate > endDate && t('ADMIN.TRANSACTIONS.WRONG_DATES')}
        </div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-between">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={t('YEAR_DATE_FORMAT')}
              margin="normal"
              id="date-picker-inline"
              label={t('ADMIN.TRANSACTIONS.START_DATE')}
              value={startDate}
              onChange={(value: Date | null) => {
                setStartDate(value as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': t('ADMIN.TRANSACTIONS.DATE_PICKER_PLACE_HOLDER'),
              }}
            />
            <div className="w-10">&nbsp;</div>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format={t('YEAR_DATE_FORMAT')}
              margin="normal"
              id="date-picker-inline"
              label={t('ADMIN.TRANSACTIONS.END_DATE')}
              value={endDate}
              onChange={(value: Date | null) => {
                setEndDate(value as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': t('ADMIN.TRANSACTIONS.DATE_PICKER_PLACE_HOLDER'),
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <div className="text-right mt-10">
          <button
            className={`button ${
              !isValid ? 'bg-primary-disabled c-no-drop' : ''
            }`}
            type="button"
            onClick={() => {
              setIsLoading(true);
              _getAdminCompanyTransactions(
                undefined,
                startDate?.toISOString(),
                endDate?.toISOString(),
              ).then(({ payload }) => {
                if (typeof payload !== 'string') {
                  const csvHeaders = [
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.PRODUCT_NAME'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.QUANTITY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BATCH'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.PRICE_PER_UNIT'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.COMMISSION'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.EXP_DATE'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_COMPANY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_EMAIL'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_NAMES'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_STREET'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_ZIPCODE'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_CITY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_COUNTRY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_COMPANY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_EMAIL'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_NAMES'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_STREET'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_ZIPCODE'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_CITY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_COUNTRY'),
                    t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.TYPE'),
                  ];

                  const transformsData = [
                    transforms.unwind({
                      paths: csvHeaders,
                    }),
                  ];
                  const json2csvParser = new Parser({
                    fields: csvHeaders,
                    transforms: transformsData,
                  });

                  const csv = json2csvParser.parse(
                    payload.data.map((el) => ({
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.PRODUCT_NAME',
                      )]: el.attributes.transaction.article_data.product_name,
                      [t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.QUANTITY')]: el
                        .attributes.transaction.article_data.units,
                      [t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BATCH')]: el
                        .attributes.transaction.article_data.batch,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.PRICE_PER_UNIT',
                      )]: el.attributes.transaction.article_data.price,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.COMMISSION',
                      )]: el.attributes.transaction.article_data.commission,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.EXP_DATE',
                      )]: new Date(
                        el.attributes.transaction.article_data.expiration_date,
                      ).toLocaleDateString(
                        i18n.language === 'en' ? 'en-US' : 'de-DE',
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        },
                      ),
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_COMPANY',
                      )]: el.attributes.transaction.seller.company_name,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_EMAIL',
                      )]: el.attributes.transaction.seller.contact_email,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_NAMES',
                      )]: el.attributes.transaction.seller.contact_person,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_STREET',
                      )]: el.attributes.transaction.seller.address.street,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_ZIPCODE',
                      )]: el.attributes.transaction.seller.address.zipcode,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_CITY',
                      )]: el.attributes.transaction.seller.address.city,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.SELLER_COUNTRY',
                      )]: el.attributes.transaction.seller.address.country,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_COMPANY',
                      )]: el.attributes.transaction.buyer.company_name,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_EMAIL',
                      )]: el.attributes.transaction.buyer.contact_email,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_NAMES',
                      )]: el.attributes.transaction.buyer.contact_person,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_STREET',
                      )]: el.attributes.transaction.buyer.address.street,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_ZIPCODE',
                      )]: el.attributes.transaction.buyer.address.zipcode,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_CITY',
                      )]: el.attributes.transaction.buyer.address.city,
                      [t(
                        'ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.BUYER_COUNTRY',
                      )]: el.attributes.transaction.buyer.address.country,
                      [t('ADMIN.TRANSACTIONS.CSV_EXPORT_HEADERS.TYPE')]: el
                        .attributes.transaction.type,
                    })),
                  );
                  const element = document.createElement('a');
                  const file = new Blob([csv], { type: 'text/csv' });
                  element.href = URL.createObjectURL(file);
                  element.download = 'tramedix-transactions.csv';
                  document.body.appendChild(element);

                  element.click();

                  setIsLoading(false);
                  handleClose();
                }
              });
            }}
            disabled={!isValid}
          >
            {t('SUBMIT_TEXT')}
            {(isLoadingTransactionsToDownload || isLoading) && (
              <CircularProgress
                style={{ color: 'white' }}
                size={15}
                className="ml-2"
              />
            )}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

interface TransactionsExportModalProps {
  _setCurrentModal: (
    type: ModalsTypes | null,
  ) => { type: string; payload: ModalsTypes | null };
  _getAdminCompanyTransactions: (
    offset?: number,
    startDate?: string,
    endDate?: string,
  ) => Promise<{
    type: string;
    payload:
      | PaginatedItems<{
          type: string;
          id: string;
          attributes: {
            transaction: Transaction;
          };
        }>
      | string;
  }>;
  isLoadingTransactionsToDownload: boolean;
  transactionsToDownload: PaginatedItems<{
    type: string;
    id: string;
    attributes: {
      transaction: Transaction;
    };
  }>;
}

const mapStateToProps = ({
  adminAreaState: {
    companies: { isLoadingTransactionsToDownload, transactionsToDownload },
  },
}: StoreI) => ({
  isLoadingTransactionsToDownload,
  transactionsToDownload,
});

export default connect(mapStateToProps, {
  _setCurrentModal: setCurrentModalType,
  _getAdminCompanyTransactions: getAdminCompanyTransactions,
})(TransactionsExportModal);
