export default function generatePaginationDetails(
  count: number,
  offset: number,
  limit: number,
) {
  return {
    pages: Math.ceil(count / limit),
    currentPage: Math.floor(offset / limit) + 1,
    count,
  };
}

export const getCompaniesListLimitBasedOnScreen = () => {
  if (window.innerWidth >= 2560) {
    return 12;
  }

  return 6;
};
