/* eslint-disable @typescript-eslint/indent */
import { Reducer } from 'redux';
import { DataTableShellState } from '../../globals/models/redux';
import initialState from '../initialState';
import {
  DataTableShellActions,
  SetDataTableShellControlsActionI,
  SetDataTableShellPageActionI,
  SetDataTableShellTabActionI,
  SetPageIndexActionI,
} from '../../globals/models/actions/dataTableShell';
import { DataTableShellActionTypes } from '../actions/types/dataTableShell';

export const dataTableShellReducer: Reducer<
  DataTableShellState,
  DataTableShellActions
> = (state = initialState.dataTableShellState, action) => {
  switch (action.type) {
    case DataTableShellActionTypes.SET_CURRENT_CONTROLS:
      return {
        ...state,
        controls: (action as SetDataTableShellControlsActionI).payload,
      };

    case DataTableShellActionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        page: (action as SetDataTableShellPageActionI).payload,
      };

    case DataTableShellActionTypes.SET_CURRENT_TAB:
      return {
        ...state,
        tab: (action as SetDataTableShellTabActionI).payload,
      };

    case DataTableShellActionTypes.SET_TABLE_PAGE_INDEX:
      return {
        ...state,
        pageIndex: (action as SetPageIndexActionI).payload,
      };

    default:
      return state;
  }
};
