/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import NumberFormat from 'react-number-format';
import numeral from 'numeral';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';
import generatePaginationDetails from '../../../shared/utils/pagination';
import { ArticleOptionsTypes } from '../../../globals/models/data/article.model';
import { formatTableCellDisplay } from '../../../shared/utils/dataTable';
import ModalsPaginator from './ModalsPaginator';

// static translation
/**
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_CONFIRMED')
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_DECLINED')
 */

const CompletedProposalsModal = ({
  articleOffer,
  closeModal,
}: CompletedProposalsModalProps) => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();

  const data =
    articleOffer && articleOffer.attributes
      ? articleOffer.attributes.proposals
          .filter((el) => [ArticleOptionsTypes.CONFIRMED].includes(el.status))
          .map((proposal) => ({
            date: proposal.completed_date
              ? new Date(proposal.completed_date).toLocaleDateString(
                  i18n.language === 'en' ? 'en-US' : 'de-DE',
                )
              : '',
            quantity: proposal.piece_amount,
            discount: Number(proposal.aep_discount),
            status: proposal.status,
            aep: articleOffer.attributes.aep,
            apu: articleOffer.attributes.apu,
            // TODO: Use commented code below for the display of minimum price
            // minPrice:
            //   articleOffer.attributes.best_offer_possible &&
            //   articleOffer.attributes. ???,
          }))
      : [];

  const possiblePageLimits = [10, 20, 50, 100, 200];
  const [currentPageMeta, setCurrentPageMeta] = useState(1);
  const [currentPageLimitMeta, setCurrentPageLimitMeta] = useState(
    possiblePageLimits[0],
  );
  const metaOffset = (currentPageMeta - 1) * currentPageLimitMeta;

  const { pages } = generatePaginationDetails(
    data.length,
    metaOffset,
    currentPageLimitMeta,
  );

  const articleType = path.includes('offers')
    ? 'OFFER_TEXT'
    : 'PURCHASE_REQUEST_TEXT';

  return (
    <div className="h-screen-70 relative">
      <h1 className="text-center pt-6 text-md text-base font-bold">
        {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ARTICLE_INFO_SUB_TITLE')}
      </h1>
      <div className="p-6 overflow-x-scroll hide-scrollbar">
        {data.length !== 0 && (
          <table
            key={Math.random()}
            className="min-w-full divide-y divide-gray-200 table-fixed border rounded-sm text-md"
          >
            <tbody>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('TABLE_COLUMN_ARTICLE_NAME')}
                </th>
                <td className="font-bold text-md whitespace-no-wrap px-6">
                  {articleOffer?.attributes.product_name}
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('PZN')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  {articleOffer?.attributes.pzn}
                </td>
              </tr>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ORIGINAL_PRICE')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  {formatTableCellDisplay(
                    articleOffer?.attributes.price,
                    'price',
                  )}{' '}
                  €
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.SOLD_ITEMS')}
                </th>
                <td className=" whitespace-no-wrap px-6">
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.SOLD_ITEMS_VALUE', {
                    soldItems: numeral(
                      articleOffer?.attributes.proposals
                        .map((proposal) => proposal.piece_amount)
                        .reduce(
                          (piece_amount1, piece_amount2) =>
                            piece_amount1 + piece_amount2,
                        ),
                    ).format(),
                    totalItems: numeral(
                      articleOffer?.attributes.piece_amount,
                    ).format(),
                  })}
                </td>
              </tr>
              <tr className="bg-grey-2">
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.AEK_DISCOUNT')}
                </th>
                <td className="whitespace-no-wrap px-6">
                  {formatTableCellDisplay(
                    articleOffer?.attributes.aep_discount,
                    'aep',
                  )}{' '}
                  %
                </td>
              </tr>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
                >
                  {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.PRICE_NEGOTIATION')}
                </th>
                <td className="whitespace-no-wrap px-6">
                  {articleOffer?.attributes.best_offer_possible
                    ? t('YES_TEXT')
                    : t('NO_TEXT')}
                </td>
              </tr>
              {/* TODO: Use commented code bellow to display the minimum price */}
              {/* <tr> */}
              {/*  <th */}
              {/*    scope="col" */}
              {/*    className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light" */}
              {/*  > */}
              {/*    {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.MIN_PRICE')} */}
              {/*  </th> */}
              {/*  <td className=" whitespace-no-wrap px-6">--- €</td> */}
              {/* </tr> */}
            </tbody>
          </table>
        )}
      </div>
      <h1 className="text-center pt-6 text-md md:text-base font-bold px-4">
        {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.SUB_TITLE', {
          productType:
            articleType === 'OFFER_TEXT'
              ? t('OFFER_TEXT')
              : t('PURCHASE_REQUEST_TEXT'),
          productName: articleOffer?.attributes.product_name,
        })}
      </h1>
      <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-sm m-6 hide-scrollbar">
        <SwipeableViews axis="x" index={currentPageMeta} enableMouseEvents>
          <span className="opacity-0">&nbsp;</span>
          {new Array(pages).fill(null).map(() => (
            <table
              className="min-w-full divide-y divide-gray-200 table-fixed border"
              key={Math.random()}
            >
              <thead className="bg-grey-light">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.DATE')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.QUANTITY')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    AEK
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    APU
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.AEK_DISCOUNT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length !== 0 &&
                  data
                    .slice(metaOffset, metaOffset + currentPageLimitMeta)
                    .map((el, index) => (
                      <tr
                        key={Math.random()}
                        className="odd:bg-grey-2 text-gray-800 text-base h-10"
                      >
                        <td className="text-center whitespace-no-wrap">
                          {el.date}
                        </td>
                        <td className="text-center whitespace-no-wrap">
                          <NumberFormat
                            value={el.quantity}
                            thousandSeparator={
                              i18n.language === 'de' ? '.' : ','
                            }
                            decimalSeparator={
                              i18n.language === 'de' ? ',' : '.'
                            }
                            className={`w-full text-center text-black ${
                              index % 2 === 0 ? 'bg-grey-2' : 'bg-white'
                            } p-0 m-0`}
                            disabled
                          />
                        </td>
                        <td className="text-center whitespace-no-wrap">
                          {formatTableCellDisplay(el.aep, 'aep')} €
                        </td>
                        <td className="text-center whitespace-no-wrap">
                          {formatTableCellDisplay(el.apu, 'aep')} €
                        </td>
                        <td className="text-center font-bold text-lg whitespace-no-wrap">
                          {el.discount > 0
                            ? '+ '
                            : el.discount === 0
                            ? '+ - '
                            : ''}{' '}
                          {formatTableCellDisplay(el.discount, 'aep')} %
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          ))}
        </SwipeableViews>
      </div>
      {data.length === 0 && (
        <div className="w-full h-10 flex flex-row justify-center whitespace-no-wrap">
          <div>{t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.NO_PROPOSALS')}</div>
        </div>
      )}
      <ModalsPaginator
        currentPageLimitMeta={currentPageLimitMeta}
        currentPageMeta={currentPageMeta}
        setCurrentPageLimitMeta={setCurrentPageLimitMeta}
        setCurrentPageMeta={setCurrentPageMeta}
        pages={pages}
        possiblePageLimits={possiblePageLimits}
      />
      <div className="text-right pt-1">
        <button
          type="button"
          className="button m-4 ml-6 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
          onClick={() => closeModal()}
        >
          {t('CLOSE_TEXT')}
        </button>
      </div>
    </div>
  );
};

interface CompletedProposalsModalProps {
  articleOffer: ArticleOfferI | null;
  closeModal: () => void;
}

export default CompletedProposalsModal;
