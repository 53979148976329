/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';
import {
  AddArticleToShoppingCartActionI,
  ResetTableActionI,
  SetIsAddingToShoppingCartActionI,
} from '../../../globals/models/actions/dataTable';
import server from '../../../services/axios.service';
import i18next from '../../../i18n';

import { ShoppingCartActionTypes } from '../types/shoppingCart';
import {
  SetArticlesActionI,
  SubmitShoppingCartDataActionI,
} from '../../../globals/models/actions/shoppingCart';
import { DataTableActionTypes } from '../types/dataTable';
import store from '../../index';
import { getMSV3Partners, getMSV3PznSuggestions } from './MSV3';

const wrapArticleDataFromProposalData = (
  attributes: any,
  type: string,
  proposalId: number,
) => {
  // eslint-disable-next-line no-param-reassign
  const returnValue = {
    type,
    id: attributes.article_id,
    attributes: {
      ...attributes,
      product_name: attributes.product_data.name,
      pzn: attributes.product_data.pzn,
      options_type: attributes.proposal_status,
      piece_amount: attributes.new_piece_amount, // TODO: Alter after the endpoint has been refactored
      price: attributes.new_price_per_piece, // TODO: Alter after the endpoint has been refactored
    },
    proposal: { id: proposalId },
  } as any;
  returnValue.attributes.calculated_aek_discount = undefined; // TODO: Remove after the endpoint has been refactored min_piece_amount
  returnValue.attributes.min_piece_amount = undefined; // TODO: Remove after the endpoint has been refactored
  delete returnValue.attributes.product_data;
  return returnValue;
};

const getShoppingCartData = async () => {
  const {
    data: {
      data: {
        attributes: { buying_proposals, selling_proposals },
      },
    },
  } = await server.get('/checkout/shopping-cart/');
  const buyingProposals = await Promise.all(
    buying_proposals.map(async (proposalId: number) => {
      const {
        data: {
          data: { attributes, type, id },
        },
      } = await server.get(`/checkout/proposal/${proposalId}/`);
      return wrapArticleDataFromProposalData(attributes, type, Number(id));
    }),
  );
  const sellingProposals = await Promise.all(
    selling_proposals.map(async (proposalId: number) => {
      const {
        data: {
          data: { attributes, type, id },
        },
      } = await server.get(`/checkout/proposal/${proposalId}/`);
      return wrapArticleDataFromProposalData(attributes, type, Number(id));
    }),
  );

  return { buyingProposals, sellingProposals };
};

export const getShoppingCartDetails: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | {
          buyingProposals: ArticleOfferI[];
          sellingProposals: ArticleOfferI[];
        }
      | string;
  }>,
  {
    type: string;
    payload:
      | {
          buyingProposals: ArticleOfferI[];
          sellingProposals: ArticleOfferI[];
        }
      | string;
  },
  null,
  SetArticlesActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const {
      buyingProposals,
      sellingProposals,
    } = (await getShoppingCartData()) as {
      buyingProposals: ArticleOfferI[];
      sellingProposals: ArticleOfferI[];
    };

    dispatch({
      type: ShoppingCartActionTypes.SET_IS_LAYOUT_LOADING,
      payload: false,
    });

    let buyingProposalsMSV3Deliverables = [];
    let sellingProposalsMSV3Deliverables = [];

    dispatch({
      type: ShoppingCartActionTypes.SET_ARTICLES,
      payload: { buyingProposals, sellingProposals },
    });

    if (buyingProposals.length !== 0) {
      buyingProposalsMSV3Deliverables = (
        await getMSV3PznSuggestions(
          buyingProposals.map((el) => ({
            amount: el.attributes.piece_amount,
            pzn: el.attributes.pzn,
            name: el.attributes.product_name,
          })),
          true,
        )(dispatch)
      ).payload as any;

      await getMSV3Partners()(dispatch);

      if (typeof (buyingProposalsMSV3Deliverables as any) !== 'string') {
        dispatch({
          type: ShoppingCartActionTypes.ADD_BUYING_MSV3_DELIVERABLES,
          payload: buyingProposalsMSV3Deliverables,
        });
      }
    }

    if (sellingProposals.length !== 0) {
      sellingProposalsMSV3Deliverables = (
        await getMSV3PznSuggestions(
          sellingProposals.map((el) => ({
            amount: el.attributes.piece_amount,
            pzn: el.attributes.pzn,
            name: el.attributes.product_name,
          })),
          true,
        )(dispatch)
      ).payload as any;

      if (typeof (sellingProposalsMSV3Deliverables as any) !== 'string') {
        dispatch({
          type: ShoppingCartActionTypes.ADD_SELLING_MSV3_DELIVERABLES,
          payload: sellingProposalsMSV3Deliverables,
        });
      }
    }

    return {
      type: ShoppingCartActionTypes.SET_ARTICLES,
      payload: { buyingProposals, sellingProposals },
    };
  } catch (e) {
    return dispatch({
      type: ShoppingCartActionTypes.CART_ERROR,
      payload: i18next.t('SHOPPING_CART_DATA_LOADING_ERROR'),
    });
  }
};

export const submitShoppingCart: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | {
          rows: string[];
          proposalType: 'buying' | 'selling';
        }
      | string;
  }>,
  {
    type: string;
    payload: { rows: string; proposalType: 'buying' | 'selling' } | string;
  },
  any,
  SubmitShoppingCartDataActionI
>> = (
  rows: ArticleOfferI[],
  proposalType: 'buying' | 'selling',
  addresses?: {
    delivery_adress_id: string;
    invoice_adress_id: string;
  },
) => async (dispatch: Dispatch) => {
  const attributes = {
    proposal_ids: rows.map((el) => el.proposal?.id),
  };

  if (addresses) {
    Object.assign(attributes, { ...addresses });
  }

  try {
    if (!addresses && proposalType === 'buying') {
      throw new Error(
        "AddressDataUndefinedError: 'delivery_adress_id' and 'invoice_adress_id' should be part of the payload when proposalType === 'buying'",
      );
    }

    await server.patch('/checkout/shopping-cart/', {
      data: {
        attributes,
      },
    });

    dispatch({
      type: DataTableActionTypes.GET_TRANSACTIONS_NUMBER,
      payload: store.getState().dataTableState.transactionsNumber - rows.length,
    });

    dispatch({
      type: DataTableActionTypes.RESET_TABLE,
    });

    return dispatch({
      type: ShoppingCartActionTypes.SUBMIT_SHOPPING_CART,
      payload: {
        rows: rows.map((el) => el.id as string),
        // SWITCHED BECAUSE OF https://gitlab.com/tramedix/marketplace-react-frontend/-/issues/133#note_488538312
        // TODO: Remove after comment's claim is fixed
        proposalType: (proposalType === 'selling' ? 'buying' : 'selling') as
          | 'buying'
          | 'selling',
      },
    });
  } catch (e) {
    if (!addresses && proposalType === 'buying') {
      console.error(e);
    }
    return dispatch({
      type: ShoppingCartActionTypes.CART_ERROR,
      payload: 'SUBMIT_SHOPPING_CART_ERROR',
    });
  }
};

export const removeItemFromShoppingCart: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | { article: ArticleOfferI; proposalType: 'buying' | 'selling' }
      | string;
  }>,
  {
    type: string;
    payload:
      | { article: ArticleOfferI; proposalType: 'buying' | 'selling' }
      | string;
  },
  any,
  AddArticleToShoppingCartActionI
>> = (row: ArticleOfferI, proposalType: 'buying' | 'selling') => async (
  dispatch: Dispatch,
) => {
  try {
    await server.delete(`/checkout/proposal/${row.proposal?.id}/`);

    dispatch({
      type: DataTableActionTypes.GET_TRANSACTIONS_NUMBER,
      payload: store.getState().dataTableState.transactionsNumber - 1,
    });

    return dispatch({
      type: ShoppingCartActionTypes.REMOVE_ITEM_FROM_SHOPPING_CART,
      payload: {
        article: row,
        proposalType: proposalType === 'buying' ? 'selling' : 'buying',
      },
    });
  } catch (e) {
    return dispatch({
      type: ShoppingCartActionTypes.CART_ERROR,
      payload: i18next.t('REMOVE_ITEM_FROM_SHOPPING_CART_ERROR'),
    });
  }
};

export const setIsAddingToShoppingCart: ActionCreator<ThunkAction<
  { type: string; payload: { value: boolean; iconRef: number } },
  { type: string; payload: { value: boolean; iconRef: number } },
  any,
  SetIsAddingToShoppingCartActionI
>> = (value: boolean, iconRef: number) => (dispatch: Dispatch) => {
  return dispatch({
    type: ShoppingCartActionTypes.SET_IS_ADDING_TO_SHOPPING_CART,
    payload: { value, iconRef },
  });
};

export const resetShoppingCartTable: ActionCreator<ThunkAction<
  { type: string },
  { type: string },
  null,
  ResetTableActionI
>> = () => (dispatch: Dispatch) => {
  return dispatch({
    type: ShoppingCartActionTypes.RESET_TABLE,
  });
};
