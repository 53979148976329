/* eslint-disable @typescript-eslint/indent,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faSearch,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import {
  getArticleSuggestionsFromPzn,
  setArticleSuggestionsValue,
  uploadMassImportFile,
} from '../../store/actions/creators/articleEditor';
import { ArticleSuggestionsSkeleton } from '../../shared/utils/articleEditor';
import { TextInputType } from '../../globals/models/components/textInput';
import TextInput from '../../components/Shared/layouts/TextInput';
import NumberInput from '../../components/Shared/layouts/NumberInput';
import { ProductFinderPropsI } from '../../globals/models/components/articleEditor';
import TrafficLight from '../../components/Shared/layouts/TrafficLight';
import {
  getMSV3PznProductFinder,
  getMSV3PznSuggestion,
} from '../../store/actions/creators/MSV3';
import { getMSV3ToolTipText } from '../../components/DataGrid/config';
import banner from '../../assets/img/Tramedix_Logo.png';
import Msv3Layout from '../../components/Shared/HOCs/Msv3Layout';
import store from '../../store';

const Msv3ProductFinder = ({
  articleSuggestions,
  _getArticleSuggestionsFromPzn,
  _setArticleSuggestionsValue,
  isLoadingSuggestions,
  _uploadMassImportFile,
  massImportData,
  _getMSV3PznSuggestion,
  currentPZNAvailability,
  productFinderImportData,
}: ProductFinderPropsI) => {
  const [pznSearchValue, setPznSearchValue] = useState('');
  const [description, setDescription] = useState('');
  const [pzn, setPzn] = useState('');
  const [amount, setAmount] = useState('');
  const [packageSize, setPackageSize] = useState('');
  const [aek, setAek] = useState('');
  const [apu, setApu] = useState('');
  const [apothekenpflichtig, setApothekenpflichtig] = useState(false);
  const [verschreibungspflichtig, setVerschreibungspflichtig] = useState(false);
  const [kennzeichenArzneimittel, setKennzeichenArzneimittel] = useState(false);
  const [btm, setBtm] = useState(false);
  const [tfg, setTfg] = useState(false);
  const [mp31, setMp31] = useState(false);
  const [kennzeichenMedizinprodukt, setKennzeichenMedizinprodukt] = useState(
    false,
  );
  const [MSV3deliverables, setMSV3deliverables] = useState<null | string>('');
  const fileUploadInputRef = React.createRef<HTMLInputElement>();

  const { t } = useTranslation();

  const pznInputRef = useRef<HTMLInputElement | null>(null);

  const [
    articleSuggestionsAnchorEl,
    setArticleSuggestionsAnchorEl,
  ] = React.useState<HTMLInputElement | null>(null);
  const open = Boolean(articleSuggestionsAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const showSuggestions = (target: HTMLInputElement) => {
    setArticleSuggestionsAnchorEl(target);
  };

  const debouncedGetSuggestions = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        _setArticleSuggestionsValue([]);
        _getArticleSuggestionsFromPzn(event.target.value).then(() => {});
        showSuggestions(event.target);
      }
    }, 1500),
    [],
  );

  const closeSuggestionsPopOver = () => {
    setArticleSuggestionsAnchorEl(null);
  };

  const convertStringToBoolean = (text: string) => {
    return text === '01';
  };
  useEffect(() => {
    if (massImportData?.content.length !== 0) {
      // let importData: any[] = [];
      console.log(massImportData);
      _getMSV3PznSuggestion(
        massImportData!.content.map((el: any) => ({
          amount: el[1],
          pzn: el[0],
          name: el[2] === 'null' ? '---' : el[2],
        })),
      ).then((val) => {
        console.log(val);
        console.log(store.getState().msv3State.currentPZNAvailability);
        console.log(
          store.getState().articleEditorState.productFinderImportData,
        );
      });
    }
  }, [massImportData]);

  return (
    <>
      <main className="general-layout">
        <div className="container mx-auto px-10 lg:px-0">
          <img src={banner} alt="banner" className="h-8 mt-4" />
          <div className="pt-6 pb-10 grid grid-cols-2">
            <div>
              <p className="font-semibold text-3xl  text-primary">
                Product finder
              </p>
              <hr />
              <div className="flex border border-gray-500 rounded-sm w-full h-8">
                <input
                  type="text"
                  id="pzn-search"
                  className="inline flex-grow outline-none px-2"
                  placeholder={t('PZN_SEARCH_INPUT_PLACEHOLDER')}
                  pattern="/^[A-Za-z0-9 /\-_]*$/"
                  onChange={(event) => {
                    if (event.target.value !== '') {
                      if (/^[A-Za-z0-9 /\-_]*$/.test(event.target.value)) {
                        setPznSearchValue(event.target.value);
                        event.persist();
                        debouncedGetSuggestions(event);
                        closeSuggestionsPopOver();
                      } else {
                        debouncedGetSuggestions.cancel();
                      }
                    } else {
                      setPznSearchValue(event.target.value);
                      debouncedGetSuggestions.cancel();
                      closeSuggestionsPopOver();
                      pznInputRef.current?.focus();
                    }
                  }}
                  onClick={() => {
                    if (articleSuggestions.length !== 0) {
                      showSuggestions(pznInputRef.current as HTMLInputElement);
                      setAmount('');
                      setMSV3deliverables(null);
                    }
                  }}
                  ref={pznInputRef}
                  min={0}
                  value={pznSearchValue}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="inline flex-none mr-3 mt-2"
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: '30px',
                paddingLeft: '300px',
              }}
            >
              {amount && (
                <Tooltip
                  title={getMSV3ToolTipText(MSV3deliverables as string)}
                  placement="top"
                >
                  <div>
                    <TrafficLight
                      RedOn={MSV3deliverables === null}
                      YellowOn={MSV3deliverables === '<'}
                      GreenOn={MSV3deliverables === '>='}
                      BlackColor="#fafafa"
                      Size={25}
                      MSV3deliverables={MSV3deliverables}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {pzn && (
            <div className="md:grid block md:grid-cols-2 gap-10">
              <TextInput
                labelKey={t('Gewünschte Menge')}
                forText="amount"
                type={TextInputType.TEXT}
                classes={{
                  input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                    !pzn && 'c-no-drop input'
                  }`,
                }}
                isRequired={false}
                value={amount}
                onChange={(val) => {
                  setAmount(val);
                  getMSV3PznProductFinder([
                    {
                      amount: Number(val),
                      pzn,
                      name: description,
                    },
                  ]).then((value) => {
                    const res = value === null ? null : value.toString();
                    setMSV3deliverables(res);
                  });
                }}
              />
              {(pzn && MSV3deliverables === '<') ||
                (MSV3deliverables === '>=' && (
                  <button
                    type="button"
                    className="button button-finder"
                    onClick={() => {
                      window.location.href = 'https://www.tramedix.com';
                    }}
                  >
                    {t('Weiter zur Bestellung auf Tramedix')}
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </button>
                ))}
            </div>
          )}
          <div className="md:grid block md:grid-cols-2 gap-10">
            <TextInput
              labelKey={t('PZN')}
              forText="pzn"
              type={TextInputType.TEXT}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !pzn && 'c-no-drop input'
                }`,
              }}
              disabled
              isRequired={false}
              value={pzn}
            />
            <TextInput
              labelKey={t('ARTICLE_DESCRIPTION_TEXT')}
              forText="article-description"
              isRequired={false}
              type={TextInputType.TEXT}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !description && 'c-no-drop input'
                }`,
              }}
              disabled
              value={description}
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <NumberInput
              labelKey={t('PACKAGE_SIZE_TEXT')}
              forText="package-size"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !packageSize && 'c-no-drop input'
                }`,
              }}
              disabled
              value={packageSize}
              type={TextInputType.NUMBER}
              min={0}
            />
            <NumberInput
              labelKey={t('AEK')}
              forText="aek"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !aek && 'c-no-drop input'
                }`,
              }}
              disabled
              value={aek}
              type={TextInputType.NUMBER}
              suffix=" €"
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <NumberInput
              labelKey={t('APU of the article')}
              forText="apu"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !apu && 'c-no-drop input'
                }`,
              }}
              disabled
              value={apu}
              type={TextInputType.NUMBER}
              suffix=" €"
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <List dense>
              {apothekenpflichtig && (
                <ListItem>
                  <ListItemText primary="- Apothekenpflichtig" />
                </ListItem>
              )}
              {verschreibungspflichtig && (
                <ListItem>
                  <ListItemText primary="- Verschreibungspflichtig" />
                </ListItem>
              )}
              {btm && (
                <ListItem>
                  <ListItemText primary="- Btm" />
                </ListItem>
              )}
              {tfg && (
                <ListItem>
                  <ListItemText primary="- Tfg" />
                </ListItem>
              )}
              {kennzeichenArzneimittel && (
                <ListItem>
                  <ListItemText primary="- Kennzeichen Arzneimittel" />
                </ListItem>
              )}
              {kennzeichenMedizinprodukt && (
                <ListItem>
                  <ListItemText primary="- Kennzeichen Medizinprodukt" />
                </ListItem>
              )}
              {mp31 && (
                <ListItem>
                  <ListItemText primary="- Mp31" />
                </ListItem>
              )}
            </List>
          </div>
        </div>
        <div className="container mx-auto px-10 lg:px-0">
          <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-sm hide-scrollbar modal-list-table">
            <span className="opacity-0">&nbsp;</span>
            <table
              className="min-w-full divide-y divide-gray-200 table-fixed border"
              key={Math.random()}
            >
              <thead className="bg-grey-light">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    PZN
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    Artikelbezeichnung
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    Gewünschte Menge verfügbar
                  </th>
                </tr>
              </thead>
              <tbody>
                {console.log(productFinderImportData)}
                {productFinderImportData.length !== 0 &&
                  productFinderImportData.map((el: any) => (
                    <tr
                      key={Math.random()}
                      className="odd:bg-grey-2 text-gray-800 text-base h-10"
                    >
                      <td className="text-center whitespace-no-wrap">
                        {' '}
                        {el.pzn}
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        {' '}
                        {el.name}
                      </td>
                      <td className="text-center whitespace-no-wrap">
                        <div className="msv3-traffic-light">
                          <TrafficLight
                            RedOn={el.amount_deliverable === null}
                            YellowOn={
                              el.amount_deliverable !== null &&
                              el.amount_deliverable < el.amount_asked
                            }
                            GreenOn={el.amount_deliverable >= el.amount_asked}
                            BlackColor="#fafafa"
                            Size={18}
                            MSV3deliverables={MSV3deliverables}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                {productFinderImportData.length === 0 && (
                  <tr
                    key={Math.random()}
                    className="odd:bg-grey-2 text-gray-800 text-base h-10"
                  >
                    <td colSpan={5} className="text-center whitespace-no-wrap">
                      {t('ADMIN.COMPANY_TRANSACTIONS.NO_SELECTED_TRANSACTION')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="container mx-auto px-10 lg:px-0">
          <div className="grid grid-cols-6 gap-4 mt-2">
            <div className="col-start-1 col-end-2">
              <input
                type="file"
                className="hidden"
                ref={fileUploadInputRef}
                accept=".csv,.xlsx, .xlsm, .xls,.ods"
                onChange={(event) => {
                  if (event.target.files) {
                    _uploadMassImportFile(event.target.files[0], false);
                  }
                }}
              />
              <button
                type="button"
                className="button bg-primary"
                onClick={() => {
                  if (fileUploadInputRef && fileUploadInputRef.current) {
                    fileUploadInputRef.current.click();
                  }
                }}
              >
                <FontAwesomeIcon icon={faUpload} className="mr-2" />
                {t('MASS_IMPORT_FILE_UPLOAD_BUTTON_TEXT')}
              </button>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-10 lg:px-0">
          {t('MASS_IMPORT_EXAMPLE_SELECTION_TEXT')}
          <a
            href="https://media.tramedix.com/public/Msv3_upload_Mustervorlage.xlsx"
            target="_blank"
            rel="noopener noreferrer"
            className="example_underline"
            download
            color="red"
          >
            <span>{t('MASS_IMPORT_EXAMPLE_FILE_TEXT')}</span>
          </a>
        </div>
        <Popover id={id} open={open} anchorEl={articleSuggestionsAnchorEl}>
          {isLoadingSuggestions && <ArticleSuggestionsSkeleton />}
          {!isLoadingSuggestions && articleSuggestions.length !== 0 && (
            <div className="h-40 overflow-y-scroll p-1 bg-light shadow-lg mt-1 rounded-md">
              {articleSuggestions.map((el) => (
                <p
                  key={el.pzn}
                  className="hover:bg-primary py-2 px-4 rounded-sm cursor-pointer hover:text-white"
                  onClick={() => {
                    setPzn(el.pzn);
                    setDescription(el.name);
                    setPackageSize(el.package_size);
                    setAek(el.aep);
                    setApu(el.apu.toString());
                    setApothekenpflichtig(
                      el.apothekenpflichtig !== undefined
                        ? convertStringToBoolean(
                            el.apothekenpflichtig.toString(),
                          )
                        : false,
                    );
                    setVerschreibungspflichtig(
                      el.verschreibungspflichtig !== undefined
                        ? convertStringToBoolean(
                            el.verschreibungspflichtig.toString(),
                          )
                        : false,
                    );
                    setBtm(
                      el.btm !== undefined
                        ? convertStringToBoolean(el.btm.toString())
                        : false,
                    );
                    setTfg(
                      el.tfg !== undefined
                        ? convertStringToBoolean(el.tfg.toString())
                        : false,
                    );
                    setKennzeichenArzneimittel(
                      el.kennzeichen_arzneimittel !== undefined
                        ? convertStringToBoolean(
                            el.kennzeichen_arzneimittel.toString(),
                          )
                        : false,
                    );
                    setKennzeichenMedizinprodukt(
                      el.kennzeichen_medizinprodukt !== undefined
                        ? convertStringToBoolean(
                            el.kennzeichen_medizinprodukt.toString(),
                          )
                        : false,
                    );
                    setMp31(
                      el.mp31 !== undefined
                        ? convertStringToBoolean(el.mp31.toString())
                        : false,
                    );
                    closeSuggestionsPopOver();
                    pznInputRef.current?.blur();
                  }}
                >
                  {el.pzn} - {el.name}
                </p>
              ))}
            </div>
          )}
        </Popover>
      </main>
    </>
  );
};

const mapStateToProps = ({
  articleEditorState: {
    articleSuggestions,
    isEditMode,
    articleOnEdit,
    isLoadingSuggestions,
    massImportData,
    productFinderImportData,
  },
  msv3State: { currentPZNAvailability },
}: StoreI) => ({
  articleSuggestions,
  isEditMode,
  articleOnEdit,
  isLoadingSuggestions,
  massImportData,
  currentPZNAvailability,
  productFinderImportData,
});

export default connect(mapStateToProps, {
  _getArticleSuggestionsFromPzn: getArticleSuggestionsFromPzn,
  _setArticleSuggestionsValue: setArticleSuggestionsValue,
  _uploadMassImportFile: uploadMassImportFile,
  _getMSV3PznSuggestion: getMSV3PznSuggestion,
})(Msv3Layout(Msv3ProductFinder));
