/* eslint-disable @typescript-eslint/indent */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';

import { RegistrationState } from '../../../globals/models/redux';
import {
  CompanyRegistrationState,
  RegistrationFieldsState,
  RegistrationFormType,
  Tokens,
  UserSignupFieldsState,
} from '../../../globals/models/components/registrationForms';
import { RegistrationActionTypes } from '../types/registration';
import {
  CompletePasswordResetActionI,
  InitializePasswordResetActionI,
  RegistrationActions,
  RegistrationErrorActionI,
  SetCurrentFormActionI,
  SetCurrentUserActionI,
  SetIsLoadingActionI,
  SetTokensStorageActionI,
} from '../../../globals/models/actions/registration';
import { User } from '../../../globals/models/data/user.model';
import server from '../../../services/axios.service';
import store from '../../index';
import {
  decodeUser,
  setCurrentUserWithDispatch,
  storeTokensInLocalStorage,
  storeTokensInSessionStorage,
} from '../../../shared/utils/redux.utils';
import i18next from '../../../i18n';
import { history } from '../../../shared/history';

export const toggleRegistrationForm: ActionCreator<ThunkAction<
  { type: string } | undefined,
  { [key: string]: string },
  RegistrationState['form'],
  RegistrationActions
  // eslint-disable-next-line consistent-return
>> = (formMeta: RegistrationState['form']) => (dispatch: Dispatch) => {
  if (!formMeta.type) {
    return dispatch({
      type: RegistrationActionTypes.HIDE_FORM,
    });
  }

  switch (formMeta.type) {
    case RegistrationFormType.SIGNUP:
      return dispatch({
        type: RegistrationActionTypes.TOGGLE_SIGNUP_FORM,
      });
    case RegistrationFormType.LOGIN:
      return dispatch({
        type: RegistrationActionTypes.TOGGLE_LOGIN_FORM,
      });
    case RegistrationFormType.PASSWORD_RESET:
      return dispatch({
        type: RegistrationActionTypes.TOGGLE_PASSWORD_RESET_FORM,
      });
    case RegistrationFormType.COMPANY:
      return dispatch({
        type: RegistrationActionTypes.TOGGLE_COMPANY_REGISTRATION_FORM,
      });
    default:
      break;
  }
};

export const setAccessTokenStorage: ActionCreator<ThunkAction<
  { type: string; payload: string },
  { type: string; payload: string },
  'localStorage' | 'cookieStorage',
  SetTokensStorageActionI
>> = (storage: 'localStorage' | 'sessionStorage') => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_TOKENS_STORAGE,
    payload: storage,
  });
};

export const login: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: User | string }>,
  { type: string; payload: User | string },
  { email: string; password: string },
  SetCurrentUserActionI
>> = (formData: { email: string; password: string }) => async (
  dispatch: Dispatch,
) => {
  try {
    const result = await server.post<any>('/api/v1/auth/login/', formData);

    dispatch({
      type: RegistrationActionTypes.HIDE_FORM,
    });

    if (store.getState().registrationState.tokensStorage === 'localStorage') {
      storeTokensInLocalStorage(
        result.data.data.access_token,
        result.data.data.refresh_token,
      );
    }

    if (store.getState().registrationState.tokensStorage === 'sessionStorage') {
      storeTokensInSessionStorage(
        result.data.data.access_token,
        result.data.data.refresh_token,
      );
    }

    dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: {
        access: result.data.data.access_token,
        refresh: result.data.data.refresh_token,
      },
    });

    // Redirecting in case it is set in the local storage
    const redirectPath = localStorage.getItem('redirect-path');
    const decodedUser = decodeUser(result.data.data.access_token);

    if (redirectPath) {
      if (window.location.href !== redirectPath) {
        history.push(redirectPath);
      }
      localStorage.removeItem('redirect-path');
    }

    return setCurrentUserWithDispatch(
      dispatch,
      decodedUser,
      true,
      store.getState().registrationState.tokensStorage,
    );
  } catch (error) {
    if (
      error.response?.data?.data?.error.name === 'INVALID_CREDENTIALS' ||
      error.response?.data?.data?.error.name === 'UserDoesNotExist' ||
      error.response?.data?.data?.error.name === 'ENTITY_NOT_FOUND'
    ) {
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: i18next.t('LOGIN_FORM_ERROR_CREDENTIALS_INVALID'),
      });
    }

    return dispatch({
      type: RegistrationActionTypes.REGISTRATION_ERROR,
      payload: i18next.t('LOGIN_FORM_ERROR_UNKNOWN_SERVER_EXCEPTION'),
    });
  }
};

export const setCurrentUser: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload: {
      isAuth: boolean;
      user: User;
      storage: 'localStorage' | 'sessionStorage';
    };
  }>,
  { [key: string]: any },
  string,
  SetCurrentUserActionI
>> = (
  tokens: Tokens,
  isAuth: boolean,
  storage: 'localStorage' | 'sessionStorage',
) => (dispatch: Dispatch) => {
  dispatch({
    type: RegistrationActionTypes.SET_TOKENS_VALUE,
    payload: tokens,
  });
  return Promise.resolve(
    setCurrentUserWithDispatch(
      dispatch,
      decodeUser(tokens.access),
      isAuth,
      storage,
    ),
  );
};

export const setErrorMessage: ActionCreator<ThunkAction<
  { type: string; payload: string | null },
  { [key: string]: any },
  string,
  RegistrationErrorActionI
>> = (errorMessage: string | null) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.REGISTRATION_ERROR,
    payload: errorMessage,
  });
};

export const signup: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: string | null }>,
  { type: string; payload: string | null },
  RegistrationFieldsState,
  RegistrationErrorActionI
>> = (fields: {
  signup: UserSignupFieldsState;
  company: CompanyRegistrationState;
}) => async (dispatch: Dispatch) => {
  const data = new FormData();

  data.append('first_name', fields.signup.firstName);
  data.append('last_name', fields.signup.lastName);
  data.append('email', fields.signup.email);
  data.append('password', fields.signup.password);
  data.append('phone', fields.signup.phoneNumber);
  data.append('company_name', fields.signup.companyName);
  data.append(
    'company_commercial_register_entry',
    fields.company.dataFields.commercialRegisterEntry,
  );
  if (fields.company.dataFields.taxId) {
    data.append('company_tax_id', fields.company.dataFields.taxId);
  }
  if (fields.company.dataFields.taxExempt) {
    data.append('company_tax_exempt', fields.company.dataFields.taxExempt);
  }
  data.append(
    'company_area_pharmacy',
    String(fields.company.approvalsFields.area.pharmacy),
  );
  data.append(
    'company_area_pharmacy_without_wholesale',
    String(fields.company.approvalsFields.area.pharmacyWithoutWholesale),
  );
  data.append(
    'company_area_wholesale',
    String(fields.company.approvalsFields.area.wholesale),
  );
  data.append(
    'company_area_pharma_manufacturer',
    String(fields.company.approvalsFields.area.pharmaManufacturer),
  );
  data.append(
    'company_area_medicine_manufacturer',
    String(fields.company.approvalsFields.area.medicineManufacturer),
  );
  data.append(
    'company_licenses_operating_license',
    fields.company.approvalsFields.companyLicenses.operatingLicense
      .value as Blob,
  );
  data.append(
    'company_licenses_manufacturing_authorization',
    fields.company.approvalsFields.companyLicenses.manufacturingAuthorization
      .value as Blob,
  );
  data.append(
    'company_licenses_commercial_register_extract',
    fields.company.approvalsFields.companyLicenses.commercialRegisterExtract
      .value as Blob,
  );
  data.append(
    'company_licenses_wholesale_permit',
    fields.company.approvalsFields.companyLicenses.wholesalePermit
      .value as Blob,
  );

  try {
    await server.post('/api/v1/auth/register/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return dispatch({
      type: RegistrationActionTypes.SET_SIGNUP_SUBMISSION_MESSAGE,
      payload: i18next.t('COMPANY_REGISTRATION_CONFIRMATION'),
    });
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data.data &&
      e.response.data.data.error &&
      e.response.data.data.error.name === 'EMAIL_ALREADY_EXISTS'
    ) {
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: i18next.t('SIGNUP_FORM_ERROR_USED_EMAIL'),
      });
    }
    return dispatch({
      type: RegistrationActionTypes.REGISTRATION_ERROR,
      payload: i18next.t('LOGIN_FORM_ERROR_UNKNOWN_SERVER_EXCEPTION'),
    });
  }
};

export const setIsLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { [key: string]: any },
  boolean,
  SetIsLoadingActionI
>> = (isLoading: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_IS_LOADING,
    payload: isLoading,
  });
};

export const initializePasswordReset: ActionCreator<ThunkAction<
  Promise<{ type: string; payload?: string }>,
  { [key: string]: any },
  string,
  InitializePasswordResetActionI
>> = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RegistrationActionTypes.SET_IS_LOADING,
      payload: true,
    });
    await server.post('/api/v1/auth/reset-password/', { email });

    toast.success(i18next.t('PASSWORD_RESET.SUCCESS_INIT_MESSAGE', { email }), {
      autoClose: false,
    });
    return dispatch({
      type: RegistrationActionTypes.PASSWORD_RESET_INIT,
    });
  } catch (e) {
    if (e.response && e.response.data.data.error.name === 'ENTITY_NOT_FOUND') {
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: i18next.t('PASSWORD_RESET.ERRORS.EMAIL_NOT_FOUND', { email }),
      });
    }

    return dispatch({
      type: RegistrationActionTypes.REGISTRATION_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const openPasswordResetConfirmationModal: ActionCreator<ThunkAction<
  { type: string; payload: string },
  { [key: string]: any },
  boolean,
  SetCurrentFormActionI
>> = (token: string) => (dispatch: Dispatch) => {
  dispatch({
    type: RegistrationActionTypes.SET_TOKENS_VALUE,
    payload: {
      ...store.getState().registrationState.tokens,
      passwordReset: token,
    },
  });

  return dispatch({
    type: RegistrationActionTypes.SET_CURRENT_FORM_TYPE,
    payload: RegistrationFormType.PASSWORD_RESET_CONFIRMATION,
  });
};

export const completePasswordReset: ActionCreator<ThunkAction<
  Promise<{ type: string; payload?: string }>,
  { [key: string]: any },
  string,
  CompletePasswordResetActionI
>> = (password: string, token: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: RegistrationActionTypes.SET_IS_LOADING,
      payload: true,
    });

    await server.post('/api/v1/auth/reset-password/confirm/', {
      password,
      token,
    });

    toast.success(i18next.t('PASSWORD_RESET.CONFIRMATION.SUCCESS_MESSAGE'), {
      autoClose: false,
    });

    return dispatch({
      type: RegistrationActionTypes.PASSWORD_RESET_COMPLETE,
    });
  } catch (e) {
    return dispatch({
      type: RegistrationActionTypes.REGISTRATION_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const setCurrentFormType: ActionCreator<ThunkAction<
  { type: string; payload: string },
  { [key: string]: any },
  boolean,
  SetCurrentFormActionI
>> = (form: RegistrationFormType) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_CURRENT_FORM_TYPE,
    payload: form,
  });
};

export const logout: ActionCreator<ThunkAction<
  void,
  void,
  boolean,
  any
>> = () => (dispatch: Dispatch) => {
  // Clearing the storage
  localStorage.removeItem('access-token');
  sessionStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
  sessionStorage.removeItem('refresh-token');

  // Initializing redux
  dispatch({
    type: RegistrationActionTypes.LOGOUT,
  });
};
