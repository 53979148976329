/* eslint-disable @typescript-eslint/indent */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { chunk } from 'lodash';
import i18next from '../../../i18n';
import {
  DeleteMSV3OpenTransactionsActionI,
  DeleteMSV3TransactionsActionI,
  DeletePartnerConnectionActionI,
  GetMSV3OpenTransactionsActionI,
  GetMSV3TransactionsActionI,
  RegisterMSV3PartnersActionI,
  SetIsLoadingActionI,
  SetMSV3TransactionsActionI,
} from '../../../globals/models/actions/MSV3';
import { MSV3ActionTypes } from '../types/MSV3';
import server, { msv3ApiInstance } from '../../../services/axios.service';
import { MSV3State } from '../../../globals/models/redux';
import {
  MSV3ApiError,
  MSV3DeliverableI,
  Msv3PartnerI,
  MSV3PZNAvailability,
  MSV3pznReqObjectI,
  MSV3ServerResponse,
} from '../../../globals/models/data/msv3.model';
import { DataTableActionTypes } from '../types/dataTable';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';
import { ArticleEditorActionTypes } from '../types/articleEditor';

export const setIsLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsLoadingActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: MSV3ActionTypes.SET_IS_LOADING,
    payload: value,
  });
};

export const getMSV3Partners = () => async (dispatch: Dispatch) => {
  let msv3UserData: MSV3State['partnersList'];

  try {
    const {
      data: { mymsv3partners: ownMsv3partners },
    } = await msv3ApiInstance.get<{
      mymsv3partners: { partner: Msv3PartnerI }[];
    }>('/msv3/getMyMSV3Partners');

    const { data: allMsv3Partners } = await msv3ApiInstance.get<Msv3PartnerI[]>(
      '/msv3/getMSV3Partners',
    );

    const allMsv3PartnersMapped = allMsv3Partners.map(
      (el: any) =>
        ({
          name: el.name,
          id: '',
          soapId: el.id,
          description: i18next.t('MSV3.LABELS.CONNECTION_ALREADY_EXISTS'),
        } as Msv3PartnerI),
    );

    const ownMsv3partnersMapped = ownMsv3partners.map(
      (el: Merge<{ partner: Msv3PartnerI }, any>) => ({
        name: el.partner.name,
        soapId: el.partner.id,
        description: i18next.t('MSV3.LABELS.CONNECTION_ALREADY_EXISTS'),
        id: el.id,
      }),
    );

    msv3UserData = {
      all: allMsv3PartnersMapped,
      own: ownMsv3partnersMapped,
      foreign: allMsv3PartnersMapped.filter(
        (el: Msv3PartnerI) =>
          !ownMsv3partnersMapped.find(
            (partner: Msv3PartnerI) => partner.soapId === el.soapId,
          ),
      ),
    };

    return dispatch({
      type: MSV3ActionTypes.SET_PARTNERS,
      payload: msv3UserData,
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const registerMSV3Partner: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: { id: string; soapId: string } | string }>,
  { type: string; payload: string },
  { username: string; password: string; id: string },
  RegisterMSV3PartnersActionI
>> = (data: { username: string; password: string; id: string }) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: MSV3ActionTypes.SET_IS_LOADING_SINGLE_PARTNER_REQUEST,
    payload: true,
  });
  try {
    const submissionData = {
      ...data,
      partner_id: data.id,
    };

    delete submissionData.id;

    const { data: registredRecord } = await msv3ApiInstance.post(
      '/msv3/registerMSV3PartnerInstance',
      submissionData,
    );

    dispatch({
      type: MSV3ActionTypes.SET_IS_LOADING_SINGLE_PARTNER_REQUEST,
      payload: false,
    });

    return dispatch({
      type: MSV3ActionTypes.REGISTER_NEW_PARTNER,
      payload: {
        id: registredRecord.data.id,
        soapId: registredRecord.data.partner_id,
      },
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const deleteMSV3Partner: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: string } | string>,
  { type: string; payload: MSV3State['partnersList'] },
  string,
  DeletePartnerConnectionActionI
>> = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: MSV3ActionTypes.SET_IS_LOADING_SINGLE_PARTNER_REQUEST,
    payload: true,
  });
  try {
    await msv3ApiInstance.delete(`/msv3/instance/${id}`);

    dispatch({
      type: MSV3ActionTypes.SET_IS_LOADING_SINGLE_PARTNER_REQUEST,
      payload: false,
    });

    toast.success(i18next.t('MSV3.LABELS.REMOVE_PARTNER_SUCCESS'));

    return dispatch({
      type: MSV3ActionTypes.DELETE_PARTNER_CONNECTION,
      payload: id,
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const setPZNAvailability = (value: MSV3PZNAvailability | null) => (
  dispatch: Dispatch,
) => {
  return dispatch({
    type: MSV3ActionTypes.SET_PZN_AVAILABILITY,
    payload: value,
  });
};

export const getMSV3PznProductFinder = async (pznlist: MSV3pznReqObjectI[]) => {
  const { data } = await msv3ApiInstance.post<{
    [key: string]: Merge<MSV3DeliverableI, any>[][] | { error: boolean };
  }>('/msv3/checkMSV3Availability', {
    pznlist,
  });
  return data;
};

export const getMSV3PznSuggestion: (
  pnlist: MSV3pznReqObjectI[],
) => (
  dispatch: Dispatch,
) => Promise<
  | { payload: Merge<MSV3DeliverableI, any>[]; type: string }
  | { payload: string; type: MSV3ActionTypes }
  | { payload: any; type: MSV3ActionTypes }
> = (pznlist: MSV3pznReqObjectI[]) => async (dispatch: Dispatch) => {
  try {
    const { data } = await msv3ApiInstance.post<{
      [key: string]: Merge<MSV3DeliverableI, any>[][] | { error: boolean };
    }>('/msv3/getPZNAvailability', {
      pznlist,
    });

    if (!data.error) {
      setPZNAvailability(data as any)(dispatch);
    }
    const mappedDeliverables: Merge<MSV3DeliverableI, any>[] = [];
    Object.values(data).forEach((delValue) => {
      if (Array.isArray(delValue)) {
        delValue.forEach(
          (
            {
              amount_asked,
              amount_deliverable,
              pzn,
              name,
            }: Merge<MSV3DeliverableI, any>,
            index,
          ) => {
            if (!mappedDeliverables[index]) {
              mappedDeliverables[index] = {
                amount_asked,
                amount_deliverable,
                pzn,
                name,
              };
            } else {
              const amountAsked = mappedDeliverables[index].amount_asked
                ? mappedDeliverables[index].amount_asked
                : null;
              let amountDeliverable =
                mappedDeliverables[index].amount_deliverable +
                amount_deliverable;
              if (amountDeliverable === 0) {
                amountDeliverable = null;
              }
              mappedDeliverables[index] = {
                amount_asked: amountAsked,
                amount_deliverable: amountDeliverable,
                pzn,
                name,
              };
            }
          },
        );
      }
    });
    console.log(mappedDeliverables);
    dispatch({
      type: DataTableActionTypes.ADD_MSV3_DELIVERABLES,
      payload: mappedDeliverables,
    });
    dispatch({
      type: ArticleEditorActionTypes.ADD_MSV3_DELIVERABLES,
      payload: mappedDeliverables,
    });

    return {
      type: '',
      payload: mappedDeliverables,
    };
  } catch (e) {
    if (
      e &&
      e.response &&
      e.response.status &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.code &&
      e.response.data.error.code === 'ENTITY_NOT_FOUND'
    ) {
      return {
        type: MSV3ActionTypes.MSV3_ERROR,
        payload: 'ENTITY_NOT_FOUND',
      };
    }
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const getMSV3PznSuggestions = (
  pznlist: MSV3pznReqObjectI[],
  isShoppingCartView = false,
  cancelToken?: CancelToken,
) => async (dispatch: Dispatch) => {
  const setLoading = (active: boolean) => {
    dispatch({
      type: MSV3ActionTypes.SET_IS_LOADING,
      payload: active,
    });
  };
  setLoading(true);

  setPZNAvailability(null)(dispatch);

  try {
    const reqOptions: AxiosRequestConfig = {};

    if (cancelToken) {
      reqOptions.cancelToken = cancelToken;
    }

    const pznChunks = chunk(pznlist, 10);
    const promises = pznChunks.map(async (pznChunk) => {
      const { data } = await msv3ApiInstance.post<{
        [key: string]: Merge<MSV3DeliverableI, any>[][] | { error: boolean };
      }>(
        '/msv3/getPZNAvailability',
        {
          pznlist: pznChunk,
        },
        reqOptions,
      );

      if (!data.error) {
        [
          MSV3ActionTypes.ADD_PZN_AVAILABILITY,
          DataTableActionTypes.ADD_MSV3_AVAILABILITIES,
        ].forEach((type) =>
          dispatch({
            type,
            payload: data,
          }),
        );
      }

      const mappedDeliverables: Merge<MSV3DeliverableI, any>[] = [];
      Object.values(data).forEach((delValue) => {
        if (Array.isArray(delValue)) {
          delValue.forEach(
            (
              {
                amount_asked,
                amount_deliverable,
                pzn,
              }: Merge<MSV3DeliverableI, any>,
              index,
            ) => {
              if (!mappedDeliverables[index]) {
                mappedDeliverables[index] = {
                  amount_asked,
                  amount_deliverable,
                  pzn,
                };
              } else {
                const amountAsked = mappedDeliverables[index].amount_asked
                  ? mappedDeliverables[index].amount_asked + amount_asked
                  : null;
                let amountDeliverable =
                  mappedDeliverables[index].amount_deliverable +
                  amount_deliverable;
                if (amountDeliverable === 0) {
                  amountDeliverable = null;
                }

                mappedDeliverables[index] = {
                  amount_asked: amountAsked,
                  amount_deliverable: amountDeliverable,
                  pzn,
                };
              }
            },
          );
        }
      });
      if (!isShoppingCartView) {
        dispatch({
          type: DataTableActionTypes.ADD_MSV3_DELIVERABLES,
          payload: mappedDeliverables,
        });
      }

      return {
        responseData: data,
        mappedDeliverables,
      };
    });

    const allData = (await Promise.all(promises)).flat();
    const mappedDeliverables = allData
      .map((data) => data.mappedDeliverables)
      .flat();

    setLoading(false);
    toast(i18next.t('MSV3.LABELS.LOADING_SUCCESSFUL'), { autoClose: 1000 });

    return {
      type: '',
      payload: mappedDeliverables,
    };
  } catch (e) {
    setLoading(false);
    if (
      e &&
      e.response &&
      e.response.status &&
      e.response.data &&
      e.response.data.error &&
      e.response.data.error.code &&
      e.response.data.error.code === 'ENTITY_NOT_FOUND'
    ) {
      return {
        type: MSV3ActionTypes.MSV3_ERROR,
        payload: 'ENTITY_NOT_FOUND',
      };
    }
    // No error toast if request is canceled
    if (e?.message === 'CAMCEL_REQUEST') {
      return {
        type: '',
        payload: [],
      };
    }
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const buyMsv3Items: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | {
          results: ObjectT<MSV3ServerResponse>;
          errors: ObjectT<MSV3ApiError>;
        }
      | string;
  }>,
  { type: string; payload: MSV3State['partnersList'] },
  any,
  DeletePartnerConnectionActionI
>> = (
  data: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount: number | null;
      touched: boolean;
      id?: number | null;
    };
  },
  proposalId?: number,
  selectedRows?: ReadonlySet<string>,
  soapIds?: string[],
) => async (dispatch: Dispatch) => {
  const errors: ObjectT<MSV3ApiError> = {};

  const results: ObjectT<MSV3ServerResponse> = {};

  try {
    await Promise.allSettled(
      Object.keys(data).map(async (providerId: string) => {
        const { pzn, amount } = data[providerId];

        if (amount !== '0' && amount !== 'null' && amount !== 'undefined') {
          const submitData: any = {
            purchaselist: [{ pzn, amount: Number(amount) }],
          };
          submitData.partnerInstanceIds = soapIds;
          if (proposalId !== 0 && typeof proposalId === 'number') {
            submitData.proposalid = proposalId;
          } else {
            submitData.is_buy_own_request = true;
          }
          if (providerId.length > 36) {
            // eslint-disable-next-line no-param-reassign
            providerId = providerId.slice(0, -8);
          }
          if (proposalId !== 0) {
            try {
              const result = await msv3ApiInstance.post(
                `/msv3/purchase/${providerId}`,
                submitData,
              );

              results[providerId] = result.data;

              return result;
            } catch (e) {
              errors[providerId] = e;
              return e;
            }
          }

          try {
            const result = await msv3ApiInstance.post(
              `/msv3/purchase/${providerId}`,
              submitData,
            );

            results[providerId] = result.data;

            return result;
          } catch (e) {
            errors[providerId] = e;
            return e;
          }
        }

        return null;
      }),
    );
    dispatch({
      type: MSV3ActionTypes.SET_MSV3_OPEN_TRANSACTIONS,
      payload: results,
    });

    return dispatch({
      type: MSV3ActionTypes.SET_MSV3_CHECKOUT_RESULTS,
      payload: { errors, results },
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const setMsv3Transactions: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleOfferI[] | string }>,
  { type: string; payload: ArticleOfferI[] },
  any,
  SetMSV3TransactionsActionI
>> = (rows: ArticleOfferI[]) => async (dispatch: Dispatch) => {
  try {
    await server.post('/api/v1/msv3-transactions/', {
      rows,
    });
    return dispatch({
      type: MSV3ActionTypes.SET_MSV3_TRANSACTIONS,
      payload: rows,
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: 'MSV3_API_ERROR',
    });
  }
};

export const getMsv3Transactions: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: [] }>,
  { type: string; payload: [] },
  any,
  GetMSV3TransactionsActionI
>> = (companyId: number | undefined) => async (dispatch: Dispatch) => {
  const { data } = await server.get(`/api/v1/msv3-transactions/${companyId}`);

  return dispatch({
    type: MSV3ActionTypes.GET_MSV3_TRANSACTIONS,
    payload: data,
  });
};

export const deleteMsv3Transaction: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: [] | string }>,
  { type: string; payload: [] },
  any,
  DeleteMSV3TransactionsActionI
>> = (items: []) => async (dispatch: Dispatch) => {
  try {
    await Promise.all(
      items.map(async (id) => {
        await server.delete(`/api/v1/msv3-transactions/${id}/`);
      }),
    );
    return dispatch({
      type: MSV3ActionTypes.DELETE_MSV3_TRANSACTIONS,
      payload: items,
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: 'MSV3_API_ERROR',
    });
  }
};

export const getMsv3OpenTransactions: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: [] }>,
  { type: string; payload: [] },
  any,
  GetMSV3OpenTransactionsActionI
>> = (companyId: number | undefined) => async (dispatch: Dispatch) => {
  const { data } = await server.get(
    `/api/v1/msv3-opentransactions/${companyId}`,
  );

  return dispatch({
    type: MSV3ActionTypes.GET_MSV3_OPEN_TRANSACTIONS,
    payload: data,
  });
};

export const deleteMsv3OpenTransaction: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: [] | string }>,
  { type: string; payload: [] },
  any,
  DeleteMSV3OpenTransactionsActionI
>> = (items: []) => async (dispatch: Dispatch) => {
  try {
    await Promise.all(
      items.map(async (id) => {
        await server.delete(`/api/v1/msv3-opentransactions/${id}/`);
      }),
    );
    return dispatch({
      type: MSV3ActionTypes.DELETE_MSV3_OPEN_TRANSACTIONS,
      payload: items,
    });
  } catch (e) {
    return dispatch({
      type: MSV3ActionTypes.MSV3_ERROR,
      payload: 'MSV3_API_ERROR',
    });
  }
};
