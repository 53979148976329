/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention */
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import addMonths from 'date-fns/addMonths';
import {
  ARTICLE_STATUSES,
  PAYMENT_DEADLINES,
  TRAMEDIX_COMMISSION,
} from '../../constants/article';
import {
  articleStatusInputsInitialState,
  editorArticleInfoInputsInitialState,
} from '../../../globals/models/components/articleEditor';
import {
  ArticleI,
  ArticleSuggestionI,
} from '../../../globals/models/data/article.model';
import { round } from '../numbers.utils';
import { User } from '../../../globals/models/data/user.model';

/**
 * Skeleton to be used when loading article suggestions
 * @constructor
 */
export const ArticleSuggestionsSkeleton = () => (
  <div className="bg-white rounded-lg shadow-lg px-2">
    <div className="flex h-10">
      <Skeleton variant="text" className="w-32" />{' '}
      <span className="pt-3 px-1 text-grey-3">-</span>
      <Skeleton variant="text" className="w-56" />{' '}
    </div>
    <div className="flex h-10">
      <Skeleton variant="text" className="w-32" />
      <span className="pt-3 px-1 text-grey-3">-</span>
      <Skeleton variant="text" className="w-56" />{' '}
    </div>
    <div className="flex h-10">
      <Skeleton variant="text" className="w-32" />
      <span className="pt-3 px-1 text-grey-3">-</span>
      <Skeleton variant="text" className="w-56" />{' '}
    </div>
    <div className="flex h-10">
      <Skeleton variant="text" className="w-32" />
      <span className="pt-3 px-1 text-grey-3">-</span>
      <Skeleton variant="text" className="w-56" />{' '}
    </div>
  </div>
);

/**
 * Parses a date to the first day of its month
 * @param date
 */
export const getDateWithFirstDayOfMonth = (date: Date) => {
  /* getDateWithFirstDayOfMonth() returns the last day of the previous month
     if the provided date had already the first day of the month.
     Always set the day to be in between. 5 for this case
  */
  date.setDate(5);
  const cpy = new Date(date.getTime());
  cpy.setUTCDate(1);
  return cpy;
};

/**
 * Gets the difference between two dates
 * @param targetDate
 * @param originDate
 */
export const getDifferenceInMonths = (
  targetDate: Date,
  originDate = new Date(),
) => {
  const absoluteDifference = Math.round(
    Math.abs(originDate.getTime() - targetDate.getTime()) /
      1000 / // milliseconds in a second
      60 / // seconds in a minutes
      60 / // minutes in an hour
      24 / // hours in a day
      30, // days in a months
  );
  return targetDate > originDate ? absoluteDifference : -absoluteDifference;
};

export function calculateDiscount(new_price: number, original_price: number) {
  if (original_price === 0) return 0;
  return -(100 - (new_price / original_price) * 100);
}

export function calculatePriceFromDiscount(
  discount: number,
  original_price: number,
) {
  return (1 + discount / 100) * original_price;
}

export function calculatePriceWithCommission(price = '0.0') {
  if (price !== '') {
    const parsedPrice = parseFloat(price);

    const commission = TRAMEDIX_COMMISSION * parsedPrice;
    if (commission > 10) {
      return parsedPrice + 10.0;
    }
    return (1 + TRAMEDIX_COMMISSION) * parsedPrice;
  }
  return 0;
}

export function calculatePriceWithoutCommission(price: string) {
  const parsedPrice = parseFloat(price) || 0.0;

  const commission = TRAMEDIX_COMMISSION * parsedPrice;
  if (commission > 10) {
    return parsedPrice - 10.0;
  }
  return (1 - TRAMEDIX_COMMISSION) * parsedPrice;
}

export function calculateTotalPriceWithoutCommission(
  price: string,
  amount: number,
) {
  const totalPrice = calculatePriceWithoutCommission(price);
  return totalPrice * amount;
}

export function calculateTotalCommission(price: string, amount: string) {
  if (amount !== '') {
    const totalPrice = calculatePriceWithoutCommission(price);
    return (Number(price) - totalPrice) * Number(amount);
  }
  return 0;
}

export function calculatePricePeFromTotal(
  totalPrice: string,
  quantity: string,
) {
  if (totalPrice !== '' && quantity !== '') {
    return Number(totalPrice) / Number(quantity);
  }

  return 0;
}

const getTradeType = () => {
  const location = window.location.pathname;
  switch (location) {
    case (location.match('/application/offers/-/create') || {}).input:
      return 'SELLING';
    case (
      location.match(
        '/application/offers/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'SELLING';
    case (location.match('/application/search/-/create') || {}).input:
      return 'BUYING';
    case (
      location.match(
        '/application/search/[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/edit',
      ) || {}
    ).input:
      return 'BUYING';
    default:
      return 'SELLING';
  }
};

/**
 * Format article creation data to meet the servers validation constraints
 * @param articleInfo
 * @param chosenArticle
 * @param artStatus
 * @param currentUser
 */
export const generateArticleCreationPayload = (
  articleInfo: typeof editorArticleInfoInputsInitialState,
  chosenArticle: ArticleSuggestionI,
  artStatus: typeof articleStatusInputsInitialState,
  currentUser: User | null,
) => {
  const data = {
    article_data: {
      available_amount: Number(articleInfo.available_amount),
      best_offer_possible: articleInfo.best_offer_possible,
      discount_aep_cleaned:
        articleInfo.discount_aep_cleaned !== ''
          ? Number(articleInfo.discount_aep_cleaned)
          : 0,
      discount_apu_cleaned:
        articleInfo.discount_apu_cleaned !== ''
          ? Number(articleInfo.discount_apu_cleaned)
          : 0,
      editing: false,
      min_amount: Number(articleInfo.min_amount),
      price_per_piece_cleaned: articleInfo.price_per_piece_cleaned,
      product_name: chosenArticle.name,
      pzn: chosenArticle.pzn,
      total_price_cleaned: articleInfo.total_price_cleaned,
    },
    best_offer_data: {
      discount_aep_cleaned:
        articleInfo.best_offer_possible && articleInfo.minPriceActive
          ? Number(articleInfo.best_offer_discount_aep_cleaned)
          : 0,
      discount_apu_cleaned:
        articleInfo.best_offer_possible && articleInfo.minPriceActive
          ? Number(articleInfo.best_offer_discount_apu_cleaned)
          : 0,
      minPriceActive: articleInfo.minPriceActive,
      price_per_piece_cleaned: articleInfo.best_offer_price_per_piece_cleaned
        ? articleInfo.best_offer_price_per_piece_cleaned
        : null,
      total_price_cleaned: articleInfo.best_offer_total_price_cleaned
        ? articleInfo.best_offer_total_price_cleaned
        : null,
    },
    product_data: {
      product_aep: chosenArticle.aep,
      product_apu: chosenArticle.apu,
      product_avp: chosenArticle.avp,
      product_name: chosenArticle.name,
      product_package_size: chosenArticle.package_size || 1,
      product_pzn: chosenArticle.pzn,
      product_pzn_already_chosen: false,
      article_trade_type: getTradeType(),
      product_producer: currentUser?.email,
    },
    status_store: {
      charge: artStatus.charge,
      payment_deadline: artStatus.payment_deadline,
      period_of_validity:
        artStatus.period_of_validity || addMonths(new Date(), 3).toISOString(),
      status: artStatus.status,
      status_description: artStatus.status_description
        ? artStatus.status_description
        : '',
      verfallsdatum: artStatus.verfallsdatum,
      shipping_costs_carrier: artStatus.shipping_costs_carrier,
      article_location_country: artStatus.article_location_country,
    },
  };

  if (!articleInfo.minPriceActive) {
    delete data.best_offer_data.discount_aep_cleaned;
    delete data.best_offer_data.discount_apu_cleaned;
    delete data.best_offer_data.minPriceActive;
    delete data.best_offer_data.price_per_piece_cleaned;
    delete data.best_offer_data.total_price_cleaned;
  }

  return data;
};

/**
 * Returns formatted values for the ArticleInformation components edit state
 * @param articleOnEdit - the article to be edited
 */
export const getOfferEditInitialState = (articleOnEdit: ArticleI) => {
  const minPriceActive = Boolean(
    articleOnEdit.offer?.aep_discount &&
      articleOnEdit.offer?.best_offer_aep_discount &&
      articleOnEdit.best_offer_possible,
  );

  const getDiscount = (checkValue: number, roundValue: any) => {
    let discount = '';

    if (checkValue) {
      discount = String(round(articleOnEdit.aep_discount, 2));
    }

    if (checkValue === 0) {
      discount = '0';
    }

    return discount;
  };

  return {
    minPriceActive,
    discount_aep_cleaned: getDiscount(
      articleOnEdit.aep_discount,
      articleOnEdit.calculated_aek_discount,
    ),
    discount_apu_cleaned: getDiscount(
      articleOnEdit.apu_discount,
      articleOnEdit.apu_discount,
    ),
    best_offer_discount_aep_cleaned:
      articleOnEdit.offer &&
      articleOnEdit.offer.best_offer_aep_discount &&
      minPriceActive
        ? String(round(Number(articleOnEdit.offer.best_offer_aep_discount), 2))
        : '',
    best_offer_discount_apu_cleaned:
      articleOnEdit.offer &&
      articleOnEdit.offer.best_offer_apu_discount &&
      minPriceActive
        ? String(round(Number(articleOnEdit.offer?.best_offer_apu_discount), 2))
        : '',
    best_offer_price_per_piece_cleaned:
      articleOnEdit.offer &&
      articleOnEdit.offer.best_offer_auto_accept &&
      minPriceActive
        ? `${round(Number(articleOnEdit.offer.best_offer_auto_accept), 2)}`
        : '0',
    min_amount: String(articleOnEdit.min_piece_amount),
    total_price_cleaned: String(
      round(
        Number(articleOnEdit.piece_amount) * Number(articleOnEdit.price),
        2,
      ),
    ),
    best_offer_total_price_cleaned:
      articleOnEdit.offer && minPriceActive
        ? `${
            Number(articleOnEdit.offer.best_offer_auto_accept) *
            articleOnEdit.piece_amount
          }`
        : '',
    offerPrice: String(
      round(calculatePriceWithCommission(articleOnEdit.price) as number, 2),
    ),
    best_offer_possible: articleOnEdit.best_offer_possible,
    available_amount: String(articleOnEdit.piece_amount),
    price_per_piece_cleaned: `${articleOnEdit.price}`,
    totalFees: String(
      round(
        calculateTotalCommission(
          String(Number(articleOnEdit.price)),
          String(articleOnEdit.piece_amount),
        ),
        2,
      ),
    ),
  };
};

/**
 * Returns formatted values for the ArticleStatus components' edit state
 * @param articleOnEdit - the article to be edited
 */
const getArticleStatus = (articleOnEdit: ArticleI) => {
  if (articleOnEdit.article_status) {
    return articleOnEdit.article_status ===
      articleOnEdit.article_status.toUpperCase()
      ? articleOnEdit.article_status
      : (ARTICLE_STATUSES.find(
          (el) => el.submitValue === articleOnEdit.article_status,
        )?.key as string);
  }
  return ARTICLE_STATUSES[0].key;
};

export const getArticleStatusEditValues = (articleOnEdit: ArticleI) => ({
  status: getArticleStatus(articleOnEdit),
  charge: String(articleOnEdit.charge_number),
  payment_deadline:
    articleOnEdit.payment_deadline ===
    articleOnEdit.payment_deadline.toUpperCase()
      ? articleOnEdit.payment_deadline
      : (PAYMENT_DEADLINES.find(
          (el) => el.submitValue === articleOnEdit.payment_deadline,
        )?.key as string),
  period_of_validity: new Date(
    articleOnEdit.end_period_of_validity,
  ).toISOString(),
  relativeDateInMonths: articleOnEdit.expiration_date
    ? String(
        getDifferenceInMonths(
          getDateWithFirstDayOfMonth(new Date(articleOnEdit.expiration_date)),
          getDateWithFirstDayOfMonth(new Date()),
        ),
      )
    : '',
  specifyExpiryDateChecked:
    Boolean(articleOnEdit.expiration_date) ||
    Boolean(articleOnEdit.end_period_of_validity),
  verfallsdatum: articleOnEdit.expiration_date
    ? new Date(articleOnEdit.expiration_date).toISOString()
    : '',
  status_description: articleOnEdit.offer?.status_description as string,
  shipping_costs_carrier: articleOnEdit.shipping_costs_carrier,
  article_location_country: articleOnEdit.article_location_country,
});

export const handlePricePerPieceCleaned = (
  value: string,
  inputsState: typeof editorArticleInfoInputsInitialState,
  setInputsState: (state: typeof editorArticleInfoInputsInitialState) => void,
  isEditMode: boolean,
  articleOnEdit: ArticleI | null,
  pickedArticleSuggestion: ArticleSuggestionI,
) => {
  const cleanedValue = Number(value);
  if (value !== '' && !Number.isNaN(cleanedValue)) {
    setInputsState({
      ...inputsState,
      total_price_cleaned: String(
        Number(inputsState.available_amount) * cleanedValue,
      ),
      price_per_piece_cleaned: `${cleanedValue}`,
      discount_aep_cleaned: String(
        round(
          calculateDiscount(
            cleanedValue,
            Number(
              isEditMode && articleOnEdit
                ? articleOnEdit.aep
                : pickedArticleSuggestion.aep,
            ),
          ),
          2,
        ),
      ),
      discount_apu_cleaned: String(
        round(
          calculateDiscount(
            cleanedValue,
            Number(
              isEditMode && articleOnEdit
                ? articleOnEdit.apu
                : pickedArticleSuggestion.apu,
            ),
          ),
          2,
        ),
      ),
      offerPrice: String(
        round(calculatePriceWithCommission(`${cleanedValue}`) as number, 2),
      ),
      totalFees: String(
        round(
          calculateTotalCommission(
            `${cleanedValue}`,
            inputsState.available_amount,
          ),
          2,
        ),
      ),
    });
  } else {
    setInputsState({
      ...inputsState,
      total_price_cleaned: '',
      price_per_piece_cleaned: '',
      discount_aep_cleaned: '',
      discount_apu_cleaned: '',
      offerPrice: '',
      totalFees: '',
    });
  }
};

export const handleBestOfferPricePerPieceCleaned = (
  value: string,
  setInputsState: (state: typeof editorArticleInfoInputsInitialState) => void,
  inputsState: typeof editorArticleInfoInputsInitialState,
  isEditMode: boolean,
  articleOnEdit: ArticleI | null,
  pickedArticleSuggestion: ArticleSuggestionI,
) => {
  const cleanedValue = Number(value);
  if (value !== '' && !Number.isNaN(cleanedValue)) {
    setInputsState({
      ...inputsState,
      best_offer_total_price_cleaned: String(
        Number(inputsState.available_amount) * cleanedValue,
      ),
      best_offer_price_per_piece_cleaned: value,
      best_offer_discount_aep_cleaned: String(
        round(
          calculateDiscount(
            cleanedValue,
            Number(
              isEditMode && articleOnEdit
                ? articleOnEdit.aep
                : pickedArticleSuggestion.aep,
            ),
          ),
          2,
        ),
      ),
      best_offer_discount_apu_cleaned: String(
        round(
          calculateDiscount(
            cleanedValue,
            Number(
              isEditMode && articleOnEdit
                ? articleOnEdit.apu
                : pickedArticleSuggestion.apu,
            ),
          ),
          2,
        ),
      ),
    });
  } else {
    setInputsState({
      ...inputsState,
      best_offer_total_price_cleaned: '',
      best_offer_price_per_piece_cleaned: '',
      best_offer_discount_aep_cleaned: '',
      best_offer_discount_apu_cleaned: '',
    });
  }
};

export const getDefaultApuAepDisplay = (
  pickedArticleSuggestion: ArticleSuggestionI,
  articleOnEdit: ArticleI | null,
  isEditMode: boolean,
  priceType: 'apu' | 'aep',
) => (
  <>
    {Boolean(
      pickedArticleSuggestion &&
        (pickedArticleSuggestion[priceType] ||
          pickedArticleSuggestion[priceType] === 0),
    ) && (
      <NumberFormat
        value={round(pickedArticleSuggestion[priceType] as number)}
        className="italic font-light text-sm block"
        suffix={priceType === 'apu' ? ' € (Original APU)' : ' € (Original AEK)'}
        disabled
      />
    )}
    {Boolean(
      isEditMode &&
        articleOnEdit &&
        (articleOnEdit[priceType] || Number(articleOnEdit[priceType]) === 0),
    ) && (
      <NumberFormat
        value={articleOnEdit ? round(Number(articleOnEdit[priceType])) : ''}
        className="italic font-light text-sm block"
        suffix={priceType === 'apu' ? ' € (Original APU)' : ' € (Original AEK)'}
        disabled
      />
    )}
  </>
);
