import React from 'react';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';

const Terms = () => {
  return (
    <div className="container mx-auto px-10 lg:px-0">
      <div className="pt-6">
        <p className="font-semibold text-3xl  text-primary">
          Allgemeine Geschäftsbedingungen (AGB) der TRAMEDIX UG
          (haftungsbeschränkt)
        </p>
        <p className="text-2xl font-light">Zuletzt geändert am: 22.03.2018</p>
      </div>
      <ol>
        <p className="text-2xl pt-4 font-bold pb-4">
          Herzlich Willkommen bei TRAMEDIX.com und TRAMEDIX.de
        </p>
        <p className="pb-2">
          <li>
            Als “TRAMEDIX” wird im Folgenden der nachfolgend dargestellte Dienst
            und sämtlicher zugehöriger Anwendungen sowie/oder den Betreiber des
            Dienstes, die TRAMEDIX UG (haftungsbeschränkt) benannt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX UG (haftungsbeschränkt, mit Sitz in der Goldberger Str.
            81A, 18273 Güstrow, bietet Webseiten-Funktionalitäten und andere
            Services an. Wenn Sie die TRAMEDIX.com- oder TRAMEDIX.de-Webseiten
            (im Folgenden „die Webseiten“) oder bei der Nutzung bereitgestellte
            Dienste in Zusammenhang mit den TRAMEDIX-Angeboten nutzen, stellt
            TRAMEDIX diese zu diesen AGB bereit.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die TRAMEDIX Telemediendienste werden mit diesen AGB als gesamten
            Vertrag zwischen dem einzelnen Nutzer und TRAMEDIX genutzt.
            Abweichende oder entgegenstehende Geschäftsbedingungen sind nur mit
            unserer ausdrücklichen Zustimmung wirksam.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Möglicherweise unterliegt der Nutzer der TRAMEDIX zusätzlichen
            Nutzungsbedingungen, welche zur Anwendung kommen, wenn er mit
            TRAMEDIX verbundene Dienste, Inhalte von Dritten oder Software von
            Dritten nutzt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Bitte lesen Sie diese Bedingungen aufmerksam, bevor Sie die TRAMEDIX
            Angebote nutzen. Durch die Nutzung der TRAMEDIX Angebote erklären
            Sie Ihr Einverständnis, an diese Bedingungen gebunden zu sein.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">GEGENSTAND DER LEISTUNG</p>
        <p className="pb-2">
          <li>
            Die TRAMEDIX UG (haftungsbeschränkt), Goldberger Str. 81A, 18273
            Güstrow betreibt unter dem Namen „TRAMEDIX” einen Telemediendienst
            in Form einer Online-Handelsplattform für lizensierte
            Pharma-Großhändler und Apotheken mit Großhandelslizenz nach § 52a
            AMG, welche der Anbahnung und dem Abschluss von Handelsgeschäften
            dient.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX ermöglicht es Drittanbietern, ihre Produkte auf der
            Webseite zum Verkauf anzubieten, Bedarfe zu listen und Erklärungen
            über Kaufabsichten abzugeben.. Auch in Fällen, in welchen TRAMEDIX
            durch Funktionen und Services eine Transaktion ermöglicht, ist
            TRAMEDIX weder der Käufer noch der Verkäufer der Waren, Produkte und
            Artikel. Insoweit wird der Vertrag, der bei Verkaufsabschluss
            zustande kommt, ausschließlich zwischen dem Käufer und dem Verkäufer
            geschlossen. TRAMEDIX ist nicht Vertragspartner und übernimmt daher
            keine Verantwortung für jenen Vertrag. TRAMEDIX ist auch nicht der
            Vertreter des Verkäufers oder Käufers..
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Zugang und die Nutzung zu unserer Handelsplattform wird erst
            nach Abschluss eines Nutzungsvertrages gemäß dieser AGB ermöglicht.
            Die TRAMEDIX Handelsplattform ist nicht öffentlich zugänglich und
            steht einem geschlossenen Nutzerkreis zur Verfügung.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Bei allen Willenserklärungen, geschäftsähnlichen Handlungen sowie
            sonstigen rechtlich relevanten Äußerungen oder Handlungen handelt es
            sich ausschließlich um solche der Nutzer selbst. TRAMEDIX handelt
            hinsichtlich solcher Willenserklärungen, Handlungen und Äußerungen
            weder im eigenen Namen noch als Vertreter in fremdem Namen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die vom jeweiligen Nutzer bei der Registrierung hinterlegten Daten
            prüft TRAMEDIX in dem Rahmen, wie er in diesen AGB beschrieben ist.
            TRAMEDIX leistet daher keine Gewähr für die tatsächliche Identität
            eines Nutzers oder das Bestehen einer Bezugs-/Handelsberechtigung
            für Arzneimittel auf Seiten eines Nutzers. Jeder Nutzer hat sich
            daher in eigener Verantwortung von der Identität sowie ggfs. der
            Bezugs-/Handelsberechtigung für Arzneimittel seines Vertragspartners
            zu überzeugen, bevor er den jeweiligen Vertrag mit diesem abwickelt.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">
          REGISTRIERUNG, ANMELDUNG UND BENUTZERKONTO
        </p>
        <p className="pb-2">
          <li>
            Die Nutzung der TRAMEDIX Angebote setzt die Registrierung und
            Anmeldung als Nutzer voraus. Die Registrierung als Benutzer erfolgt
            durch Anlegen eines Kontos. Mit Registrierung wird den AGB und
            Datenschutzbestimmungen zugestimmt. Mit der Registrierung und
            Anmeldung kommt zwischen Benutzer und der TRAMEDIX UG
            (haftungsbeschränkt) ein Vertrag über die Nutzung der TRAMEDIX
            Angebote (“Nutzungsvertrag”) zustande.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Mit der Registrierung und Anmeldung erkennt der Benutzer die AGB der
            TRAMEDIX als für sich verbindlich an.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die Registrierung als Benutzer erfolgt nach Eingang der
            Registrierungsinformationen durch das Anmeldeformular der TRAMEDIX.
            Nach Prüfung der Angaben des Benutzers im durch diese AGB
            festgelegten Rahmen erfolgt ggfs. eine Freischaltung für den Zugang
            und die Nutzung zur Handelsplattform. Der Nutzungsvertrag kommt erst
            mit der tatsächlichen Freischaltung des Benutzers zustande. Der
            Benutzers wird darüber via E-Mail informiert.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Ein Rechtsanspruch auf Abschluss eines entsprechenden
            Nutzungsvertrages besteht nicht. TRAMEDIX behält sich vor, eine
            Registrierung und Freischaltung jederzeit ohne Angabe von Gründen
            zurückzuweisen und/ oder zurückzunehmen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die Anmeldung ist nur juristischen Personen, Personengesellschaften
            und unbeschränkt geschäftsfähigen natürlichen Personen im Rahmen
            einer vollkaufmännisch ausgeübten, gewerblichen Tätigkeit erlaubt;
            insoweit muss ein Handelsregistereintrag bestehen und über
            entsprechende Erlaubnisse verfügt werden, welche bei der
            Registrierung anzugeben sind. Eine juristische Person darf nur von
            einer vertretungsberechtigten natürlichen, namentlich zu benennenden
            Person, registriert werden. TRAMEDIX kann jederzeit Verlangen, dass
            diese Vertretungsmacht in objektiv geeigneter, verkehrsüblicher
            Weise nachzuweisen ist.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die jeweiligen Erlaubnisse sind TRAMEDIX im Rahmen der Registrierung
            in Kopie entweder per Datei-Upload oder via E-Mail zu übermitteln.
            Diese werden bei TRAMEDIX hinterlegt und im Rahmen eines bei
            TRAMEDIX zwischen zwei Nutzern zustande gekommenen Vertrages dem
            jeweils anderen Vertragspartner zur Verfügung gestellt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX ist berechtigt,von dem jeweiligen Benutzer jederzeit, auch
            wiederholt innerhalb der Vertragslaufzeit, ohne Angabe von Gründen,
            Nachweise über die Erfüllung bzw. den Fortbestand der Anmelde- und
            Nutzungsvoraussetzungen zu verlangen.alle Informationen, die der
            jeweilige Benutzer oder an der Nutzung Interessierte bei der
            Registrierung oder Nutzung bereitgestellt hat, zu benutzen, um die
            Richtigkeit dieser Angaben zu überprüfen und zu diesem Zweck, sowie
            im Rahmen des Vertragszwecks im Allgemeinen, Dritte, insbesondere
            Wirtschaftsauskunfteien und andere Informationsdienste
            einzuschalten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer oder an der Nutzung Interessierte sichert mit der
            Registrierung und/ oder Anmeldung zu, dass alle von ihm dabei
            getätigten Angaben wahr, vollständig und damit die
            Nutzungsvoraussetzungen erfüllt sind. Bei Änderungen in Bezug auf
            Angaben und Daten, auch und insbesondere Kontaktdaten, innerhalb der
            Vertragslaufzeit, ist der Benutzer verpflichtet, diese Veränderungen
            TRAMEDIX unverzüglich mitzuteilen bzw. in seinem Benutzerkonto
            anzupassen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Unwahre oder unvollständige Angaben oder das Unterlassen der
            Aktualisierung und Berichtigung von Angaben/Daten in seinem
            Benutzerkonto , können mit sofortiger Wirkung zum teilweisem oder
            vollständigem Ausschluss von der Nutzung der Angebote und Dienste
            der TRAMEDIX führen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Für vertragliche Zwecke stimmt der Nutzer zu, elektronische
            Kommunikation von TRAMEDIX zu erhalten und er stimmt ebenfalls zu,
            dass alle Zustimmungen, Benachrichtigungen, Veröffentlichungen und
            andere Kommunikation, die TRAMEDIX elektronisch mitteilt bzw.
            übermittelt keine Schriftform erfordern. Ausnahmen zu dieser
            Regelung sind zwingende gesetzliche Vorschriften, welche eine andere
            Form der Kommunikation vorschreiben bzw. erfordern.
          </li>
        </p>
        <p className="pb-2">
          <li>Ein TRAMEDIX Benutzerkonto ist nicht übertragbar.</li>
        </p>
        <p className="text-2xl py-4 font-bold">ZUGANGSDATEN</p>
        <p className="pb-2">
          <li>
            Der Benutzer von TRAMEDIX hat dafür Sorge zu tragen, dass das bei
            der Registrierung gewählte Passwort sowie die E-Mailadresse vor
            unberechtigtem Zugriff Dritter geschützt sind und insbesondere nicht
            Personen zugänglich werden können, die nach diesen AGB nicht zur
            Anmeldung und Registrierung berechtigt sind oder nicht der eigenen
            Unternehmensorganisation des Benutzers zuzuordnen sind.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Jede unberechtigte Nutzung der aus E-Mailadresse und Passwort
            bestehenden Zugangsdaten, von der der Benutzer Kenntnis erhält oder
            die er vermutet, ist TRAMEDIX unverzüglich mitzuteilen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Jeder Benutzer haftet im Verhältnis zu anderen Benutzern als auch
            gegenüber TRAMEDIX für jeden aus einer unberechtigten Nutzung seiner
            Zugangsdaten entstehenden Schaden, es sei denn, er kann nachweisen,
            dass die betreffenden Sorgfaltspflichten nicht verletzt wurden.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX speichert alle Zugangsdaten der Benutzer verschlüsselt,
            gibt diese unter keinen Umständen an Dritte weiter und wird einen
            Nutzer nicht nach seinem Passwort fragen.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">NUTZUNGSUMFANG</p>
        <p className="pb-2">
          <li>
            Bei der Nutzung der TRAMEDIX Handelsplattform gelten für die
            verschiedenen Nutzergruppen u.a. die folgenden Einschränkungen:
          </li>
        </p>
        <p className="pb-2">
          <li>
            ‘Großhändler’ <br /> Benutzer, die sich unter Vorlage einer
            Großhandelserlaubnis gemäß § 52a AMG angemeldet haben und nicht
            zugleich auch eine Apothekenbetriebserlaubnis gemäß § 1 Abs. 2 ApoG
            besitzen, unterliegen beim Handel in dem durch diese AGB
            vorgegebenen Rahmen keinerlei Einschränkungen bei der Benutzung
            unserer Dienste.
          </li>
        </p>
        <p className="pb-2">
          <li>
            ‘Apotheken mit Großhandelserlaubnis’ <br />
            Benutzer, die sich unter Vorlage einer auf dieselbe juristische oder
            natürliche Person lautenden Apothekenbetriebserlaubnis gemäß § 1
            Abs. 2 ApoG und einer Großhandelserlaubnis gemäß § 52a AMG
            angemeldet haben, unterliegen beim Handel in dem durch § 1 dieser
            AGB vorgegebenen Rahmen ebenfalls keinerlei Einschränkungen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Ein Nutzungsanspruch der Angebote und Leistungen der TRAMEDIX
            besteht nur im Rahmen des aktuellen Standes der Technik. TRAMEDIX
            kann Leistungen zeitweilig beschränken, wenn dies im Hinblick auf
            die Sicherheit und Stabilität der Handelsplattform oder zur
            Durchführung von Wartungsarbeiten notwendig ist. Wann immer möglich
            wird TRAMEDIX die Benutzer vorab über Einschränkungen informieren.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX kann die Funktionalität, die Oberfläche und das
            Dienstleistungsangebot jederzeit ändern, soweit einzelne Leistungen
            nicht Gegenstand einer gesonderten Vergütung sind und die Änderungen
            für die Benutzer unter Berücksichtigung der Interessen von TRAMEDIX
            zumutbar sind.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">BESCHAFFENHEIT UND NUTZUNG</p>
        <p className="pb-2">
          <li>
            Auf TRAMEDIX kann durch die Benutzer Ware stets nur anonym
            angeboten, gesucht oder Kauf- bzw. Verkaufsabsichten geäußert
            werden. Ersteller eines Angebotes oder Gesuches haben keine
            Information über die Identität des Bieters/ Nachfragers. Die Profile
            der Benutzer sind untereinander nicht einsehbar, ebenso wie Details
            zu Transaktionen insoweit es sich nicht um bereits abgewickelte
            Transaktionen handelt, an denen sie selbst als Käufer oder Verkäufer
            beteiligt waren.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Das vom Benutzer erstellte und innerhalb der Handelsplattform
            TRAMEDIX veröffentlichte Angebot oder Gesuch stellt eine
            Aufforderung zur Abgabe eines verbindlichen Vertragsangebotes
            gegenüber dem Benutzer, welches das Angebot oder Gesuch eingestellt
            hat, dar. Die Abgabe eines Gebotes (Gebotsartikel/ Gebotsformat oder
            Sofort-Kaufen) auf ein solches, bei TRAMEDIX veröffentlichtes
            Angebot oder Gesuch stellt ein rechtsverbindliches Angebot zum
            Abschluss eines Vertrages durch den Bieter gegenüber dem Ersteller
            des Angebotes und/ oder Gesuches mit dem darin festgehaltenen Inhalt
            dar. Ein rechtsverbindlicher Vertrag zwischen den beiden Parteien
            kommt zustande, indem der Benutzer, welcher das Angebotes oder
            Gesuch eingestellt hat, dem Bieter den Zuschlag erteilt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer kann ein von ihm eingestelltes Angebot oder Gesuch
            jederzeit zurücknehmen. Gebote können durch den jeweiligen Benutzer
            auch zurückgenommen werden, soweit dem Gebot noch nicht der Zuschlag
            erteilt wurde. Die Rücknahme wird in beiden Fällen jeweils erst mit
            Löschung des Angebotes, Gesuches oder des Gebots von unserer
            Handelsplattform wirksam. Es kann zu systembedingten
            Zeitverzögerungen kommen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Erst nach Erteilung des Zuschlags und wirksamen Zustandekommen eines
            Vertrags zwischen zwei Benutzern macht TRAMEDIX diese beiden
            Vertragsparteien -ausschließlich für die beiden Vertragsparteien-
            sichtbar und übermittelt beiden die jeweiligen Kontaktdaten,
            Bankverbindung, hinterlegte Berechtigungen und die Details zum
            Vertragsgegenstand einschließlich der Chargennummern, soweit diese
            vom Verkäufer hinterlegt wurden. Die weitere Abwicklung/Erfüllung
            des geschlossenen Vertrages liegt ausschließlich in den Händen der
            beiden Vertragsparteien.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Bei Gesuchen kann der Verkäufer zunächst die Lieferfähigkeit
            anzeigen und muss diese nach Zuschlag durch den Benutzer, welcher
            das Gesuch eingestellt hat, bestätigen. Sollte sich zwischen Abgabe
            des Angebotes bzw. Anzeige der Lieferfähigkeit beim Verkäufer
            ergeben, dass dieser nicht (mehr) in der gesuchten Mengen liefern
            kann, so hat der Benutzer, welcher das Gesuch eingestellt hat, die
            Möglichkeit die geänderte Liefermenge zu akzeptieren oder den
            Vertrag zu stornieren.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Bei der Erstellung eines Angebots oder Gesuchs auf der
            Handelsplattform TRAMEDIX ist die Ware anhand der von TRAMEDIX
            vorgegebenen Eigenschaften und Informationen zutreffend und mit der
            Sorgfalt eines ordentlichen Kaufmanns zu beschreiben.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Alle über TRAMEDIX zwischen Benutzern geschlossenen Verträge
            enthalten die folgenden, für beide Seiten verbindlichen
            Vertragsbedingungen:die Ware bleibt bis zur vollständigen Zahlung
            des Kaufpreises Eigentum des Verkäufers, soweit eine Vertragspartei
            bei der Eingehung eines konkreten Vertrages, welcher Arzneimittel
            zum Gegenstand hat, tatsächlich nicht mehr zum Bezug bzw. zur Abgabe
            von Arzneimitteln berechtigt war, oder die Berechtigung vor dem
            Vollzug des Vertrages weggefallen ist, soll der geschlossene Vertrag
            als von Anfang an nichtig gelten, soweit ein Vertrag gefälschte
            Arzneimittel zum Gegenstand hat, soll dieser als von Anfang an
            nichtig gelten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Außer in Fällen höherer Gewalt ist der Verkäufer darüber hinaus
            nicht berechtigt, den Vertrag nach Vertragsschluss zu stornieren.
            Die gesetzlichen Rechte der Parteien (z.B. Rücktritt, Anfechtung)
            bleiben unberührt. Das gilt auch für eine zwischen den Benutzern
            nach Vertragsschluss einvernehmlich vereinbarte Stornierung. Der
            Anspruch auf eine Transaktionsvergütung Seitens TRAMEDIX entfällt
            damit nicht.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Verkäufer versichert, dass er zur uneingeschränkten Verfügung
            über die angebotene Ware befugt und diese nicht mit Rechten Dritter
            belastet ist, zum Verkauf in ausreichender Stückzahl zu Verfügung
            steht und er somit gemäß der dazu von ihm gemachten Angaben bzw. den
            vertraglichen Vereinbarungen ohne Einschränkungen lieferfähig ist.
            Er versichert weiterhin, dass es sich um Originalware handelt, deren
            Beschaffenheit seinen diesbezüglichen Angaben bzw. den vertraglichen
            Vereinbarungen voll entspricht.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Käufer versichert bei Abgabe eines Angebotes, dass er zur
            vollständigen Zahlung des Kaufpreises zum Zeitpunkt der Fälligkeit
            in der Lage ist.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Preis der jeweiligen Angebote und Kaufgesuche versteht sich als
            Nettopreis. Soweit diese anfällt, ist zusätzlich Umsatzsteuer zu
            entrichten. Zusätzlich zum Kaufpreis können Versand- und
            Lieferkosten Vertragsbestandteil sein, soweit möglich ergibt sich
            diese aus dem veröffentlichten Angebot.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX hat das Recht, Inhalte von Gesuchen und Angeboten vor und
            nach der Veröffentlichung zu überprüfen, Nachweise für
            Lieferfähigkeit zu verlangen, das Veröffentlichen von Gesuchen und
            Angeboten zurückzuweisen oder abgegebene, eigene Erklärung
            nachträglich zu ändern oder zu löschen.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">PFLICHTEN DER BENUTZER</p>
        <p className="pb-2">
          <li>
            Der Benutzer stellt in eigener Verantwortung sicher, dass er bei der
            Anbahnung, dem Abschluss und der Erfüllung sowie Abwicklung von
            Verträgen mit anderen Benutzern über TRAMEDIX alle für ihn geltenden
            gesetzlichen Bestimmungen einhält und stets mit der Sorgfalt eines
            ordentlichen Kaufmanns handelt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer ist verpflichtet, im Verhältnis zu TRAMEDIX die
            vereinbarten Gebühren stets vollständig zu entrichten. Ein Abzug ist
            nicht zulässig.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer ist verpflichtet im Rahmen der Veröffentlichung eines
            Angebotes oder Gesuches sowie der zugehörigen Transaktionen, bei und
            gegenüber TRAMEDIX im eigenen Namen aufzutreten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die Nutzung von TRAMEDIX erfordert kompatible Geräte, Internetzugang
            und ggf. Software. Zudem können regelmäßige Updates erforderlich
            sein. Die Nutzung von TRAMEDIX kann von der Leistungsfähigkeit
            dieser Faktoren abhängen. Die Erfüllung dieser Voraussetzungen, die
            sich jederzeit ändern können, liegt in der Verantwortung des
            Benutzers.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer ist verpflichtet, in eigener Verantwortung
            sicherzustellen, dass er von TRAMEDIX bereitgestellte Informationen,
            die er selbst zum Zwecke der Buchführung, Beweissicherung oder für
            andere, in seiner Sphäre liegende Zwecke benötigt, selbst außerhalb
            von TRAMEDIX speichert bzw. archiviert.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Dem Benutzer ist es untersagt, Daten von oder aus TRAMEDIX oder ihm
            durch die Nutzung von TRAMEDIX bekannt gewordene Daten anderer
            Benutzer in irgendeiner Form außerhalb von TRAMEDIX zu verbreiten,
            öffentlich wiederzugeben oder sonst zu verwenden, soweit dies nicht
            zur Erfüllung zwingender gesetzlicher Vorschriften notwendig ist
            oder eine ausdrückliche Genehmigung von TRAMEDIX und ggfs. des
            anderen Benutzers vorliegt. Dies gilt insbesondere für den
            Weiterverkauf solcher Daten, sowie Ihre Nutzung für die werbliche
            Ansprache oder sonstige, direkte Kontaktaufnahme zu einem anderen
            Nutzer außerhalb der Abwicklung eines mit diesem auf TRAMEDIX
            geschlossenen Vertrages.
          </li>
        </p>
        <div className="pb-2">
          <li>
            TRAMEDIX enthält und ermöglicht den Zugriff auf urheberrechtlich
            geschützte Datenbanken und Informationen der IFA GmbH, Hamburger
            Allee 26 – 28, 60486 Frankfurt am Main, welche TRAMEDIX in Lizenz
            nutzt und im Rahmen dieser Lizenz sowie in dem, durch die TRAMEDIX
            Handelsplattform selbst vorgegebenen, Rahmen allen Benutzern zur
            Verfügung stellt bzw. veröffentlicht. Insbesondere ist es allen
            Benutzern untersagt,, Insoweit ist TRAMEDIX unter Ausschluss von
            Ansprüchen der Benutzer gegen die IFA GmbH als Dienstanbieter allein
            für diese Inhalte verantwortlich.
          </li>
          <ul>
            <li className="py-2">
              <strong>&gt; &nbsp;</strong>die Daten zu anderen Zwecken zu
              nutzen, als zum Abschluss und/oder zur Abwicklung von Geschäften
              über die Handelsplattform tramedix;
            </li>
            <li className="pb-2">
              <strong>&gt; &nbsp;</strong>die Daten im Ganzen oder in
              wesentlichen Teilen herunterzuladen und zu speichern;
            </li>
            <li className="pb-2">
              <strong>&gt; &nbsp;</strong>die Daten im Ganzen oder in
              wesentlichen Teilen weiterzugeben oder sie – sei es vorübergehend
              oder dauerhaft – zu vervielfältigen oder umzugestalten.
            </li>
          </ul>
        </div>
        <p className="pb-2">
          <li>
            Allen Benutzern ist es untersagt, nach Art und Umfang wesentliche
            Teile des Lizenzmaterials zu speichern, zu vervielfältigen, zu
            verbreiten, öffentlich wiederzugeben oder umzugestalten, wobei die
            wiederholte und systematische Speicherung, Vervielfältigung,
            Verbreitung, öffentliche Wiedergabe oder Umgestaltung von nach Art
            und Umfang unwesentlichen Teilen des Lizenzmaterials der
            Speicherung, Vervielfältigung, Verbreitung, öffentlichen Wiedergabe
            oder Umgestaltung eines nach Art und Umfang wesentlichen Teils des
            Lizenzmaterials dabei gleich steht.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Alle Benutzer sind verpflichtet, das Lizenzmaterial nur auf dem
            Bildschirm darzustellen, nicht mehr als einige wenige
            Bildschirmseiten auszudrucken und nach Art und Umfang wesentliche
            Teile des Lizenzmaterials nicht auf einen Rechner oder einen
            Datenträger herunterzuladen. Das wiederholte und systematische
            Herunterladen von nach Art und Umfang unwesentlichen Teilen des
            Lizenzmaterials steht dem Herunterladen eines nach Art und Umfang
            wesentlichen Teils des Lizenzmaterials dabei gleich.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Alle Benutzer sind verpflichtet, das Lizenzmaterial oder Teile
            daraus nur für eigene Zwecke zu nutzen und Ausdrucke oder Downloads
            aus dem Lizenzmaterial, auch wenn es sich dabei um unwesentliche
            Teile des Lizenzmaterials handelt, nicht an Dritte außerhalb des
            Benutzerkreises der TRAMEDIX weiterzugeben.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Alle Benutzer sind verpflichtet, einen unbefugten Zugriff auf das
            Lizenzmaterial sowie die Nutzung oder Kenntnisnahme des
            Lizenzmaterials durch Dritte auszuschließen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Allen Benutzern ist es untersagt, das Lizenzmaterial im Ganzen oder
            in Teilen für oder im Zusammenhang mit Warenwirtschafts- und/ oder
            Bestellsystemen zu nutzen.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">
          VERTRAGSLAUFZEIT, KÜNDIGUNG, PREISE UND ZAHLUNG
        </p>
        <p className="pb-2">
          <li>
            Tramedix übernimmt keinerlei Gewähr für die Liefermenge,
            Beschaffenheit oder andere Vertragsinhalte der Benutzer. Die
            Benutzer haften untereinander für die Abwicklung der jeweilligen
            Verträge. Tramedix haftet somit für keinerlei Verträge der Benutzer
            oder Vorlieferanten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Für Pharma-Großhändler und Apotheken mit Großhandelserlaubnis ist
            die Registrierung, Einrichtung und Betrieb eines TRAMEDIX
            Benutzerkontos kostenlos. Der entstehende Nutzungsvertrag ist
            unbefristet und jederzeit mit sofortiger Wirkung in Textform
            kündbar. Die Kündigung hat die unverzügliche Löschung des
            entsprechenden Benutzerkontos zur Folge.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX kann einen kostenlosen Nutzungsvertrag jederzeit mit einer
            Frist von 14 Tagen zum Monatsende kündigen. Das Recht zum
            einseitigen Ausschluss sowie zur außerordentlichen Kündigung aus
            wichtigem Grund bleibt hiervon unberührt. Die Verträge zwischen den
            Nutzern untereinander werden durch die Kündigung des
            Nutzungsvertrages nicht berührt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Auf ein Angebot oder ein Gesuch zu reagieren und ein verbindliches
            Gebot abzugeben, ist kostenlos. Auch bei einer erfolgreichen
            Transaktion entstehen dem Bieter keine Kosten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Mit Zustandekommen eines Vertrages zwischen zwei Benutzern aufgrund
            eines auf TRAMEDIX veröffentlichten Angebotes oder Kaufgesuchs fällt
            für den Benutzer, welcher das Angebot eingestellt hat eine
            Transaktionsgebühr in Höhe von jeweils 0,75 % des
            Netto-Angebotswertes (Preislise). Bei Gesuchen fällt die
            Transaktionsgebühr in Höhe von 0,75 % des Netto-Kaufgesuchswertes
            für den Benutzer an, welcher das Kaufgesuch erfüllen kann. Der
            Anspruch auf diese Gebühr besteht für TRAMEDIX gegenüber dem
            Benutzer, welcher das Angebot eingestellt hat oder sich für die
            Belieferung eines Gesuchs verpflichtet hat, unabhängig davon, ob der
            zwischen den Benutzern geschlossene Vertrag durchgeführt wird. Das
            gilt insbesondere, wenn die Benutzer den Vertrag einvernehmlich
            aufheben oder der Vertrag auf andere Weise mit Wirkung für die
            Zukunft beendet wird.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die Transaktionsgebühr wird dem Verkäufer einer Ware bei Angeboten
            und bei Gesuchen, nach TransaktionsabschlussZustandekommen eines
            Vertrages, von TRAMEDIX in Rechnung gestellt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Alle Preise verstehen sich zuzüglich gesetzlicher Umsatzsteuer,
            soweit diese fällig wird.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Die Zahlung der Transaktionsgebühren sind jeweils sofort zur Zahlung
            fällig und können über die von TRAMEDIX angebotenen
            Zahlungsmethoden, Rechnung oder Lastschrift beglichen werden.
            TRAMEDIX behält sich vor, einzelne Zahlungsarten für bestimmte
            Gebührenarten und/ oder einzelne Benutzer nicht anzubieten.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX behält sich vor, einzelne Zahlungsmodalitäten zu ändern,
            insbesondere auf Entrichtung eines Guthabens zur Begleichung
            zukünftiger Transaktionsgebühren.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX behält sich vor zur Zahlungsabwicklung und zum
            Forderungseinzug externe Dienstleister und/ oder eine
            Abrechnungsorganisation beauftragen.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Der Benutzer ist damit einverstanden, Rechnungen und damit
            verbundene Informationen von TRAMEDIX per E-Mail als PDF zum
            Originalausdruck zu erhalten. Lediglich auf Anforderung wird
            TRAMEDIX einen Rechnungsausdruck erstellen der auf dem Postweg
            zugestellt wird. Hierfür werden dem Nutzer die tatsächlichen Kosten,
            mindestens jedoch 1 Euro pro Brief, in Rechnung gestellt.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Schlägt ein Forderungseinzug fehl, hat der Benutzer TRAMEDIX die
            dafür anfallenden Mehrkosten zu erstatten, soweit er den Fehlschlag
            zu vertreten hat.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Benutzer dürfen gegen Forderungen und Ansprüche von TRAMEDIX mit
            fälligen und/ oder zukünftigen Forderungen nur dann aufrechnen, wenn
            diese Forderungen rechtskräftig festgestellt oder unbestritten sind.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX behält sich das Recht vor, für die Sperrung von Mitgliedern
            wegen Verstoßes gegen diese AGB eine angemessene Aufwandspauschale
            zu berechnen, soweit der Nutzer den Verstoß zu vertreten hat, es sei
            denn, dieser weist nach, dass ein Schaden überhaupt nicht oder in
            wesentlich geringerer Höhe entstanden ist.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX kann die Gebühren jederzeit ändern. Preisänderungen werden
            den Nutzer rechtzeitig vor dem Inkrafttreten auf der
            TRAMEDIX-Website mitgeteilt.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">
          DATENSCHUTZ UND NUTZUNG VON DATEN
        </p>
        <p className="pb-2">
          <li>
            Die jeweils aktuelle Datenschutzerklärung von TRAMEDIX ist
            Bestandteil dieser AGB.
          </li>
        </p>
        <p className="pb-2">
          <li>
            Mit der Nutzung der Website www.tramedix.de und/ oder
            www.tramedix.com sowie der Registrierung bei TRAMEDIX stimmt der
            Benutzer der Erhebung, Speicherung und Verwendung seiner Daten
            entsprechend der Datenschutzerklärung für die gesamte Dauer der
            Nutzung von TRAMEDIX zu. Dies gilt auch für vom Benutzer
            hochgeladene Bildmaterial und sonstige Dateien.
          </li>
        </p>
        <p className="text-2xl py-4 font-bold">HAFTUNG</p>
        <p className="pb-2">
          <li>
            TRAMEDIX übernimmt keine Gewähr für die zwischen den jeweiligen
            Benutzern abgeschlossenen Vereinbarungen und zur Verfügung
            gestellten Informationen. Insbesondere schließt TRAMEDIX jede eigene
            Sachmängelhaftung für von Benutzern/ Vertragspartnern gelieferte
            Waren aus.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX haftet ebenfalls nicht für die Richtigkeit und/ oder
            Vollständigkeit der von Nutzern auf TRAMEDIX im Rahmen von Angeboten
            und Gesuchen gemachten Angaben zur Beschaffenheit der Ware, nachdem
            die Angaben durch den Anbieter erfolgen und TRAMEDIX keine eigene
            Prüfungsmöglichkeit hat.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX haftet nicht für Ansprüche, die daraus entstehen, dass
            TRAMEDIX vorübergehend, insbesondere auf Grund von Wartungsarbeiten
            nicht erreichbar ist.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX übernimmt keine Haftung für Leistungseinschränkungen, die
            sich aufgrund von Inkompatibilitäten oder Softwareeinstellungen beim
            Nutzer oder bei Dritten ergeben.
          </li>
        </p>
        <p className="pb-2">
          <li>
            TRAMEDIX haftet f&uuml;r Sch&auml;den, au&szlig;er im Fall der
            Verletzung wesentlicher Vertragspflichten, d.h. solcher Pflichten,
            deren Einhaltung f&uuml;r die Erreichung des Vertragszwecks von
            besonderer Bedeutung ist, nur, wenn und soweit TRAMEDIX selbst, den
            gesetzlichen Vertretern, leitenden Angestellten oder sonstigen
            Erf&uuml;llungsgehilfen Vorsatz oder grobe Fahrl&auml;ssigkeit zur
            Last f&auml;llt. Im Fall der Verletzung wesentlicher
            Vertragspflichten haftet TRAMEDIX f&uuml;r jedes schuldhafte
            Verhalten der gesetzlichen Vertreter, leitenden Angestellten oder
            sonstigen Erf&uuml;llungsgehilfen.
          </li>
        </p>

        <p className="pb-2">
          <li>
            Au&szlig;er bei Vorsatz oder grober Fahrl&auml;ssigkeit gesetzlicher
            Vertreter, leitender Angestellter oder sonstiger
            Erf&uuml;llungsgehilfen von TRAMEDIX ist die Haftung der H&ouml;he
            nach auf die bei Vertragsschluss typischerweise vorhersehbaren
            Sch&auml;den begrenzt.
          </li>
        </p>

        <p className="pb-2">
          <li>
            F&uuml;r den Verlust von Daten haftet TRAMEDIX nur in H&ouml;he des
            Aufwandes, der entsteht, wenn der Nutzer regelm&auml;&szlig;ig und
            im angemessenen Umfang eine eigene Datensicherung durchf&uuml;hrt
            und so sichergestellt hat, dass verloren gegangene Daten mit
            vertretbarem Aufwand wieder hergestellt werden k&ouml;nnen.
          </li>
        </p>

        <p className="pb-2">
          <li>
            Der Nutzer wird TRAMEDIX von allen Anspr&uuml;chen anderer
            TRAMEDIX-Nutzer oder Dritter wegen der Verletzung ihrer Rechte die
            auf sein eigenes Nutzungsverhalten zur&uuml;ckgehen, freistellen,
            soweit er diese Verletzung zu vertreten hat.
          </li>
        </p>

        <div className="text-2xl py-4 font-bold">
          <h2>&Auml;NDERUNGEN</h2>
        </div>

        <p className="pb-2">
          <li>
            TRAMEDIX beh&auml;lt sich vor, diese AGB jederzeit ohne Nennung von
            Gr&uuml;nden zu &auml;ndern, es sei denn, das ist f&uuml;r den
            Benutzer nicht zumutbar. TRAMEDIX wird den Benutzer &uuml;ber
            &Auml;nderungen der AGB rechtzeitig benachrichtigen. Widerspricht
            der Benutzer der Geltung der neuen AGB nicht innerhalb von vier (4)
            Wochen nach der Benachrichtigung, gelten die ge&auml;nderten AGB als
            vom Benutzer angenommen. TRAMEDIX wird den Nutzer in der
            Benachrichtigung auf sein Widerspruchsrecht und die Bedeutung der
            Widerspruchsfrist hinweisen.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX beh&auml;lt sich dar&uuml;ber hinaus vor, AGB zu
            &auml;ndern.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX wird &uuml;ber solche &Auml;nderungen der AGB informieren,
            zum Beispiel auf der TRAMEDIX-Website oder an die vom Benutzer
            hinterlegte E-Mail Adresse..
          </li>
        </p>

        <p className="pb-2">
          <li>
            In Bezug auf Produkte und Dienstleistungen, die einzeln gebucht
            werden und nicht im Rahmen eines Dauerschuldverh&auml;ltnisses
            angeboten werden (z. B. die Buchung von Werbung) kommt je Buchung
            ein gesonderter Vertrag zustande, dessen zus&auml;tzliche
            Bedingungen gegebenenfalls vor Buchung im Einzelfall gesondert
            angezeigt werden. Die entsprechenden Vertragsbedingungen und
            Leistungen k&ouml;nnen unabh&auml;ngig von den hier genannten
            Anforderungen an Leistungs&auml;nderungen f&uuml;r zuk&uuml;nftige
            Buchungen ge&auml;ndert werden.
          </li>
        </p>

        <div className="text-2xl py-4 font-bold">
          <h2>ANWENDBARES RECHT, GERICHTSSTAND und SONSTIGE VEREINBARUNGEN</h2>
        </div>

        <p className="pb-2">
          <li>
            F&uuml;r alle Rechtsbeziehungen zwischen Nutzern und TRAMEDIX
            einschlie&szlig;lich dieser AGB gilt deutsches Recht.
          </li>
        </p>

        <p className="pb-2">
          <li>
            Im Verh&auml;ltnis zu Nutzern, die Kaufmann im Sinne des
            Handelsgesetzbuchs, ein &ouml;ffentlich-rechtliches
            Sonderverm&ouml;gen oder eine juristische Person des
            &ouml;ffentlichen Rechts sind, ist der Sitz der TRAMEDIX,
            G&uuml;strow, ausschlie&szlig;licher Gerichtsstand.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX kann Unterauftragnehmer einsetzen. TRAMEDIX bleibt auch bei
            Einsetzung von Unterauftragnehmern verantwortlich f&uuml;r die
            Erf&uuml;llung der durch TRAMEDIX &uuml;bernommen Pflichten.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX ist berechtigt, Rechte und Pflichten ganz oder teilweise an
            Dritte zu &uuml;bertragen.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX ist berechtigt, mit einer Ank&uuml;ndigungsfrist von vier
            Wochen, seine Recht und Pflichten aus diesem Vertragsverh&auml;ltnis
            ganz oder teilweise auf Dritte zu &uuml;bertragen. In diesem Fall
            ist der Nutzer berechtigt, den Nutzungsvertrag au&szlig;erordentlich
            in Textform zu k&uuml;ndigen.
          </li>
        </p>

        <p className="pb-2">
          <li>
            TRAMEDIX kann Erkl&auml;rungen gegen&uuml;ber dem Nutzer per E-Mail,
            Fax oder Brief &uuml;bermitteln, sofern in den AGB nicht anders
            geregelt. Au&szlig;erdem kann TRAMEDIX Erkl&auml;rungen
            gegen&uuml;ber dem Benutzer per Mitteilung an die vom Benutzer
            hinterlegte E-Mail-Adresse, welche der Benutzers bei seiner
            Registrierung hinterlegt hat, abgeben.
          </li>
        </p>

        <p className="pb-2">
          <li>
            Erf&uuml;llungsort ist Gesellschaftssitz der TRAMEDIX, G&uuml;strow.
          </li>
        </p>

        <p className="pb-2">
          <li>
            Der Einbeziehung von AGB der Mitglieder/nutzer wird hiermit
            widersprochen.
          </li>
        </p>

        <div className="text-2xl py-4 font-bold">
          <h2>SALVATORISCHE KLAUSEL</h2>
        </div>

        <p className="pb-2">
          <li>
            Sofern einzelne Bestimmungen der vorliegenden AGB unwirksam sind,
            bleiben die &uuml;brigen Bestimmungen davon unber&uuml;hrt. Die
            unwirksame Regelung ist von den Parteien durch eine rechtswirksame
            zu ersetzen, deren wirtschaftlicher Erfolg der unwirksamen Regelung
            soweit wie m&ouml;glich entspricht. Dies gilt auch f&uuml;r
            eventuelle Regelungsl&uuml;cken.
          </li>
        </p>
        <p>
          <strong>G&uuml;strow, 28.09.2021</strong>
        </p>
      </ol>
    </div>
  );
};

export default GeneralLayout(Terms);
