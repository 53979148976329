import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../Shared/layouts/TextInput';
import { InputChangeState } from '../../../globals/models/components/textInput';
import { infoMaterialFormValidationSchema } from '../../../shared/validators/home.schemas';
import { submitHomeContactForm } from '../../../shared/helpers/home';

const InfoMaterialFormInitialValues = {
  name: '',
  email: '',
};

const InfoMaterialForm = ({ canSubmit }: InfoMaterialFormProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Formik
      onSubmit={(values, formikHelpers) => {
        setIsSubmitting(true);
        submitHomeContactForm(values)
          .then(() => {
            setIsSubmitting(false);
            toast.success(t('CONTACT_FORM.SUBMISSION_SUCCESS'));
            formikHelpers.resetForm();
          })
          .catch(() => {
            setIsSubmitting(false);
            toast.error(t('CONTACT_FORM.SUBMISSION_ERROR'));
          });
      }}
      validateOnChange
      initialValues={InfoMaterialFormInitialValues}
      validationSchema={infoMaterialFormValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          handleSubmit,
        } = props;

        const isFormTouched = Object.values(touched).length > 0;
        const isFormValid =
          isValid &&
          Object.values(errors).length === 0 &&
          isFormTouched &&
          canSubmit;

        return (
          <div className="md:border-r border-gray-500 pb-16 md:pr-20 pr-6 md:pl-0 pl-6">
            <h1 className="font-bold md:text-2xl text-xl pb-8">
              {t('HOME_PAGE_CONTACT_FORM_TITLE_1')}
            </h1>
            <p className="text-lg">{t('HOME_CONTACT_FORM_EXPL_TEXT_1')}</p>
            <div className="py-8">
              <div className="grid grid-cols-2 gap-10">
                <TextInput
                  forText="contactFormName2"
                  isRequired
                  labelKey={t('NAME_TEXT')}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  changeState={
                    touched.name && errors.name
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.name}
                  value={values.name}
                  classes={{
                    input: 'border border-gray-500 rounded-sm w-full h-8 mt-2',
                    container: 'w-full',
                  }}
                  name="name"
                  disabled={isSubmitting}
                />
                <TextInput
                  forText="contactFormEmail"
                  isRequired
                  labelKey={t('EMAIL_TEXT')}
                  onChange={(value, event) => {
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  changeState={
                    touched.email && errors.email
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.email}
                  value={values.email}
                  classes={{
                    input: 'border border-gray-500 rounded-sm w-full h-8 mt-2',
                    container: 'w-full',
                  }}
                  name="email"
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <p className="card-footer">
              <button
                type="button"
                className={`button focus:outline-none ${
                  isFormValid
                    ? 'cursor-pointer bg-green-600'
                    : 'cursor-not-allowed bg-green-400'
                }`}
                disabled={!isFormValid || isSubmitting}
                onClick={() => handleSubmit()}
              >
                {t('HOME_CONTACT_FORM_SUBMIT_TEXT_1')}
                {isSubmitting && (
                  <CircularProgress
                    style={{ color: 'white' }}
                    size={15}
                    className="ml-2"
                  />
                )}
              </button>
            </p>
          </div>
        );
      }}
    </Formik>
  );
};

interface InfoMaterialFormProps {
  canSubmit: boolean;
}

export default InfoMaterialForm;
