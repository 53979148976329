import React, { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import NumberInput from '../../../../Shared/layouts/NumberInput';
import {
  InputChangeState,
  TextInputType,
} from '../../../../../globals/models/components/textInput';

const MSV3DeliveryAmountPicker: React.FC<MSV3DeliveryAmountPickerProps> = ({
  emitNextValue,
  initialValue,
  errorMessage,
  disabled,
}) => {
  const [value, setValue] = useState(initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEmit = useCallback(debounce(emitNextValue, 1000), []);

  return (
    <NumberInput
      labelKey=""
      forText="min-total-proceeds-"
      isRequired={false}
      classes={{
        input: `rounded-sm w-full h-8 border border-gray-500 ${
          !disabled ? '' : 'text-center'
        }`,
        container: 'sm:mt-0 mb-2 px-2',
      }}
      value={value}
      type={TextInputType.NUMBER}
      errorMessage={errorMessage}
      onChange={(value_) => {
        const amount = Number(value_);
        if (value_) {
          setValue(`${amount}`);
          debouncedEmit(`${amount}`);
          return;
        }

        setValue('0');
        debouncedEmit('0');
      }}
      allowNegative={false}
      changeState={errorMessage ? InputChangeState.INVALID : undefined}
    />
  );
};

interface MSV3DeliveryAmountPickerProps {
  emitNextValue: (nextValue: string) => void;
  initialValue: string;
  errorMessage?: string;
  disabled: boolean;
}

export default MSV3DeliveryAmountPicker;
