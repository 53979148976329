/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faExchangeAlt,
  faMailBulk,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import HeaderProfile from './HeaderProfile';
import { User, UserRole } from '../../../../globals/models/data/user.model';
import banner from '../../../../assets/img/Tramedix_Logo.png';
import {
  cancelLastGetArticlesRequest,
  resetDataTable,
} from '../../../../store/actions/creators/dataTable';
import { resetShoppingCartTable } from '../../../../store/actions/creators/shoppingCart';
import showLicenseInvalidOrExpiredToast from '../../../../shared/helpers/showInvalidOrExpiredLicenseToast';
import CustomLink from '../CustomLink';
import order_devil_logo from '../../../../assets/img/order_devil_transparent.png';

const Shell = ({
  currentUser,
  _resetDataTable,
  transactionsNumber,
  _resetShoppingCartTable,
  messagesNumber,
}: HeaderAuthContentProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const getLinkDisabledState = (disabledState: boolean) => {
    return currentUser?.isLicenseDueOrInvalid ? false : disabledState;
  };

  return (
    <header>
      <div className="lg:h-14 relative flex flex-wrap items-center justify-between navbar-expand-lg bg-white text-primary lg:text-lg">
        <div className="w-full flex flex-wrap justify-between md:h-full">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start py-4 lg:w-1/5 pl-6">
            <CustomLink
              to="/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 "
              onClick={() => showLicenseInvalidOrExpiredToast(currentUser)}
              disabled={getLinkDisabledState(location.pathname === '/')}
            >
              <img src={banner} alt="banner" className="h-8" />
              <br />
            </CustomLink>
            <button
              className="cursor-pointer  text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none mr-2"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <nav
            className={`lg:flex flex-grow md:h-full lg:w-4/5 xl:pl-16 pl-0 ${
              navbarOpen ? ' flex' : ' hidden'
            }`}
            id="example-navbar-danger"
          >
            <div className="flex lg:flex-row flex-col w-full h-full">
              {/* {!((currentUser?.role as UserRole) === UserRole.ADMIN) && */}
              {/*  !currentUser?.consulting && ( */}
              <ul className="flex flex-col lg:flex-row flex-grow list-none lg:ml-auto md:h-full lg:text-2xl text-xl">
                {!((currentUser?.role as UserRole) === UserRole.ADMIN) &&
                !currentUser?.consulting ? (
                  <>
                    <li className="nav-item">
                      <div
                        className={`flex flex-col items-center justify-center h-full px-4 lg:py-0 py-1 hover:opacity-75 font-bold xl:mr-6 ${
                          location.pathname.includes('offers') &&
                          'lg:border-b-5 lg:border-transparent lg:border-primary'
                        }`}
                      >
                        <CustomLink
                          to="/application/offers"
                          className="w-full"
                          onClick={() => {
                            cancelLastGetArticlesRequest();
                            showLicenseInvalidOrExpiredToast(currentUser);
                            _resetDataTable();
                          }}
                          disabled={getLinkDisabledState(
                            location.pathname === '/application/offers/own' ||
                              location.pathname === '/application/offers/all',
                          )}
                        >
                          {t('HEADER_OFFERS_BUTTON')}
                        </CustomLink>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        className={`flex flex-col items-center justify-center h-full px-4 lg:py-0 py-1 hover:opacity-75 font-bold text-green-600 ${
                          location.pathname.includes('search') &&
                          'lg:border-b-5 lg:border-transparent lg:border-green-600'
                        }`}
                      >
                        <CustomLink
                          to="/application/search"
                          className="w-full"
                          onClick={() => {
                            cancelLastGetArticlesRequest();
                            showLicenseInvalidOrExpiredToast(currentUser);
                            _resetDataTable();
                          }}
                          disabled={getLinkDisabledState(
                            location.pathname === '/application/search/own' ||
                              location.pathname === '/application/search/all',
                          )}
                        >
                          {t('HEADER_SEARCH_BUTTON')}
                        </CustomLink>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        className={`flex flex-col items-center justify-center h-full px-4 lg:py-0 py-1 hover:opacity-75 font-bold xl:mr-6 ${
                          location.pathname.includes('msv3') &&
                          'lg:border-transparent lg:border-primary'
                        }`}
                      >
                        <CustomLink
                          to="/application/msv3"
                          className="w-full"
                          onClick={() => {
                            cancelLastGetArticlesRequest();
                            showLicenseInvalidOrExpiredToast(currentUser);
                            _resetDataTable();
                          }}
                          disabled={getLinkDisabledState(
                            location.pathname === '/application/msv3',
                          )}
                        >
                          <img
                            src={order_devil_logo}
                            alt="order_devil_logo"
                            className="h-40"
                          />
                        </CustomLink>
                      </div>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <div
                      className={`flex flex-col items-center justify-center h-full px-4 lg:py-0 py-1 hover:opacity-75 font-bold xl:mr-6 ${
                        location.pathname.includes('msv3') &&
                        'lg:border-transparent lg:border-primary'
                      }`}
                    >
                      <CustomLink
                        to="/application/msv3"
                        className="w-full"
                        onClick={() => {
                          cancelLastGetArticlesRequest();
                          showLicenseInvalidOrExpiredToast(currentUser);
                          _resetDataTable();
                        }}
                        disabled={getLinkDisabledState(
                          location.pathname === '/application/msv3',
                        )}
                      >
                        <img
                          src={order_devil_logo}
                          alt="order_devil_logo"
                          className="h-40"
                        />
                      </CustomLink>
                    </div>
                  </li>
                )}
              </ul>
              <ul className="flex flex-col lg:flex-row flex-wrap list-none lg:ml-auto md:h-full">
                <li
                  className={`nav-item ${
                    currentUser?.role === UserRole.ADMIN && 'pr-10'
                  }`}
                >
                  <div className="flex flex-col items-center justify-center hover:opacity-75 h-full px-4 lg:py-0 py-1">
                    <div className="w-full">
                      <button
                        type="button"
                        aria-describedby={id}
                        color="primary"
                        onClick={(e) => {
                          handleClick(e);
                        }}
                        className="w-full h-full focus:outline-none lg:text-center text-left truncate"
                      >
                        <span className="pr-2">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        {`${currentUser?.firstName} ${currentUser?.lastName}`}
                        <span className="pl-2">
                          <FontAwesomeIcon
                            icon={open ? faAngleUp : faAngleDown}
                          />
                        </span>
                      </button>
                      <div className="mt-4">
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 40,
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <HeaderProfile handleClose={handleClose} />
                        </Popover>
                      </div>
                    </div>
                  </div>
                </li>
                {!((currentUser?.role as UserRole) === UserRole.ADMIN) && (
                  <>
                    <li className="nav-item">
                      <CustomLink
                        className="flex flex-col items-center items-center justify-center hover:opacity-75 h-full pl-4 pr-8 lg:py-0 py-1"
                        to="/-/profile/messages"
                        onClick={() => {
                          cancelLastGetArticlesRequest();
                          showLicenseInvalidOrExpiredToast(currentUser);
                        }}
                        disabled={getLinkDisabledState(
                          location.pathname === '/-/profile/messages',
                        )}
                      >
                        <Tooltip
                          title={
                            t('SHELL.LABELS.MESSAGES_TIP', {
                              messagesNumber,
                            }) as string
                          }
                          placement="top"
                        >
                          <div className="w-full">
                            <span className="pr-2">
                              <FontAwesomeIcon icon={faMailBulk} />
                            </span>
                            {t('HEADER_MESSAGES_BUTTON')}
                            <span className="ml-1 rounded-full bg-white text-white w-2 h-2 text-sm px-1 bg-primary">
                              {messagesNumber}
                            </span>
                          </div>
                        </Tooltip>
                      </CustomLink>
                    </li>
                    <li className="nav-item">
                      <CustomLink
                        onClick={() => {
                          cancelLastGetArticlesRequest();
                          showLicenseInvalidOrExpiredToast(currentUser);
                          _resetShoppingCartTable();
                          _resetDataTable();
                        }}
                        to="/cart"
                        className="flex flex-col items-center justify-center hover:opacity-75 lg:bg-primary lg:text-white h-full xl:px-6 px-4 lg:py-0 py-1"
                        disabled={location.pathname === '/cart'}
                      >
                        <div className="w-full">
                          <span className="pr-2">
                            <FontAwesomeIcon icon={faExchangeAlt} />
                          </span>
                          {t('HEADER_TRANSACTIONS_BUTTON')}
                          <span className="ml-1 rounded-full bg-white text-primary w-2 h-2 text-sm px-1">
                            {transactionsNumber}
                          </span>
                        </div>
                      </CustomLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

interface HeaderAuthContentProps {
  currentUser: User | null;
  _resetDataTable: () => { type: string };
  transactionsNumber: number;
  _resetShoppingCartTable: () => { type: string };
  messagesNumber: number;
}

const mapStateToProps = ({
  registrationState: { currentUser },
  dataTableState: { transactionsNumber },
  profileState: { messagesNumber },
}: StoreI) => ({
  currentUser,
  transactionsNumber,
  messagesNumber,
});

export default connect(mapStateToProps, {
  _resetDataTable: resetDataTable,
  _resetShoppingCartTable: resetShoppingCartTable,
})(Shell);
