/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

interface FileInputProps {
  containerClassName?: string;
  inputClassName?: string;
  onChange?: (value: File | null) => void;
  value?: File | null;
}

const FileInput = ({
  containerClassName,
  inputClassName,
  onChange,
  value,
}: FileInputProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [value_, setValue] = useState(value);
  return (
    <p className={containerClassName}>
      <input
        className={`relative text-white ${inputClassName}`}
        type="file"
        accept="application/pdf, image/*"
        onChange={(event) => {
          setValue(event.target.files ? event.target.files[0] : null);
          if (onChange) {
            onChange(event.target.files ? event.target.files[0] : null);
          }
        }}
      />
      <span className="absolute mt-1 auth__company-file-input-label">
        {value_?.name}
      </span>
    </p>
  );
};

export default FileInput;
