import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  keySeparator: '.',

  interpolation: {
    escapeValue: false,
  },
});

/**
 * The changing of the language happens here
 */

const localLanguageKey = localStorage.getItem('REACT_APP_DEFAULT_LANGUAGE_KEY');
const defaultLanguageKey = process.env.REACT_APP_DEFAULT_LANGUAGE_KEY;

if (localLanguageKey) {
  i18n.changeLanguage(localLanguageKey);
} else if (defaultLanguageKey) {
  i18n.changeLanguage(defaultLanguageKey);
} else {
  i18n.changeLanguage('de');
}

export default i18n;
