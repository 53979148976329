import React, { useLayoutEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';
import { TRAMEDIX_PHONE_NUMBER } from '../../shared/constants/constantChoices';

const faqQuestions = [
  {
    title: <h1 className="font-bold">WER KANN AUF TRAMEDIX HANDELN?</h1>,
    content: (
      <div className="px-4">
        <h1>Tramedix ist eine Handelsplattform für den</h1>
        <ul className="px-10 py-4 list-disc">
          <li>Pharmagroßhandel und für</li>
          <li>Apotheken mit Großhandelserlaubnis.</li>
        </ul>
        <p>
          Im Interesse der Arzneimittelsicherheit muss jeder Nutzer im
          gesetzlichen Rahmen zum Arzneimittelbezug bzw.-Handel zugelassen sein.
          Beschränkter Zugang besteht für:
        </p>
        <ul className="px-10 py-4 list-disc">
          <li>Arzneimittelhersteller und</li>
          <li>Medizinproduktehersteller und -händler.</li>
        </ul>
        <p>
          Arzneimittelhersteller können auf tramedix ihre eigenen Produkte
          verkaufen. Der Bezug von Arzneimitteln ist nicht freigegeben.
          Medizinproduktehersteller und- händler sind ebenfalls bei tramedix
          herzlich willkommen. Medizinprodukte lassen sich auf tramedix frei
          handeln. Arzneimittel können von Ihnen nicht eingesehen oder gehandelt
          werden. Für einzelne Nutzer oder Nutzergruppen können sich gemäß der
          gesetzlichen Bestimmungen Einschränkungen in Bezug auf Art, Gegenstand
          und Umfang der möglichen Transaktionen ergeben.
        </p>
      </div>
    ),
    accordionId: 'wer-darf-handeln',
  },
  {
    title: (
      <h1 className="font-bold">
        WELCHE UNTERLAGEN WERDEN ZUR REGISTRIERUNG BENÖTIGT?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          TIm Interesse der Arzneimittelsicherheit und im Interesse aller
          handelnden Akteure steht die tramedix Handelsplattform nur einem
          geschlossenen Nutzerkreis nach erfolgreicher Registrierung zur
          Verfügung. Um auf tramedix aktiv werden zu können, benötigen Sie
          folgende Dokumente in aktueller Fassung während der Registrierung:
          <br />
          <br />
          Apotheken mit Großhandelserlaubnis
        </h1>
        <ul className="px-10 py-4 list-disc">
          <li>Betriebserlaubnis (§ 1 Abs. 2 ApoG) und</li>
          <li>Großhandelserlaubnis (§ 52 A AMG)</li>
          <li>Handelsregisterauszug</li>
        </ul>
        <p>Pharmagroß- und zwischenhändler:</p>
        <ul className="px-10 py-4 list-disc">
          <li>Großhandelserlaubnis (§ 52 A AMG)</li>
          <li>Handelsregisterauszug</li>
        </ul>
        <p>Arzneimittelhersteller</p>
        <ul className="px-10 py-4 list-disc">
          <li>Herstellererlaubnis (§ 13 AMG)</li>
        </ul>
        <p>Medizinproduktehersteller und händler</p>
        <ul className="px-10 py-4 list-disc">
          <li>Handelsregisterauszug</li>
        </ul>
      </div>
    ),
    accordionId: 'unterlagen',
  },
  {
    title: (
      <h1 className="font-bold">WAS BEDEUTET SICHERES UND ANONYMES HANDELN?</h1>
    ),
    content: (
      <div className="px-4">
        <p>
          Tramedix steht einem geschlossenen Nutzerkreis, berechtigter
          Teilnehmer zur Verfügung. Das Einstellen von Kaufgesuchen sowie das
          Abgeben von Geboten findet vollständig anonym statt.
          <br />
          Eingestellte Lagerbestände und abgegebene Preisvorschläge können von
          anderen Akteuren nicht ihrem Handelsaccount zugeordnet werden. Erst
          wenn beide Seiten einer Transaktion zustimmen, erhalten die
          beteiligten Parteien, alle für die Abwicklung der Transaktion
          notwendigen Daten.
          <br />
          Sie geben somit nur dem, für die entsprechende Artikelposition
          relevantem Adressaten, ihre Preiskalkulation bzw. ihren Lagerbestand
          bekannt.
          <br />
          <br />
          Apotheken mit Großhandelserlaubnis
        </p>
      </div>
    ),
    accordionId: 'sicheres-handeln',
  },
  {
    title: <h1 className="font-bold">MIT WELCHEN KOSTEN MUSS ICH RECHNEN?</h1>,
    content: (
      <div className="px-4">
        <h1>
          Es lohnt sich für Sie, auf tramedix zu handeln. Unsere Gebühren sind
          daher einfach, transparent und fair.
          <br />
          <br />
        </h1>
        <table className="w-full text-md bg-white shadow-md rounded">
          <tbody>
            <tr className="border-b border-t rounded">
              <td className="py-2 pl-4">Eröffnung Benutzerkonto</td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">
                Einstellen von Angeboten und Gesuchen
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 pl-4">
                Änderungen von Angeboten und Gesuchen
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">
                Abgeben von Preisvorschlägen / Geboten
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 pl-4">
                Ablehnen von Preisvorschlägen / Geboten
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">
                Abgabe von Preisvorschlägen und Geboten
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 pl-4">
                Erfolgreiche Transaktion, für den Bieter
              </td>
              <td>kostenfrei</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">
                <strong>
                  Erfolgreiche Transaktion, für den Einsteller des Angebots oder
                  Kaufgesuchs
                </strong>
              </td>
              <td>
                <strong>0,75 % vom Nettoumsatz</strong>
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-2 pl-4">Vertragslaufzeit</td>
              <td>keine</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">Kündigungsfrist</td>
              <td>keine</td>
            </tr>
            <tr className="border-b">
              <td className="py-2 pl-4">Zahlungsbedingungen</td>
              <td>Sammelrechung zum 15. und 30. des Monats</td>
            </tr>
            <tr className="border-b bg-gray-100">
              <td className="py-2 pl-4">Zahlungsweise</td>
              <td>SEPA Lastschrift</td>
            </tr>
          </tbody>
        </table>
        <p className="pt-4">Alle Preise zzgl. gesetzl. MwSt.</p>
      </div>
    ),
    accordionId: 'kosten',
  },
  {
    title: <h1 className="font-bold">WELCHE WARE KANN GEHANDELT WERDEN?</h1>,
    content: (
      <div className="px-4">
        <h1>
          Alle Fertigarzneimittel, die in der aktuellen Lauer-Taxe gelistet
          sind, sowie alle Arzneimittel, Hilfsmittel und andere
          Apothekenprodukte für die eine Pharmazentralnummer (PZN) vergeben
          wurde, können auf tramedix gehandelt werden.
          <br />
          Verschreibungspflichtige Arzneimittel (Rx-Präparate) können nur von
          Akteuren mit Großhandelserlaubnis (§52a AMG) gehandelt werden.
          Betäubungsmittel (BTM) können nicht eingestellt werden.
          <br />
          Medizinproduktehersteller und -händler sind vom Handel mit
          Arzneimitteln ausgeschlossen. Sie können tramedix mit seinen
          Funktionalitäten als Handelsplattform für Medizinprodukte nutzen.
        </h1>
      </div>
    ),
    accordionId: 'gehandelte-ware',
  },
  {
    title: <h1 className="font-bold">MÜSSEN DIE WAREN NEUWERTIG SEIN?</h1>,
    content: (
      <div className="px-4">
        <h1>
          Nein. Neben verkaufsfähiger Ware können auch Waren mit dem Status
        </h1>
        <ul className="px-10 py-4 list-disc">
          <li>Bruch,</li>
          <li>kurzes Verfallsdatum, </li>
          <li>abgelaufenes Verfallsdatum oder </li>
          <li>Verpackung beschädigt</li>
        </ul>
        <p>eingestellt werden.</p>
      </div>
    ),
    accordionId: 'art-der-ware',
  },
  {
    title: (
      <h1 className="font-bold">WIE STELLE ICH EIN VERKAUFSANGEBOT EIN?</h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Sie haben Lagerware und möchten diese Artikel gerne auf der
          Handelsplattform anbieten?
          <br />
          <br />
          Handelt es sich um eine Liste mit mehreren Produkten, bietet sich der
          „Masssenimport“ an. Möchten Sie nur einen oder wenige Artikel anlegen,
          so ist dies über den Dialog „Verkaufsangebot anlegen“ schnell und
          unkompliziert erledigt.
        </h1>
      </div>
    ),
    accordionId: 'wie-angebot-erstellen',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE FÜHRE ICH EINEN MASSENIMPORT MEINER ARTIKELDATEN DURCH?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Sie möchten eine Liste mit Artikeln auf tramedix bereitstellen, welche
          sie kaufen oder verkaufen möchten?
        </h1>
        <ol>
          <li>
            Wählen Sie hierzu bitte im Untermenü Verkaufen oder Gesuche den
            Button „Massen Import“ aus.
          </li>
          <li>
            Sie können Tabellen im Excel (.xls und .xlsx), .csv oder .pdf-Format
            hochladen.
            <ul>
              <li>
                Klicken Sie hierzu entweder auf den großen hellblauen Kreis und
                wählen Sie die entsprechende Datei von Ihrer Festplatte aus oder{' '}
              </li>
              <li>
                ziehen Sie Ihre Dateien von Ihrem Computer einfach auf das
                hellblaue Feld (Drag and Drop)
              </li>
            </ul>
          </li>
          <li>
            Ihre Tabelle sollte folgende Spalten enthalten: PZN, Charge, Menge
            PZN-Einheiten, Verfall, Ablaufdatum des Angebots, Zahlungsziel und
            Preis.
          </li>
        </ol>
      </div>
    ),
    accordionId: 'massen-import',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE SOLLTEN DIE IMPORTDATEN AUFBEREITET SEIN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Sollte es beim Import ihrer Daten zu unvollständigen oder fehlerhaften
          Datensätzen gekommen sein, empfehlen wir folgendes Vorgehen:
        </h1>
        <ol>
          <li>
            Prüfen Sie den Aufbau ihrer Tabelle. Laden Sie sich gegebenenfalls
            unsere Beispieltabelle (LINK) herunter und tragen Sie Ihre Daten
            ein.
          </li>
          <li>
            Hat Ihre Datei das richtige Format? Importiert werden können Excel-
            (.xls, .xlsx), CSV- und PDF-Dateien.{' '}
          </li>
          <li>
            Sind in ihrer Tabelle die richtigen Spaltenüberschriften
            eingetragen?{' '}
          </li>
        </ol>
        <p>Notwendige Angaben:</p>
        <ul className="px-10 py-4 list-disc">
          <li>PZN</li>
          <li>Charge</li>
          <li>Menge PZN-Einheiten</li>
          <li>Verfall</li>
        </ul>
        <p>ggf. sind zusätzliche Spalten notwendig:</p>
        <ul className="px-10 py-4 list-disc">
          <li>Ablaufdatum</li>
          <li>Zahlungsziel</li>
          <li>Preis</li>
        </ul>
        <p>
          Ablaufdatum und Zahlungsziel sollten Sie in der Importtabelle
          hinterlegen, wenn diese Werte nicht bei allen Artikeln gleich sind.
          Andernfalls, steht Ihnen eine Option während des Importdialogs zur
          Verfügung, um diese Werte für alle Importartikel zu hinterlegen.
          <br />
          <br />
          Zur Hinterlegung des Ablaufdatums nutzen Sie bitte eines der folgenden
          Formate:
        </p>
        <ul className="px-10 py-4 list-disc">
          <li>DD.MM.YYYY</li>
          <li>DD/MM/YYYY</li>
          <li>MM.DD.YYYY</li>
          <li>MM/DD/YYYY</li>
          <li>MM.YYYY</li>
          <li>MM/YYYY</li>
        </ul>
        <p>
          Als Zahlungsziele (Standardeinstellung) können derzeit berücksichtigt
          werden:
        </p>
        <ul className="px-10 py-4 list-disc">
          <li>Vorkasse,</li>
          <li>Sofort nach Wareneingang,</li>
          <li>7 Tage nach Wareneingang,</li>
          <li>14 Tage nach Wareneingang</li>
        </ul>
        <p>
          Preis Sie können für jeden Artikel in der Importtabelle einen Preis
          definieren. Alternativ steht Ihnen im Importdialog die Möglichkeit zur
          Verfügung, automatisch den aktuellen AEP für jeden Importartikel zu
          verwenden.
        </p>
      </div>
    ),
    accordionId: 'import-daten',
  },
  {
    title: (
      <h1 className="font-bold">
        WO FINDE ICH UNTERSTÜTZUNG BEIM IMPORT MEINER DATEN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Selbstverständlich stehen wir Ihnen bei allen Fragen zum Import Ihrer
          Daten gerne telefonisch, per E-Mail und über Chat zur Verfügung. Eine
          Hilfefunktion steht Ihnen auch während des Imports zur Verfügung.
          <br />
          <br />
          Wenn Sie unseren Importservice nutzen wollen, informieren Sie uns
          kurz, dass wir Ihre Daten prüfen sollen. Wir erstellen Ihnen nach
          Aufwandsabschätzung gerne ein individuelles Angebot, sodass Sie sich
          nicht mehr um den Import kümmern müssen.
        </h1>
      </div>
    ),
    accordionId: 'unterstützung-beim-import',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE LEGE ICH EIN VERKAUFSANGEBOT FÜR EINEN EINZELARTIKEL AN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Im Bereich „Verkaufen“ finden Sie den Unterpunkt „Verkaufsangebot
          anlegen“.
          <br />
          <br />
          Wählen Sie im folgenden die Pharmazentralnummer (PZN) oder die
          Bezeichnung des Artikels aus, den sie verkaufen möchten. In der
          Auswahlliste finden Sie nun den von ihnen gesuchten Artikel.
          <br />
          <br />
          Die übrigen Felder werden aus der tramedix-Datenbank bereits für Sie
          ausgefüllt. Sollte der Artikel noch nicht in unserer Datenbank sein,
          weil es sich um einen Artikel handelt, für den keine PZN vergeben
          wurde, haben Sie hier die Möglichkeit den Artikel neu anzulegen.
          <br />
          <br />
          Im folgenden Dialog können Sie die relevanten Verkaufsdaten eingeben,
          die übrigen Felder berechnen sich automatisch:
        </h1>
        <ul className="px-10 py-4 list-disc">
          <li>Verfügbare Menge</li>
          <li>Mindestabnahmemengen und</li>
          <li>Preis. </li>
        </ul>
        <p>
          Sie können sowohl einen Preis pro Einheit definieren oder einen Preis
          für den gesamten Posten. Sie haben aber auch die Möglichkeit,
          prozentuale Rabatte zu definieren.
          <br />
          <br />
          <strong>Weitere Optionen / Checkboxen</strong>
          <br />
          <br />
          <strong>Preisvorschlag möglich?</strong>
          <br />
          Interessenten können Ihnen für diese Artikelposition Preisvorschläge
          -&gt; unterbreiten. Diese können von Ihnen angenommen oder abgelehnt
          werden. Erhaltene Preisvorschläge auf Ihre Angebote können Sie unter
          Verkaufen Preisvorschläge einsehen.
          <br />
          <br />
          <strong>Mindestpreis für einen Preisvorschlag festlegen?</strong>
          <br />
          Wählen Sie diese Checkbox an, brauchen Sie sich um erhaltene
          Preisvorschläge auf Ihre Artikel nicht mehr zu kümmern. Sie können
          angeben, welchen Preis Sie mindestens erzielen möchten und tramedix
          lehnt zu niedrige Preisvorschläge automatisch ab und akzeptiert
          Preisangebote, die innerhalb Ihres Angebotsspielraums liegen.
        </p>
      </div>
    ),
    accordionId: 'wie-verkaufsangebot-anlegen',
  },
  {
    title: (
      <h1 className="font-bold">
        SIND STÜCKPREIS UND MENGE BEI ANGEBOTEN/KAUFGESUCHEN VERHANDELBAR?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Auf tramedix können Angebote zu festen Preisen und Mengen ebenso
          eingestellt werden wie Angebote mit Verhandlungsspielräumen bei Preis
          und Menge. Das bestimmt der Einsteller des Verkaufsangebotes bzw. des
          Kaufgesuchs.
        </h1>
      </div>
    ),
    accordionId: 'preis-verhandelbar',
  },
  {
    title: (
      <h1 className="font-bold">
        KANN ICH MEINE ANGEBOTE/KAUFGESUCHE NACHTRÄGLICH ÄNDERN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Laufende Angebote und Kaufgesuche können von Ihnen geändert werden,
          solange kein Gebot abgegeben wurde.
          <br />
          <br />
          Klicken Sie hierzu in der Artikelübersicht
          &ldquo;Verkaufsangebote&rdquo; bzw. &ldquo;Kaufgesuche&rdquo; auf den
          Button &ldquo;Artikel bearbeiten &rdquo; in der rechten Spalte.
          <br />
          <br />
          Alternativ können Sie einen Massenimport mit geänderten Parametern
          durchführen. Die Angebote, für welche noch keine Gebote abgegeben
          wurden, werden daraufhin aktualisiert.
        </h1>
      </div>
    ),
    accordionId: 'angebote-nachträglich-ändern',
  },
  {
    title: (
      <h1 className="font-bold">KANN ICH ALS BIETER EIN GEBOT ZURÜCKZIEHEN?</h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Ja. Sie können Ihr Gebot/Preisvorschlag löschen, solange Sie vom
          Einsteller des Angebots / Kaufgesuchs noch keinen Zuschlag erhalten
          haben.
          <br />
          <br />
          Um ein Gebot zurückzuziehen, gehen Sie im Untermenü auf “im
          Verkaufsprozess“ / “im Kaufprozess“ und klicken Sie in der rechten
          Spalte auf „Artikel bearbeiten“.
        </h1>
      </div>
    ),
    accordionId: 'angebot-zurückziehen',
  },
  {
    title: (
      <h1 className="font-bold">
        WERDE ICH BEI WICHTIGEN EREIGNISSEN INFORMIERT?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Ja. Sobald einer ihrer angebotenen Artikel verkauft wurde oder sie
          einen Preisvorschlag für einen von ihnen angebotenen Artikel erhalten
          haben, werden sie automatisch per E-Mail informiert.
          <br />
          <br />
          Gleiches gilt, wenn Sie einen Artikel gekauft haben oder auf den von
          ihnen abgegebenen Preisvorschlag reagiert wurde.
          <br />
          <br />
          Sie werden darüber hinaus auch informiert, wenn sie neue Artikel
          erfolgreich eingestellt haben oder die Gültigkeit der von ihnen
          hinterlegten Berechtigungsnachweise geprüft werden müssen.
        </h1>
      </div>
    ),
    accordionId: 'nachricht-bei-ereignissen',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE ERHALTE ICH INFORMATIONEN ZU MEINEN HANDELSPARTNERN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Sind Sie sich mit Ihrem Handelspartner einig und sie haben den
          Zuschlag erteilt bzw. erhalten, werden beiden Parteien automatisch die
          Kontaktdaten immer die Bankverbindung und die Bezugsberechtigungen
          sowie eine Übersicht der georderten Artikelaufstellungen per E-Mail
          zugeschickt. Die Rechnungslegung und der Warenaustausch erfolgen
          anschließend direkt zwischen Ihnen und Ihrem Handelspartner.
        </h1>
      </div>
    ),
    accordionId: 'informationen-zu-handelspartnern',
  },
  {
    title: (
      <h1 className="font-bold">
        ICH HÄTTE INTERESSE AN EINER SCHULUNG. BIETEN SIE SO ETWAS AN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Die einzelnen Geschäftsvorfälle, die auf tramedix durchgeführt werden
          können, erklären wir in unseren Hilfevideos. Eventuell klären sich
          hierdurch schon einige Fragen.
          <br />
          <br />
          Selbstverständlich bieten wir Ihnen auch gerne Einzelschulungen per
          Videokonferenz direkt an Ihrem Arbeitsplatz an.
          <br />
          <br />
          Wenn Sie an einer Schulung für mehrere Teilnehmer interessiert sind,
          erstellen wir Ihnen gerne ein Angebot für eine Präsenzschulung bei
          Ihnen vor Ort in Ihrem Unternehmen oder bei uns in Güstrow.
        </h1>
      </div>
    ),
    accordionId: 'schulung',
  },
  {
    title: <h1 className="font-bold">WO FINDE ICH WEITERE INFORMATIONEN?</h1>,
    content: (
      <div className="px-4">
        <h1>
          Bei weiteren Fragen erreichen Sie unsere Service-Hotline unter &nbsp;
          {TRAMEDIX_PHONE_NUMBER}. Oder schreiben Sie uns eine E-Mail an
          info@tramedix.de. Im Chat (grüner Button unten links) stehen wir Ihnen
          auch gerne schnell und direkt zur Verfügung.
        </h1>
      </div>
    ),
    accordionId: 'weitere-informationen',
  },
  {
    title: (
      <h1 className="font-bold">
        KANN ICH BEKANNTE HANDELSPARTNER PRIORISIEREN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Ja. Im Kundenmenü finden Sie den Menüpunkt Favoritenliste. Tragen Sie
          einfach den Namen des Unternehmens ein, mit dem Sie gerne und gut
          zusammenarbeiten möchten. Bereits ausgewählte Unternehmen werden Ihnen
          in der, unter dem Suchfeld angezeigten Liste, aufgeführt. Angebot und
          Gesuche von favorisierten Unternehmen werden in den Übersichten
          farblich hervorgehoben.
        </h1>
        <br />
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/2hzH94cy5X4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    accordionId: 'partner-priorisieren',
  },
  {
    title: <h1 className="font-bold">KANN ICH HANDELSPARTNER AUSSCHLIEßEN?</h1>,
    content: (
      <div className="px-4">
        <h1>
          Ja. Wählen Sie hierzu bitte in Ihrem Kundenmenü die Blacklist aus.
          Geben Sie im Suchfeld den Namen des Unternehmens ein, mit dem Sie
          künftig keinen Handel betreiben möchten. Unternehmen deren Angebote
          und Gesuche Ihnen künftig nicht mehr dargestellt werden, finden Sie
          nun in Ihrer Blacklist. Unternehmen, die auf Ihrer Blacklist stehen,
          werden über Ihre Wahl nicht informiert. Ihre Angebote und Gesuche
          werden diesen Unternehmen weiterhin angezeigt. Kaufanfragen und
          Preisvorschläge werden vom System jedoch automatisch abgelehnt, mit
          dem Hinweis, dass dieses Angebot derzeit nicht lieferbar ist, bzw. der
          Preisvorschlag nicht akzeptiert wurde. Ihre Anonymität bleibt somit in
          jedem Fall gewahrt.
        </h1>
        <br />
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/2hzH94cy5X4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    accordionId: 'partner-ausschließen',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE KANN ICH DIE LIEFERVERFÜGBARKEIT BEIM GROßHANDEL VORAB PRÜFEN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Über das Kundenkonto erreichen Sie das MSV3-Modul. Wählen Sie hier
          Ihre Großhändler und Vorlieferanten aus, welche über eine
          MSV3-Schnittstelle verfügen. Sie benötigen lediglich Ihre
          Zugangsdaten, die Ihnen der Großhändler zur Verfügung stellt. Ab
          sofort wird Ihnen, über das Ampelsymbol angezeigt, ob der jeweilige
          Artikel bei einem oder mehrerer Ihrer Vorlieferanten verfügbar ist.
          Bei einer roten Ampel ist der Artikel nicht verfügbar, bei einer
          gelben Ampel ist der Artikel bei einem oder mehrerer Ihrer Lieferanten
          verfügbar, jedoch nicht in der gewünschten Menge. Bei einer grünen
          Ampel ist der Artikel über mindestens einen Ihrer Lieferanten in
          ausreichender Menge verfügbar.
        </h1>
        <br />
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/KSPSUxgBx_4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    accordionId: 'msv3-verfügbarkeit',
  },
  {
    title: (
      <h1 className="font-bold">
        WIE KANN ICH BEI MEINEM GROßHANDEL DIREKT BESTELLEN?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Wenn Sie Vorlieferanten über die MSV3-Schnittstelle hinzugefügt haben,
          wird Ihnen über das Ampelsymbol die Verfügbarkeit angezeigt.
          Verfügbare Ware kann mit einem Klick auf das Ampelsymbol direkt bei
          einem oder mehrerer Ihrer Großhändler bzw. Vorlieferanten bestellt
          werden.
        </h1>
        <br />
        <br />
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/KSPSUxgBx_4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    accordionId: 'msv3-bestellen',
  },
  {
    title: (
      <h1 className="font-bold">
        IN WELCHEN BEREICHEN STEHT DIE MSV3-SCHNITTSTELLE ZUR VERFÜGUNG?
      </h1>
    ),
    content: (
      <div className="px-4">
        <h1>
          Standardmäßig stellen wir Ihnen die MSV3-Schnittstelle zur
          Verfügbarkeitsabfrage und mit Bestelloption im Bereich der
          „Verfügbaren Kaufgesuche“ kostenfrei zur Verfügung. Bitte informieren
          Sie uns, wenn Sie die MSV3 Verfügbarkeitsabfrage und Bestelloption
          auch in den Bereichen „Meine Angebote“ und „Meine Kaufgesuche“ nutzen
          möchten. Gerne schalten wir Ihnen diese Funktionalität entsprechend
          unserer Preisliste frei.
          <br />
          <br />
        </h1>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/KSPSUxgBx_4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    ),
    accordionId: 'verfügbarkeit-msv3-schnittstelle',
  },
];

const Faq = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  useLayoutEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if (anchor) {
      const accExists = faqQuestions.find((q) => q.accordionId === anchor);
      if (accExists) {
        setExpanded(anchor);
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView();
        }
      }
    }
  }, []);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="container mx-auto px-10 lg:px-0">
      <div className="py-6">
        <p className="font-semibold text-3xl  text-primary">Häufige Fragen</p>
      </div>
      <div className="pb-10">
        {faqQuestions.map((question) => (
          <Accordion
            key={question.accordionId}
            id={question.accordionId}
            expanded={expanded === question.accordionId}
            onChange={handleChange(question.accordionId)}
          >
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              aria-controls="panel1a-content"
            >
              <div>{question.title}</div>
            </AccordionSummary>
            <AccordionDetails>{question.content}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default GeneralLayout(Faq);
