import React from 'react';
import { useTranslation } from 'react-i18next';

const MSV3CheckoutResultsDisplay: React.FC<MSV3CheckoutResultsDisplayProps> = ({
  data,
  desiredAmounts,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <table
        className="min-w-full divide-y divide-gray-200 table-fixed border"
        key={Math.random()}
      >
        <thead className="bg-grey-light">
          <tr>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
            >
              {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.PROVIDER NAME')}
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
            >
              PZN
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
            >
              {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DELIVERABLE_AMOUNT')}
            </th>
            <th
              scope="col"
              className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
            >
              {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DESIRED_AMOUNT')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, index) =>
            el.deliverableAmount ? (
              <tr
                key={Math.random()}
                className="odd:bg-grey-2 text-gray-800 text-base h-10"
              >
                <td className="text-center whitespace-no-wrap">
                  {el.partnerName}
                </td>
                <td className="text-center whitespace-no-wrap">
                  {desiredAmounts[el.providerId].pzn}
                </td>
                <td className="text-center whitespace-no-wrap">
                  {el.deliverableAmount
                    ? el.deliverableAmount.toLocaleString(i18n.language)
                    : ''}
                </td>

                <td className="text-center whitespace-no-wrap">
                  {desiredAmounts[el.providerId].amount}
                </td>
              </tr>
            ) : (
              ''
            ),
          )}
        </tbody>
      </table>
    </>
  );
};

interface MSV3CheckoutResultsDisplayProps {
  data: {
    partnerName: string;
    deliverableAmount: number;
    providerId: string;
  }[];

  desiredAmounts: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount: number;
      touched: boolean;
    };
  };
}

export default MSV3CheckoutResultsDisplay;
