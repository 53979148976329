import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatTableCellDisplay } from '../../../../shared/utils/dataTable';
import { ArticleOfferI } from '../../../../globals/models/components/dataTable';

const MSV3ArticleInfoTable: React.FC<MSV3ArticleInfoTableProps> = ({
  articleOffer,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-6 overflow-x-scroll hide-scrollbar">
      <table
        key={Math.random()}
        className="min-w-full divide-y divide-gray-200 table-fixed border rounded-sm text-md"
      >
        <tbody>
          <tr className="bg-grey-2">
            <th
              scope="col"
              className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
            >
              {t('TABLE_COLUMN_ARTICLE_NAME')}
            </th>
            <td className="font-bold text-md whitespace-no-wrap px-6">
              {articleOffer?.attributes.product_name}
            </td>
          </tr>
          <tr>
            <th
              scope="col"
              className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
            >
              {t('PZN')}
            </th>
            <td className=" whitespace-no-wrap px-6">
              {articleOffer?.attributes.pzn}
            </td>
          </tr>
          <tr className="bg-grey-2">
            <th
              scope="col"
              className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
            >
              {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ORIGINAL_PRICE')}
            </th>
            <td className=" whitespace-no-wrap px-6">
              {formatTableCellDisplay(articleOffer?.attributes.price, 'price')}{' '}
              €
            </td>
          </tr>

          <tr>
            <th
              scope="col"
              className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
            >
              {t('ARTICLE_MATCHING.AMOUNT_CALCULATED')}
            </th>
            <td className="whitespace-no-wrap px-6 ">
              {formatTableCellDisplay(
                articleOffer?.attributes.piece_amount,
                'piece_amount',
              )}{' '}
            </td>
          </tr>

          <tr className="bg-grey-2">
            <th
              scope="col"
              className="py-3 px-4 text-left font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap bg-grey-light"
            >
              {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.AEK_DISCOUNT')}
            </th>
            <td className="whitespace-no-wrap px-6">
              {formatTableCellDisplay(
                articleOffer?.attributes.aep_discount,
                'aep',
              )}{' '}
              %
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface MSV3ArticleInfoTableProps {
  articleOffer?: ArticleOfferI;
}

export default MSV3ArticleInfoTable;
