import { Reducer } from 'redux';
import { toast } from 'react-toastify';
import { MSV3State } from '../../globals/models/redux';
import initialState from '../initialState';
import {
  AddPZNAvailabilityActionI,
  DeleteMSV3OpenTransactionsActionI,
  DeleteMSV3TransactionsActionI,
  DeletePartnerConnectionActionI,
  GetMSV3OpenTransactionsActionI,
  GetMSV3PartnersActionI,
  GetMSV3TransactionsActionI,
  MSV3Actions,
  RegisterMSV3PartnersActionI,
  SetIsLoadingActionI,
  SetIsRegisteringPartnerActionI,
  SetMSV3CheckoutResultsActionI,
  SetMSV3OpenTransactionsActionI,
  SetMSV3TransactionsActionI,
  SetPZNAvailabilityActionI,
} from '../../globals/models/actions/MSV3';
import { MSV3ActionTypes } from '../actions/types/MSV3';
import { Msv3PartnerI } from '../../globals/models/data/msv3.model';
import i18next from '../../i18n';

export const msv3Reducer: Reducer<MSV3State, MSV3Actions> = (
  state = initialState.msv3State,
  action,
) => {
  switch (action.type) {
    case MSV3ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoadingPartners: (action as SetIsLoadingActionI).payload,
      };

    case MSV3ActionTypes.SET_PARTNERS:
      return {
        ...state,
        partnersList: (action as GetMSV3PartnersActionI).payload,
      };

    case MSV3ActionTypes.REGISTER_NEW_PARTNER:
      toast.success(i18next.t('MSV3.LABELS.REGISTER_PARTNER_SUCCESS'));
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          own: [
            ...state.partnersList.own,
            {
              ...(state.partnersList.foreign.find(
                (el) =>
                  el.soapId ===
                  (action as RegisterMSV3PartnersActionI).payload.soapId,
              ) as Msv3PartnerI),
              description: i18next.t('MSV3.LABELS.CONNECTION_ALREADY_EXISTS'),
              id: (action as RegisterMSV3PartnersActionI).payload.id,
            },
          ],
          foreign: state.partnersList.foreign.filter(
            (el) =>
              el.soapId !==
              (action as RegisterMSV3PartnersActionI).payload.soapId,
          ),
        },
        isLoadingSinglePartnerRequest: false,
      };

    case MSV3ActionTypes.DELETE_PARTNER_CONNECTION:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          foreign: [
            ...state.partnersList.foreign,
            {
              ...(state.partnersList.own.find(
                (el) =>
                  el.id === (action as DeletePartnerConnectionActionI).payload,
              ) as Msv3PartnerI),
              description: i18next.t('MSV3.LABELS.CONNECTION_NOT_EXISTING'),
            },
          ],
          own: state.partnersList.own.filter(
            (el) =>
              el.id !== (action as DeletePartnerConnectionActionI).payload,
          ),
        },
        isLoadingSinglePartnerRequest: false,
      };

    case MSV3ActionTypes.SET_IS_LOADING_SINGLE_PARTNER_REQUEST:
      return {
        ...state,
        isLoadingSinglePartnerRequest: (action as SetIsRegisteringPartnerActionI)
          .payload,
      };

    case MSV3ActionTypes.MSV3_ERROR:
      console.log(action.payload);
      // toast.error((action as MSV3ErrorActionI).payload);

      return {
        ...state,
        isLoadingSinglePartnerRequest: false,
        isLoadingPartners: false,
      };

    case MSV3ActionTypes.SET_PZN_AVAILABILITY:
      return {
        ...state,
        currentPZNAvailability: (action as SetPZNAvailabilityActionI).payload,
      };

    case MSV3ActionTypes.ADD_PZN_AVAILABILITY: {
      const existingData = state.currentPZNAvailability;
      const { payload } = action as AddPZNAvailabilityActionI;
      // data is set the first time
      if (!existingData) {
        return {
          ...state,
          currentPZNAvailability: payload,
        };
      }
      // else merge the data
      // Data is a map - key represents an msv3 partner id, and the value contains the availability data for a list of pzn (array)
      // New data has the same format, therefore we have two objects, with the same key and their values (array) must be merged
      // TODO: we should avoid object mutation, but the whole code base is doing that anyways.. so..
      Object.entries(existingData).forEach(([partnerId, pznList]) => {
        const newData = payload[partnerId];
        if (newData) {
          existingData[partnerId].push(...newData);
        }
      });

      return {
        ...state,
        currentPZNAvailability: existingData,
      };
    }

    case MSV3ActionTypes.SET_MSV3_CHECKOUT_RESULTS:
      return {
        ...state,
        checkoutResult: (action as SetMSV3CheckoutResultsActionI).payload,
      };

    case MSV3ActionTypes.SET_MSV3_OPEN_TRANSACTIONS:
      return {
        ...state,
        msv3OpenTransactions: (action as SetMSV3OpenTransactionsActionI)
          .payload,
      };

    case MSV3ActionTypes.GET_MSV3_OPEN_TRANSACTIONS:
      return {
        ...state,
        msv3OpenTransactions: (action as GetMSV3OpenTransactionsActionI)
          .payload,
      };

    case MSV3ActionTypes.SET_MSV3_TRANSACTIONS:
      return {
        ...state,
        msv3Transactions: (action as SetMSV3TransactionsActionI).payload,
      };

    case MSV3ActionTypes.DELETE_MSV3_OPEN_TRANSACTIONS:
      return {
        ...state,
        msv3OpenTransactions: (action as DeleteMSV3OpenTransactionsActionI)
          .payload,
      };

    case MSV3ActionTypes.DELETE_MSV3_TRANSACTIONS:
      return {
        ...state,
        msv3Transactions: (action as DeleteMSV3TransactionsActionI).payload,
      };

    case MSV3ActionTypes.GET_MSV3_TRANSACTIONS:
      return {
        ...state,
        msv3Transactions: (action as GetMSV3TransactionsActionI).payload,
      };

    default:
      return state;
  }
};
