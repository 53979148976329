/* eslint-disable react/require-default-props,no-nested-ternary,@typescript-eslint/indent,react/no-unused-prop-types */
import React from 'react';
import NumberFormat from 'react-number-format';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  FormTextInputProps,
  InputChangeState,
} from '../../../globals/models/components/textInput';
import { cleanFormattedNumber } from '../../../shared/utils/numbers.utils';

interface NumberInputPropsI extends FormTextInputProps {
  thousandSeparator?: string | boolean;
  decimalSeparator?: string | boolean;
  disabled?: boolean;
  decimalScale?: number;
  allowNegative?: boolean;
  suffix?: string;
  minValue?: number;
  maxValue?: number;
  fixedDecimalScale?: boolean;
}

const NumberInput = ({
  labelKey,
  forText,
  isRequired,
  classes,
  tabIndex,
  placeHolder,
  onChange,
  value,
  changeState = InputChangeState.UNTOUCHED,
  errorMessage,
  onBlur,
  min,
  disabled,
  thousandSeparator,
  decimalSeparator,
  decimalScale,
  allowNegative,
  suffix,
  minValue,
  maxValue,
  fixedDecimalScale,
}: NumberInputPropsI) => {
  const isInvalid = changeState === InputChangeState.INVALID;
  const getInputClasses = () => {
    if (classes?.input) {
      return classes.input;
    }
    return `block ${isInvalid ? 'border-red-400' : 'border-primary'} border-2`;
  };

  const { i18n } = useTranslation();
  return (
    <div className={`mt-4 ${classes?.container}`}>
      <label htmlFor={forText} className={classes?.label}>
        {labelKey}
        {isRequired && <span className="text-red-400">*</span>}
      </label>
      <NumberFormat
        id={forText}
        className={`rounded-sm w-full h-8 mt-2 px-2 ${getInputClasses()}`}
        tabIndex={tabIndex}
        placeholder={placeHolder}
        onChange={(changeEvent) => {
          return onChange
            ? onChange(
                cleanFormattedNumber(changeEvent.target.value, i18n.language),
                changeEvent,
              )
            : null;
        }}
        value={value}
        name={forText}
        onBlur={onBlur}
        min={min}
        disabled={disabled}
        displayType="input"
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        allowNegative={allowNegative}
        thousandSeparator={
          thousandSeparator || (i18next.language === 'de' ? '.' : ',')
        }
        decimalSeparator={
          decimalSeparator || (i18next.language === 'de' ? ',' : '.')
        }
        isNumericString
        suffix={suffix}
        isAllowed={(values) => {
          const { floatValue } = values;

          if (Number(floatValue)) {
            if (minValue && !maxValue) {
              return (floatValue as number) >= minValue;
            }

            if (maxValue && !minValue) {
              return (floatValue as number) <= maxValue;
            }

            if (minValue && maxValue) {
              return (
                (floatValue as number) >= minValue &&
                (floatValue as number) <= maxValue
              );
            }
          }

          return true;
        }}
      />
      {isInvalid && <p className="text-red-400 p-0 text-sm">{errorMessage}</p>}
    </div>
  );
};

export default NumberInput;
