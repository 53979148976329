/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import { TFunction } from 'i18next';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  ArticleOfferI,
  DataTableColumnsConfigI,
  DataTablePageE,
  PaginatedArticles,
} from '../../../globals/models/components/dataTable';
import {
  getDateWithFirstDayOfMonth,
  getDifferenceInMonths,
} from '../articleEditor';
import i18next from '../../../i18n';
import { DataTableFilters } from '../../../globals/models/components/sidebar';
import { ArticleI } from '../../../globals/models/data/article.model';
import { arrayContainsEvery } from '../array.utils';
import {
  DT_SCREEN_RATIO,
  PAGE_SIZE_CHOICES,
  tableNumberColumnsIds,
} from '../../constants/dataTable';
import { roundToFixedWithLocale } from '../numbers.utils';
import { tableColumnsList } from '../../../components/DataGrid/config/columnsList';

/**
 * Generates proper query params for article requests
 * @param {number} pageNumber -
 * @param {number} itemsPerPage -
 * @param {number} pageName -
 * @param filters
 * @param sort
 * @param desc
 * @returns string - the computed query params string
 */
export const createPaginationQueryString = (
  pageNumber = 1,
  itemsPerPage = PAGE_SIZE_CHOICES[1],
  pageName = DataTablePageE.OFFERS,
  filters?: DataTableFilters | string | null,
  sort?: string,
  desc?: boolean,
) => {
  const getPageMeta = () => {
    return `page[limit]=${itemsPerPage}&page[offset]=${
      (pageNumber - 1) * itemsPerPage
    }`;
  };

  const getFilters = () => {
    let filtersSting = '';
    if (filters && typeof filters !== 'string') {
      filters.forEach((el) => {
        filtersSting = `${filtersSting}&filter[${el.key}]=${el.value}`;
      });
    }

    if (filters && typeof filters === 'string') {
      return filters;
    }
    return filtersSting;
  };

  const getSort = () => {
    if (sort && desc) {
      return `&filter[sort_by]=${sort}`;
    }

    if (sort && !desc) {
      return `&filter[sort_by]=-${sort}`;
    }
    return '';
  };

  switch (pageName) {
    case DataTablePageE.OFFERS:
      return `filter[selling_type]=active&${getPageMeta()}${getFilters()}${getSort()}`;
    case DataTablePageE.SEARCH:
      return `filter[selling_type]=active_gesuche&${getPageMeta()}${getFilters()}${getSort()}`;
    case DataTablePageE.DELIVER:
      return `filter[selling_type]=active_delivery&${getPageMeta()}${getFilters()}${getSort()}`;
    case DataTablePageE.BUY:
      return `filter[selling_type]=active_buying&${getPageMeta()}${getFilters()}${getSort()}`;
    default:
      return `filter[selling_type]=active&${getPageMeta()}${getFilters()}${getSort()}`;
  }
};

export const filterColumnsListFromSelectedColumnsConfig = (
  columnsConfig: DataTableColumnsConfigI[],
) => {
  let newColumnsConfig = columnsConfig;
  const newColumns = tableColumnsList.map((config) => {
    const columnListItem = newColumnsConfig.find(
      (col) => col.config_id === config.config_id,
    );
    if (columnListItem) {
      return config;
    }

    newColumnsConfig = [...newColumnsConfig, { ...config, isSelected: true }];
    return config;
  });

  return newColumnsConfig
    .filter((el) => el.isSelected)
    .map((config) =>
      newColumns.find((col) => col.config_id === config.config_id),
    ) as typeof tableColumnsList;
};

const wrapArticlesDataWithNecessaryCalculations = (data: ArticleOfferI[]) =>
  data.map((article) => ({
    ...article,
    attributes: {
      ...article.attributes,

      aep_marge: article.attributes.aep_marge
        ? Number(article.attributes.aep_marge).toFixed(3).slice(0, -1)
        : 0,

      relative_exp_date: article.attributes.expiration_date
        ? getDifferenceInMonths(
            getDateWithFirstDayOfMonth(
              new Date(article.attributes.expiration_date),
            ),
            getDateWithFirstDayOfMonth(new Date()),
          )
        : '',

      proposals: article.attributes.proposals
        ? article.attributes.proposals
        : [],
      own_proposals: article.attributes.own_proposals
        ? article.attributes.own_proposals
        : [],

      MSV3deliverables: article.MSV3deliverables,
    },
  }));

/**
 * Adds suffixes and makes calculations on a few properties of articles
 * @param {PaginatedArticles} articles -
 * @returns {PaginatedArticles} -
 */
export const wrapArticlesWithNecessaryCalculations = (
  articles: PaginatedArticles,
) => {
  return {
    ...articles,
    data: wrapArticlesDataWithNecessaryCalculations(articles.data),
  } as PaginatedArticles;
};

export const getAEKIconAndClass = (value: number) => {
  if (value < 0) {
    return {
      class: 'text-red-600',
      icon: faCaretDown,
    };
  }
  if (value === 0) {
    return {
      class: 'text-black-600',
      icon: faCaretRight,
    };
  }

  if (value > 0) {
    return {
      class: 'text-green-600',
      icon: faCaretUp,
    };
  }

  return {
    class: 'text-black-600',
    icon: faCaretRight,
  };
};

export const getDTRowValues = (
  articles: ArticleI[],
  rowValues: { [key: string]: any },
) => {
  let allValues = {};

  articles.forEach((el: ArticleI) => {
    if (arrayContainsEvery(Object.values(el), Object.values(rowValues))) {
      allValues = el;
    }
  });

  return allValues as ArticleI;
};

export const handleTHeadToolTip = (
  props: any,
  columnSlice: {
    header: string;
    isSorted: boolean;
    isSortedDesc: boolean;
    sortable: boolean;
  },
  t: TFunction,
) => {
  let sortTitle = '';

  if (!columnSlice.isSorted) {
    sortTitle = `${t('TABLE_HEADER_TOOLTIP_NOT_SORTED')} ${t(
      columnSlice.header,
    )}`;
  } else if (columnSlice.isSortedDesc) {
    sortTitle = `${t('TABLE_HEADER_TOOLTIP_DESCENDING_SORT')}`;
  } else {
    sortTitle = `${t('TABLE_HEADER_TOOLTIP_ASCENDING_SORT')}`;
  }

  const newProps = {
    ...props,
    title: sortTitle,
  };

  return {
    ...newProps,
    onClick: columnSlice.sortable ? newProps.onClick : undefined,
    style: {
      ...props.style,
      cursor: columnSlice.sortable ? newProps.style.cursor : 'default',
    },
  };
};

export const sidebarPagesBlackList = ['/cart'];
export const offersAndSearchesLinks = [
  '/application/search/all',
  '/application/search/own',
  '/application/offers/all',
  '/application/offers/own',
];

/*
 * This will be improved in the future for other screen sizes
 * This is done this way because the table is js generated
 * This is the only way of displaying the table based on the screen width
 * */
export const getDtScreenRatio = () => {
  if (window.innerWidth >= 1792) {
    return DT_SCREEN_RATIO * 1.1;
  }

  if (window.innerWidth >= 1280) {
    return DT_SCREEN_RATIO;
  }

  if (window.innerWidth >= 1024) {
    return DT_SCREEN_RATIO * 1.7;
  }

  if (window.innerWidth >= 768) {
    return DT_SCREEN_RATIO * 2.1;
  }

  if (window.innerWidth >= 320) {
    return DT_SCREEN_RATIO * 3;
  }

  return DT_SCREEN_RATIO;
};

export const formatTableCellDisplay = (
  cellValue: string | number | undefined | null,
  columnId?: string,
) => {
  if (cellValue !== 0 && !cellValue) {
    return '';
  }

  if (columnId && tableNumberColumnsIds.includes(columnId)) {
    return roundToFixedWithLocale(Number(cellValue), 2, i18next.language);
  }

  if (columnId && !tableNumberColumnsIds.includes(columnId)) {
    return roundToFixedWithLocale(Number(cellValue), 2, i18next.language);
  }

  return '';
};
