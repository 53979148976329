/* eslint-disable react/require-default-props */
import React from 'react';
import {
  FormTextInputProps,
  InputChangeState,
  TextInputType,
} from '../../../globals/models/components/textInput';

const TextInput = ({
  labelKey,
  forText,
  isRequired,
  classes,
  tabIndex,
  type,
  placeHolder,
  onChange,
  value,
  changeState = InputChangeState.UNTOUCHED,
  errorMessage,
  onBlur,
  min,
  disabled,
  name,
  isLoading,
  subLabel,
  pattern,
}: FormTextInputProps) => {
  const isInvalid = changeState === InputChangeState.INVALID;
  const getInputClasses = () => {
    if (classes?.input) {
      return classes.input;
    }
    return `block ${isInvalid ? 'border-red-400' : 'border-primary'} border-2`;
  };
  return (
    <div className={`mt-4 ${classes?.container}`}>
      <label
        htmlFor={forText}
        className={`text-xs sm:text-sm md:text-base ${classes?.label}`}
      >
        {labelKey}
        {isRequired && <span className="text-red-400">*</span>}
      </label>
      {!isLoading ? (
        <input
          type={type || TextInputType.TEXT}
          id={forText}
          className={`rounded-sm w-full h-8 mt-2 px-2 ${getInputClasses()}`}
          tabIndex={tabIndex}
          placeholder={placeHolder}
          onChange={(changeEvent) =>
            onChange ? onChange(changeEvent.target.value, changeEvent) : null
          }
          value={value}
          name={name || forText}
          onBlur={onBlur}
          min={min}
          disabled={disabled}
          required={isRequired}
          pattern={pattern}
        />
      ) : (
        '...'
      )}
      {subLabel && (
        <label htmlFor={forText} className={`text-xs ${classes?.label}`}>
          {subLabel}
        </label>
      )}
      {isInvalid && <p className="text-red-400 p-0 text-sm">{errorMessage}</p>}
    </div>
  );
};

export default TextInput;
