/* eslint-disable jsx-a11y/tabindex-no-positive,@typescript-eslint/indent */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import {
  RegistrationFormType,
  userSignupFieldsInitialValues,
  SignupFieldsProps,
} from '../../../globals/models/components/registrationForms';

import TextInput from '../../Shared/layouts/TextInput';
import {
  InputChangeState,
  TextInputType,
} from '../../../globals/models/components/textInput';
import useEnterKeyListener from '../../Shared/hooks/useEnterKeyListener';
import {
  setErrorMessage,
  toggleRegistrationForm,
} from '../../../store/actions/creators/registration';
import { userSignupValidationSchema } from '../../../shared/validators/registration.schemas';

const UserSignupFields = ({
  errorMessage,
  handleSubmit: _handleSubmit,
  initialValues = userSignupFieldsInitialValues,
  _toggleRegistrationForm,
  _isEditMode,
  _setErrorMessage,
  isLoading,
}: SignupFieldsProps) => {
  const { t } = useTranslation();
  useEnterKeyListener({ querySelectorToExecuteClick: '#signup-submit-button' });

  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(
    initialValues.hasAcceptedPrivacyPolicy,
  );

  const [isEditMode, setIsEditMode] = useState(_isEditMode);

  return (
    <Formik
      onSubmit={(values) => {
        _handleSubmit(values);
      }}
      validateOnChange
      initialValues={initialValues}
      validateOnMount
      validationSchema={userSignupValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          handleSubmit,
        } = props;

        const isFormTouched = Object.values(touched).length > 0;

        const isFormValid =
          (isValid &&
            Object.values(errors).length === 0 &&
            isFormTouched &&
            isPrivacyPolicyChecked) ||
          isEditMode;

        return (
          <>
            <p className="text-red-400 font-medium text-center">
              {errorMessage}
            </p>
            <TextInput
              forText="companyName"
              isRequired
              labelKey={t('SIGNUP_FORM_COMPANY_NAME')}
              tabIndex={1}
              value={values.companyName}
              onChange={(value, event) => {
                setIsEditMode(false);
                handleChange(event);
              }}
              onBlur={handleBlur}
              changeState={
                touched.companyName && errors.companyName
                  ? InputChangeState.INVALID
                  : InputChangeState.VALID
              }
              errorMessage={errors.companyName}
              classes={{
                label: 'text-xs sm:text-sm md:text-base',
              }}
              disabled={isLoading}
            />
            <div className="lg:grid block lg:grid-cols-2 lg:gap-5">
              <TextInput
                forText="firstName"
                isRequired
                labelKey={t('FIRST_NAME_FIELD_TEXT')}
                tabIndex={2}
                value={values.firstName}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.firstName && errors.firstName
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.firstName}
                classes={{
                  label: 'text-xs sm:text-sm md:text-base',
                  container: 'sm:mt-2',
                }}
                disabled={isLoading}
              />
              <TextInput
                forText="lastName"
                isRequired
                labelKey={t('LAST_NAME_FIELD_TEXT')}
                tabIndex={3}
                value={values.lastName}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.lastName && errors.lastName
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.lastName}
                disabled={isLoading}
                classes={{
                  container: 'sm:mt-2',
                }}
              />
            </div>
            <div className="lg:grid block lg:grid-cols-2 lg:gap-5">
              <TextInput
                forText="email"
                isRequired
                labelKey={t('EMAIL_TEXT')}
                tabIndex={4}
                value={values.email}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.email && errors.email
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.email}
                disabled={isLoading}
                classes={{
                  container: 'sm:mt-2',
                }}
              />
              <TextInput
                forText="phoneNumber"
                isRequired
                labelKey={t('PHONE_FIELD_TEXT')}
                tabIndex={5}
                value={values.phoneNumber}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.phoneNumber && errors.phoneNumber
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.phoneNumber}
                disabled={isLoading}
                classes={{
                  container: 'sm:mt-2',
                }}
              />
            </div>
            <div className="lg:grid block lg:grid-cols-2 lg:gap-5">
              <TextInput
                forText="password"
                isRequired
                labelKey={t('PASSWORD_FIELD_TEXT')}
                tabIndex={6}
                type={TextInputType.PASSWORD}
                value={values.password}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.password && errors.password
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.password}
                disabled={isLoading}
                classes={{
                  container: 'sm:mt-2',
                }}
              />
              <TextInput
                forText="confirmPassword"
                isRequired
                labelKey={t('REGISTRATION.LABELS.CONFIRM_PASSWORD_FIELD')}
                tabIndex={7}
                type={TextInputType.PASSWORD}
                value={values.confirmPassword}
                onChange={(value, event) => {
                  setIsEditMode(false);
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.confirmPassword && errors.confirmPassword
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.confirmPassword}
                disabled={isLoading}
                classes={{
                  container: 'sm:mt-2',
                }}
              />
            </div>
            <div className="pt-2">
              {isFormTouched && !isPrivacyPolicyChecked && (
                <p className="text-red-400 pb-2 text-center font-md text-sm">
                  {t('SIGNUP_FORM_PRIVACY_WARNING_LEFT')}
                  <Link
                    to="/agb"
                    className="px-1 font-bold hover:underline"
                    onClick={() => {
                      _toggleRegistrationForm({ type: null });
                    }}
                  >
                    AGB
                  </Link>
                  {t('SIGNUP_FORM_PRIVACY_WARNING_RIGHT')}
                  <Link
                    to="/datenschutz"
                    className="pl-1 font-bold hover:underline"
                    onClick={() => {
                      _toggleRegistrationForm({ type: null });
                    }}
                  >
                    {t('PRIVACY_TEXT')}
                  </Link>
                </p>
              )}
              <p className="py-1">
                <input
                  type="checkbox"
                  className="mr-2"
                  required
                  checked={values.hasAcceptedPrivacyPolicy}
                  name="hasAcceptedPrivacyPolicy"
                  onChange={(event) => {
                    if (isEditMode) {
                      setIsEditMode(false);
                    }
                    setIsPrivacyPolicyChecked(event.target.checked);
                    handleChange(event);
                    handleBlur(event);
                  }}
                  disabled={isLoading}
                />
                <span>{t('SIGNUP_CONFIRMATION_TEXT_1')}</span>
                <Link
                  to="/agb"
                  className="px-1 text-green-600 font-bold hover:underline"
                  onClick={() => {
                    _toggleRegistrationForm({ type: null });
                  }}
                >
                  AGB
                </Link>
                <span>{t('SIGNUP_CONFIRMATION_TEXT_2')}</span>
                <Link
                  to="/datenschutz"
                  className="pl-1 text-green-600 font-bold hover:underline"
                  onClick={() => {
                    _toggleRegistrationForm({ type: null });
                  }}
                >
                  {t('PRIVACY_TEXT')}
                </Link>
                <span>{t('SIGNUP_CONFIRMATION_TEXT_3')}</span>
              </p>
            </div>
            <div className="grid grid-cols-2 text-center md:text-left">
              <p className="md:mt-0 mt-2">
                <button
                  type="button"
                  className="text-green-600 font-bold hover:underline focus:outline-none"
                  onClick={
                    !isLoading
                      ? () => {
                          _toggleRegistrationForm({
                            type: RegistrationFormType.LOGIN,
                          });
                        }
                      : undefined
                  }
                >
                  {t('SIGNUP_FORM_SWITCH')}
                </button>
              </p>
              <p className="text-center md:text-right md:mt-0">
                <button
                  id="signup-submit-button"
                  type="button"
                  className={`button mb-2 ${
                    isFormValid && !isLoading
                      ? 'cursor-pointer bg-green-600'
                      : 'cursor-not-allowed bg-green-400'
                  }`}
                  onClick={
                    isFormValid && !isLoading
                      ? () => {
                          handleSubmit();
                          if (isEditMode) {
                            _setErrorMessage(null);
                          }
                        }
                      : undefined
                  }
                >
                  {t('SIGNUP_FORM_SUBMIT_BUTTON')}
                </button>
              </p>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const mapStatesToProps = ({
  registrationState: { errorMessage, isLoading },
}: StoreI) => ({
  errorMessage,
  isLoading,
});

export default connect(mapStatesToProps, {
  _toggleRegistrationForm: toggleRegistrationForm,
  _setErrorMessage: setErrorMessage,
})(UserSignupFields);
