import * as Yup from 'yup';
import i18n from '../../i18n';

export const infoMaterialFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_INVALID'))
    .required(i18n.t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED')),
  name: Yup.string()
    .min(3, i18n.t('CONTACT_FORM.VALIDATION.NAME_MIN_VALUE_ERROR'))
    .max(32, i18n.t('CONTACT_FORM.VALIDATION.NAME_MAX_VALUE_ERROR'))
    .required(i18n.t('CONTACT_FORM.VALIDATION.NAME_REQUIRED_ERROR')),
});

export const personalConsultationFormValidationSchema = Yup.object().shape({
  phone: Yup.string().required(
    i18n.t('REGISTRATION_FORM_ERROR_EMAIL_REQUIRED'),
  ),
  name: Yup.string()
    .min(3, i18n.t('CONTACT_FORM.VALIDATION.NAME_MIN_VALUE_ERROR'))
    .max(32, i18n.t('CONTACT_FORM.VALIDATION.NAME_MAX_VALUE_ERROR'))
    .required(i18n.t('CONTACT_FORM.VALIDATION.NAME_REQUIRED_ERROR')),
  timeslot: Yup.string().required(
    i18n.t('CONTACT_FORM.VALIDATION.TIME_REQUIRED_ERROR'),
  ),
});
