/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React from 'react';
import { Column } from 'react-data-grid';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Announcement';
import ArticleMatching from '@material-ui/icons/CompareArrows';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { green } from '@material-ui/core/colors';
import i18next from '../../../i18n';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../globals/models/components/dataTable';
import {
  formatTableCellDisplay,
  getAEKIconAndClass,
} from '../../../shared/utils/dataTable';
import ColumnsConfig from './columns';
import RowActions from '../content/rowActions';
import {
  ArticleMatchingI,
  ArticleOptionsTypes,
  ProposalI,
} from '../../../globals/models/data/article.model';
import { ShippingCostOptions } from '../../../shared/constants/articleEditor';
import TrafficLight from '../../Shared/layouts/TrafficLight';

numeral.locale(i18next.language === 'en' ? 'en-gb' : 'de');

export const getMSV3ToolTipText = (msv3DeliverableLabel: string) => {
  switch (msv3DeliverableLabel) {
    case '>=':
      return i18next.t('MSV3.LABELS.TOOLTIPS.GREATER_NUMBER_OR_EQUAL_DELIVERY');
    case '<':
      return i18next.t('MSV3.LABELS.TOOLTIPS.LESS_NUMBER_DELIVERY');
    default:
      return i18next.t('MSV3.LABELS.TOOLTIPS.NO_DELIVERY');
  }
};

export const getTableColumnsList = (
  data: ArticleOfferI[],
  _setTableModal?: (
    value: DataTableModalOptions | null,
    title: string | null,
    article?: ArticleOfferI,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  ) => {
    type: string;
    payload: {
      page: DataTableModalOptions | null;
      title: string | null;
      article?: ArticleOfferI;
    };
  },
  setCurrentArticle?: (value: {
    name: string;
    aep_discount: number;
    apu_discount: number;
    price: string;
    pzn: string;
    aep: string;
    apu: string;
    matching: ArticleMatchingI[];
  }) => void,
): Merge<Column<ArticleOfferI>, { id: string; config_id: string }>[] => [
  {
    id: 'Matches',
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    key: 'Matches',
    name: i18next.t('Matches'),
    sortable: false,
    formatter({ row }) {
      const {
        attributes: { proposals },
      } = row;
      // TODO: Use row.original.attributes.own_proposals.length when counter_offers are enabled
      const matchesNumber = proposals.filter(
        (el: ProposalI) =>
          ![
            ArticleOptionsTypes.CONFIRMED,
            ArticleOptionsTypes.DECLINED,
          ].includes(el.status),
      ).length;

      let isSold = 0;

      proposals.forEach((element: ProposalI) => {
        if (element.status === ArticleOptionsTypes.CONFIRMED) {
          isSold += element.piece_amount;
        }
      });

      return (
        <Tooltip
          title={
            row.attributes.piece_amount === isSold
              ? (i18next.t(
                  'DATA_TABLE.OPEN_PROPOSALS_MODAL.TIPS.GOTO_BUTTON_COMPLETED',
                ) as string)
              : matchesNumber === 0
              ? (i18next.t(
                  'DATA_TABLE.OPEN_PROPOSALS_MODAL.TIPS.GOTO_BUTTON_DISABLED',
                ) as string)
              : (i18next.t(
                  'DATA_TABLE.OPEN_PROPOSALS_MODAL.TIPS.GOTO_BUTTON',
                ) as string)
          }
          placement="top-end"
          arrow
        >
          <p className={matchesNumber ? 'font-bold' : 'text-grey-1 c-no-drop'}>
            <button
              type="button"
              className={`outline-none general-hover-effect ${
                matchesNumber === 0 ? 'c-no-drop text-grey-1' : ' '
              }`}
              onClick={() => {
                if (matchesNumber !== 0 && _setTableModal) {
                  _setTableModal(
                    DataTableModalOptions.OPEN_PROPOSALS,
                    'DATA_TABLE.OPEN_PROPOSALS_MODAL.TITLE',
                    row,
                    'md',
                  );
                }
              }}
            >
              <Badge badgeContent={matchesNumber} color="primary">
                {row.attributes.piece_amount === isSold ? (
                  <DoneOutlineIcon style={{ color: green[500] }} />
                ) : (
                  <MailIcon />
                )}
              </Badge>
            </button>
          </p>
        </Tooltip>
      );
    },
    config_id: 'Matches',
    frozen: true,
  },
  {
    id: 'STATUS',
    config_id: 'Status',
    name: i18next.t('DATA_TABLE.LABELS.ARTICLE_MATCHES'),
    key: 'status',
    sortable: false,
    formatter({
      row: {
        attributes: {
          matchmaking,
          product_name,
          price,
          pzn,
          aep_discount,
          apu_discount,
          aep,
          apu,
        },
      },
    }) {
      const matchesExist = matchmaking && matchmaking.length !== 0;

      return (
        <Tooltip
          title={
            matchesExist
              ? (i18next.t(
                  'DATA_TABLE.MATCHING.TIPS.OPEN_MATCHES_MODAL',
                ) as string)
              : (i18next.t(
                  'DATA_TABLE.MATCHING.TIPS.MATCHES_MODAL_DISABLED',
                ) as string)
          }
          placement="top-end"
          arrow
        >
          <p className={matchesExist ? 'font-bold' : 'text-grey-1 c-no-drop'}>
            <button
              type="button"
              className={`outline-none general-hover-effect ${
                !matchesExist ? 'c-no-drop text-grey-1' : ' '
              }`}
              onClick={() => {
                if (matchesExist && setCurrentArticle) {
                  setCurrentArticle({
                    price,
                    pzn,
                    aep_discount,
                    apu_discount,
                    aep,
                    apu,
                    name: product_name,
                    matching: matchmaking,
                  });
                }
              }}
            >
              <Badge
                badgeContent={matchesExist ? matchmaking.length : undefined}
                color="primary"
              >
                <ArticleMatching />
              </Badge>
            </button>
          </p>
        </Tooltip>
      );
    },
    resizable: true,
    minWidth: 110,
    width: 120,
    frozen: true,
  },
  {
    id: 'TABLE_COLUMN_TIMELINE',
    config_id: 'Time frame',
    name: i18next.t('TABLE_COLUMN_TIMELINE'),
    key: 'end_period_of_validity',
    minWidth: 120,
    width: 120,
    sortable: false,
    resizable: true,
    formatter({
      row: {
        attributes: { relative_exp_date, end_period_of_validity },
      },
    }) {
      if (relative_exp_date && relative_exp_date < 0) {
        return i18next.t('TABLED_ENDED_LABEL');
      }

      return (
        <div>
          {formatDistanceToNow(new Date(end_period_of_validity), {
            locale: i18next.language === 'de' ? de : en,
          })}
        </div>
      );
    },
  },
  {
    id: 'SHIPPING_COSTS_HANDLER',
    config_id: 'SHIPPING_COSTS_HANDLER',
    name: i18next.t('SHIPPING_COSTS_HANDLER'),
    key: 'shipping_costs_carrier',
    minWidth: 120,
    width: 120,
    sortable: false,
    resizable: true,
    formatter({
      row: {
        attributes: { shipping_costs_carrier },
      },
    }) {
      return (
        <div>
          {i18next.t(
            ShippingCostOptions.find(
              (el) => el.value === shipping_costs_carrier,
            )?.text as string,
          )}
        </div>
      );
    },
  },
  {
    id: 'ARTICLE_LOCATION_COUNTRY',
    config_id: 'ARTICLE_LOCATION_COUNTRY',
    name: i18next.t('ARTICLE_LOCATION_COUNTRY'),
    key: 'article_location_country',
    minWidth: 110,
    width: 110,
    sortable: false,
    resizable: true,
    formatter({
      row: {
        attributes: { article_location_country },
      },
    }) {
      return (
        <div>
          {i18next.t(`COUNTRY_CODE.${String(article_location_country)}`)}
        </div>
      );
    },
  },
  {
    id: 'PZN',
    config_id: 'PZN',
    name: i18next.t('PZN'),
    key: 'pzn',
    sortable: true,
    formatter({
      row: {
        attributes: { pzn },
      },
    }) {
      return <div>{pzn}</div>;
    },
    resizable: true,
    minWidth: 90,
    width: 90,
  },
  {
    id: 'TABLE_COLUMN_ARTICLE_NAME',
    config_id: 'Name',
    name: i18next.t('TABLE_COLUMN_ARTICLE_NAME'),
    key: 'product_name',
    minWidth: 250,
    sortable: true,
    resizable: true,
    formatter({
      row: {
        attributes: { product_name },
      },
    }) {
      return <div>{product_name}</div>;
    },
  },
  {
    id: 'TABLE_COLUMN_EXPIRATION',
    config_id: 'Expiration',
    name: i18next.t('TABLE_COLUMN_EXPIRATION'),
    key: 'expiration_date',
    sortable: true,
    resizable: true,
    minWidth: 90,
    width: 90,
    formatter({
      row: {
        attributes: { expiration_date },
      },
    }) {
      return (
        <div>
          {expiration_date
            ? new Date(expiration_date).toLocaleDateString(
                i18next.language === 'en' ? 'en-US' : 'de-DE',
                {
                  month: '2-digit',
                  year: 'numeric',
                },
              )
            : ''}
        </div>
      );
    },
  },
  {
    id: 'TABLE_COLUMN_REL_EXPIRATION',
    config_id: 'Expiration in Months',
    name: i18next.t('TABLE_COLUMN_REL_EXPIRATION'),
    key: 'relative_exp_date',
    sortable: true,
    resizable: true,
    minWidth: 90,
    width: 90,
    formatter({
      row: {
        attributes: { relative_exp_date },
      },
    }) {
      return <div>{relative_exp_date}</div>;
    },
  },
  {
    id: 'TABLE_COLUMN_BATCH',
    resizable: true,
    config_id: 'Batch',
    name: i18next.t('TABLE_COLUMN_BATCH'),
    key: 'charge_number',
    sortable: true,
    minWidth: 90,
    width: 90,
    formatter({
      row: {
        attributes: { charge_number },
      },
    }) {
      return <div>{charge_number}</div>;
    },
  },
  {
    id: 'TABLE_COLUMN_MIN_QUANTITY',
    config_id: 'Minimum quantity',
    name: i18next.t('TABLE_COLUMN_MIN_QUANTITY'),
    key: 'min_piece_amount',
    resizable: true,
    sortable: true,
    minWidth: 90,
    width: 90,
    formatter({
      row: {
        attributes: { min_piece_amount },
      },
    }) {
      return <div>{numeral(min_piece_amount).format()}</div>;
    },
  },
  {
    id: 'TABLE_COLUMN_QUANTITY',
    config_id: 'Quantity',
    name: i18next.t('TABLE_COLUMN_QUANTITY'),
    key: 'piece_amount',
    resizable: true,
    sortable: true,
    minWidth: 90,
    width: 90,
    formatter({
      row: {
        attributes: { piece_amount },
      },
    }) {
      return <div>{numeral(piece_amount).format()}</div>;
    },
  },
  {
    id: 'TABLE_COLUMN_PRICE_PE',
    config_id: 'Price p.E.',
    name: i18next.t('TABLE_COLUMN_PRICE_PE'),
    key: 'price',
    sortable: true,
    minWidth: 100,
    width: 100,
    resizable: true,
    formatter({
      row: {
        attributes: { price },
      },
      column: { key },
    }) {
      return <div>{formatTableCellDisplay(price, key)} €</div>;
    },
  },
  {
    id: 'AEK / APU',
    config_id: 'AEP / APU',
    name: i18next.t('AEK / APU'),
    key: 'aep',
    sortable: true,
    resizable: true,
    minWidth: 150,
    formatter({
      row: {
        attributes: { aep, apu },
      },
      column: { key },
    }) {
      return (
        <div>
          {formatTableCellDisplay(aep, key)} € /{' '}
          {formatTableCellDisplay(apu, key)} €
        </div>
      );
    },
  },
  {
    id: 'AEK_PERCENTAGE',
    config_id: 'AEK+%',
    name: i18next.t('AEK+%'),
    key: 'calculated_aek_discount',
    sortable: true,
    resizable: true,
    minWidth: 110,
    width: 110,
    formatter({
      row: {
        attributes: { aep_discount },
      },
      column: { key },
    }) {
      return (
        <div className="">
          <span
            className={`pb-1 pr-2 inline ${
              getAEKIconAndClass(aep_discount).class
            }`}
          >
            <FontAwesomeIcon icon={getAEKIconAndClass(aep_discount).icon} />
          </span>
          {formatTableCellDisplay(aep_discount, key)} %
        </div>
      );
    },
  },
  {
    id: 'TABLE_COLUMN_MSV3_TRAFFIC_LIGHT',
    config_id: 'TRAFFIC_LIGHT',
    name: i18next.t('TABLE_COLUMN_MSV3_TRAFFIC_LIGHT'),
    key: 'attributes.calculated_aek_discount',
    sortable: false,
    resizable: true,
    minWidth: 110,
    width: 110,
    formatter({ row, column: { key } }) {
      const {
        attributes: { MSV3deliverables },
      } = row;

      return (
        <Tooltip
          title={getMSV3ToolTipText(MSV3deliverables as string)}
          placement="top"
        >
          <div className="msv3-traffic-light">
            <button
              type="button"
              onClick={() => {
                console.log(row);
                if (_setTableModal && MSV3deliverables !== null) {
                  if (
                    window.location.pathname.includes('cart') ||
                    window.location.pathname.includes('search/own') ||
                    window.location.pathname.includes('search/all') ||
                    window.location.pathname.includes('offers/own')
                  ) {
                    _setTableModal(
                      DataTableModalOptions.MSV3_CHECKOUT,
                      'DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.CHECKOUT_TITLE',
                      row,
                      'md',
                    );
                  } else {
                    _setTableModal(
                      DataTableModalOptions.MSV3_PZN_AVAILABILITY,
                      'DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.TITLE',
                      row,
                      'md',
                    );
                  }
                }
              }}
            >
              <TrafficLight
                RedOn={MSV3deliverables === null}
                YellowOn={MSV3deliverables === '<'}
                GreenOn={MSV3deliverables === '>='}
                Size={20}
                MSV3deliverables={MSV3deliverables}
              />
            </button>
          </div>
        </Tooltip>
      );
    },
  },
  {
    id: 'config',
    config_id: 'config',
    name: <ColumnsConfig />,
    key: 'config',
    sortable: false,
    resizable: false,
    minWidth: 130,
    frozen: false,
    formatter({ row }) {
      return (
        <div className="flex justify-center w-full font-light text-gray-800">
          <RowActions
            isShoppingCartView={window.location.pathname === '/cart'}
            row={row as any}
            _data={data}
          />
        </div>
      );
    },
  },
];

export const shoppingCartColumnIdsBlackList = [
  'Time frame',
  'Minimum quantity',
  'Matches',
  'Status',
  'SHIPPING_COSTS_HANDLER',
  'ARTICLE_LOCATION_COUNTRY',
];
