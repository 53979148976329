/**
 * This is the Home page component.
 */

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import HomeAbout from '../../components/Home/HomeAbout';
import HomeActions from '../../components/Home/HomeActions';
import HomeAdvantages from '../../components/Home/HomeAdvantages';
import HomeContactFrom from '../../components/Home/HomeContactForms';
import RecaptchaProvider from '../../components/Shared/HOCs/RecaptchaProvider';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';
import { openPasswordResetConfirmationModal } from '../../store/actions/creators/registration';

function Home({ _openPasswordResetConfirmationModal }: HomeProps) {
  const history = useHistory();

  const { 'password-reset-token': passwordResetToken } = queryString.parse(
    useLocation().search,
  );

  if (passwordResetToken) {
    _openPasswordResetConfirmationModal(passwordResetToken as string);
    history.replace({
      search: '',
    });
  }

  return (
    <>
      <HomeAbout />
      <HomeActions />
      <HomeAdvantages />
      <HomeContactFrom />
    </>
  );
}

interface HomeProps {
  _openPasswordResetConfirmationModal: (
    token: string,
  ) => { type: string; payload: string };
}

export default connect(null, {
  _openPasswordResetConfirmationModal: openPasswordResetConfirmationModal,
})(GeneralLayout(RecaptchaProvider(Home)));
