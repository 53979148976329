/* eslint-disable @typescript-eslint/indent,react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';

export default function CustomLink({
  children,
  to,
  disabled,
  onClick,
  className,
}: {
  children: any;
  to: string;
  disabled?: boolean;
  onClick?: (event?: any) => void;
  className?: string;
}) {
  return (
    <Link
      to={to}
      onClick={(event) => {
        if (disabled) {
          event.preventDefault();
        } else if (onClick) {
          onClick(event);
        }
      }}
      className={className}
    >
      {children}
    </Link>
  );
}
