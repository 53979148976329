export enum ProfileActionTypes {
  GET_ADDRESSES = 'Profile/GET_ADDRESSES',
  PROFILE_ERROR = 'Profile/PROFILE_ERROR',
  CREATE_ADDRESS = 'Profile/CREATE_ADDRESS',
  EDIT_ADDRESS = 'Profile/EDIT_ADDRESS',
  DELETE_ADDRESS = 'Profile/DELETE_ADDRESS',
  SET_ADDRESS_EDITOR_IS_LOADING = 'Profile/SET_ADDRESS_EDITOR_IS_LOADING',
  GET_USER_DETAILS = 'Profile/GET_USER_DETAILS',
  SET_IS_PROFILE_LOADING = 'Profile/SET_IS_PROFILE_LOADING',
  UPLOAD_LICENSE_DOCUMENT = 'Profile/UPLOAD_LICENSE_DOCUMENT',
  GET_USER_MESSAGES = 'Profile/GET_USER_MESSAGES',
  SET_MESSAGES_LIST_IS_LOADING = 'Profile/SET_MESSAGES_LIST_IS_LOADING',
  SET_IS_MESSAGE_READ = 'Article-Editor/SET_IS_MESSAGE_READ',
  GET_UNREAD_MESSAGES_NUMBER = 'Article-Editor/GET_UNREAD_MESSAGES_NUMBER',
  GET_USER_SETTINGS = 'Profile/GET_USER_SETTINGS',
  SET_USER_SETTINGS = 'Profile/SET_USER_SETTINGS',
  SET_MSV3_DAILY_LIMIT = 'MSV3/SET_MSV3_DAILY_LIMIT',
}
