/* eslint-disable func-names,@typescript-eslint/indent,no-restricted-globals,no-param-reassign,no-nested-ternary */
import * as Yup from 'yup';
import numeral from 'numeral';
import parse from 'date-fns/parse';
import { Dispatch } from 'redux';
import addMonths from 'date-fns/addMonths';
import { ARTICLE_STATUSES, PAYMENT_DEADLINES } from '../../constants/article';
import {
  ArticleEditorCreationData,
  MIValidation,
} from '../../../globals/models/components/articleEditor';
import {
  MASS_IMPORT_POSSIBLE_FIELDS,
  ShippingCostHandlerMap,
  MassImportExpDateFormats,
} from '../../constants/articleEditor';
import i18n from '../../../i18n';
import { ArticleEditorActionTypes } from '../../../store/actions/types/articleEditor';
import {
  calculateDiscount,
  calculatePriceFromDiscount,
  getDateWithFirstDayOfMonth,
  getDifferenceInMonths,
} from './index';
import { round } from '../numbers.utils';
import server from '../../../services/axios.service';
import {
  ArticleSuggestionI,
  ShippingCostsHandlerOptions,
} from '../../../globals/models/data/article.model';
import { UserProfileSettings } from '../../../globals/models/data/userProfileSettings.model';

export enum MIColumnsIndexes {
  PZN = 0,
  QUANTITY = 1,
  MIN_AMOUNT = 2,
  PRICE = 3,
  DISCOUNT_AEP = 4,
  CHARGE = 5,
  ARTICLE_EXPIRATION_DATE = 6,
  MIN_PRICE_ACTIVE = 7,
  MIN_PRICE = 8,
  PERIOD_OF_VALIDITY = 9,
  ARTICLE_STATUS = 10,
  PAYMENT_DEADLINE = 11,
  ARTICLE_COUNTRY_LOCATION = 12,
  SHIPPING_COSTS_HANDLER = 13,
}

export enum MIColumnsIndexesMM {
  PZN = 0,
  QUANTITY = 1,
  MIN_AMOUNT = 2,
  PRICE = 3,
  DISCOUNT_AEP = 4,
  CHARGE = 5,
  ARTICLE_EXPIRATION_DATE = 6,
  MIN_PRICE_ACTIVE = 7,
  MIN_PRICE = 8,
  PERIOD_OF_VALIDITY = 9,
  ARTICLE_STATUS = 10,
  PAYMENT_DEADLINE = 11,
  ARTICLE_COUNTRY_LOCATION = 12,
  SHIPPING_COSTS_HANDLER = 13,
}

export const massImportContentValidators = {
  [MIColumnsIndexes.PZN]: Yup.string().test('maxDigits', '', (number) => {
    const parsedNumber = Number(number);
    const originalStringValue = String(number);
    const parsedNumberStringValue = String(parsedNumber);

    if (!parsedNumber) {
      return false;
    }

    if (String(number).length !== String(parsedNumber).length) {
      return (
        `${originalStringValue.replace(
          parsedNumberStringValue,
          '',
        )}${parsedNumberStringValue}`.length === 8
      );
    }

    return String(Number(number)).length === 8;
  }),

  [MIColumnsIndexes.QUANTITY]: Yup.number().min(1),

  [MIColumnsIndexes.MIN_AMOUNT]: Yup.number().test(
    'min_quantity',
    '',
    function (value) {
      return (
        Number(value) >= 1 &&
        Number(value) <= (this.options.context as any).quantity
      );
    },
  ),

  [MIColumnsIndexes.PRICE]: Yup.number().min(0),

  [MIColumnsIndexes.DISCOUNT_AEP]: Yup.number(),

  [MIColumnsIndexes.CHARGE]: Yup.string().matches(new RegExp('[A-Za-z0-9 ]+')),

  [MIColumnsIndexes.ARTICLE_EXPIRATION_DATE]: Yup.date(),

  [MIColumnsIndexes.MIN_PRICE_ACTIVE]: Yup.boolean(),

  [MIColumnsIndexes.MIN_PRICE]: Yup.number()
    .min(0)
    .test('min_price', '', function (value) {
      return Number(value) <= (this.options.context as any).price;
    }),

  [MIColumnsIndexes.PERIOD_OF_VALIDITY]: Yup.date().test(
    'period_of_validity',
    '',
    function (value) {
      return !(
        Boolean(value) &&
        (new Date(value as Date) < new Date() ||
          new Date(value as Date) > addMonths(new Date(), 3))
      );
    },
  ),

  [MIColumnsIndexes.ARTICLE_STATUS]: Yup.string().test(
    'status_description',
    '',
    function (value) {
      return ARTICLE_STATUSES.map((el) => el.submitValue).includes(
        value as string,
      );
    },
  ),

  [MIColumnsIndexes.PAYMENT_DEADLINE]: Yup.string().test(
    'payment_deadline',
    '',
    function (value) {
      return PAYMENT_DEADLINES.map((el) => el.submitValue).includes(
        value as string,
      );
    },
  ),

  [MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION]: Yup.string().test(
    'payment_deadline',
    '',
    function (value) {
      return ['AUSTRIA', 'GERMANY'].includes(String(value).toUpperCase());
    },
  ),

  [MIColumnsIndexes.SHIPPING_COSTS_HANDLER]: Yup.string().test(
    'payment_deadline',
    '',
    function (value) {
      return ['ICH', 'PARTNER'].includes(String(value).toUpperCase());
    },
  ),
} as {
  [key: number]:
    | Yup.StringSchema
    | Yup.NumberSchema
    | Yup.BooleanSchema
    | Yup.DateSchema;
};

/**
 * Gets the value that will be used as argument to the validator Yup function
 * @param {Object} selectedHeaders - Headers options selected from DBFieldsMatcher component
 * @param {String} cell - The cell value
 * @param {Number} index - the index in the loop
 * @param {String} dbField - The name of the db field
 */
const getArticleExpirationDateOrPeriodOvValidity = (
  selectedHeaders: {
    names: { [key: string]: string };
    formats: { [key: string]: string };
  },
  cell: string,
  index: number,
  dbField: 'verfallsdatum' | 'period_of_validity',
) => {
  if (
    cell &&
    selectedHeaders.formats[dbField] === MassImportExpDateFormats.REL_DATE &&
    Number(cell)
  ) {
    return getDateWithFirstDayOfMonth(
      addMonths(getDateWithFirstDayOfMonth(new Date()), Number(cell)),
    );
  }

  if (cell) {
    return massImportContentValidators[index].validateSync(
      parse(cell, selectedHeaders.formats[dbField], new Date()),
    );
  }

  return '';
};

export const validateMassImportData = (
  selectedHeaders: {
    names: { [p: string]: string };
    formats: { [p: string]: string };
  },
  profileSettings?: UserProfileSettings | null,
) => {
  const validatePeriodOfValidity = (index: number, col: string) => {
    const validatedValue = getArticleExpirationDateOrPeriodOvValidity(
      selectedHeaders,
      col,
      index,
      'period_of_validity',
    );

    const relativeDateInMonths = getDifferenceInMonths(
      getDateWithFirstDayOfMonth(new Date(validatedValue as Date)),
      getDateWithFirstDayOfMonth(new Date()),
    );

    if (relativeDateInMonths < 0 || relativeDateInMonths > 3) {
      return addMonths(new Date(), 3);
    }

    return validatedValue;
  };

  return (row: string[]) => {
    const rowData: MIValidation[] = [];
    return row.map((col, index) => {
      let validationObj;

      try {
        switch (index) {
          case MIColumnsIndexes.QUANTITY:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(
                    numeral(col).value(),
                  )
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.MIN_AMOUNT:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: massImportContentValidators[index].validateSync(
                numeral(col).value(),
                {
                  context: {
                    quantity: numeral(row[MIColumnsIndexes.QUANTITY]).value(),
                  },
                },
              ),
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.PRICE:
            numeral.locale(
              selectedHeaders.formats.price_per_piece_cleaned === 'English'
                ? 'en-gb'
                : 'de',
            );

            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(
                    numeral(col).value(),
                  )
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.DISCOUNT_AEP:
            numeral.locale(
              selectedHeaders.formats.discount_aep_cleaned === 'English'
                ? 'en-gb'
                : 'de',
            );

            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(
                    numeral(col).value(),
                  )
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.ARTICLE_EXPIRATION_DATE:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: getArticleExpirationDateOrPeriodOvValidity(
                selectedHeaders,
                col,
                index,
                'verfallsdatum',
              ),
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.MIN_PRICE_ACTIVE:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(
                    col.toLowerCase() ===
                      selectedHeaders.formats.minPriceActive.toLowerCase(),
                  )
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.MIN_PRICE:
            numeral.locale(
              selectedHeaders.formats.best_offer_price_per_piece_cleaned ===
                'English'
                ? 'en-gb'
                : 'de',
            );

            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(
                    numeral(col).value(),
                    {
                      context: {
                        price: numeral(row[MIColumnsIndexes.PRICE]).value(),
                      },
                    },
                  )
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.PERIOD_OF_VALIDITY:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: validatePeriodOfValidity(index, col),
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;
          case MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION:
            validationObj = {
              validation:
                profileSettings !== null
                  ? 'success'
                  : col
                  ? 'success'
                  : 'unselected',
              validatedValue: profileSettings
                ? profileSettings.article_location_country
                : col
                ? massImportContentValidators[index].validateSync(col)
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue:
                profileSettings !== null
                  ? profileSettings?.article_location_country
                  : col,
            } as MIValidation;
            break;
          case MIColumnsIndexes.PAYMENT_DEADLINE:
            validationObj = {
              validation:
                profileSettings !== null
                  ? 'success'
                  : col
                  ? 'success'
                  : 'unselected',
              validatedValue: profileSettings
                ? profileSettings.payment_deadline
                : col
                ? massImportContentValidators[index].validateSync(col)
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue:
                profileSettings !== null
                  ? profileSettings?.payment_deadline
                  : col,
            } as MIValidation;
            break;
          case MIColumnsIndexes.SHIPPING_COSTS_HANDLER:
            validationObj = {
              validation:
                profileSettings !== null
                  ? 'success'
                  : col
                  ? 'success'
                  : 'unselected',
              validatedValue: profileSettings
                ? profileSettings.shipping_costs_carrier
                : col
                ? massImportContentValidators[index].validateSync(col)
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue:
                profileSettings !== null
                  ? profileSettings?.shipping_costs_carrier
                  : col,
            } as MIValidation;
            break;

          default:
            validationObj = {
              validation: col ? 'success' : 'unselected',
              validatedValue: col
                ? massImportContentValidators[index].validateSync(col)
                : col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;
        }
      } catch (e) {
        switch (index) {
          case MIColumnsIndexes.MIN_AMOUNT:
            validationObj = {
              validation: col
                ? rowData[MIColumnsIndexes.QUANTITY].validation
                : 'unselected',
              validatedValue: 1,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.PRICE:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: Number(row[MIColumnsIndexes.DISCOUNT_AEP])
                ? row[MIColumnsIndexes.DISCOUNT_AEP]
                : undefined,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.DISCOUNT_AEP:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: 0,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.CHARGE:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: '',
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.ARTICLE_EXPIRATION_DATE:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: '',
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.MIN_PRICE_ACTIVE:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: false,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.MIN_PRICE:
            rowData[MIColumnsIndexes.MIN_PRICE_ACTIVE] = {
              validation:
                rowData[MIColumnsIndexes.MIN_PRICE_ACTIVE] &&
                typeof rowData[MIColumnsIndexes.MIN_PRICE_ACTIVE]
                  .validatedValue !== 'boolean'
                  ? 'warning'
                  : 'unselected',
              validatedValue: false,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[7].key,
              originalValue: col,
            } as MIValidation;

            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: '',
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.PERIOD_OF_VALIDITY:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: addMonths(new Date(), 3),
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.ARTICLE_STATUS:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: '',
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.PAYMENT_DEADLINE:
            validationObj = {
              validation: col ? 'warning' : 'unselected',
              validatedValue: '',
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          case MIColumnsIndexes.SHIPPING_COSTS_HANDLER:
            validationObj = {
              validation: 'warning',
              validatedValue: col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;

          default:
            validationObj = {
              validation: col ? 'error' : 'unselected',
              validatedValue: col,
              columnName: MASS_IMPORT_POSSIBLE_FIELDS[index].key,
              originalValue: col,
            } as MIValidation;
            break;
        }
      }

      rowData.push(validationObj);
      return validationObj;
    });
  };
};

export const getExpirationDateString = (
  cell: MIValidation,
  on: keyof MIValidation,
) => {
  if (cell.columnName === 'verfallsdatum') {
    return cell[on]
      ? new Date(cell[on] as string).toLocaleDateString(
          i18n.language === 'en' ? 'en-US' : 'de-DE',
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          },
        )
      : '';
  }

  return cell[on]
    ? new Date(cell[on] as string).toLocaleDateString(
        i18n.language === 'en' ? 'en-US' : 'de-DE',
      )
    : '';
};

export const getCellToolTip = (cell: MIValidation) => {
  if (
    [
      'available_amount',
      'min_amount',
      'price_per_piece_cleaned',
      'discount_aep_cleaned',
      'best_offer_price_per_piece_cleaned',
    ].includes(cell.columnName)
  ) {
    if (cell.validation === 'error') {
      return i18n.t('MASS_IMPORT.ERRORS.ERROR_TOOLTIP', {
        originalValue: cell.originalValue,
        columnName: i18n.t(
          MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
            ?.value as string,
        ),
      });
    }

    return i18n.t('MASS_IMPORT.ERRORS.WARNING_TOOLTIP', {
      originalValue: cell.originalValue,
      type: 'Number',
      validatedValue: cell.validatedValue,
      columnName: i18n.t(
        MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
          ?.value as string,
      ),
    });
  }

  if (['verfallsdatum', 'period_of_validity'].includes(cell.columnName)) {
    if (cell.validation === 'error') {
      return i18n.t('MASS_IMPORT.ERRORS.ERROR_TOOLTIP', {
        originalValue: cell.originalValue,
        columnName: i18n.t(
          MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
            ?.value as string,
        ),
      });
    }

    return i18n.t('MASS_IMPORT.ERRORS.WARNING_TOOLTIP', {
      originalValue: cell.originalValue,
      type: 'Date',
      validatedValue: getExpirationDateString(cell, 'validatedValue'),
      columnName: i18n.t(
        MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
          ?.value as string,
      ),
    });
  }

  if (['shipping_costs_carrier'].includes(cell.columnName)) {
    if (cell.validation === 'error') {
      return i18n.t('MASS_IMPORT.ERRORS.ERROR_TOOLTIP', {
        originalValue: cell.originalValue,
        columnName: i18n.t(
          MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
            ?.value as string,
        ),
      });
    }

    return i18n.t('MASS_IMPORT.ERRORS.WARNING_TOOLTIP', {
      originalValue: cell.originalValue,
      type: 'Text',
      validatedValue: ShippingCostsHandlerOptions.NOT_DEFINED,
      columnName: i18n.t(
        MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
          ?.value as string,
      ),
    });
  }

  return cell.validation === 'error'
    ? i18n.t('MASS_IMPORT.ERRORS.ERROR_TOOLTIP', {
        originalValue: cell.originalValue,
        columnName: i18n.t(
          MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
            ?.value as string,
        ),
      })
    : i18n.t('MASS_IMPORT.ERRORS.DEFAULT_TOOLTIP', {
        originalValue: cell.originalValue,
        validatedValue: getExpirationDateString(cell, 'validatedValue'),
        columnName: i18n.t(
          MASS_IMPORT_POSSIBLE_FIELDS.find((el) => el.key === cell.columnName)
            ?.value as string,
        ),
      });
};

export const createMIArticlePayload = (
  row: MIValidation[],
  product_list: ArticleSuggestionI[],
  tradeType: 'BUYING' | 'SELLING',
  addressIds?: {
    delivery_address_id: string;
    invoice_address_id: string;
  },
) => {
  const getArticlePrice = () => {
    if (row[MIColumnsIndexes.PRICE].validatedValue) {
      return row[MIColumnsIndexes.PRICE].validatedValue;
    }

    if (row[MIColumnsIndexes.DISCOUNT_AEP].validatedValue) {
      return round(
        calculatePriceFromDiscount(
          row[MIColumnsIndexes.DISCOUNT_AEP].validatedValue as number,
          Number(product_list[0].aep),
        ),
      );
    }

    return product_list[0].aep;
  };

  const bestOfferPossible = Boolean(
    row[MIColumnsIndexes.MIN_PRICE_ACTIVE].validatedValue,
  );

  const getBestOfferPrice = () => {
    if (row[MIColumnsIndexes.MIN_PRICE].validatedValue) {
      return row[MIColumnsIndexes.MIN_PRICE].validatedValue;
    }

    return getArticlePrice();
  };

  const getMIDate = (date: MIValidation) => {
    if (
      date.validatedValue instanceof Date &&
      !isNaN(date.validatedValue as any)
    ) {
      return date.validatedValue.toISOString();
    }

    if (date.columnName === 'period_of_validity') {
      return addMonths(getDateWithFirstDayOfMonth(new Date()), 3).toISOString();
    }

    return '';
  };

  const charge = row[MIColumnsIndexes.CHARGE].validatedValue;

  const payload = {
    status_store: {
      charge: charge && charge !== 'null' ? charge : '',
      payment_deadline:
        PAYMENT_DEADLINES.find(
          (el) =>
            el.submitValue ===
            row[MIColumnsIndexes.PAYMENT_DEADLINE].validatedValue,
        )?.key || 'NOT_DEFINED',
      period_of_validity: getMIDate(row[MIColumnsIndexes.PERIOD_OF_VALIDITY]),
      status:
        ARTICLE_STATUSES.find(
          (el) =>
            el.submitValue ===
            row[MIColumnsIndexes.ARTICLE_STATUS].validatedValue,
        )?.key || 'NO_STATUS',
      status_description: '_',
      verfallsdatum: getMIDate(row[MIColumnsIndexes.ARTICLE_EXPIRATION_DATE]),
      shipping_costs_carrier: row[MIColumnsIndexes.SHIPPING_COSTS_HANDLER]
        ? (ShippingCostHandlerMap as {
            [key: string]: any;
          })[
            row[MIColumnsIndexes.SHIPPING_COSTS_HANDLER]
              .validatedValue as string
          ]
        : null,
      article_location_country:
        row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION] &&
        row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION].validatedValue
          ? ['error', 'unset'].includes(
              row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION].validation,
            )
            ? 'GERMANY'
            : (row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION]
                .validatedValue as string).toUpperCase()
          : 'GERMANY',
    },
    product_data: {
      article_trade_type: tradeType,
      product_aep: product_list[0].aep,
      product_apu: product_list[0].apu,
      product_avp: product_list[0].avp,
      product_name: product_list[0].name,
      product_package_size: product_list[0].package_size,
      product_pzn: product_list[0].pzn,
      product_pzn_already_chosen: false,
      product_producer: 'producer',
    },
    best_offer_data: {
      discount_aep_cleaned: round(
        calculateDiscount(
          getBestOfferPrice() as number,
          Number(product_list[0].aep),
        ),
        2,
      ),
      discount_apu_cleaned: round(
        calculateDiscount(
          getBestOfferPrice() as number,
          Number(product_list[0].apu),
        ),
        2,
      ),
      minPriceActive: bestOfferPossible,
      price_per_piece_cleaned: getBestOfferPrice(),
      total_price_cleaned: round(
        (getBestOfferPrice() as number) *
          (row[MIColumnsIndexes.QUANTITY].validatedValue as number),
      ),
    },
    article_data: {
      available_amount: row[MIColumnsIndexes.QUANTITY].validatedValue,
      best_offer_possible: bestOfferPossible,
      discount_aep_cleaned:
        row[MIColumnsIndexes.DISCOUNT_AEP].validatedValue ||
        round(
          calculateDiscount(
            getArticlePrice() as number,
            Number(product_list[0].aep),
          ),
          2,
        ),
      discount_apu_cleaned: round(
        calculateDiscount(
          getArticlePrice() as number,
          Number(product_list[0].apu),
        ),
        2,
      ),
      editing: false,
      min_amount: row[MIColumnsIndexes.MIN_AMOUNT].validatedValue,
      price_per_piece_cleaned: getArticlePrice(),
      product_name: product_list[0].name,
      pzn: product_list[0].pzn,
      total_price_cleaned: round(
        (getArticlePrice() as number) *
          (row[MIColumnsIndexes.QUANTITY].validatedValue as number),
      ),
    },
  } as ArticleEditorCreationData;

  if (addressIds) {
    Object.assign(payload, { ...addressIds });
  }

  return payload;
};

export const submitMiData = (
  articleCreationPayload: ArticleEditorCreationData,
  tradeType: 'BUYING' | 'SELLING',
  validatedDataLength: number,
  dispatch: Dispatch,
  resolveStorage: (value?: unknown) => void,
  rejectStorage: (reason?: unknown) => void,
  incrementCounter: () => void,
) => {
  const urlSuffix = tradeType === 'SELLING' ? 'articles' : 'purchase-requests';

  server
    .post(`/api/v1/${urlSuffix}/`, articleCreationPayload)
    .then(() => {
      dispatch({
        type: ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS,
        payload: {
          totalItems: validatedDataLength,
          currentItem: incrementCounter(),
        },
      });

      resolveStorage();
    })
    .catch(() => {
      dispatch({
        type: ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS,
        payload: {
          totalItems: validatedDataLength,
          currentItem: incrementCounter(),
        },
      });

      rejectStorage();
    });
};

export const getIsRowInvalid = (row: MIValidation[]) => {
  let pznAndQuantityValidationArr = [
    row[MIColumnsIndexes.PZN].validation,
    row[MIColumnsIndexes.QUANTITY].validation,
  ];

  if (
    row[MIColumnsIndexes.SHIPPING_COSTS_HANDLER] &&
    row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION]
  ) {
    pznAndQuantityValidationArr = [
      ...pznAndQuantityValidationArr,
      row[MIColumnsIndexes.SHIPPING_COSTS_HANDLER].validation,
      row[MIColumnsIndexes.ARTICLE_COUNTRY_LOCATION].validation,
    ];
  }

  return (
    pznAndQuantityValidationArr.includes('error') ||
    [
      ...pznAndQuantityValidationArr,
      row[MIColumnsIndexes.PRICE].validation,
    ].includes('error') ||
    [
      ...pznAndQuantityValidationArr,
      row[MIColumnsIndexes.DISCOUNT_AEP].validation,
    ].includes('error')
  );
};
