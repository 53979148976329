export enum DataTableActionTypes {
  GET_COLUMNS_CONFIG = 'DataTable/GET_COLUMNS_CONFIG',
  SET_COLUMNS_CONFIG = 'DataTable/SET_COLUMNS_CONFIG',
  SET_ARTICLES = 'DataTable/SET_ARTICLES',
  TABLE_ERROR = 'DataTable/TABLE_ERROR',
  SET_IS_CONTENT_LOADING = 'DataTable/SET_IS_CONTENT_LOADING',
  SET_IS_LAYOUT_LOADING = 'DataTable/SET_IS_LAYOUT_LOADING',
  SET_PAGE_SIZE = 'DataTable/SET_PAGE_SIZE',
  SET_SHOPPING_CART_ARTICLES = 'DataTable/SET_SHOPPING_CART_ARTICLES',
  ADD_ITEM_TO_SHOPPING_CART = 'DataTable/ADD_ITEM_TO_SHOPPING_CART',
  RESET_TABLE = 'DataTable/RESET_TABLE',
  SET_IS_ADDING_TO_SHOPPING_CART = 'DataTable/SET_IS_ADDING_TO_SHOPPING_CART',
  GET_TRANSACTIONS_NUMBER = 'DataTable/GET_TRANSACTIONS_NUMBER',
  SET_CURRENT_MODAL = 'DataTable/SET_CURRENT_MODAL',
  SET_IS_CURRENT_MODAL_LOADING = 'DataTable/SET_IS_CURRENT_MODAL_LOADING',
  START_SUBSET_OR_BID = 'DataTable/START_SUBSET_OR_BID',
  SUBMIT_BID_DECLINED = 'DataTable/SUBMIT_BID_DECLINED',
  REMOVE_ITEM_FROM_SHOPPING_CART = 'DataTable/REMOVE_ITEM_FROM_SHOPPING_CART',
  SUBMIT_SUBSET_OR_BID = 'DataTable/SUBMIT_SUBSET_OR_BID',
  TOGGLE_TABLE_MODAL = 'DataTable/TOGGLE_TABLE_MODAL',
  SET_SELECTED_ROWS = 'DataTable/SET_SELECTED_ROWS',
  SUBMIT_SHOPPING_CART = 'DataTable/SUBMIT_SHOPPING_CART',
  DELETE_ARTICLE = 'DataTable/DELETE_ARTICLE',
  ACCEPT_OR_DECLINE_MATCHING = 'DataTable/ACCEPT_OR_DECLINE_MATCHING',
  ADD_MSV3_DELIVERABLES = 'DataTable/ADD_MSV3_DELIVERABLES',
  GET_RELATIONSHIPS = 'DataTable/GET_RELATIONSHIPS',
  SET_RELATIONSHIPS = 'DataTable/SET_RELATIONSHIPS',
  DELETE_RELATIONSHIPS = 'DataTable/DELETE_RELATIONSHIPS',
  SET_SORT_REF = 'DataTable/SET_SORT_REF',
  GET_SORT_REF = 'DataTable/GET_SORT_REF',
  ADD_MSV3_AVAILABILITIES = 'DataTable/ADD_MSV3_AVAILABILITIES',
}
