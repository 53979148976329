/* eslint-disable react/require-default-props */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

interface CheckBoxInputProps {
  labelText: string;
  isRequired?: boolean;
  className?: string;
  onChange?: (
    value: boolean,
    event?: Event | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  value?: boolean;
  name?: string;
  disabled?: boolean;
}

export default function CheckboxInput({
  labelText,
  isRequired,
  className,
  onChange,
  value,
  name,
  disabled,
}: CheckBoxInputProps) {
  return (
    <p className={className}>
      <Checkbox
        className={`mr-2 ${disabled && 'mu-checkbox-not-allowed'}`}
        required={isRequired}
        onChange={(changeEvent) =>
          onChange ? onChange(changeEvent.target.checked, changeEvent) : null
        }
        checked={value}
        name={name}
        disabled={disabled}
        color="primary"
        style={{
          margin: '-10px 0 -10px -10px',
        }}
        size="small"
      />
      <span>
        {labelText}
        {isRequired && <span className="text-red-600">*</span>}
      </span>
    </p>
  );
}
