/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention,default-case,no-case-declarations,consistent-return */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { SetFilterStringActionI } from '../../../globals/models/actions/sidebar';
import server from '../../../services/axios.service';
import { AddressI } from '../../../globals/models/data/address.model';
import { ProfileActionTypes } from '../types/profile';
import { ProfileState } from '../../../globals/models/redux';
import {
  AddressType,
  CreatedEditedDeletedAddress,
} from '../../../globals/models/components/addresses';
import { SetIsLoadingActionI } from '../../../globals/models/actions/articleEditor';
import {
  GetUnreadMessagesNumberActionI,
  GetUserMessagesActionI,
  GetUserProfileActionI,
  GetUserSettingsActionI,
  SetIsMessageReadActionI,
  SetMSV3DailyLimitActionI,
  SetUserSettingsActionI,
} from '../../../globals/models/actions/profile';
import {
  LicenseFile,
  UserProfile,
} from '../../../globals/models/data/userProfile.model';
import { Message } from '../../../globals/models/data/message.model';
import { PaginatedItems } from '../../../globals/models/data';
import store from '../../index';
import { UserProfileSettings } from '../../../globals/models/data/userProfileSettings.model';

export const getUsersAddresses: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload:
      | string
      | {
          billing: ProfileState['addresses']['billing'];
          delivery: ProfileState['addresses']['delivery'];
        };
  }>,
  { type: string; payload: string | ProfileState['addresses'] },
  null,
  SetFilterStringActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const {
      data: { data },
    } = await server.get('/api/v1/addresses/');

    return dispatch({
      type: ProfileActionTypes.GET_ADDRESSES,
      payload: {
        billing: data
          .filter(
            (el: { attributes: AddressI }) => el.attributes.type === 'invoice',
          )
          .map((el: { attributes: AddressI; id: string }) => ({
            ...el.attributes,
            id: el.id,
          })) as AddressI[],
        delivery: data
          .filter(
            (el: { attributes: AddressI }) => el.attributes.type === 'delivery',
          )
          .map((el: { attributes: AddressI; id: string }) => ({
            ...el.attributes,
            id: el.id,
          })) as AddressI[],
        isLoading: false,
      },
    });
  } catch (e) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'PROFILER_GET_ADDRESSES_ERROR',
    });
  }
};

export const createEditOrDeleteAddress: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: string | CreatedEditedDeletedAddress }>,
  { type: string; payload: string | AddressI },
  any,
  SetFilterStringActionI
>> = (
  type: AddressType,
  address: AddressI,
  mode: 'create' | 'edit' | 'delete',
) => async (dispatch: Dispatch) => {
  try {
    switch (mode) {
      case 'create':
        // eslint-disable-next-line no-param-reassign
        delete address.id;
        const {
          data: { data },
        } = await server.post('/api/v1/addresses/', {
          ...address,
          type: type === AddressType.BILLING ? 'invoice' : 'delivery',
          active: true,
          additional_infos: null,
        });

        return dispatch({
          type: ProfileActionTypes.CREATE_ADDRESS,
          payload: {
            address: data as AddressI,
            type,
          },
        });
      case 'edit':
        const { data: editedAddress } = await server.put(
          `/api/v1/addresses/${address.id}/`,
          {
            ...address,
            active: true,
            additional_infos: null,
            type: type === AddressType.BILLING ? 'invoice' : 'delivery',
          },
        );

        delete editedAddress.company;

        return dispatch({
          type: ProfileActionTypes.EDIT_ADDRESS,
          payload: {
            address: editedAddress as AddressI,
            type,
          },
        });
      case 'delete':
        await server.delete(`/api/v1/addresses/${address.id}/`);
        return dispatch({
          type: ProfileActionTypes.DELETE_ADDRESS,
          payload: {
            address,
            type,
          },
        });
    }
  } catch (e) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'PROFILE_CREATE_UPDATE_DELETE_ADDRESS_ERROR',
    });
  }
};

export const setAddressEditorIsLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsLoadingActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: ProfileActionTypes.SET_ADDRESS_EDITOR_IS_LOADING,
    payload: value,
  });
};

export const getUserDetails: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: UserProfile }>,
  { type: string; payload: UserProfile },
  null,
  GetUserProfileActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await server.get('/api/v1/profile/account/detail/');
    const licenses: any = {};

    Object.keys(data.licenses).forEach((el: string) => {
      licenses[el] = data.licenses[el]
        ? data.licenses[el].split('?')[0].split('/').pop()
        : null;
    });

    return dispatch({
      type: ProfileActionTypes.GET_USER_DETAILS,
      payload: { ...data, licenses, licencesLinks: data.licenses },
    });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const submitLicence: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: UserProfile }>,
  { type: string; payload: UserProfile },
  null,
  any
>> = (docData: LicenseFile) => async (dispatch: Dispatch) => {
  const data = new FormData();

  data.append('doc_type', docData.type);
  data.append('doc', docData.file as Blob);

  try {
    const { data: backendData } = await server.post(
      '/api/v1/profile/account/detail/',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    const licenses: any = {};

    Object.keys(backendData.licenses).forEach((el: string) => {
      licenses[el] = backendData.licenses[el]
        ? backendData.licenses[el].split('?')[0].split('/').pop()
        : null;
    });

    return dispatch({
      type: ProfileActionTypes.GET_USER_DETAILS,
      payload: {
        ...backendData,
        licenses,
        licencesLinks: backendData.licenses,
      },
    });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const getUserMessages: ActionCreator<ThunkAction<
  Promise<{
    type: string;
    payload: PaginatedItems<{
      type: string;
      id: string;
      attributes: Message;
    }>;
  }>,
  {
    type: string;
    payload: PaginatedItems<{
      type: string;
      id: string;
      attributes: Message;
    }>;
  },
  null,
  GetUserMessagesActionI
>> = (offset?: number) => async (dispatch: Dispatch) => {
  try {
    const { data } = await server.get(
      `/api/v1/messages${offset ? `?page[offset]=${offset}` : ''}`,
    );

    const messages = data;

    messages.data = await Promise.all(
      data.data.map(async (el: any) => {
        const content = (await server.get(`/api/v1/messages/${el.id}`)).data;
        if (content.title.includes('MSV3-Kauf')) {
          return {
            content: {
              body: {
                msv3: content.body,
                subheadline: 'Msv3-Kauf',
              },
              id: content.id,
              link: content.link,
              title: content.title,
            },
            created_at: el.attributes.created_at,
            id: content.id,
            unread: content.unread,
          } as Message;
        }
        return {
          content: {
            body: {
              addressData: content.body.adressData,
              invoiceData: content.body.sellerContactData,
              proposal: {
                ...content.body.proposal,
                // TODO: Change when the endpoint has been refactored
                piece_amount: content.body.proposal.new_piece_amount,
                price_per_piece: content.body.proposal.new_price_per_piece,
              },
              subheadline: content.body.subheadline,
            },
            id: content.id,
            link: content.link,
            title: content.title,
          },
          created_at: el.attributes.created_at,
          id: content.id,
          unread: content.unread,
        } as Message;
      }),
    );
    return dispatch({
      type: ProfileActionTypes.GET_USER_MESSAGES,
      payload: messages,
    });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setMessagesListIsLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsLoadingActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: ProfileActionTypes.SET_MESSAGES_LIST_IS_LOADING,
    payload: value,
  });
};

export const setIsMessageRead: ActionCreator<ThunkAction<
  Promise<void>,
  void,
  null,
  SetIsMessageReadActionI
>> = (
  messageId: number,
  messagesRecords: {
    type: string;
    id: string;
    attributes: Message;
  }[],
) => async (dispatch: Dispatch) => {
  try {
    await server.put(`/api/v1/messages/${messageId}`, { unread: false });

    if (store.getState().profileState.messagesNumber !== 0) {
      dispatch({
        type: ProfileActionTypes.GET_UNREAD_MESSAGES_NUMBER,
        payload: store.getState().profileState.messagesNumber - 1,
      });
    }

    dispatch({
      type: ProfileActionTypes.SET_IS_MESSAGE_READ,
      payload: { messageId, messagesRecords },
    });
  } catch (e) {
    toast.error(i18next.t('GENERAL_API_ERROR'));
  }
};

export const getUnreadMessagesNumber: ActionCreator<ThunkAction<
  Promise<void>,
  void,
  null,
  GetUnreadMessagesNumberActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const {
      data: {
        data: { count: number },
      },
    } = await server.get('/api/v1/messages/unread-count/');

    dispatch({
      type: ProfileActionTypes.GET_UNREAD_MESSAGES_NUMBER,
      payload: number,
    });
  } catch (e) {
    toast.error(i18next.t('PROFILE.MESSAGES.API_ERROR'));
  }
};

export const getUserSettings: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: UserProfileSettings }>,
  { type: string; payload: UserProfileSettings },
  null,
  GetUserSettingsActionI
>> = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await server.get('/api/v1/profile/settings/');

    return dispatch({
      type: ProfileActionTypes.GET_USER_SETTINGS,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const saveProfileSettings: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: UserProfileSettings }>,
  { type: string; payload: UserProfileSettings },
  null,
  any
>> = (profileSettings: UserProfileSettings) => async (dispatch: Dispatch) => {
  try {
    const { data: backendData } = await server.post(
      '/api/v1/profile/settings/',
      profileSettings,
    );
    const licenses: any = {};

    return dispatch({
      type: ProfileActionTypes.GET_USER_DETAILS,
      payload: {
        ...backendData,
        licenses,
        licencesLinks: backendData.licenses,
      },
    });
  } catch (error) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setProfileSettings: ActionCreator<ThunkAction<
  { type: string; payload: UserProfileSettings },
  { type: string; payload: UserProfileSettings },
  null,
  SetUserSettingsActionI
>> = (profileSettings: UserProfileSettings) => (dispatch: Dispatch) => {
  return dispatch({
    type: ProfileActionTypes.SET_USER_SETTINGS,
    payload: profileSettings,
  });
};

export const setMsv3DailyLimit: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: string }>,
  { type: string; payload: string },
  any,
  SetMSV3DailyLimitActionI
>> = (daily_limit: string) => async (dispatch: Dispatch) => {
  try {
    await server.post('/api/v1/daily_limit/', {
      daily_limit,
    });
    return dispatch({
      type: ProfileActionTypes.SET_MSV3_DAILY_LIMIT,
      payload: daily_limit,
    });
  } catch (e) {
    return dispatch({
      type: ProfileActionTypes.PROFILE_ERROR,
      payload: 'MSV3_API_ERROR',
    });
  }
};
