import { toast } from 'react-toastify';
import { User } from '../../globals/models/data/user.model';
import i18n from '../../i18n';

export default function showLicenseInvalidOrExpiredToast(
  currentUser: User | null,
) {
  if (currentUser?.isLicenseDueOrInvalid) {
    toast.error(
      i18n.t('PROFILE.LICENSES.NO_LICENSE_OR_LICENSE_EXPIRED_NAVIGATION_TOAST'),
    );
  }
}
