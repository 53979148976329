import { Reducer } from 'redux';
import { ModalsState } from '../../globals/models/redux';
import initialState from '../initialState';
import {
  ModalsActions,
  SetCurrentTypeActionI,
  SetSelectedShoppingCartRowsActionI,
} from '../../globals/models/actions/modals';
import { ModalsActionTypes } from '../actions/types/modals';

export const modalsReducer: Reducer<ModalsState, ModalsActions> = (
  state = initialState.modalsState,
  action,
) => {
  switch (action.type) {
    case ModalsActionTypes.SET_CURRENT_TYPE:
      return {
        ...state,
        type: (action as SetCurrentTypeActionI).payload,
      };

    case ModalsActionTypes.SET_SELECTED_SHOPPING_CART_ROWS:
      return {
        ...state,
        selectedRows: (action as SetSelectedShoppingCartRowsActionI).payload,
      };

    default:
      return state;
  }
};
