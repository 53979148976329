/* eslint-disable @typescript-eslint/indent,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faSearch,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import DataGrid, { Column, SelectColumn } from 'react-data-grid';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import {
  getArticleSuggestionsFromPzn,
  setArticleSuggestionsValue,
  uploadMassImportFile,
} from '../../store/actions/creators/articleEditor';
import { ArticleSuggestionsSkeleton } from '../../shared/utils/articleEditor';
import { TextInputType } from '../../globals/models/components/textInput';
import TextInput from '../../components/Shared/layouts/TextInput';
import NumberInput from '../../components/Shared/layouts/NumberInput';
import { ProductFinderPropsI } from '../../globals/models/components/articleEditor';
import TrafficLight from '../../components/Shared/layouts/TrafficLight';
import {
  buyMsv3Items,
  deleteMsv3OpenTransaction,
  deleteMsv3Transaction,
  getMsv3OpenTransactions,
  getMSV3Partners,
  getMSV3PznProductFinder,
  getMSV3PznSuggestions,
  getMsv3Transactions,
  setMsv3Transactions,
} from '../../store/actions/creators/MSV3';
import { getMSV3ToolTipText } from '../../components/DataGrid/config';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';
import { setTableModal } from '../../store/actions/creators/dataTable';
import { ShippingCostsHandlerOptions } from '../../globals/models/data/article.model';
import store from '../../store';
import getCSSRemValue from '../../shared/helpers/getCSSRemValue';
import {
  MSV3OpenTransaction,
  MSV3Transaction,
} from '../../globals/models/data/msv3.model';
import {
  getUnreadMessagesNumber,
  getUserDetails,
  setMsv3DailyLimit,
} from '../../store/actions/creators/profile';

const ProductFinder = ({
  articleSuggestions,
  _getArticleSuggestionsFromPzn,
  _setArticleSuggestionsValue,
  isLoadingSuggestions,
  _setTableModal,
  _getMSV3Partners,
  _uploadMassImportFile,
  massImportData,
  _buyMsv3Items,
  _getMsv3OpenTransactions,
  _getMsv3Transactions,
  _getUserDetails,
  currentUserProfile,
  _deleteMsv3OpenTransaction,
  _deleteMsv3Transaction,
  _getMSV3PznSuggestion,
  currentPZNAvailability,
  _setMsv3Transactions,
  _getUnreadMessagesNumber,
  ownMsv3PartnersList,
  _setMsv3DailyLimit,
}: ProductFinderPropsI) => {
  const [pznSearchValue, setPznSearchValue] = useState('');
  const [dailyLimit, setDailyLimit] = useState('');
  const [article, setArticle] = useState({
    type: 'Article',
    attributes: {
      aep: '',
      aep_discount: 0,
      aep_marge: 0,
      apu: '',
      apu_discount: 0,
      avp: 0,
      article_status: '',
      pzn: '',
      min_piece_amount: 0,
      piece_amount: 0,
      best_offer_possible: false,
      charge_number: 0,
      expiration_date: '',
      end_period_of_validity: '',
      is_outdated: false,
      lq_url: '',
      matches_exist: false,
      options_type: '',
      payment_deadline: '',
      payment_deadline_label: '',
      proposals_exist: false,
      price: '',
      product_name: '',
      selling_date: '',
      trade_type: '',
      relative_exp_date: 0,
      calculated_aek_discount: 0,
      id: '',
      proposals: [],
      own_proposals: [],
      shipping_costs_carrier: ShippingCostsHandlerOptions.NOT_DEFINED,
      matchmaking: [],
      article_location_country: '',
      favorite: false,
    },
  });
  const [description, setDescription] = useState('');
  const [pzn, setPzn] = useState('');
  const [amount, setAmount] = useState(0);
  const [packageSize, setPackageSize] = useState('');
  const [aek, setAek] = useState('');
  const [apu, setApu] = useState('');
  const [apothekenpflichtig, setApothekenpflichtig] = useState(false);
  const [verschreibungspflichtig, setVerschreibungspflichtig] = useState(false);
  const [kennzeichenArzneimittel, setKennzeichenArzneimittel] = useState(false);
  const [btm, setBtm] = useState(false);
  const [tfg, setTfg] = useState(false);
  const [mp31, setMp31] = useState(false);
  const [kennzeichenMedizinprodukt, setKennzeichenMedizinprodukt] = useState(
    false,
  );
  const [MSV3deliverables, setMSV3deliverables] = useState<null | string>('');
  const [msv3OpenTransactions, setMsv3OpenTransactions] = useState<
    MSV3OpenTransaction[]
  >([]);
  const [msv3Transactions, setMsv3Transaction] = useState<MSV3Transaction[]>(
    [],
  );
  const fileUploadInputRef = React.createRef<HTMLInputElement>();
  const { t } = useTranslation();
  const pznInputRef = useRef<HTMLInputElement | null>(null);
  // const [partners, setPartners] = useState<Array<Msv3PartnerI>>([]);
  const [ids, setPartnerIds] = useState<Array<string>>([]);

  const [
    articleSuggestionsAnchorEl,
    setArticleSuggestionsAnchorEl,
  ] = React.useState<HTMLInputElement | null>(null);
  const open = Boolean(articleSuggestionsAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  const showSuggestions = (target: HTMLInputElement) => {
    setArticleSuggestionsAnchorEl(target);
  };

  const activeBgColor = 'bg-primary';
  const inactiveBgColor = 'bg-primary-disabled c-no-drop';

  const debouncedGetSuggestions = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        _setArticleSuggestionsValue([]);
        _getArticleSuggestionsFromPzn(event.target.value).then(() => {});
        showSuggestions(event.target);
      }
    }, 1500),
    [],
  );
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(
    () => new Set(),
  );
  const [
    selectedMsv3TransactionRows,
    setSelectedMsv3TransactionRows,
  ] = useState<ReadonlySet<any>>(() => new Set());

  const amountsInitialState: {
    [key: string]: {
      amount: string;
      pzn: string;
      deliverableAmount?: number | null;
      touched: boolean;
      id?: number | null;
    };
  } = {};

  const closeSuggestionsPopOver = () => {
    setArticleSuggestionsAnchorEl(null);
  };

  const convertStringToBoolean = (text: string) => {
    return text === '01';
  };
  // const msv3Availabilities: any[] = store.getState().msv3State.msv3Transactions;
  // const msv3Availabilities: any[] = [];
  // store.getState().msv3State.msv3Transactions.forEach((item: ArticleI) => {
  //   if (item.MSV3Availabilities) {
  //     item.MSV3Availabilities.forEach((val: MSV3AvailabilitiesI) => {
  //       if (val) {
  //         if (item.attributes) {
  //           // eslint-disable-next-line no-param-reassign
  //           val.product_name = item.attributes.product_name;
  //         }
  //         const uid = Math.floor(Math.random() * Date.now());
  //         // eslint-disable-next-line no-param-reassign
  //         val.id = uid;
  //         msv3Availabilities.push(val);
  //       }
  //     });
  //   }
  // });
  const [desiredAmounts, setDesiredAmounts] = useState(amountsInitialState);
  const selectPartners = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;

    if (ids.includes(selectedId)) {
      const newIds = ids.filter((partnerid) => partnerid !== selectedId);
      setPartnerIds(newIds);
    } else {
      const newIds = [...ids];
      newIds.push(selectedId);
      setPartnerIds(newIds);
    }
  };

  // todo vorerst auskommentiert
  // if (currentUserProfile?.subscription === 'BASIC') {
  //   massImportData?.content.splice(25);
  //   ownMsv3PartnersList.splice(1);
  // } else if (currentUserProfile?.subscription === 'SILBER') {
  //   massImportData?.content.splice(50);
  //   ownMsv3PartnersList.splice(3);
  // }
  // setPartnerIds(ownMsv3PartnersList.map((partner) => partner.id));

  useEffect(() => {
    setPartnerIds(ownMsv3PartnersList.map((partner) => partner.id));
    setDailyLimit(`${currentUserProfile?.daily_limit}`);
    _getMSV3Partners();
    _getUserDetails().then((res) => {
      _getMsv3OpenTransactions(res.payload.company_id).then((res1) => {
        setMsv3OpenTransactions(res1.payload);
      });
      _getMsv3Transactions(res.payload.company_id).then((res2) => {
        res2.payload.forEach((value: MSV3Transaction) => {
          amountsInitialState[value.provider_id + value.pzn] = {
            amount: value.desired_amount
              ? value.desired_amount.toString()
              : '0',
            pzn: value.pzn,
            deliverableAmount: null,
            touched: true,
            id: value.id,
          };
        });
        setDesiredAmounts(amountsInitialState);
        setMsv3Transaction(res2.payload);
      });
    });

    if (massImportData?.content.length !== 0) {
      let importData: any[] = [];
      const partner = store.getState().msv3State.partnersList.own[0];
      importData = importData.concat(
        massImportData?.content?.map((item) => {
          return {
            id: null,
            product_name: item[2] === 'null' ? '---' : item[2],
            amount_asked: item[1],
            amount_deliverable: null,
            expiration_date: '',
            pzn: String(item[0]).padStart(8, '0'),
            partner_name: partner.name ? partner.name : '',
            partner_id: partner.soapId,
          };
        }),
      );
      if (massImportData?.headers.length === 3) {
        importData.sort((a, b) => a.product_name.localeCompare(b.product_name));
      } else {
        importData.sort((a, b) => a.pzn.localeCompare(b.pzn));
      }

      console.log(importData);
      _setMsv3Transactions(importData).then(() => {
        _getMsv3Transactions(currentUserProfile?.company_id).then((value) => {
          store.getState().articleEditorState.massImportData = {
            content: [],
            headers: [],
            isMM: false,
          };
          setMsv3Transaction(value.payload);
        });
      });
      importData?.forEach((value) => {
        amountsInitialState[value.partner_id + value.pzn] = {
          amount: value.desired_amount,
          pzn: value.pzn,
          deliverableAmount: null,
          touched: true,
        };
      });
      console.log(amountsInitialState);
    }
    // msv3Availabilities.forEach((value) => {
    //   // eslint-disable-next-line no-return-assign
    //   console.log(value);
    //   amountsInitialState[value.partner_id + value.pzn] = {
    //     amount: `${value.amount_asked}`,
    //     pzn: value.pzn,
    //     deliverableAmount: value.amount_deliverable as number,
    //     touched: true,
    //     id: value.id,
    //   };
    // });
    setDesiredAmounts(amountsInitialState);
    console.log(desiredAmounts);
  }, [massImportData, _getMSV3Partners, _getUserDetails]);

  if (msv3OpenTransactions?.length !== 0) {
    msv3OpenTransactions.sort((a, b) =>
      a.product_name.localeCompare(b.product_name),
    );
  } else {
    msv3OpenTransactions.sort((a, b) => a.pzn.localeCompare(b.pzn));
  }

  interface RowMsv3Transaction {
    id?: number | null;
    product_name: string;
    desired_amount?: number | null;
    amount_deliverable?: number | null;
    expiration_date: string;
    pzn: string;
    name: string;
    provider_name: string;
    provider_id: string;
  }

  const columnsMsv3Transaction = (): Column<MSV3Transaction, any>[] => [
    { key: 'product_name', name: 'Artikelbezeichnung', sortable: true },
    {
      key: 'pzn',
      name: 'PZN',
    },
    {
      key: 'amount_deliverable',
      name: 'Lieferbare Menge',
      formatter(value) {
        if (value.row.amount_deliverable === 0) {
          return <div />;
        }
        return <div>{value.row.amount_deliverable}</div>;
      },
    },
    {
      key: 'desired_amount',
      name: 'Gewünschte Menge',
    },
  ];

  const cssRemValue = getCSSRemValue();
  function rowKeyGetterOpenTransactionMsv3(row: RowMsv3Transaction) {
    return (row.id as unknown) as string;
  }
  const columnsMsv3 = [SelectColumn, ...columnsMsv3Transaction()];

  const gridElementMsv3Transactions = (
    <DataGrid
      rowKeyGetter={rowKeyGetterOpenTransactionMsv3}
      columns={columnsMsv3}
      rows={msv3Transactions}
      className="rdg-light"
      rowClass={(row) => 'text-center text-base'}
      style={{
        border: 0,
        minHeight: 2 * cssRemValue, // 5rem
      }}
      selectedRows={selectedMsv3TransactionRows}
      onSelectedRowsChange={setSelectedMsv3TransactionRows}
    />
  );

  interface RowOpenTransaction {
    id: number;
    product_name: string;
    desired_amount: number;
    delivered_amount: number;
    pzn: string;
    provider_name: string;
  }

  function rowKeyGetterOpenTransaction(row: RowOpenTransaction) {
    return (row.id as unknown) as string;
  }

  const columnsOpenTransaction = (): (
    | { name: string; sortable: boolean; key: string }
    | { formatter({ row }: { row: any }): void; name: string; key: string }
    | { name: string; key: string }
  )[] => [
    { key: 'product_name', name: 'Artikelbezeichnung', sortable: true },
    {
      key: 'pzn',
      name: 'PZN',
    },
    {
      key: 'desired_amount',
      name: 'Gewünschte Menge',
    },
    {
      key: 'missing_amount',
      name: 'Aktuelle Menge',
    },
    {
      key: 'updated_at',
      name: 'zuletzt aktualisiert',
      formatter(value) {
        return (
          <div>
            {value.row.updated_at
              ? new Date(value.row.updated_at).toLocaleDateString(
                  i18next.language === 'en' ? 'en-US' : 'de-DE',
                  {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  },
                )
              : ''}
          </div>
        );
      },
    },
  ];
  const columns = [SelectColumn, ...columnsOpenTransaction()];

  const gridElement = (
    <DataGrid
      rowKeyGetter={rowKeyGetterOpenTransaction}
      columns={columns}
      rows={msv3OpenTransactions}
      className="rdg-light"
      rowClass={(row) => 'text-center text-base'}
      style={{
        border: 0,
        minHeight: 2 * cssRemValue, // 5rem
      }}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  );

  return (
    <>
      <main className="general-layout">
        <div className="container mx-auto px-10 lg:px-0">
          <br />
          <div className="w-full text-gray-700 border-l-4 border-gray-700 pl-6 title-description">
            <p className="font-semibold">{t('MSV3.CHECKOUT.INFO')}</p>
          </div>
          <div className="pt-6 pb-10 grid grid-cols-2">
            <div>
              <hr />
              <div className="flex border border-gray-500 rounded-sm w-full h-8">
                <input
                  type="text"
                  id="pzn-search"
                  className="inline flex-grow outline-none px-2"
                  placeholder={t('PZN_SEARCH_INPUT_PLACEHOLDER')}
                  pattern="/^[A-Za-z0-9 /\-_]*$/"
                  onChange={(event) => {
                    if (event.target.value !== '') {
                      if (/^[A-Za-z0-9 /\-_]*$/.test(event.target.value)) {
                        setPznSearchValue(event.target.value);
                        event.persist();
                        debouncedGetSuggestions(event);
                        closeSuggestionsPopOver();
                      } else {
                        debouncedGetSuggestions.cancel();
                      }
                    } else {
                      setPznSearchValue(event.target.value);
                      debouncedGetSuggestions.cancel();
                      closeSuggestionsPopOver();
                      pznInputRef.current?.focus();
                    }
                  }}
                  onClick={() => {
                    if (articleSuggestions.length !== 0) {
                      showSuggestions(pznInputRef.current as HTMLInputElement);
                      setAmount(0);
                      setMSV3deliverables(null);
                    }
                  }}
                  ref={pznInputRef}
                  min={0}
                  value={pznSearchValue}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="inline flex-none mr-3 mt-2"
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: '30px',
                paddingLeft: '300px',
              }}
            >
              {amount !== 0 && (
                <Tooltip
                  title={getMSV3ToolTipText(MSV3deliverables as string)}
                  placement="top"
                >
                  <div>
                    <TrafficLight
                      RedOn={MSV3deliverables === null}
                      YellowOn={MSV3deliverables === '<'}
                      GreenOn={MSV3deliverables === '>='}
                      BlackColor="#fafafa"
                      Size={25}
                      MSV3deliverables={MSV3deliverables}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          {pzn && (
            <div className="md:grid block md:grid-cols-2 gap-10">
              <TextInput
                labelKey={t('Gewünschte Menge')}
                forText="amount"
                type={TextInputType.TEXT}
                classes={{
                  input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                    !pzn && 'c-no-drop input'
                  }`,
                }}
                isRequired={false}
                value={amount.toString()}
                onChange={(val) => {
                  setAmount(Number(val));
                  getMSV3PznProductFinder([
                    {
                      amount: Number(val),
                      pzn,
                      name: description,
                    },
                  ]).then((value) => {
                    const res = value === null ? null : value.toString();
                    setMSV3deliverables(res);
                    const b = {
                      ...article,
                      attributes: {
                        ...article.attributes,
                        piece_amount: Number(val),
                      },
                      MSV3deliverables: res,
                    };
                    setArticle(b);
                  });
                  _getMSV3PznSuggestion([
                    {
                      amount: Number(val),
                      pzn,
                      name: description,
                    },
                  ]).then((value) => {
                    console.log(value.payload);
                  });
                }}
              />
              {pzn && amount !== 0 && (
                <button
                  type="button"
                  className="button button-finder"
                  onClick={() => {
                    const msv3TransactionData = currentPZNAvailability
                      ? Object.keys(currentPZNAvailability).map(
                          (providerId) => {
                            const res3 = currentPZNAvailability[
                              providerId
                            ].find((el) => el.pzn === article.attributes.pzn);
                            return {
                              id: Math.floor(Math.random() * Date.now()),
                              product_name: article.attributes.product_name,
                              amount_asked: amount,
                              amount_deliverable: res3?.amount_deliverable
                                ? res3.amount_deliverable
                                : 0,
                              expiration_date: '',
                              pzn: article.attributes.pzn,
                              partner_name: res3?.partner_name
                                ? res3?.partner_name
                                : '',
                              partner_id: providerId,
                              name: article.attributes.product_name,
                            };
                          },
                        )
                      : [];
                    // todo mit splice auf 1 reduzieren, da Object.keys(currentPZNAvailability)[0] Overload-Problem
                    msv3TransactionData.splice(1);
                    _setMsv3Transactions(msv3TransactionData).then((res2) => {
                      console.log(res2);
                      _getMsv3Transactions(currentUserProfile?.company_id).then(
                        (res) => {
                          console.log(res);
                          setMsv3Transaction(res.payload);
                          msv3TransactionData.forEach((value) => {
                            let currentAvailability = null;
                            if (currentPZNAvailability) {
                              // eslint-disable-next-line prefer-destructuring
                              currentAvailability =
                                currentPZNAvailability[value.partner_id][0];
                            }
                            if (currentAvailability) {
                              // eslint-disable-next-line no-return-assign
                              amountsInitialState[
                                currentAvailability.partner_id + value.pzn
                              ] = {
                                amount: `${value.amount_asked}`,
                                pzn: value.pzn,
                                deliverableAmount: value.amount_deliverable as number,
                                touched: true,
                                id: value.id,
                              };
                            }
                          });
                          setDesiredAmounts(amountsInitialState);
                        },
                      );
                    });
                  }}
                >
                  {t('Weiter zur Bestellung')}
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </button>
              )}
            </div>
          )}
          <div className="md:grid block md:grid-cols-2 gap-10">
            <TextInput
              labelKey={t('PZN')}
              forText="pzn"
              type={TextInputType.TEXT}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !pzn && 'c-no-drop input'
                }`,
              }}
              disabled
              isRequired={false}
              value={pzn}
            />
            <TextInput
              labelKey={t('ARTICLE_DESCRIPTION_TEXT')}
              forText="article-description"
              isRequired={false}
              type={TextInputType.TEXT}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !description && 'c-no-drop input'
                }`,
              }}
              disabled
              value={description}
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <NumberInput
              labelKey={t('PACKAGE_SIZE_TEXT')}
              forText="package-size"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !packageSize && 'c-no-drop input'
                }`,
              }}
              disabled
              value={packageSize}
              type={TextInputType.NUMBER}
              min={0}
            />
            <NumberInput
              labelKey={t('AEK')}
              forText="aek"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !aek && 'c-no-drop input'
                }`,
              }}
              disabled
              value={aek}
              type={TextInputType.NUMBER}
              suffix=" €"
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <NumberInput
              labelKey={t('APU of the article')}
              forText="apu"
              isRequired={false}
              classes={{
                input: `border border-gray-500 rounded-sm w-full h-8 mt-2 ${
                  !apu && 'c-no-drop input'
                }`,
              }}
              disabled
              value={apu}
              type={TextInputType.NUMBER}
              suffix=" €"
              decimalScale={2}
              fixedDecimalScale
            />
          </div>
          <div className="md:grid block md:grid-cols-2 gap-10">
            <List dense>
              {apothekenpflichtig && (
                <ListItem>
                  <ListItemText primary="- Apothekenpflichtig" />
                </ListItem>
              )}
              {verschreibungspflichtig && (
                <ListItem>
                  <ListItemText primary="- Verschreibungspflichtig" />
                </ListItem>
              )}
              {btm && (
                <ListItem>
                  <ListItemText primary="- Btm" />
                </ListItem>
              )}
              {tfg && (
                <ListItem>
                  <ListItemText primary="- Tfg" />
                </ListItem>
              )}
              {kennzeichenArzneimittel && (
                <ListItem>
                  <ListItemText primary="- Kennzeichen Arzneimittel" />
                </ListItem>
              )}
              {kennzeichenMedizinprodukt && (
                <ListItem>
                  <ListItemText primary="- Kennzeichen Medizinprodukt" />
                </ListItem>
              )}
              {mp31 && (
                <ListItem>
                  <ListItemText primary="- Mp31" />
                </ListItem>
              )}
            </List>
          </div>
        </div>
        <br />
        <div className="container mx-auto px-10 lg:px-0">
          <hr />
          <br />
          <span className="text-primary text-md">
            {t('MSV3 Transaktionen')}
          </span>
        </div>
        <br />
        <div className="container mx-auto px-10 lg:px-0">
          {t(
            'Die Großhändler müssen ausgewählt sein, um bei der Ausführung berücksichtigt zu werden.',
          )}
          {ownMsv3PartnersList && (
            <div>
              {ownMsv3PartnersList.map((partner, index) => (
                <div key={partner.id}>
                  {/* eslint-disable-next-line react/jsx-key */}
                  <span>
                    <input
                      type="checkbox"
                      value={partner.id}
                      onChange={selectPartners}
                      checked={ids.includes(partner.id)}
                    />
                  </span>
                  &nbsp;
                  <span>{partner.name} </span>
                </div>
              ))}
            </div>
          )}
          <div className="col-start-3 col-span-2">
            <NumberInput
              labelKey={t('Tageslimit der Transaktionen')}
              forText="tageslimit"
              type={TextInputType.NUMBER}
              classes={{
                input: 'border border-gray-500 rounded-sm w-full h-8 mt-2',
              }}
              isRequired={false}
              value={dailyLimit}
              onChange={(value) => {
                if (value !== '' && value !== '0') {
                  setDailyLimit(value);
                }
              }}
            />
          </div>
          <div className="col-start-3 col-span-2">
            <Tooltip
              title="Hier wird das Tageslimit gespeichert."
              placement="top"
            >
              <button
                className="button mt-2 bg-primary"
                type="button"
                onClick={(event) => {
                  _setMsv3DailyLimit(dailyLimit);
                }}
              >
                {t('Tageslimit Speichern')}
              </button>
            </Tooltip>
          </div>
        </div>
        <br />
        <div className="container mx-auto px-10 lg:px-0">
          {gridElementMsv3Transactions}
          <div className="grid grid-cols-6 gap-4 mt-2">
            <div className="col-start-1 col-end-2">
              <input
                type="file"
                className="hidden"
                ref={fileUploadInputRef}
                accept=".csv,.xlsx, .xlsm, .xls,.ods"
                onChange={(event) => {
                  if (event.target.files) {
                    _uploadMassImportFile(event.target.files[0], false);
                  }
                }}
              />
              <Tooltip
                title="Hier lassen sich Daten per .xls oder .csv-Datei einlesen. Nutzen bitte Sie das Format der Musterdatei!"
                placement="top"
              >
                <button
                  type="button"
                  className="button bg-primary"
                  onClick={() => {
                    if (fileUploadInputRef && fileUploadInputRef.current) {
                      fileUploadInputRef.current.click();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  {t('MASS_IMPORT_FILE_UPLOAD_BUTTON_TEXT')}
                </button>
              </Tooltip>
            </div>
            <div className="col-start-3 col-span-2">
              <Tooltip
                title="Hierüber können alle oder einzeln ausgewählte Elemente der Liste wieder entfernt werden."
                placement="top"
              >
                <button
                  className={`button ${
                    selectedMsv3TransactionRows.size === 0
                      ? inactiveBgColor
                      : activeBgColor
                  }`}
                  type="button"
                  onClick={(event) => {
                    _deleteMsv3Transaction(
                      Array.from(selectedMsv3TransactionRows),
                    ).then(() => {
                      _getMsv3Transactions(currentUserProfile?.company_id).then(
                        (res) => {
                          setMsv3Transaction(res.payload);
                          setSelectedRows(new Set());
                          setDesiredAmounts({});
                        },
                      );
                    });
                  }}
                >
                  {t('Löschen')}
                </button>
              </Tooltip>
            </div>
            <div className="col-start-5 col-span-2">
              <button
                className={`button ${
                  ids.length === 0 ? inactiveBgColor : activeBgColor
                }`}
                type="button"
                onClick={(value) => {
                  if (ids.length > 0) {
                    _buyMsv3Items(
                      desiredAmounts,
                      0,
                      selectedMsv3TransactionRows,
                      ids,
                    ).then((payload) => {
                      if (typeof payload.payload !== 'string') {
                        toast.success('Transaktion erfolgreich');
                        setMsv3Transaction([]);
                        setSelectedMsv3TransactionRows(new Set());
                        _getUnreadMessagesNumber();
                        _deleteMsv3Transaction(
                          msv3Transactions.map((el) => el.id),
                        ).then(() => {
                          setMsv3Transaction([]);
                          setSelectedMsv3TransactionRows(new Set());
                          _getMsv3OpenTransactions(
                            currentUserProfile?.company_id,
                          ).then((res) => {
                            setMsv3OpenTransactions(res.payload);
                          });
                          _getMsv3Transactions(
                            currentUserProfile?.company_id,
                          ).then((res) => {
                            setMsv3Transaction(res.payload);
                          });
                        });
                      }
                    });
                  }
                }}
              >
                {t('MSV3-Kaufauftrag ausführen')}
              </button>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-10 lg:px-0">
          {t('PRODUCT_FINDER_IMPORT_EXAMPLE_SELECTION_TEXT')}
        </div>
        <div className="container mx-auto px-10 lg:px-0">
          {t('MASS_IMPORT_EXAMPLE_SELECTION_TEXT')}
          <a
            href="https://media.tramedix.com/public/Msv3_upload_Mustervorlage.xlsx"
            target="_blank"
            rel="noopener noreferrer"
            className="example_underline"
            download
            color="red"
          >
            <span>{t('MASS_IMPORT_EXAMPLE_FILE_TEXT')}</span>
          </a>
        </div>
        <br />
        <div className="container mx-auto px-10 lg:px-0">
          <hr />
          <br />
          <span className="text-primary text-md">
            {t('MSV3.CHECKOUT.OPEN_TRANSACTIONS')}
          </span>
        </div>
        <br />
        <div className="container mx-auto px-10 lg:px-0">
          {gridElement}
          <div className="grid grid-cols-6 gap-4 mt-2">
            <div className="col-start-5 col-span-2 mt-2">
              <Tooltip title="MSV3destroy" placement="top">
                <button
                  className={`button ${
                    selectedRows.size === 0 ? inactiveBgColor : activeBgColor
                  }`}
                  type="button"
                  onClick={() => {
                    _deleteMsv3OpenTransaction(Array.from(selectedRows)).then(
                      () => {
                        _getMsv3OpenTransactions(
                          currentUserProfile?.company_id,
                        ).then((res) => {
                          setMsv3OpenTransactions(res.payload);
                          setSelectedRows(new Set());
                        });
                      },
                    );
                  }}
                >
                  {t('Transaktion abbrechen')}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <Popover id={id} open={open} anchorEl={articleSuggestionsAnchorEl}>
          {isLoadingSuggestions && <ArticleSuggestionsSkeleton />}
          {!isLoadingSuggestions && articleSuggestions.length !== 0 && (
            <div className="h-40 overflow-y-scroll p-1 bg-light shadow-lg mt-1 rounded-md">
              {articleSuggestions.map((el) => (
                <p
                  key={el.pzn}
                  className="hover:bg-primary py-2 px-4 rounded-sm cursor-pointer hover:text-white"
                  onClick={() => {
                    setPzn(el.pzn);
                    setDescription(el.name);
                    setPackageSize(el.package_size);
                    setAek(el.aep);
                    setApu(el.apu.toString());
                    setApothekenpflichtig(
                      el.apothekenpflichtig !== undefined
                        ? convertStringToBoolean(
                            el.apothekenpflichtig.toString(),
                          )
                        : false,
                    );
                    setVerschreibungspflichtig(
                      el.verschreibungspflichtig !== undefined
                        ? convertStringToBoolean(
                            el.verschreibungspflichtig.toString(),
                          )
                        : false,
                    );
                    setBtm(
                      el.btm !== undefined
                        ? convertStringToBoolean(el.btm.toString())
                        : false,
                    );
                    setTfg(
                      el.tfg !== undefined
                        ? convertStringToBoolean(el.tfg.toString())
                        : false,
                    );
                    setKennzeichenArzneimittel(
                      el.kennzeichen_arzneimittel !== undefined
                        ? convertStringToBoolean(
                            el.kennzeichen_arzneimittel.toString(),
                          )
                        : false,
                    );
                    setKennzeichenMedizinprodukt(
                      el.kennzeichen_medizinprodukt !== undefined
                        ? convertStringToBoolean(
                            el.kennzeichen_medizinprodukt.toString(),
                          )
                        : false,
                    );
                    setMp31(
                      el.mp31 !== undefined
                        ? convertStringToBoolean(el.mp31.toString())
                        : false,
                    );
                    const data = {
                      ...article,
                      attributes: {
                        ...article.attributes,
                        aep: el.aep,
                        pzn: el.pzn,
                        min_piece_amount: 1,
                        apu: el.apu.toString(),
                        piece_amount: 0,
                        product_name: el.name,
                        price: el.aep,
                      },
                    };
                    setArticle(data);
                    closeSuggestionsPopOver();
                    pznInputRef.current?.blur();
                  }}
                >
                  {el.pzn} - {el.name}
                </p>
              ))}
            </div>
          )}
        </Popover>
      </main>
    </>
  );
};

const mapStateToProps = ({
  articleEditorState: {
    articleSuggestions,
    isEditMode,
    articleOnEdit,
    isLoadingSuggestions,
    massImportData,
  },
  registrationState: { currentUser },
  profileState: { userProfile },
  msv3State: {
    currentPZNAvailability,
    partnersList: { own },
  },
}: StoreI) => ({
  articleSuggestions,
  isEditMode,
  articleOnEdit,
  isLoadingSuggestions,
  massImportData,
  currentUser,
  currentUserProfile: userProfile,
  currentPZNAvailability,
  ownMsv3PartnersList: own,
});

export default connect(mapStateToProps, {
  _getArticleSuggestionsFromPzn: getArticleSuggestionsFromPzn,
  _setArticleSuggestionsValue: setArticleSuggestionsValue,
  _setTableModal: setTableModal,
  _getMSV3Partners: getMSV3Partners,
  _uploadMassImportFile: uploadMassImportFile,
  _buyMsv3Items: buyMsv3Items,
  _getMsv3OpenTransactions: getMsv3OpenTransactions,
  _getMsv3Transactions: getMsv3Transactions,
  _getUserDetails: getUserDetails,
  _deleteMsv3OpenTransaction: deleteMsv3OpenTransaction,
  _deleteMsv3Transaction: deleteMsv3Transaction,
  _getMSV3PznSuggestion: getMSV3PznSuggestions,
  _setMsv3Transactions: setMsv3Transactions,
  _getUnreadMessagesNumber: getUnreadMessagesNumber,
  _setMsv3DailyLimit: setMsv3DailyLimit,
})(GeneralLayout(ProductFinder));
