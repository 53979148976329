/* eslint-disable no-case-declarations */
import { Reducer } from 'redux';
import { toast } from 'react-toastify';
import { DataTableState } from '../../globals/models/redux';
import initialState from '../initialState';
import {
  AcceptOrDeclineMatchingActionI,
  AddArticleToShoppingCartActionI,
  AddMsv3AvailabilitiesActionI,
  AddMSV3deliverablesActionI,
  DataTableActions,
  DataTableErrorActionI,
  DeleteArticleActionI,
  GetSortRefActionI,
  GetTableColumnsConfigActionI,
  GetTransactionsNumberActionI,
  SetArticlesActionI,
  SetIsAddingToShoppingCartActionI,
  SetIsContentLoadingActionI,
  SetIsCurrentModalLoadingActionI,
  SetIsLayoutLoadingActionI,
  SetPageSizeActionI,
  SetSelectedRowsActionI,
  SetSortRefActionI,
  SetTableColumnsConfigActionI,
  SetTableModalActionI,
  StartSubsetOrBidActionI,
  SubmitASubsetActionI,
  SubmitBidDeclinedActionI,
  ToggleTableModalActionI,
} from '../../globals/models/actions/dataTable';
import { DataTableActionTypes } from '../actions/types/dataTable';
import { ArticleOfferI } from '../../globals/models/components/dataTable';
import i18next from '../../i18n';
import { ArticleOptionsTypes } from '../../globals/models/data/article.model';
import { getMSV3DeliverableSign } from '../../shared/utils/redux.utils';

export const dataTableReducer: Reducer<DataTableState, DataTableActions> = (
  state = initialState.dataTableState,
  action,
) => {
  switch (action.type) {
    case DataTableActionTypes.GET_COLUMNS_CONFIG:
      return {
        ...state,
        columnsConfig: (action as GetTableColumnsConfigActionI).payload,
      };

    case DataTableActionTypes.TABLE_ERROR:
      console.log(action.payload);
      // toast.error(action.payload);
      return {
        ...state,
        isContentLoading: false,
        isLayoutLoading: false,
        errorMessage: (action as DataTableErrorActionI).payload,
        articles: {
          data: [],
        },
        currentModal: {
          ...state.currentModal,
          isLoading: false,
        },
      };

    case DataTableActionTypes.SET_COLUMNS_CONFIG:
      return {
        ...state,
        columnsConfig: (action as SetTableColumnsConfigActionI).payload,
      };

    case DataTableActionTypes.SET_ARTICLES:
      return {
        ...state,
        articles: (action as SetArticlesActionI).payload,
        isContentLoading: false,
      };

    case DataTableActionTypes.SET_IS_CONTENT_LOADING:
      return {
        ...state,
        isContentLoading: (action as SetIsContentLoadingActionI).payload,
      };

    case DataTableActionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: (action as SetPageSizeActionI).payload,
      };

    case DataTableActionTypes.SET_IS_LAYOUT_LOADING:
      return {
        ...state,
        isLayoutLoading: (action as SetIsLayoutLoadingActionI).payload,
      };

    case DataTableActionTypes.RESET_TABLE:
      return {
        ...state,
        articles: {
          ...state.articles,
          data: [],
          shoppingCart: undefined,
        },
        isContentLoading: true,
        isLayoutLoading: true,
        isAddingToShoppingCart: false,
        loadingIconRef: null,
        currentModal: {
          ...state.currentModal,
          page: null,
          article: null,
          isLoading: false,
        },
      };

    case DataTableActionTypes.ADD_ITEM_TO_SHOPPING_CART:
      const cartOffer = (action as AddArticleToShoppingCartActionI).payload;
      const cartArticles = state.articles.data;
      const cartOfferIndexes = cartOffer.map((offer) =>
        cartArticles.findIndex((el) => el.id === offer.id),
      );

      cartOfferIndexes.forEach((element, index) => {
        cartArticles[element] = {
          ...cartOffer[index],
          attributes: {
            ...cartOffer[index].attributes,
            options_type: ArticleOptionsTypes.IN_CHECKOUT,
          },
        } as ArticleOfferI;
      });

      toast.success(
        `${cartOffer.length} ${i18next.t('SHOPPING_CART_ADD_SUCCESS')}`,
      );

      return {
        ...state,
        articles: {
          ...state.articles,
          data: cartArticles,
        },
        isAddingToShoppingCart: false,
        loadingIconRef: null,
        transactionsNumber: state.transactionsNumber + cartOffer.length,
      };

    case DataTableActionTypes.SET_IS_ADDING_TO_SHOPPING_CART:
      return {
        ...state,
        isAddingToShoppingCart: (action as SetIsAddingToShoppingCartActionI)
          .payload.value,
        loadingIconRef: (action as SetIsAddingToShoppingCartActionI).payload
          .iconRef,
      };

    case DataTableActionTypes.GET_TRANSACTIONS_NUMBER:
      return {
        ...state,
        transactionsNumber: (action as GetTransactionsNumberActionI).payload,
      };

    case DataTableActionTypes.START_SUBSET_OR_BID:
      return {
        ...state,
        currentModal: {
          ...state.currentModal,
          ...(action as StartSubsetOrBidActionI).payload,
        },
      };

    case DataTableActionTypes.SUBMIT_SUBSET_OR_BID:
      const subsetOffer = (action as SubmitASubsetActionI).payload;
      const subsetArticles = state.articles.data;
      const subsetOfferIndex = subsetArticles.findIndex(
        (el) => el.id === subsetOffer.id,
      );

      subsetArticles[subsetOfferIndex] = {
        ...subsetOffer,
        attributes: {
          ...subsetOffer.attributes,
          options_type: ArticleOptionsTypes.IN_CHECKOUT,
        },
      } as ArticleOfferI;

      toast.success(i18next.t('TABLE_MODAL_SUBSET_SUCCESS_MESSAGE'), {
        autoClose: false,
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          data: subsetArticles,
        },
        currentModal: {
          ...state.currentModal,
          page: null,
          article: null,
          isLoading: false,
        },
        transactionsNumber: state.transactionsNumber + 1,
      };

    case DataTableActionTypes.SET_IS_CURRENT_MODAL_LOADING:
      return {
        ...state,
        currentModal: {
          ...state.currentModal,
          isLoading: (action as SetIsCurrentModalLoadingActionI).payload,
        },
      };

    case DataTableActionTypes.SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: {
          ...state.currentModal,
          page: (action as SetTableModalActionI).payload.page,
          title: (action as SetTableModalActionI).payload.title,
          article: (action as SetTableModalActionI).payload
            .article as ArticleOfferI,
          maxWidth: (action as SetTableModalActionI).payload.maxWidth,
        },
      };

    case DataTableActionTypes.SUBMIT_BID_DECLINED:
      const declinedSubsetOffer = (action as SubmitBidDeclinedActionI).payload;
      const declinedSubsetArticles = state.articles.data;
      const declinedSubsetOfferIndex = declinedSubsetArticles.findIndex(
        (el) => el.id === declinedSubsetOffer.id,
      );

      declinedSubsetArticles[declinedSubsetOfferIndex] = declinedSubsetOffer;

      return {
        ...state,
        articles: {
          ...state.articles,
          data: declinedSubsetArticles,
        },
        currentModal: {
          ...state.currentModal,
          page: null,
          article: null,
          isLoading: false,
        },
      };

    case DataTableActionTypes.TOGGLE_TABLE_MODAL:
      return {
        ...state,
        currentModal: {
          ...state.currentModal,
          page: (action as ToggleTableModalActionI).payload,
        },
      };

    case DataTableActionTypes.SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: (action as SetSelectedRowsActionI).payload,
      };

    case DataTableActionTypes.DELETE_ARTICLE:
      toast.success(
        `"${(action as DeleteArticleActionI).payload
          .map((article) => article.attributes.product_name)
          .join(' & ')}" ${i18next.t('ARTICLE_DELETION_SUCCESS_MESSAGE')}`,
      );
      return {
        ...state,
        articles: {
          ...state.articles,
          data: state.articles.data.filter(
            (el) =>
              !(action as DeleteArticleActionI).payload
                .map((element) => element.id)
                .includes(el.id),
          ),
        },
        currentModal: {
          isLoading: false,
          page: null,
          article: null,
          title: null,
        },
      };

    case DataTableActionTypes.ACCEPT_OR_DECLINE_MATCHING:
      const matchingArticle = (action as AcceptOrDeclineMatchingActionI).payload
        .article;
      const matchingProposal = (action as AcceptOrDeclineMatchingActionI)
        .payload.proposal;

      const matchingTableArticles = state.articles.data;

      const matchedArticleIndex = matchingTableArticles.findIndex(
        (el) => el.id === matchingArticle.id,
      );

      const matchedProposalIndex = matchingArticle.attributes.proposals.findIndex(
        (el) => el.proposal_id === matchingProposal.proposal_id,
      );

      matchingProposal.status = (action as AcceptOrDeclineMatchingActionI).payload.status;

      matchingArticle.attributes.proposals[
        matchedProposalIndex
      ] = matchingProposal;

      matchingArticle.attributes.proposals = [
        ...matchingArticle.attributes.proposals,
      ];

      matchingTableArticles[matchedArticleIndex] = matchingArticle;

      toast.success(i18next.t('DONE_TEXT'));

      return {
        ...state,
        articles: {
          ...state.articles,
          data: matchingTableArticles,
        },
        currentModal: {
          ...state.currentModal,
          article: { ...matchingArticle },
        },
      };

    case DataTableActionTypes.ADD_MSV3_DELIVERABLES:
      const MSV3Deliverables = (action as AddMSV3deliverablesActionI).payload;
      const articleData = state.articles.data;
      // Only update the articles that are actually matching the msv3 payload
      const updatedData = articleData.map((article) => {
        const msv3Update = MSV3Deliverables.find(
          (msv3Data) => msv3Data.pzn === article.attributes.pzn,
        );
        if (msv3Update) {
          // object mutation is intended here
          // eslint-disable-next-line no-param-reassign
          article.MSV3deliverables = getMSV3DeliverableSign(
            article,
            msv3Update,
          );
        }

        return article;
      });

      return {
        ...state,
        articles: {
          ...state.articles,
          data: updatedData,
        },
      };

    case DataTableActionTypes.ADD_MSV3_AVAILABILITIES:
      const MSV3PZNAvailability = (action as AddMsv3AvailabilitiesActionI)
        .payload;

      const msv3PartnerData = Object.values(MSV3PZNAvailability);
      const updatedAvailabilityData = state.articles.data.map(
        (stateArticle) => {
          const { pzn } = stateArticle.attributes;
          // 1. Iterate over all partner data
          // 2. If partner data has pzn of article, add partnerdata to the availability data of the article
          /* eslint-disable no-restricted-syntax */
          /* eslint-disable no-param-reassign */
          for (const partnerArticles of msv3PartnerData) {
            for (const article of partnerArticles) {
              if (article.pzn === pzn) {
                if (Array.isArray(stateArticle.MSV3Availabilities)) {
                  stateArticle.MSV3Availabilities.push(article);
                } else {
                  stateArticle.MSV3Availabilities = [article];
                }
              }
            }
          }
          /* eslint-enable */

          return stateArticle;
        },
      );

      return {
        ...state,
        articles: {
          ...state.articles,
          data: updatedAvailabilityData,
        },
        msv3AvailabilitiesSet: true,
      };

    case DataTableActionTypes.SET_SORT_REF:
      return {
        ...state,
        sort: (action as SetSortRefActionI).payload,
      };

    case DataTableActionTypes.GET_SORT_REF:
      return {
        ...state,
        sort: (action as GetSortRefActionI).payload,
      };

    default:
      return state;
  }
};
