import * as React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundSvg from '../../../assets/svg/notfound.svg';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="container ml-auto mr-auto md:pt-24 md:pt-20 pt-10">
      <div className="md:grid md:grid-cols-2 md:gap-10 min-h-1/4 h-screen60">
        <div className="md:block hidden">
          <img src={notFoundSvg} alt="" />
        </div>
        <div className="px-6">
          <h1 className="md:text-12xl text-6xl">
            <span className="text-primary">4</span>
            <span className="text-secondary">0</span>
            <span className="text-green-600">4</span>
          </h1>
          <h4 className="font-bold md:text-4xl text-2xl pb-4">
            Oops! {t('NOT_FOUND_TITLE')}
          </h4>
          <p className="text-base pb-8">
            {t('NOT_FOUND_CONTENT_1')} <br /> {t('NOT_FOUND_CONTENT_2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
