/* eslint-disable @typescript-eslint/indent */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ModalsTypes } from '../../../globals/models/redux';
import { ModalsActionTypes } from '../types/modals';
import {
  SetCurrentTypeActionI,
  SetSelectedShoppingCartRowsActionI,
} from '../../../globals/models/actions/modals';
import { ArticleOfferI } from '../../../globals/models/components/dataTable';

export const setCurrentModalType: ActionCreator<ThunkAction<
  { type: string; payload: ModalsTypes | null },
  { type: string; payload: ModalsTypes | null },
  string | null,
  SetCurrentTypeActionI
>> = (type: ModalsTypes | null) => (dispatch: Dispatch) => {
  return dispatch({
    type: ModalsActionTypes.SET_CURRENT_TYPE,
    payload: type,
  });
};

export const setShoppingCartSelectedRows: ActionCreator<ThunkAction<
  { type: string; payload: ArticleOfferI[] },
  { type: string; payload: ArticleOfferI[] },
  ArticleOfferI[],
  SetSelectedShoppingCartRowsActionI
>> = (value: ArticleOfferI[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: ModalsActionTypes.SET_SELECTED_SHOPPING_CART_ROWS,
    payload: value,
  });
};
