import React from 'react';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { ArticleOfferI } from '../../../../globals/models/components/dataTable';

interface LQIconProps {
  row: ArticleOfferI;
}

const LQIcon = ({ row }: LQIconProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t('LQ_ICON_TIP') as string}
      placement="top-end"
      className="hover:text-primary cursor-pointer"
    >
      <a href={row.attributes.lq_url} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFilePdf} />
      </a>
    </Tooltip>
  );
};

export default LQIcon;
