/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import i18next from '../../../i18n';
import LanguageSelector from './LanguageSelector';

const Footer = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  return (
    <footer className="bg-primary py-6 general-layout__footer">
      <div className="container mx-auto text-white">
        <div className="px-12 flex flex-col items-center justify-center">
          <ul className="text-sm lg:text-base md:flex flex-row justify-between w-full">
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/imprint">{t('Imprint')}</Link>
            </li>
            <li>
              <Link to="/datenschutz">{t('PRIVACY_TEXT')}</Link>
            </li>
            <li>
              <Link to="/agb">{t('General Terms And Conditions')}</Link>
            </li>
            <li>
              <Link to="/pricing">{t('Prices')}</Link>
            </li>
            <li>
              V {process.env.REACT_APP_CURRENT_VERSION} -{' '}
              {new Date(
                Number(process.env.REACT_APP_UPDATED_AT),
              ).toLocaleDateString(
                i18next.language === 'en' ? 'en-US' : 'de-DE',
                {
                  month: '2-digit',
                  year: 'numeric',
                },
              )}
            </li>

            {pathname === '/' && (
              <li>
                <LanguageSelector />
              </li>
            )}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
