import React from 'react';
import GeneralLayout from '../../components/Shared/HOCs/GeneralLayout';
import { TRAMEDIX_PHONE_NUMBER } from '../../shared/constants/constantChoices';

const Imprint = () => {
  return (
    <div className="container mx-auto px-10 lg:px-0">
      <div className="pt-6 pb-10 grid grid-cols-2">
        <div>
          <p className="font-semibold text-3xl  text-primary">Impressum</p>
          <hr />
        </div>
      </div>
      <div>
        <p className="text-2xl pt-2 font-bold pb-4">
          Gesetzliche Anbieterkennzeichnung
        </p>
        <p className="pb-4">
          TRAMEDIX GmbH
          <br /> Goldberger Str. 81A <br />
          18273 Güstrow <br />
          Germany
        </p>
        <p className="pb-4">
          Vertreten durch ihre Geschäftsführer: <br />
          Axel Lehmann <br />
          Jörg Lehmann <br />
        </p>
        <p className="pb-4">
          Telefon: {TRAMEDIX_PHONE_NUMBER} <br />
          E-Mail: info@tramedix.com <br />
        </p>
        <p className="pb-4">
          Eingetragen im Handelsregister des Amtsgerichts Rostock
          <br />
          Handelsregister-Nummer HRB 14122 <br />
          USt-IdNr. DE 316979778
        </p>
      </div>
      <p className="text-2xl pt-2 font-bold pb-4">Streitbeilegung</p>
      <p className="pb-4">
        Die Europäische Kommission bietet eine Online-Plattform zur
        Streitbeilegung (OS):
        <a
          className="text-primary"
          href="https://ec.europa.eu/consumers/odr"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://ec.europa.eu/consumers/odr
        </a>
        . <br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
        <br />
      </p>
    </div>
  );
};

export default GeneralLayout(Imprint);
