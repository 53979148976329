import server from '../../services/axios.service';

interface HomeContactFormSubmissionData {
  name: string;
  email?: string;
  phone?: string;
  timeslot?: string;
}

export const submitHomeContactForm = async (
  submitData: HomeContactFormSubmissionData,
) => {
  const { data } = await server.post('/api/v1/support/contact/', submitData);
  return data;
};
