/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import GeneralIcon from './GeneralIcon';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../../globals/models/components/dataTable';
import { ProposalI } from '../../../../globals/models/data/article.model';

const SubtableIcons = ({ row, proposal }: SubtableIconsProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <GeneralIcon
        tipText={t('CONFIRM_TEXT')}
        faIcon={faCheckCircle}
        hoverClassName="text-primary ml-2"
        modalType={DataTableModalOptions.ACCEPT_MATCHING}
        row={row}
        proposal={proposal}
      />
      <GeneralIcon
        tipText={t('DECLINE_TEXT')}
        faIcon={faBan}
        hoverClassName="text-red-600 ml-2"
        row={row}
        modalType={DataTableModalOptions.DECLINE_MATCHING}
        proposal={proposal}
      />
    </div>
  );
};

interface SubtableIconsProps {
  row: ArticleOfferI;
  proposal?: ProposalI;
}

export default SubtableIcons;
