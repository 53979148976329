/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import {
  LoginFieldsState,
  RegistrationFieldsState,
  RegistrationFormType,
} from '../../../globals/models/components/registrationForms';
import {
  setAccessTokenStorage,
  setCurrentFormType,
  setIsLoading,
} from '../../../store/actions/creators/registration';
import CheckboxInput from '../../Shared/layouts/CheckBoxInput';
import { TextInputType } from '../../../globals/models/components/textInput';
import useEnterKeyListener from '../../Shared/hooks/useEnterKeyListener';
import { loginValidationSchema } from '../../../shared/validators/registration.schemas';

const loginInitialValues: LoginFieldsState = {
  email: '',
  password: '',
};

const LoginFields = ({
  handleNavigation,
  handleSubmit: _handleSubmit,
  _setAccessTokenStorage,
  errorMessage,
  _setIsLoading,
  isLoading,
  parentName,
  _setCurrentFormType,
}: LoginFieldsProps) => {
  const { t } = useTranslation();
  useEnterKeyListener({ querySelectorToExecuteClick: '#login-submit-button' });
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Formik
      onSubmit={(values) => {
        _handleSubmit(values);
      }}
      validateOnChange
      initialValues={loginInitialValues}
      validationSchema={loginValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          handleSubmit,
        } = props;
        const isFormValid = isValid && Object.values(errors).length === 0;

        return (
          <div>
            <p className="text-red-400 font-medium text-center">
              {errorMessage}
            </p>
            <div className="md:pb-8 pb-4">
              <Grid container spacing={3} direction="column">
                <Grid item />
                <Grid item>
                  <div>
                    <FormControl fullWidth>
                      <TextField
                        id="emailLogin"
                        variant="outlined"
                        required
                        label={t('REGISTRATION_FORM_LOGIN_EMAIL_TEXT')}
                        onChange={handleChange('email')}
                        onBlur={handleBlur}
                        error={!!(touched.email && errors.email)}
                        helperText={errorMessage}
                        value={values.email}
                        disabled={isLoading}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="standard-adornment-password">
                      {t('REGISTRATION_FORM_LOGIN_PASSWORD_TEXT')}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={
                        passwordShown
                          ? TextInputType.TEXT
                          : TextInputType.PASSWORD
                      }
                      value={values.password}
                      onChange={handleChange('password')}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              togglePasswordVisiblity();
                            }}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                            color="primary"
                          >
                            {passwordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label={t('REGISTRATION_FORM_LOGIN_PASSWORD_TEXT')}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <div className="flex w-full">
                    <CheckboxInput
                      labelText={t('LOGIN_FORM_REMEMBER_ME_TEXT')}
                      onChange={(value) => {
                        if (value) _setAccessTokenStorage('localStorage');
                        else _setAccessTokenStorage('sessionStorage');
                      }}
                      className="text-justify flex-wrap text-xs sm:text-sm md:text-base whitespace-no-wrap"
                      disabled={isLoading}
                    />
                    <p className="text-right font-bold text-green-600 flex-grow text-xs sm:text-sm md:text-base">
                      <button
                        type="button"
                        className="focus:outline-none hover:underline outline-none"
                        onClick={
                          !isLoading
                            ? () =>
                                handleNavigation(
                                  RegistrationFormType.PASSWORD_RESET,
                                )
                            : undefined
                        }
                      >
                        {t('PASSWORD_RESET_SWITCH')}
                      </button>
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="grid grid-cols-2">
              <p className="md:mt-0 mt-4">
                <button
                  type="button"
                  className="text-green-600 font-bold hover:underline py-2 focus:outline-none text-xs sm:text-sm md:text-base"
                  onClick={
                    !isLoading
                      ? () => {
                          if (parentName) {
                            return handleNavigation();
                          }
                          return _setCurrentFormType(
                            RegistrationFormType.SIGNUP,
                          );
                        }
                      : undefined
                  }
                >
                  {t('LOGIN_FORM_SWITCH')}
                </button>
              </p>
              <p className="text-right md:mt-0 mt-6">
                <button
                  id="login-submit-button"
                  type="button"
                  className={`text-white md:text-lg p-2 rounded-sm px-4 ${
                    isFormValid && !isLoading
                      ? 'cursor-pointer bg-green-600'
                      : 'cursor-not-allowed bg-green-400'
                  }`}
                  onClick={
                    isFormValid && !isLoading
                      ? () => {
                          _setIsLoading(true);
                          handleSubmit();
                        }
                      : undefined
                  }
                >
                  {t('LOGIN_TEXT')}
                </button>
              </p>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface LoginFieldsProps {
  handleNavigation: (to?: RegistrationFormType | null) => void;
  handleSubmit: (fieldsData: RegistrationFieldsState) => void;
  _setAccessTokenStorage: (
    storage: 'localStorage' | 'sessionStorage',
  ) => { type: string; payload: string };
  errorMessage: string | null;
  _setIsLoading: (isLoading: boolean) => { type: string; payload: boolean };
  isLoading: boolean;
  // eslint-disable-next-line react/require-default-props
  parentName?: string;
  _setCurrentFormType: (
    form: RegistrationFormType,
  ) => { type: string; payload: string };
}

const mapStateToProps = ({
  registrationState: { errorMessage, isLoading },
}: StoreI) => ({
  errorMessage,
  isLoading,
});

export default connect(mapStateToProps, {
  _setAccessTokenStorage: setAccessTokenStorage,
  _setIsLoading: setIsLoading,
  _setCurrentFormType: setCurrentFormType,
})(LoginFields);
