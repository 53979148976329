import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import server from '../../../services/axios.service';
import InfoMaterialForm from './InfoMaterialForm';
import PersonalConsultationForm from './PersonalConsultationForm';

// Static translation keys

/**
 * t('CONTACT_FORM.VALIDATION.NAME_MAX_VALUE_ERROR')
 * t('CONTACT_FORM.VALIDATION.NAME_REQUIRED_ERROR')
 * t('CONTACT_FORM.VALIDATION.NAME_MIN_VALUE_ERROR')
 * t('CONTACT_FORM.VALIDATION.TIME_REQUIRED_ERROR')
 * t('HOME_PAGE_CONS_TIME_CHOICE_1')
 * t('HOME_PAGE_CONS_TIME_CHOICE_2')
 * t('HOME_PAGE_CONS_TIME_CHOICE_3')
 */

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState<string>();
  const [canSubmit, setCanSubmit] = useState(false);

  if (executeRecaptcha && !recaptchaToken && process.env.NODE_ENV !== 'test') {
    executeRecaptcha('home_contact_form').then((value) => {
      setRecaptchaToken(value);
      server
        .post('/api/v1/auth/recaptcha/', {
          token: value,
        })
        .then(({ data: { data } }) => {
          setCanSubmit(data.success);
        })
        .catch(() => {
          setCanSubmit(false);
        });
    });
  }

  return (
    <div className="md:grid block md:grid-cols-2 md:pb-16 pb-10 container ml-auto mr-auto pt-16 px-5 lg:px-10">
      <InfoMaterialForm canSubmit={canSubmit} />
      <PersonalConsultationForm canSubmit={canSubmit} />
    </div>
  );
};

export default ContactForm;
