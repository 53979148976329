import React, { lazy, Suspense } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import Home from '../pages/Home';
import { history } from '../shared/history';
import NotFound from '../components/Shared/pages/NotFound';
import { Tokens } from '../globals/models/components/registrationForms';
import GuardedRoute from './GuardedRoute';
import Pricing from '../pages/Static/Pricing';
import { setCurrentUser } from '../store/actions/creators/registration';
import { User, UserRole } from '../globals/models/data/user.model';
import Signup from '../components/Auth/Signup';
import Imprint from '../pages/Static/Imprint';
import Terms from '../pages/Static/Terms';
import Privacy from '../pages/Static/Privacy';
import Faq from '../pages/Static/Faq';
import ProductFinder from '../pages/Static/Productfinder';
import { getTransactionsNumber } from '../store/actions/creators/dataTable';
import { getUnreadMessagesNumber } from '../store/actions/creators/profile';
import ModalsContainer from '../components/Shared/layouts/ModalsContainer';
import Msv3ProductFinder from '../pages/Static/Msv3';

const AdminArea = lazy(() => import('../pages/AdminArea'));
const CartLayout = lazy(() => import('../pages/ShoppingCart'));
const Searches = lazy(() => import('../pages/Searches'));
const Offers = lazy(() => import('../pages/Offers'));
const Profile = lazy(() => import('../pages/Profile'));

function Routes({
  isAuth,
  _setCurrentUser,
  _getTransactionsNumber,
  currentUser,
  _getUnreadMessagesNumber,
}: RoutesProps) {
  const localStorageTokens = {
    access: localStorage.getItem('access-token'),
    refresh: localStorage.getItem('refresh-token'),
  };

  const sessionStorageTokens = {
    access: sessionStorage.getItem('access-token'),
    refresh: sessionStorage.getItem('refresh-token'),
  };

  if (!currentUser && localStorageTokens.access && localStorageTokens.refresh) {
    _setCurrentUser(localStorageTokens as Tokens, true, 'localStorage').then(
      ({ payload: { user } }) => {
        if (!user.isLicenseDueOrInvalid && user.role !== UserRole.ADMIN) {
          _getTransactionsNumber();
          _getUnreadMessagesNumber();
        }
      },
    );
  } else if (
    !isAuth &&
    sessionStorageTokens.access &&
    sessionStorageTokens.refresh
  ) {
    _setCurrentUser(
      sessionStorageTokens as Tokens,
      true,
      'sessionStorage',
    ).then(({ payload: { user } }) => {
      if (!user.isLicenseDueOrInvalid && user.role !== UserRole.ADMIN) {
        _getTransactionsNumber();
        _getUnreadMessagesNumber();
      }
    });
  } else if (
    currentUser &&
    !currentUser.isLicenseDueOrInvalid &&
    currentUser.role !== UserRole.ADMIN
  ) {
    _getTransactionsNumber();
    _getUnreadMessagesNumber();
  }

  const handleAuthStatus = () => {
    if (currentUser && currentUser.role === UserRole.ADMIN) {
      return <Redirect to="/admin" />;
    }

    if (currentUser && currentUser.role === UserRole.NORMAL) {
      if (currentUser.consulting) {
        return <Redirect to="/application/msv3" />;
      }
      return <Redirect to="/application/offers/own" />;
    }

    return <Home />;
  };

  return (
    <Router history={history}>
      <div className="md:text-base h-full">
        <Suspense
          fallback={
            <div className="w-screen h-screen text-center flex flex-col justify-center">
              <div>
                <CircularProgress size={50} />
              </div>
            </div>
          }
        >
          <Switch>
            <Redirect exact from="/index.html" to="/" />
            <Route exact path="/" component={() => handleAuthStatus()} />
            <GuardedRoute
              path="/application/offers"
              Component={Offers}
              roles={[UserRole.NORMAL]}
              redirectTo="/"
            />
            <GuardedRoute
              path="/application/search"
              Component={Searches}
              roles={[UserRole.NORMAL]}
              redirectTo="/"
            />
            <GuardedRoute
              path="/application/msv3"
              Component={ProductFinder}
              roles={[UserRole.NORMAL]}
              redirectTo="/"
            />
            <GuardedRoute
              path="/-/profile"
              Component={Profile}
              roles={[UserRole.NORMAL, UserRole.ADMIN]}
              redirectTo="/"
            />
            <GuardedRoute
              path="/cart"
              Component={CartLayout}
              roles={[UserRole.NORMAL]}
              redirectTo="/"
            />
            <GuardedRoute
              path="/admin"
              roles={[UserRole.ADMIN]}
              Component={AdminArea}
              redirectTo="/"
            />
            <Route exact path="/pricing" component={Pricing} />
            <Route
              exact
              path="/auth/signup"
              component={
                isAuth
                  ? () => <Redirect to="/application/offers/own" />
                  : Signup
              }
            />
            <Route exact path="/imprint" component={Imprint} />
            <Route exact path="/agb" component={Terms} />
            <Route exact path="/datenschutz" component={Privacy} />
            <Route exact path="/faq" component={Faq} />
            <Route
              exact
              path="/Apotheke-Schwalbenohl"
              component={Msv3ProductFinder}
            />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </div>
      <ModalsContainer />
    </Router>
  );
}

interface RoutesProps {
  isAuth: boolean;
  _setCurrentUser: (
    tokens: Tokens,
    isAuth: boolean,
    storage: 'localStorage' | 'sessionStorage',
  ) => Promise<{ type: string; payload: { isAuth: boolean; user: User } }>;
  _getTransactionsNumber: () => Promise<{
    type: string;
    payload: number | string;
  }>;
  _getUnreadMessagesNumber: () => Promise<void>;
  currentUser: User | null;
}

const mapStateToProps = ({
  registrationState: { isAuth, currentUser },
}: StoreI) => ({
  isAuth,
  currentUser,
});

export default connect(mapStateToProps, {
  _setCurrentUser: setCurrentUser,
  _getTransactionsNumber: getTransactionsNumber,
  _getUnreadMessagesNumber: getUnreadMessagesNumber,
})(Routes);
