import React, { useState } from 'react';
import { checkVAT, countries } from 'jsvat';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../../Shared/layouts/TextInput';
import { CompanyDataFieldsState } from '../../../globals/models/components/registrationForms';
import { InputChangeState } from '../../../globals/models/components/textInput';

interface CompanyDataFieldsProps {
  onChange: (value: CompanyDataFieldsState) => void;
  value: CompanyDataFieldsState;
  handleNext: () => void;
  handleBack: (step: number) => void;
  setIsEditMode: (mode: boolean) => void;
}

const CompanyDataFields = ({
  onChange,
  value,
  handleNext,
  handleBack,
  setIsEditMode,
}: CompanyDataFieldsProps) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState(value);

  const [formValidityState, setFormValidityState] = useState({
    commercialRegisterEntry: true,
    taxId: true,
  });

  const [errorMessages, setErrorMessages] = useState({
    commercialRegisterEntry: t(
      'COMPANY_REGISTRATION_ERROR_COMMERCIAL_REGISTRY_REQUIRED',
    ),
    taxId: t('COMPANY_REGISTRATION_ERROR_TAX_ID_INVALID'),
  });

  const isFormTouchedAndValid =
    fields.commercialRegisterEntry !== '' &&
    formValidityState.commercialRegisterEntry &&
    formValidityState.taxId;

  return (
    <div>
      <div className="pb-8">
        <TextInput
          forText="commercialRegisterEntry"
          isRequired
          labelKey={t('COMPANY_REGISTRATION_FORM_LABEL_1')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              commercialRegisterEntry: value_,
            };
            setFields(newState);

            if (value_ === '') {
              setFormValidityState({
                ...formValidityState,
                commercialRegisterEntry: false,
              });
              onChange(newState);
            } else {
              onChange(newState);
              setFormValidityState({
                ...formValidityState,
                commercialRegisterEntry: true,
              });
            }
          }}
          value={fields.commercialRegisterEntry}
          changeState={
            formValidityState.commercialRegisterEntry
              ? InputChangeState.VALID
              : InputChangeState.INVALID
          }
          errorMessage={errorMessages.commercialRegisterEntry}
        />
        <TextInput
          forText="taxId"
          isRequired={false}
          labelKey={t('COMPANY_REGISTRATION_FORM_LABEL_2')}
          placeHolder={t('COMPANY_REGISTRATION_FORM_LABEL_1_EG')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              taxId: value_,
            };

            setFields(newState);
            if (value_ === '') {
              setFormValidityState({
                ...formValidityState,
                taxId: true,
              });
              onChange(newState);
            } else if (!checkVAT(value_, countries).isValid) {
              setErrorMessages({
                ...errorMessages,
                taxId: t('COMPANY_REGISTRATION_ERROR_TAX_ID_INVALID'),
              });
              setFormValidityState({
                ...formValidityState,
                taxId: false,
              });
              onChange(newState);
            } else {
              onChange(newState);
              setFormValidityState({
                ...formValidityState,
                taxId: true,
              });
            }
          }}
          value={fields.taxId}
          changeState={
            formValidityState.taxId
              ? InputChangeState.VALID
              : InputChangeState.INVALID
          }
          errorMessage={errorMessages.taxId}
        />
        <TextInput
          forText="taxExempt"
          isRequired={false}
          labelKey={t('COMPANY_REGISTRATION_FORM_LABEL_3')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              taxExempt: value_,
            };
            setFields(newState);
            onChange(newState);
          }}
          value={fields.taxExempt}
        />
      </div>
      <div className="block md:grid md:grid-cols-2 md:gap-5">
        <p className="text-left">
          <button
            type="button"
            onClick={() => {
              setIsEditMode(true);
              handleBack(0);
            }}
            className="button bg-green-600 focus:outline-none w-full md:w-auto"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 h-4 right-0" />
            {t('COMPANY_REGISTRATION_MODAL.NAV.GO_TO_STEP_0_BUTTON_TEXT')}
          </button>
        </p>
        <p className="text-right">
          <button
            type="button"
            id="company-reg-next-button"
            onClick={isFormTouchedAndValid ? handleNext : undefined}
            className={`button focus:outline-none w-full md:w-auto ${
              isFormTouchedAndValid
                ? 'cursor-pointer bg-green-600'
                : 'cursor-not-allowed bg-green-400'
            }`}
          >
            {t('COMPANY_REGISTRATION_MODAL.NAV.GO_TO_STEP_2_BUTTON_TEXT')}
            <FontAwesomeIcon icon={faArrowRight} className="ml-2 h-4 right-0" />
          </button>
        </p>
      </div>
    </div>
  );
};

export default CompanyDataFields;
