/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CheckBoxInput from '../../Shared/layouts/CheckBoxInput';
import FileInput from '../../Shared/layouts/FileInput';
import { CompanyApprovalsFieldsState } from '../../../globals/models/components/registrationForms';
import { InputChangeState } from '../../../globals/models/components/textInput';

interface CompanyApprovalsFieldsProps {
  onChange: (value: CompanyApprovalsFieldsState) => void;
  value: CompanyApprovalsFieldsState;
  handleBack: (step: number) => void;
  handleNext: () => void;
}

const CompanyApprovalsFields = ({
  onChange,
  value,
  handleBack,
  handleNext,
}: CompanyApprovalsFieldsProps) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState(value);

  const validateCompanyLicenses = () => {
    let reducedValue = false;
    Object.values(fields.companyLicenses).forEach((el) => {
      if (el.isChecked) reducedValue = Boolean(el.value);
    });
    return reducedValue;
  };

  const isSection1Valid = Object.values(fields.area).reduce(
    (value1, value2) => value1 || value2,
  );

  const isSection2Valid = validateCompanyLicenses();

  const isFormValid =
    fields.validityState.section2 === InputChangeState.TOUCHED
      ? isSection1Valid && isSection2Valid
      : isSection1Valid;

  return (
    <div className="">
      <div>
        <p className="pt-4 pb-2 font-bold">
          {t('COMPANY_REGISTRATION_SUB_TITLE_1')}
          {fields.validityState.form === InputChangeState.TOUCHED &&
            !isSection1Valid && (
              <span className="text-red-400 text-sm font-normal pl-2">
                ({t('COMPANY_REGISTRATION_ERROR_AREA_REQUIRED')})
              </span>
            )}
        </p>
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_5')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              area: {
                ...fields.area,
                pharmacy: value_,
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section1: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.area.pharmacy}
        />
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_6')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              area: {
                ...fields.area,
                wholesale: value_,
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                section1: InputChangeState.TOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.area.wholesale}
        />
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_7')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              area: {
                ...fields.area,
                pharmaManufacturer: value_,
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section1: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.area.pharmaManufacturer}
        />
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_8')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              area: {
                ...fields.area,
                medicineManufacturer: value_,
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                section1: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.area.medicineManufacturer}
        />
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_13')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              area: {
                ...fields.area,
                pharmacyWithoutWholesale: value_,
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section1: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.area.pharmacyWithoutWholesale}
        />
      </div>

      <br />
      <div>
        <p className="pb-2 font-bold">
          {t('COMPANY_REGISTRATION_SUB_TITLE_2')}
          {fields.validityState.form === InputChangeState.TOUCHED &&
            !isSection2Valid && (
              <span className="text-red-400 text-sm font-normal pl-2">
                ({t('COMPANY_REGISTRATION_ERROR_LICENSES_REQUIRED')})
              </span>
            )}
        </p>

        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_9')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              companyLicenses: {
                ...fields.companyLicenses,
                operatingLicense: {
                  value: value_
                    ? fields.companyLicenses.operatingLicense.value
                    : null,
                  isChecked: value_,
                },
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section2: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.companyLicenses.operatingLicense.isChecked}
        />
        {fields.companyLicenses.operatingLicense.isChecked && (
          <FileInput
            inputClassName="mb-4 ml-6 auth__company-file-input"
            onChange={(value__) => {
              const newState = {
                ...fields,
                companyLicenses: {
                  ...fields.companyLicenses,
                  operatingLicense: {
                    isChecked:
                      fields.companyLicenses.operatingLicense.isChecked,
                    value: value__,
                  },
                },
              };
              setFields(newState);
              onChange(newState);
            }}
            value={fields.companyLicenses.operatingLicense.value}
          />
        )}
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_10')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              companyLicenses: {
                ...fields.companyLicenses,
                wholesalePermit: {
                  value: value_
                    ? fields.companyLicenses.wholesalePermit.value
                    : null,
                  isChecked: value_,
                },
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section2: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.companyLicenses.wholesalePermit.isChecked}
        />
        {fields.companyLicenses.wholesalePermit.isChecked && (
          <FileInput
            inputClassName="mb-4 ml-6 auth__company-file-input"
            onChange={(value__) => {
              const newState = {
                ...fields,
                companyLicenses: {
                  ...fields.companyLicenses,
                  wholesalePermit: {
                    isChecked: fields.companyLicenses.wholesalePermit.isChecked,
                    value: value__,
                  },
                },
              };
              setFields(newState);
              onChange(newState);
            }}
            value={fields.companyLicenses.wholesalePermit.value}
          />
        )}
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_11')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              companyLicenses: {
                ...fields.companyLicenses,
                manufacturingAuthorization: {
                  value: value_
                    ? fields.companyLicenses.manufacturingAuthorization.value
                    : null,
                  isChecked: value_,
                },
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section2: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.companyLicenses.manufacturingAuthorization.isChecked}
        />
        {fields.companyLicenses.manufacturingAuthorization.isChecked && (
          <FileInput
            inputClassName="mb-4 ml-6 auth__company-file-input"
            onChange={(value__) => {
              const newState = {
                ...fields,
                companyLicenses: {
                  ...fields.companyLicenses,
                  manufacturingAuthorization: {
                    isChecked:
                      fields.companyLicenses.manufacturingAuthorization
                        .isChecked,
                    value: value__,
                  },
                },
              };
              setFields(newState);
              onChange(newState);
            }}
            value={fields.companyLicenses.manufacturingAuthorization.value}
          />
        )}
        <CheckBoxInput
          labelText={t('COMPANY_REGISTRATION_FORM_LABEL_12')}
          onChange={(value_) => {
            const newState = {
              ...fields,
              companyLicenses: {
                ...fields.companyLicenses,
                commercialRegisterExtract: {
                  value: value_
                    ? fields.companyLicenses.commercialRegisterExtract.value
                    : null,
                  isChecked: value_,
                },
              },
            };

            setFields({
              ...newState,
              validityState: {
                ...newState.validityState,
                form: InputChangeState.TOUCHED,
                section2: value_
                  ? InputChangeState.TOUCHED
                  : InputChangeState.UNTOUCHED,
              },
            });
            onChange(newState);
          }}
          value={fields.companyLicenses.commercialRegisterExtract.isChecked}
        />
        {fields.companyLicenses.commercialRegisterExtract.isChecked && (
          <FileInput
            inputClassName="mb-4 ml-6 auth__company-file-input"
            onChange={(value__) => {
              const newState = {
                ...fields,
                companyLicenses: {
                  ...fields.companyLicenses,
                  commercialRegisterExtract: {
                    isChecked:
                      fields.companyLicenses.commercialRegisterExtract
                        .isChecked,
                    value: value__,
                  },
                },
              };
              setFields(newState);
              onChange(newState);
            }}
            value={fields.companyLicenses.commercialRegisterExtract.value}
          />
        )}
      </div>
      <div className="block md:grid md:grid-cols-2 md:gap-5 pt-6">
        <p className="text-left">
          <button
            type="button"
            onClick={() => handleBack(1)}
            className="button bg-green-600 focus:outline-none w-full md:w-auto"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 h-4 right-0" />
            {t('COMPANY_REGISTRATION_MODAL_STEP_1_BUTTON_TEXT')}
          </button>
        </p>
        <p className="text-right">
          <button
            type="button"
            id="company-reg-next-button"
            onClick={isFormValid ? handleNext : undefined}
            className={`button focus:outline-none w-full md:w-auto ${
              fields.validityState.form === InputChangeState.TOUCHED &&
              isSection2Valid &&
              isSection1Valid
                ? 'cursor-pointer bg-green-600'
                : 'cursor-not-allowed bg-green-400'
            }`}
          >
            {t('COMPANY_REGISTRATION_SUBMIT_TEXT')}
          </button>
        </p>
      </div>
    </div>
  );
};

export default CompanyApprovalsFields;
