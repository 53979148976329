import { InputChangeState } from './textInput';

export enum RegistrationFormType {
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_CONFIRMATION = 'PASSWORD_RESET_CONFIRMATION',
  COMPANY = 'COMPANY',
}

export interface LoginFieldsState {
  email: string;
  password: string;
}

export interface UserSignupFieldsState {
  companyName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  hasAcceptedPrivacyPolicy: boolean;
}

export interface CompanyRegistrationState {
  dataFields: CompanyDataFieldsState;
  approvalsFields: CompanyApprovalsFieldsState;
}

export interface CompanyDataFieldsState {
  commercialRegisterEntry: string;
  taxId: string;
  taxExempt: string;
}

export interface CompanyApprovalsFieldsState {
  area: {
    pharmacy: boolean;
    wholesale: boolean;
    pharmaManufacturer: boolean;
    medicineManufacturer: boolean;
    pharmacyWithoutWholesale: boolean;
  };
  companyLicenses: {
    operatingLicense: {
      isChecked: boolean;
      value: File | null;
    };
    wholesalePermit: {
      isChecked: boolean;
      value: File | null;
    };
    manufacturingAuthorization: {
      isChecked: boolean;
      value: File | null;
    };
    commercialRegisterExtract: {
      isChecked: boolean;
      value: File | null;
    };
  };
  validityState: {
    section1: InputChangeState;
    section2: InputChangeState;
    form: InputChangeState;
  };
}

export interface PasswordResetFieldsState {
  email: string;
}

export interface PasswordResetConfirmationFieldsState {
  newPassword: string;
  confirmPassword: string;
}

export interface Tokens {
  access: string;
  refresh: string;
  passwordReset?: string;
}

export const companyRegistrationInitialValues: CompanyRegistrationState = {
  dataFields: {
    commercialRegisterEntry: '',
    taxId: '',
    taxExempt: '',
  },
  approvalsFields: {
    area: {
      pharmacy: false,
      wholesale: false,
      pharmaManufacturer: false,
      medicineManufacturer: false,
      pharmacyWithoutWholesale: false,
    },
    companyLicenses: {
      operatingLicense: {
        isChecked: false,
        value: null,
      },
      wholesalePermit: {
        isChecked: false,
        value: null,
      },
      manufacturingAuthorization: {
        isChecked: false,
        value: null,
      },
      commercialRegisterExtract: {
        isChecked: false,
        value: null,
      },
    },
    validityState: {
      section1: InputChangeState.UNTOUCHED,
      section2: InputChangeState.UNTOUCHED,
      form: InputChangeState.UNTOUCHED,
    },
  },
};

export const userSignupFieldsInitialValues: UserSignupFieldsState = {
  companyName: '',
  firstName: '',
  email: '',
  phoneNumber: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  hasAcceptedPrivacyPolicy: false,
};

export const passwordResetConfirmationInitialValues: PasswordResetConfirmationFieldsState = {
  newPassword: '',
  confirmPassword: '',
};

export interface SignupFieldsProps {
  errorMessage: string | null;
  handleSubmit: (fieldsData: UserSignupFieldsState) => void;
  // eslint-disable-next-line react/require-default-props
  initialValues?: UserSignupFieldsState;
  _toggleRegistrationForm: (formMeta: {
    type: RegistrationFormType | null;
  }) => { type: string } | undefined;
  _isEditMode: boolean;
  _setErrorMessage: (
    errorMessage: string | null,
  ) => { type: string; payload: string | null };
  isLoading: boolean;
}

export type RegistrationFieldsState =
  | LoginFieldsState
  | UserSignupFieldsState
  | CompanyRegistrationState
  | PasswordResetFieldsState
  | PasswordResetConfirmationFieldsState;
