/* eslint-disable no-case-declarations,@typescript-eslint/indent */
import { Reducer } from 'redux';
import { toast } from 'react-toastify';
import { ShoppingCartState } from '../../globals/models/redux';
import initialState from '../initialState';
import {
  AddBuyingMSV3deliverablesActionI,
  AddSellingMSV3deliverablesActionI,
  RemoveArticleFromShoppingCartActionI,
  SetArticlesActionI,
  SetIsAddingToShoppingCartActionI,
  SetIsLayoutLoadingActionI,
  ShoppingCartTableActions,
  ShoppingCartTableErrorActionI,
  SubmitShoppingCartDataActionI,
} from '../../globals/models/actions/shoppingCart';
import { ShoppingCartActionTypes } from '../actions/types/shoppingCart';
import i18next from '../../i18n';
import { ArticleOfferI } from '../../globals/models/components/dataTable';
import { getMSV3DeliverableSign } from '../../shared/utils/redux.utils';

export const shoppingCartReducer: Reducer<
  ShoppingCartState,
  ShoppingCartTableActions
> = (state = initialState.shoppingCartState, action) => {
  switch (action.type) {
    case ShoppingCartActionTypes.CART_ERROR:
      console.log(action.payload);
      // toast.error(action.payload);
      return {
        ...state,
        isContentLoading: false,
        errorMessage: (action as ShoppingCartTableErrorActionI).payload,
        isLayoutLoading: false,
        buyingProposals: [],
        sellingProposals: [],
        isAddingToCart: false,
      };
    case ShoppingCartActionTypes.SET_ARTICLES:
      return {
        ...state,
        sellingProposals: (action as SetArticlesActionI).payload
          .sellingProposals,
        buyingProposals: (action as SetArticlesActionI).payload.buyingProposals,
        isContentLoading: false,
        isLayoutLoading: false,
      };

    case ShoppingCartActionTypes.SET_IS_LAYOUT_LOADING:
      return {
        ...state,
        isLayoutLoading: (action as SetIsLayoutLoadingActionI).payload,
      };

    case ShoppingCartActionTypes.SET_IS_ADDING_TO_SHOPPING_CART:
      return {
        ...state,
        isAddingToCart: (action as SetIsAddingToShoppingCartActionI).payload
          .value,
        loadingIconRef: (action as SetIsAddingToShoppingCartActionI).payload
          .iconRef,
      };

    case ShoppingCartActionTypes.REMOVE_ITEM_FROM_SHOPPING_CART:
      const {
        proposalType,
        article,
      } = (action as RemoveArticleFromShoppingCartActionI).payload;

      toast.success(i18next.t('DONE_TEXT'));

      console.log('Reducer --->', proposalType);

      return {
        ...state,
        sellingProposals:
          proposalType === 'selling'
            ? (state.sellingProposals.filter(
                (el) => el.id !== article.id,
              ) as ArticleOfferI[])
            : (state.sellingProposals as ArticleOfferI[]),
        buyingProposals:
          proposalType === 'buying'
            ? (state.buyingProposals.filter(
                (el) => el.id !== article.id,
              ) as ArticleOfferI[])
            : (state.buyingProposals as ArticleOfferI[]),
        isAddingToCart: false,
      };

    case ShoppingCartActionTypes.SUBMIT_SHOPPING_CART:
      const {
        proposalType: cartSubmissionProposalType,
        rows: cartSubmissionRows,
      } = (action as SubmitShoppingCartDataActionI).payload;

      toast.success(i18next.t('SHOPPING_CART_DATA_SUBMISSION_SUCCESS_MESSAGE'));

      return {
        ...state,
        sellingProposals:
          cartSubmissionProposalType === 'selling'
            ? (state.sellingProposals.filter(
                (el) => !cartSubmissionRows.includes(el.id as string),
              ) as ArticleOfferI[])
            : (state.sellingProposals as ArticleOfferI[]),
        buyingProposals:
          cartSubmissionProposalType === 'buying'
            ? (state.buyingProposals.filter(
                (el) => !cartSubmissionRows.includes(el.id as string),
              ) as ArticleOfferI[])
            : (state.buyingProposals as ArticleOfferI[]),
      };

    case ShoppingCartActionTypes.RESET_TABLE:
      return initialState.shoppingCartState;

    case ShoppingCartActionTypes.ADD_SELLING_MSV3_DELIVERABLES:
      const sellingMSV3Deliverables = (action as AddSellingMSV3deliverablesActionI)
        .payload;

      return {
        ...state,
        sellingProposals: state.sellingProposals.map((el) => ({
          ...el,
          attributes: {
            ...el.attributes,
            MSV3deliverables: getMSV3DeliverableSign(
              el,
              sellingMSV3Deliverables.find(
                (del) => del.pzn === el.attributes.pzn,
              ),
            ),
          },
        })),
      };

    case ShoppingCartActionTypes.ADD_BUYING_MSV3_DELIVERABLES:
      const buyingMSV3Deliverables = (action as AddBuyingMSV3deliverablesActionI)
        .payload;

      return {
        ...state,
        buyingProposals: state.buyingProposals.map((el) => ({
          ...el,
          attributes: {
            ...el.attributes,
            MSV3deliverables: getMSV3DeliverableSign(
              el,
              buyingMSV3Deliverables.find(
                (del) => del.pzn === el.attributes.pzn,
              ),
            ),
          },
        })),
      };

    default:
      return state;
  }
};
