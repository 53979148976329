/* eslint-disable react/require-default-props,@typescript-eslint/indent */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { User, UserRole } from '../globals/models/data/user.model';

interface AuthGuardProps extends RouteProps {
  Component: any;
  isAuth: boolean;
  rest?: any;
  currentUser: User | null;
  roles?: UserRole[];
  redirectTo?: string;
}

const GuardedRoute = ({
  Component,
  isAuth,
  currentUser,
  roles,
  redirectTo = '/not-found',
  ...rest
}: AuthGuardProps) => {
  const { pathname } = useLocation();

  if (
    currentUser &&
    currentUser?.isLicenseDueOrInvalid &&
    pathname !== '/-/profile/licenses'
  ) {
    return (
      <Route
        {...rest}
        render={(props) => <Redirect to="/-/profile/licenses" {...props} />}
      />
    );
  }

  if (roles && roles.length !== 0) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuth &&
            currentUser &&
            roles &&
            roles.includes(currentUser.role) ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirectTo} />
          );
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuth ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = ({
  registrationState: { isAuth, currentUser },
}: StoreI) => ({
  isAuth,
  currentUser,
});

export default connect(mapStateToProps)(GuardedRoute);
