/* eslint-disable @typescript-eslint/indent */
import { Reducer } from 'redux';
import { toast } from 'react-toastify';
import initialState from '../initialState';
import { RegistrationState } from '../../globals/models/redux';
import {
  RegistrationActions,
  RegistrationErrorActionI,
  SetCurrentFormActionI,
  SetCurrentUserActionI,
  SetIsLoadingActionI,
  SetSignupSuccessActionI,
  SetTokensActionI,
  SetTokensStorageActionI,
} from '../../globals/models/actions/registration';
import { RegistrationActionTypes } from '../actions/types/registration';
import i18next from '../../i18n';
import { RegistrationFormType } from '../../globals/models/components/registrationForms';

export const registrationReducer: Reducer<
  RegistrationState,
  RegistrationActions
> = (state = initialState.registrationState, action) => {
  switch (action.type) {
    case RegistrationActionTypes.TOGGLE_SIGNUP_FORM:
      return {
        ...state,
        form: {
          type: RegistrationFormType.SIGNUP,
        },
        isLoading: false,
      };

    case RegistrationActionTypes.TOGGLE_LOGIN_FORM:
      return {
        ...state,
        form: {
          type: RegistrationFormType.LOGIN,
        },
        isLoading: false,
      };

    case RegistrationActionTypes.TOGGLE_PASSWORD_RESET_FORM:
      return {
        ...state,
        form: {
          type: RegistrationFormType.PASSWORD_RESET,
        },
        isLoading: false,
      };

    case RegistrationActionTypes.TOGGLE_COMPANY_REGISTRATION_FORM:
      return {
        ...state,
        form: {
          type: RegistrationFormType.COMPANY,
        },
        isLoading: false,
      };

    case RegistrationActionTypes.HIDE_FORM:
      return {
        ...state,
        form: {
          type: null,
        },
        isLoading: false,
      };

    case RegistrationActionTypes.SET_TOKENS_STORAGE:
      return {
        ...state,
        tokensStorage: (action as SetTokensStorageActionI).payload,
      };

    case RegistrationActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: (action as SetCurrentUserActionI).payload.user,
        isAuth: (action as SetCurrentUserActionI).payload.isAuth,
        storage: (action as SetCurrentUserActionI).payload.storage,
      };

    case RegistrationActionTypes.REGISTRATION_ERROR:
      if (
        (action as RegistrationErrorActionI).payload &&
        (action as RegistrationErrorActionI).payload !== '' &&
        (action as RegistrationErrorActionI).payload !== state.message
      ) {
        toast.error(
          i18next.t((action as RegistrationErrorActionI).payload as string),
        );
      }

      return {
        ...state,
        errorMessage: (action as RegistrationErrorActionI).payload,
        isLoading: false,
      };

    case RegistrationActionTypes.SET_SIGNUP_SUBMISSION_MESSAGE:
      return {
        ...state,
        message: (action as SetSignupSuccessActionI).payload,
        isLoading: false,
      };

    case RegistrationActionTypes.SET_TOKENS_VALUE:
      return {
        ...state,
        tokens: (action as SetTokensActionI).payload,
      };

    case RegistrationActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: (action as SetIsLoadingActionI).payload,
      };

    case RegistrationActionTypes.PASSWORD_RESET_INIT:
      return {
        ...state,
        form: { type: null },
        isLoading: false,
        errorMessage: null,
      };

    case RegistrationActionTypes.PASSWORD_RESET_COMPLETE:
      return {
        ...state,
        form: { type: RegistrationFormType.LOGIN },
        isLoading: false,
        tokens: { access: '', refresh: '', passwordReset: '' },
        errorMessage: null,
      };

    case RegistrationActionTypes.SET_CURRENT_FORM_TYPE:
      return {
        ...state,
        form: { type: (action as SetCurrentFormActionI).payload },
      };

    default:
      return state;
  }
};
