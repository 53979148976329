import React from 'react';
import {
  faCheckCircle,
  faDivide,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import GeneralIcon from './GeneralIcon';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../../globals/models/components/dataTable';

interface NotConfirmedIconsProps {
  row: ArticleOfferI;
}

const NotConfirmedIcons = ({ row }: NotConfirmedIconsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <GeneralIcon
        tipText={t('confirm')}
        faIcon={faCheckCircle}
        hoverClassName="text-primary"
        modalType={DataTableModalOptions.DEFAULT}
        row={row}
      />
      <GeneralIcon
        tipText={t('decline')}
        faIcon={faTimesCircle}
        hoverClassName="text-primary"
        modalType={DataTableModalOptions.DEFAULT}
        row={row}
      />
      {Boolean(row.attributes.min_piece_amount) && (
        <GeneralIcon
          tipText={t('Subset')}
          faIcon={faDivide}
          hoverClassName="text-primary"
          modalType={DataTableModalOptions.ARTICLE_SUBSET}
          row={row}
        />
      )}
    </>
  );
};

export default NotConfirmedIcons;
