import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordResetConfirmationFieldsState } from '../../../globals/models/components/registrationForms';
import TextInput from '../../Shared/layouts/TextInput';
import {
  InputChangeState,
  TextInputType,
} from '../../../globals/models/components/textInput';
import useEnterKeyListener from '../../Shared/hooks/useEnterKeyListener';
import { passwordResetConfirmationValidationSchema } from '../../../shared/validators/registration.schemas';

const PasswordResetConfirmation = ({
  initialValues,
  handleSubmit,
}: PasswordResetConfirmationProps) => {
  const { t } = useTranslation();
  useEnterKeyListener({
    querySelectorToExecuteClick: '#pwd-reset-submit-button',
  });

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validateOnChange
      initialValues={initialValues}
      validationSchema={passwordResetConfirmationValidationSchema}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
        } = props;

        const isFormTouched = Object.values(touched).length > 0;
        const isFormValid =
          isValid && Object.values(errors).length === 0 && isFormTouched;

        return (
          <div>
            <div className="pb-8">
              <TextInput
                forText="new-password"
                isRequired
                labelKey={t('PASSWORD_RESET.CONFIRMATION.NEW_PASSWORD_FIELD')}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.newPassword && errors.newPassword
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                name="newPassword"
                errorMessage={errors.newPassword}
                type={TextInputType.PASSWORD}
              />
              <TextInput
                forText="confirm-password"
                isRequired
                labelKey={t(
                  'PASSWORD_RESET.CONFIRMATION.CONFIRM_PASSWORD_FIELD',
                )}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                onBlur={handleBlur}
                changeState={
                  touched.confirmPassword && errors.confirmPassword
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                name="confirmPassword"
                errorMessage={errors.confirmPassword}
                type={TextInputType.PASSWORD}
              />
            </div>
            <div className="grid grid-cols-2">
              <p className="md:mt-0 mt-4">&nbsp;</p>
              <p className="text-right md:mt-0 mt-6">
                <button
                  id="pwd-reset-submit-button"
                  type="button"
                  className={`text-white text-lg p-2 rounded-sm px-4 ${
                    isFormValid
                      ? 'cursor-pointer bg-green-600'
                      : 'cursor-not-allowed bg-green-400'
                  }`}
                  onClick={isFormValid ? () => handleSubmit(values) : undefined}
                >
                  {t('PASSWORD_RESET_BUTTON_TEXT')}
                </button>
              </p>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

interface PasswordResetConfirmationProps {
  initialValues: PasswordResetConfirmationFieldsState;
  handleSubmit: (fields: PasswordResetConfirmationFieldsState) => void;
}

export default PasswordResetConfirmation;
