/* eslint-disable @typescript-eslint/indent,no-nested-ternary */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import { useRouteMatch } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { connect } from 'react-redux';
import { ArticleOfferI } from '../../../../globals/models/components/dataTable';
import generatePaginationDetails from '../../../../shared/utils/pagination';
import {
  Msv3PartnerI,
  MSV3PZNAvailability,
} from '../../../../globals/models/data/msv3.model';
import MSV3ArticleInfoTable from './MSV3ArticleInfoTable';
// import TrafficLight from '../../Shared/layouts/TrafficLight';

// static translation
/**
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_CONFIRMED')
 * t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.STATUS_DECLINED')
 */

const MSV3PZNDeliverySuggestionsModal = ({
  articleOffer,
  closeModal,
  currentPZNAvailability,
  ownMsv3PartnersList,
}: CompletedProposalsModalProps) => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();

  const data = currentPZNAvailability
    ? Object.keys(currentPZNAvailability).map((providerId) => {
        const results = currentPZNAvailability[providerId].find(
          (el) => el.pzn === articleOffer.attributes.pzn,
        );

        return {
          partnerName: ownMsv3PartnersList.find((el) => el.id === providerId)
            ?.name,
          deliverableAmount: results?.amount_deliverable,
        };
      })
    : [];

  const possiblePageLimits = [10, 20, 50, 100, 200];
  const [currentPageMeta, setCurrentPageMeta] = useState(1);
  const [currentPageLimitMeta, setCurrentPageLimitMeta] = useState(
    possiblePageLimits[0],
  );
  const metaOffset = (currentPageMeta - 1) * currentPageLimitMeta;

  const { pages } = generatePaginationDetails(
    data.length,
    metaOffset,
    currentPageLimitMeta,
  );

  const articleType = path.includes('offers')
    ? 'OFFER_TEXT'
    : 'PURCHASE_REQUEST_TEXT';

  return (
    <div className="h-screen-70 relative">
      <h1 className="text-center pt-6 text-md text-base font-bold">
        {t('DATA_TABLE.COMPLETED_PROPOSALS_MODAL.ARTICLE_INFO_SUB_TITLE')}
      </h1>
      <MSV3ArticleInfoTable articleOffer={articleOffer} />
      <h1 className="text-center pt-6 text-md md:text-base font-bold px-4">
        {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.SUB_TITLE', {
          productType:
            articleType === 'OFFER_TEXT'
              ? t('OFFER_TEXT')
              : t('PURCHASE_REQUEST_TEXT'),
          productName: articleOffer?.attributes.product_name,
        })}
      </h1>
      <div className="overflow-x-scroll border-b border-gray-200 sm:rounded-sm m-6 hide-scrollbar modal-list-table">
        <SwipeableViews axis="x" index={currentPageMeta} enableMouseEvents>
          <span className="opacity-0">&nbsp;</span>
          {new Array(pages).fill(null).map(() => (
            <table
              className="min-w-full divide-y divide-gray-200 table-fixed border"
              key={Math.random()}
            >
              <thead className="bg-grey-light">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.PROVIDER NAME')}
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    PZN
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center"
                  >
                    {t(
                      'DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DELIVERABLE_AMOUNT',
                    )}
                  </th>
                  {/* TODO: Uncomment to add the traffic light column */}
                  {/* <th */}
                  {/*  scope="col" */}
                  {/*  className="py-3 text-left text-xs font-medium font-bold uppercase tracking-wider w-1/5 whitespace-no-wrap text-center" */}
                  {/* > */}
                  {/*  {t( */}
                  {/*    'DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.DELIVERABLE_AMOUNT_SIGN', */}
                  {/*  )} */}
                  {/* </th> */}
                </tr>
              </thead>
              <tbody>
                {data.length !== 0 &&
                  data
                    .slice(metaOffset, metaOffset + currentPageLimitMeta)
                    .map((el, index) =>
                      el.deliverableAmount ? (
                        <tr
                          key={Math.random()}
                          className="odd:bg-grey-2 text-gray-800 text-base h-10"
                        >
                          <td className="text-center whitespace-no-wrap">
                            {el.partnerName}
                          </td>
                          <td className="text-center whitespace-no-wrap">
                            {articleOffer.attributes.pzn}
                          </td>
                          <td className="text-center whitespace-no-wrap">
                            {el.deliverableAmount
                              ? el.deliverableAmount.toLocaleString(
                                  i18n.language,
                                )
                              : ''}
                          </td>
                          {/* TODO: Uncomment to add the traffic light column */}
                          {/* <td className="text-center whitespace-no-wrap"> */}
                          {/*  <TrafficLight */}
                          {/*    RedOn={el.deliverableAmount === null} */}
                          {/*    YellowOn={ */}
                          {/*      el.deliverableAmount < */}
                          {/*      articleOffer.attributes.piece_amount */}
                          {/*    } */}
                          {/*    GreenOn={ */}
                          {/*      el.deliverableAmount >= */}
                          {/*      articleOffer.attributes.piece_amount */}
                          {/*    } */}
                          {/*    Size={20} */}
                          {/*  /> */}
                          {/* </td> */}
                        </tr>
                      ) : (
                        ''
                      ),
                    )}
              </tbody>
            </table>
          ))}
        </SwipeableViews>
      </div>
      {data.length === 0 && (
        <div className="w-full h-10 flex flex-row justify-center whitespace-no-wrap">
          <div>
            {t('DATA_TABLE.MSV3_PZN_SUGGESTIONS_MODAL.NO_DELIVERABLES')}
          </div>
        </div>
      )}
      <div className="w-full flex flex-row justify-between mb-6 pl-4 px-4 overflow-x-scroll hide-scrollbar">
        <div className="w-full flex flex-row justify-between pl-6 px-4">
          <div className="flex">
            <div className="pt-2 whitespace-no-wrap mr-2">{`${t(
              'TABLE_ITEMS_PER_PAGE',
            )}`}</div>
            <Select
              name="timeslot"
              id="time-choices"
              className="border rounded-sm w-full h-8 mt-1 px-1"
              defaultValue={possiblePageLimits[0]}
              onChange={({ target: { value } }) => {
                setCurrentPageLimitMeta(value as number);
              }}
              value={currentPageLimitMeta}
              disableUnderline
              native
            >
              {possiblePageLimits.map((el) => (
                <option
                  value={el}
                  key={el}
                  className="hover:bg-grey-4 cursor-pointer"
                >
                  {el}
                </option>
              ))}
            </Select>
          </div>
          <div className="pt-2">
            <Pagination
              count={pages}
              onChange={(e, page) => {
                if (page !== currentPageMeta) {
                  setCurrentPageMeta(page);
                }
              }}
            />
          </div>
        </div>
        <div className="text-right pt-1">
          <button
            type="button"
            className="button m-4 ml-6 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
            onClick={() => closeModal()}
          >
            {t('CLOSE_TEXT')}
          </button>
        </div>
      </div>
    </div>
  );
};

interface CompletedProposalsModalProps {
  articleOffer: ArticleOfferI;
  closeModal: () => void;
  currentPZNAvailability: MSV3PZNAvailability | null;
  ownMsv3PartnersList: Msv3PartnerI[];
}

const mapStateToProps = ({
  msv3State: {
    currentPZNAvailability,
    partnersList: { own },
  },
}: StoreI) => ({
  currentPZNAvailability,
  ownMsv3PartnersList: own,
});

export default connect(mapStateToProps)(MSV3PZNDeliverySuggestionsModal);
