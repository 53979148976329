import React, { useState } from 'react';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  addItemsToShoppingCart,
  setIsAddingToShoppingCart,
} from '../../../../store/actions/creators/dataTable';
import { ArticleOfferI } from '../../../../globals/models/components/dataTable';

interface ShoppingCartActionProps {
  tipText: string;
  row: ArticleOfferI;
  _addItemToShoppingCart: (row: ArticleOfferI[]) => Promise<{ type: string }>;
  _setIsAddingToShoppingCart: (
    value: boolean,
    iconRef: number,
  ) => { type: string; payload: { value: boolean; iconRef: number } };
  isLoading: boolean;
  iconRef: number | null;
}

const ShoppingCartIcon = ({
  tipText,
  row,
  _addItemToShoppingCart,
  _setIsAddingToShoppingCart,
  isLoading,
  iconRef,
}: ShoppingCartActionProps) => {
  const [ref] = useState(Math.random());
  return (
    <Tooltip
      title={tipText}
      placement="top-end"
      className="hover:text-primary cursor-pointer"
    >
      <button
        type="button"
        onClick={() => {
          _setIsAddingToShoppingCart(true, ref);
          _addItemToShoppingCart([row]);
        }}
        disabled={isLoading}
        className={`${isLoading && 'cursor-not-allowed'}`}
      >
        {isLoading && iconRef === ref ? (
          <CircularProgress size={15} className="ml-2" />
        ) : (
          <FontAwesomeIcon icon={faCartPlus} className="ml-2" />
        )}
      </button>
    </Tooltip>
  );
};

const mapStateToProps = ({
  dataTableState: { isAddingToShoppingCart, loadingIconRef },
}: StoreI) => ({
  isLoading: isAddingToShoppingCart,
  iconRef: loadingIconRef,
});

export default connect(mapStateToProps, {
  _addItemToShoppingCart: addItemsToShoppingCart,
  _setIsAddingToShoppingCart: setIsAddingToShoppingCart,
})(ShoppingCartIcon);
