import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOCALES } from '../../../shared/constants/constantChoices';

// eslint-disable-next-line react/require-default-props
const LanguageSelector = ({ classes }: { classes?: string }) => {
  const { t, i18n } = useTranslation();

  const handleLocaleSelection = (locale: string) => {
    if (locale !== 'none') {
      i18n.changeLanguage(locale);
      localStorage.setItem('REACT_APP_DEFAULT_LANGUAGE_KEY', locale);
    }
  };

  return (
    <select
      name=""
      id={t('Language')}
      className={`text-primary outline-none px-2 py-1 rounded-md md:ml-4 md:mt-0 mt-2 cursor-pointer ${classes}`}
      onChange={(e) => {
        handleLocaleSelection(e.target.value);
      }}
      data-testid="language-select"
      value={i18n.language}
    >
      {LOCALES.map((el) => (
        <option key={el.value} value={el.value}>
          {el.text}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
