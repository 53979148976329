export enum ShoppingCartActionTypes {
  CART_ERROR = 'ShoppingCart/CART_ERROR',
  SET_IS_LAYOUT_LOADING = 'ShoppingCart/SET_IS_LAYOUT_LOADING',
  SET_SHOPPING_CART_ARTICLES = 'ShoppingCart/SET_SHOPPING_CART_ARTICLES',
  RESET_TABLE = 'ShoppingCart/RESET_TABLE',
  REMOVE_ITEM_FROM_SHOPPING_CART = 'ShoppingCart/REMOVE_ITEM_FROM_SHOPPING_CART',
  SUBMIT_SHOPPING_CART = 'ShoppingCart/SUBMIT_SHOPPING_CART',
  SET_ARTICLES = 'ShoppingCart/SET_ARTICLES',
  SET_IS_ADDING_TO_SHOPPING_CART = 'ShoppingCart/SET_IS_ADDING_TO_SHOPPING_CART',
  ADD_SELLING_MSV3_DELIVERABLES = 'ShoppingCart/ADD_SELLING_MSV3_DELIVERABLES',
  ADD_BUYING_MSV3_DELIVERABLES = 'ShoppingCart/ADD_BUYING_MSV3_DELIVERABLES',
}
