import {
  ArticleI,
  ShippingCostsHandlerOptions,
} from '../../globals/models/data/article.model';

/**
 * DT_SCREEN_RATIO is an arbitrary constant that is the most valid for
 * the calculation DT_SCREEN_RATIO = tableCellWidth / widow.innerWidth to make the
 * table render a cell that based on the screen looks reasonable and fits the content
 */
export const DT_SCREEN_RATIO = 0.061;

export const DUMMY_ARTICLE: ArticleI = {
  article_location_country: 'GERMANY',
  aep: '7.4',
  aep_discount: 210.810811,
  aep_marge: 210.810811,
  apu: '6.29',
  apu_discount: 265.659777,
  calculated_aek_discount: 210.81,
  article_status: 'BRUCH',
  avp: 0,
  best_offer_possible: true,
  charge_number: 2,
  expiration_date: '2021-03-01',
  end_period_of_validity: '2020-12-19',
  is_outdated: false,
  lq_url: '',
  matches_exist: false,
  min_piece_amount: 12,
  options_type: 'EDITABLE',
  payment_deadline: 'DAYS_7',
  payment_deadline_label: '7 Tage nach Wareneingang',
  proposals_exist: false,
  piece_amount: 12,
  price: '23',
  product_name: 'HANDSCHUHE EINM LA PUFR KL',
  pzn: '08795329',
  selling_date: null,
  trade_type: 'SELLING',
  relative_exp_date: 2,
  id: '234233-4323sf2',
  proposals: [],
  own_proposals: [],
  shipping_costs_carrier: ShippingCostsHandlerOptions.NOT_DEFINED,
  matchmaking: [],
  favorite: false,
};

export const DEFAULT_COLUMNS_CONFIG = [
  {
    id: 'Matches',
    config_id: 'Matches',
    Header: 'Matches',
    displayText: 'Matches',
    accessor: 'attributes.end_period_of_validity',
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    isSelected: true,
    sortId: 'end_period_of_validity', // Can vary from the accessor for some columns
    sortable: false,
  },
  {
    id: 'STATUS',
    config_id: 'Status',
    Header: 'STATUS',
    displayText: 'Matches',
    accessor: 'attributes.end_period_of_validity',
    minWidth: 110,
    width: 110,
    maxWidth: 110,
    isSelected: true,
    sortId: 'end_period_of_validity', // Can vary from the accessor for some columns
    sortable: false,
  },
  {
    id: 'TABLE_COLUMN_TIMELINE',
    config_id: 'Time frame',
    Header: 'TABLE_COLUMN_TIMELINE',
    displayText: 'TABLE_COLUMN_TIMELINE',
    accessor: 'attributes.end_period_of_validity',
    width: 110,
    isSelected: true,
    sortId: 'end_period_of_validity', // Can vary from the accessor for some columns
    sortable: false,
  },
  {
    id: 'SHIPPING_COSTS_HANDLER',
    config_id: 'SHIPPING_COSTS_HANDLER',
    Header: 'SHIPPING_COSTS_HANDLER',
    displayText: 'SHIPPING_COSTS_HANDLER',
    accessor: 'shipping_costs_carrier',
    width: 110,
    isSelected: true,
    sortId: 'shipping_costs_carrier', // Can vary from the accessor for some columns
    sortable: false,
  },
  {
    id: 'ARTICLE_LOCATION_COUNTRY',
    config_id: 'ARTICLE_LOCATION_COUNTRY',
    Header: 'ARTICLE_LOCATION_COUNTRY',
    displayText: 'ARTICLE_LOCATION_COUNTRY',
    accessor: 'article_location_country',
    width: 110,
    isSelected: true,
    sortId: 'article_location_country', // Can vary from the accessor for some columns
    sortable: false,
  },
  {
    id: 'PZN',
    config_id: 'PZN',
    isSelected: true,
    displayText: 'PZN',
    Header: 'PZN',
    accessor: 'attributes.pzn',
    sortId: 'pzn',
    sortable: true,
  },
  {
    id: 'TABLE_COLUMN_ARTICLE_NAME',
    config_id: 'Name',
    isSelected: true,
    displayText: 'TABLE_COLUMN_ARTICLE_NAME',
    Header: 'TABLE_COLUMN_ARTICLE_NAME',
    accessor: 'attributes.product_name',
    sortId: 'product_name',
    sortable: true,
  },
  {
    id: 'TABLE_COLUMN_EXPIRATION',
    config_id: 'Expiration',
    isSelected: true,
    displayText: 'TABLE_COLUMN_EXPIRATION',
    Header: 'TABLE_COLUMN_EXPIRATION',
    accessor: 'attributes.expiration_date',
    sortId: 'expiration_date',
    sortable: true,
  },
  {
    id: 'TABLE_COLUMN_REL_EXPIRATION',
    config_id: 'Expiration in Months',
    isSelected: true,
    displayText: 'TABLE_COLUMN_REL_EXPIRATION',
    Header: 'TABLE_COLUMN_REL_EXPIRATION',
    accessor: 'attributes.relative_exp_date',
    sortable: true,
    sortId: 'expiration_date',
  },
  {
    id: 'TABLE_COLUMN_BATCH',
    config_id: 'Batch',
    isSelected: true,
    displayText: 'TABLE_COLUMN_BATCH',
    Header: 'TABLE_COLUMN_BATCH',
    accessor: 'attributes.charge_number',
    sortable: true,
    sortId: 'charge_number',
  },
  {
    id: 'TABLE_COLUMN_MIN_QUANTITY',
    config_id: 'Minimum quantity',
    isSelected: true,
    displayText: 'TABLE_COLUMN_MIN_QUANTITY',
    Header: 'TABLE_COLUMN_MIN_QUANTITY',
    accessor: 'attributes.min_piece_amount',
    sortable: true,
    sortId: 'min_piece_amount',
  },
  {
    id: 'TABLE_COLUMN_QUANTITY',
    config_id: 'Quantity',
    isSelected: true,
    displayText: 'TABLE_COLUMN_QUANTITY',
    Header: 'TABLE_COLUMN_QUANTITY',
    accessor: 'attributes.piece_amount',
    sortId: 'piece_amount',
    sortable: true,
  },
  {
    id: 'TABLE_COLUMN_PRICE_PE',
    config_id: 'Price p.E.',
    isSelected: true,
    displayText: 'TABLE_COLUMN_PRICE_PE',
    Header: 'TABLE_COLUMN_PRICE_PE',
    accessor: 'attributes.price',
    sortId: 'price',
    sortable: true,
  },
  {
    id: 'AEK / APU',
    config_id: 'AEP / APU',
    isSelected: true,
    displayText: 'AEK / APU',
    Header: 'AEK / APU',
    accessor: 'attributes.aep',
    sortId: 'aep',
    sortable: true,
  },
  {
    id: 'AEK+%',
    config_id: 'AEK+%',
    isSelected: true,
    displayText: 'AEK+%',
    Header: 'AEK_PERCENTAGE',
    accessor: 'attributes.calculated_aek_discount',
    sortId: 'aep_discount',
    sortable: true,
  },
  {
    id: 'TABLE_COLUMN_MSV3_TRAFFIC_LIGHT',
    config_id: 'TRAFFIC_LIGHT',
    Header: 'TABLE_COLUMN_MSV3_TRAFFIC_LIGHT',
    accessor: 'attributes.calculated_aek_discount',
    sortable: false,
    displayText: 'TABLE_COLUMN_MSV3_TRAFFIC_LIGHT',
    isSelected: true,
    // NOTE: Add this as dummy value to avoid that code fails due to missing typing of `DEFAULT_COLUMNS_CONFIG`
    sortId: 'msv3_traffic_light',
  },
];

export const PAGE_SIZE_CHOICES = [25, 50, 100];

export const tableNumberColumnsIds = [
  'calculated_aek_discount',
  'aep',
  'price',
];

export enum MSV3CheckoutSteps {
  PREFERRED_AMOUNTS = 1,
  CHECK_BEFORE_BUYING = 2,
  RESULTS_DISPLAY = 3,
}
