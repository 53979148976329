import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../globals/models/components/dataTable';
import {
  deleteArticle,
  setIsTableModalLoading,
  setTableModal,
} from '../../../store/actions/creators/dataTable';

const DeleteArticleModal = ({
  currentModalArticle,
  _setIsTableModalLoading,
  isLoading,
  _setTableModal,
  _deleteArticle,
}: SubsetModalProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const activeColor = path.includes('offers') ? 'bg-primary' : 'bg-green-600';
  const inactiveColor = path.includes('offers')
    ? 'bg-primary-disabled'
    : 'bg-green-400';
  const articleType = path.includes('offers')
    ? 'OFFER_TEXT'
    : 'PURCHASE_REQUEST_TEXT';

  return (
    <div className="w-full">
      <div className="p-6 text-base">
        <Trans
          i18nKey="DATA_TABLE.DELETE_MODAL.CONTENT.QUESTION"
          values={{
            productType:
              articleType === 'OFFER_TEXT'
                ? t('OFFER_TEXT')
                : t('PURCHASE_REQUEST_TEXT'),
            productName: currentModalArticle?.attributes.product_name,
          }}
          components={{ italic: <i />, bold: <strong /> }}
        />
      </div>
      <div className="w-full flex flex-row justify-between pt-4 border-t px-4">
        {currentModalArticle && (
          <button
            type="button"
            className="focus:outline-none button mr-4 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
            onClick={() => {
              _setIsTableModalLoading(false);
              _setTableModal(null, null);
            }}
          >
            {t('CANCEL_TEXT')}
          </button>
        )}
        <button
          type="button"
          className={`focus:outline-none button ${
            !isLoading ? activeColor : inactiveColor
          }`}
          onClick={() => {
            if (currentModalArticle) {
              _setIsTableModalLoading(true);
              _deleteArticle([currentModalArticle]);
            } else {
              _setIsTableModalLoading(false);
              _setTableModal(null, null);
            }
          }}
          disabled={isLoading}
        >
          {currentModalArticle && t('YES_TEXT')}
          {isLoading && (
            <CircularProgress
              style={{ color: 'white' }}
              size={15}
              className="ml-2"
            />
          )}
        </button>
      </div>
    </div>
  );
};

interface SubsetModalProps {
  currentModalArticle: ArticleOfferI | null;
  _setIsTableModalLoading: (
    value: boolean,
  ) => { type: string; payload: boolean };
  isLoading: boolean;
  _setTableModal: (
    value: DataTableModalOptions | null,
    title: string | null,
  ) => {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  };
  _deleteArticle: (
    value: ArticleOfferI[],
  ) => Promise<{ type: string; payload: ArticleOfferI[] | string }>;
}

const mapStateToProps = ({
  dataTableState: {
    currentModal: { article, isLoading },
  },
}: StoreI) => ({
  currentModalArticle: article,
  isLoading,
});

export default connect(mapStateToProps, {
  _setIsTableModalLoading: setIsTableModalLoading,
  _setTableModal: setTableModal,
  _deleteArticle: deleteArticle,
})(DeleteArticleModal);
