import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
import i18next from 'i18next';
import numeral from 'numeral';

import TextInput from '../../Shared/layouts/TextInput';
import {
  InputChangeState,
  TextInputType,
} from '../../../globals/models/components/textInput';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../globals/models/components/dataTable';
import NumberInput from '../../Shared/layouts/NumberInput';
import {
  calculateDiscount,
  calculatePriceFromDiscount,
} from '../../../shared/utils/articleEditor';
import { round } from '../../../shared/utils/numbers.utils';

import {
  setIsTableModalLoading,
  setTableModal,
  submitABid,
} from '../../../store/actions/creators/dataTable';
import { bidModalValidationSchema } from '../../../shared/validators/dataTable';

type FieldsT = {
  articleSubset: string;
  unitPrice: string;
  totalPrice: string;
  aepDiscount: string;
  apuDiscount: string;
};

const BidModal = ({
  currentModalArticle,
  _setIsTableModalLoading,
  isLoading,
  _setTableModal,
  _submitABid,
}: BidModalProps) => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();
  const activeColor = path.includes('offers') ? 'bg-primary' : 'bg-green-600';
  const inactiveColor = path.includes('offers')
    ? 'bg-primary-disabled cursor-not-allowed'
    : 'bg-green-400 cursor-not-allowed';
  const minimumAmount =
    (currentModalArticle?.attributes.min_piece_amount as number) ||
    (currentModalArticle?.attributes.piece_amount as number);

  numeral.locale(i18n.language === 'en' ? 'en-gb' : 'de');

  return (
    <div className="w-full">
      <Formik
        onSubmit={() => {}}
        validateOnChange
        initialValues={{
          articleSubset: `${currentModalArticle?.attributes.piece_amount}`,
          unitPrice: currentModalArticle?.attributes.price,
          totalPrice: `${
            Number(currentModalArticle?.attributes.piece_amount) *
            Number(currentModalArticle?.attributes.price)
          }`,
          aepDiscount: `${currentModalArticle?.attributes.calculated_aek_discount}`,
          apuDiscount: `${currentModalArticle?.attributes.apu_discount}`,
        }}
        validationSchema={bidModalValidationSchema(
          minimumAmount,
          currentModalArticle?.attributes.piece_amount as number,
        )}
      >
        {(props) => {
          const {
            touched,
            errors,
            handleBlur,
            isValid,
            values,
            setValues,
          } = props;

          return (
            <>
              <div className="pl-4 px-4 pb-5">
                <TextInput
                  labelKey={t('ARTICLE_BID_TITLE')}
                  forText="articleSubset"
                  type={TextInputType.NUMBER}
                  isRequired={false}
                  onBlur={handleBlur}
                  classes={{
                    container: '',
                    input: 'border border-gray-500 rounded-sm w-full h-8 mt-2',
                  }}
                  onChange={(value) => {
                    setValues({
                      ...values,
                      totalPrice: `${Number(value) * Number(values.unitPrice)}`,
                      articleSubset: value,
                    });
                  }}
                  changeState={
                    touched.articleSubset && errors.articleSubset
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={errors.articleSubset}
                  value={`${values.articleSubset}`}
                />
                <span className={`text-sm ${!isValid && 'c-no-drop'}`}>
                  {t('TABLE_SUBSET_MODAL.QUANTITY_LABEL', {
                    minimumAmount: numeral(minimumAmount).format(),
                    maximumAmount: numeral(
                      currentModalArticle?.attributes.piece_amount as number,
                    ).format(),
                  })}
                </span>

                <div className="md:grid block md:grid-cols-2 gap-10">
                  <div>
                    <NumberInput
                      labelKey={t('UNIT_PRICE_TEXT')}
                      forText="sales-per-unit"
                      isRequired
                      classes={{
                        input:
                          'border border-gray-500 rounded-sm w-full h-8 mt-2',
                      }}
                      onChange={(value) => {
                        const cleanedValue = Number(value);

                        setValues({
                          ...values,
                          unitPrice: `${cleanedValue}`,
                          totalPrice: `${
                            Number(values.articleSubset) * cleanedValue
                          }`,
                          apuDiscount: `${calculateDiscount(
                            cleanedValue,
                            currentModalArticle?.attributes.apu
                              ? Number(currentModalArticle?.attributes.apu)
                              : 0,
                          )}`,
                          aepDiscount: `${calculateDiscount(
                            cleanedValue,
                            currentModalArticle?.attributes.aep
                              ? Number(currentModalArticle?.attributes.aep)
                              : 0,
                          )}`,
                        });
                      }}
                      value={`${round(Number(values.unitPrice), 2)}`}
                      suffix=" €"
                      allowNegative={false}
                      errorMessage={errors.unitPrice}
                    />
                    <span className="text-sm whitespace-no-wrap">
                      {t('TABLE_BID_MODAL.ORIGINAL_PRICE_LABEL')}:{' '}
                      <NumberFormat
                        value={round(
                          Number(currentModalArticle?.attributes.price),
                          2,
                        )}
                        thousandSeparator={
                          i18next.language === 'de' ? '.' : ','
                        }
                        decimalSeparator={i18next.language === 'de' ? ',' : '.'}
                        className="italic font-light text-sm inline bg-white"
                        suffix=" €"
                        disabled
                      />
                    </span>
                  </div>
                  <div>
                    <NumberInput
                      labelKey={t('TOTAL_PRICE_TEXT')}
                      forText="min-total-proceeds-"
                      isRequired
                      classes={{
                        input:
                          'border border-gray-500 rounded-sm w-full h-8 mt-2',
                      }}
                      value={`${round(Number(values.totalPrice), 2)}`}
                      type={TextInputType.NUMBER}
                      suffix=" €"
                      errorMessage={errors.totalPrice}
                      onChange={(value) => {
                        const cleanedValue = Number(value);

                        const unitPrice =
                          cleanedValue / Number(values.articleSubset);

                        setValues({
                          ...values,
                          totalPrice: `${cleanedValue}`,
                          unitPrice: `${unitPrice}`,
                          apuDiscount: `${calculateDiscount(
                            unitPrice,
                            currentModalArticle?.attributes.apu
                              ? Number(currentModalArticle?.attributes.apu)
                              : 0,
                          )}`,
                          aepDiscount: `${calculateDiscount(
                            unitPrice,
                            currentModalArticle?.attributes.aep
                              ? Number(currentModalArticle?.attributes.aep)
                              : 0,
                          )}`,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="md:grid block md:grid-cols-2 gap-10">
                  <div>
                    <NumberInput
                      labelKey={t('ARTICLE_EDITOR_DISCOUNT_PREMIUM_AEK')}
                      forText="discount-premium-aek"
                      isRequired={false}
                      classes={{
                        input:
                          'border border-gray-500 rounded-sm w-full h-8 mt-2',
                      }}
                      value={`${round(Number(values.aepDiscount), 2)}`}
                      type={TextInputType.NUMBER}
                      suffix=" %"
                      onChange={(value) => {
                        if (value !== '-') {
                          const unitPrice = calculatePriceFromDiscount(
                            Number(value),
                            Number(currentModalArticle?.attributes.aep),
                          );

                          setValues({
                            ...values,
                            totalPrice: `${
                              unitPrice * Number(values.articleSubset)
                            }`,
                            unitPrice: `${unitPrice}`,
                            apuDiscount: `${calculateDiscount(
                              unitPrice,
                              unitPrice
                                ? Number(currentModalArticle?.attributes.apu)
                                : 0,
                            )}`,
                            aepDiscount: value,
                          });
                        }
                      }}
                      errorMessage={errors.aepDiscount}
                      minValue={-100}
                    />
                    <span className="text-sm">
                      {t('AEK of the article')}:{' '}
                      <NumberFormat
                        value={Number(
                          round(Number(currentModalArticle?.attributes.aep), 2),
                        )}
                        thousandSeparator={
                          i18next.language === 'de' ? '.' : ','
                        }
                        decimalSeparator={i18next.language === 'de' ? ',' : '.'}
                        className="italic font-light text-sm inline bg-white"
                        suffix=" €"
                        disabled
                      />
                    </span>
                  </div>
                  <div>
                    <NumberInput
                      labelKey={t('ARTICLE_EDITOR_DISCOUNT_PREMIUM_APU')}
                      forText="discount-premium-apu"
                      isRequired={false}
                      classes={{
                        input:
                          'border border-gray-500 rounded-sm w-full h-8 mt-2',
                      }}
                      value={`${round(Number(values.apuDiscount), 2)}`}
                      type={TextInputType.NUMBER}
                      suffix=" %"
                      onChange={(value) => {
                        if (value !== '-') {
                          const unitPrice = calculatePriceFromDiscount(
                            Number(value),
                            Number(currentModalArticle?.attributes.apu),
                          );

                          setValues({
                            ...values,
                            totalPrice: `${
                              unitPrice * Number(values.articleSubset)
                            }`,
                            unitPrice: `${unitPrice}`,
                            apuDiscount: value,
                            aepDiscount: `${calculateDiscount(
                              unitPrice,
                              unitPrice
                                ? Number(currentModalArticle?.attributes.aep)
                                : 0,
                            )}`,
                          });
                        }
                      }}
                      errorMessage={errors.apuDiscount}
                      minValue={-100}
                    />
                    <span className={`text-sm ${!isValid && 'c-no-drop'}`}>
                      {t('APU of the article')}:{' '}
                      <NumberFormat
                        value={Number(
                          round(Number(currentModalArticle?.attributes.apu), 2),
                        )}
                        thousandSeparator={
                          i18next.language === 'de' ? '.' : ','
                        }
                        decimalSeparator={i18next.language === 'de' ? ',' : '.'}
                        className="italic font-light text-sm inline bg-white"
                        suffix=" €"
                        disabled
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-row justify-between pt-4 border-t px-4">
                {currentModalArticle && (
                  <button
                    type="button"
                    className="focus:outline-none button mr-4 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
                    onClick={() => {
                      _setIsTableModalLoading(false);
                      _setTableModal(null, null);
                    }}
                  >
                    {t('Cancel')}
                  </button>
                )}
                <button
                  type="button"
                  className={`focus:outline-none button ${
                    isValid ? activeColor : inactiveColor
                  }`}
                  onClick={() => {
                    if (currentModalArticle) {
                      _setIsTableModalLoading(true);
                      _submitABid(
                        currentModalArticle as ArticleOfferI,
                        values as FieldsT,
                      );
                    } else {
                      _setIsTableModalLoading(false);
                      _setTableModal(null, null);
                    }
                  }}
                  disabled={!isValid}
                >
                  {currentModalArticle ? t('Add to shopping cart') : t('Close')}
                  {isLoading && (
                    <CircularProgress
                      style={{ color: 'white' }}
                      size={15}
                      className="ml-2"
                    />
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

interface BidModalProps {
  currentModalArticle: ArticleOfferI | null;
  _submitABid: (
    article: ArticleOfferI,
    chosenData: FieldsT,
  ) => Promise<{
    type: string;
    payload: { article: ArticleOfferI } | string;
  } | void>;
  _setIsTableModalLoading: (
    value: boolean,
  ) => { type: string; payload: boolean };
  isLoading: boolean;
  _setTableModal: (
    value: DataTableModalOptions | null,
    title: string | null,
  ) => {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  };
}

const mapStateToProps = ({
  dataTableState: {
    currentModal: { article, isLoading },
  },
}: StoreI) => ({
  currentModalArticle: article,
  isLoading,
});

export default connect(mapStateToProps, {
  _submitABid: submitABid,
  _setIsTableModalLoading: setIsTableModalLoading,
  _setTableModal: setTableModal,
})(BidModal);
