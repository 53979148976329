export enum UserRole {
  ADMIN = 'Admin',
  NORMAL = 'Normal',
}

export class User {
  public readonly isLicenseDueOrInvalid: boolean;

  constructor(
    public user_id: number,
    public email: string,
    public firstName: string,
    public lastName: string,
    public username: string,
    public role: UserRole,
    public license_renewal_due: string,
    public readonly has_supplier_qualification: boolean,
    public readonly msv3_buy_own: boolean,
    public readonly consulting: boolean,
  ) {
    if (role === UserRole.ADMIN) {
      this.isLicenseDueOrInvalid = false;
    } else {
      this.isLicenseDueOrInvalid =
        !license_renewal_due || new Date(license_renewal_due) < new Date();
    }
  }
}
