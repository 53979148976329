import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toggleRegistrationForm } from '../../store/actions/creators/registration';
import { RegistrationState } from '../../globals/models/redux';
import { RegistrationFormType } from '../../globals/models/components/registrationForms';

interface HomeAboutProps {
  _toggleRegistrationForm: (
    formMeta: RegistrationState['form'],
  ) => { type: string } | undefined;
}

const HomeAbout = ({ _toggleRegistrationForm }: HomeAboutProps) => {
  const { t } = useTranslation();
  const youtubeCommercialLink =
    'https://www.youtube.com/embed/lAECkM7f_Po?autoplay=1&mute=1&' +
    'controls=1&playsinline=1&showinfo=0&rel=0&' +
    'iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1';

  return (
    <div className="container ml-auto mr-auto px-5 lg:px-10">
      <div className="md:flex bloc md:justify-between md:py-20 py-16 md:pl-3 pl-6 pr-3">
        <div className="md:w-1/2 w-full">
          <p className="text-xl md:text-2xl xl:text-3xl  tracking-wider md:pr-8">
            <strong>Tramedix</strong>
            {t('HOME_PAGE_SLOGAN')}
          </p>
          <br />
          <p className="text-lg tracking-wide leading-8 md:pr-6">
            {t('HOME_PAGE_SLOGAN_2')}
          </p>
          <br />
          <br />
          <button
            type="button"
            className="button"
            onClick={() =>
              _toggleRegistrationForm({ type: RegistrationFormType.SIGNUP })
            }
          >
            {t('SIGNUP_TEXT')}
          </button>
        </div>
        <div className="md:w-1/2 w-full">
          <iframe
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            width="100%"
            height="100%"
            src={youtubeCommercialLink}
            id="widget2"
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  _toggleRegistrationForm: toggleRegistrationForm,
})(HomeAbout);
