export enum MSV3ActionTypes {
  SET_PARTNERS = 'MSV3/SET_PARTNERS',
  SET_IS_LOADING = 'MSV3/SET_IS_LOADING',
  SET_IS_LOADING_SINGLE_PARTNER_REQUEST = 'MSV3/SET_IS_LOADING_SINGLE_PARTNER_REQUEST',
  DELETE_PARTNER_CONNECTION = 'MSV3/DELETE_PARTNER_CONNECTION',
  SET_IS_REGISTERING_NEW_PARTNER = 'MSV3/SET_IS_REGISTERING_PARTNERS',
  REGISTER_NEW_PARTNER = 'MSV3/REGISTER_NEW_PARTNER',
  SET_PZN_AVAILABILITY = 'MSV3/SET_PZN_AVAILABILITY',
  SET_MSV3_CHECKOUT_RESULTS = 'MSV3/SET_MSV3_CHECKOUT_RESULTS',
  MSV3_ERROR = 'MSV3/MSV3_ERROR',
  GET_MSV3_PZN_SUGGESTION = 'MSV3/PZN_SUGGESTION',
  SET_MSV3_OPEN_TRANSACTIONS = 'MSV3/SET_MSV3_OPEN_TRANSACTIONS',
  SET_MSV3_TRANSACTIONS = 'MSV3/SET_MSV3_TRANSACTIONS',
  GET_MSV3_TRANSACTIONS = 'MSV3/GET_MSV3_TRANSACTIONS',
  GET_MSV3_OPEN_TRANSACTIONS = 'MSV3/GET_MSV3_OPEN_TRANSACTIONS',
  DELETE_MSV3_OPEN_TRANSACTIONS = 'MSV3/DELETE_MSV3_OPEN_TRANSACTIONS',
  DELETE_MSV3_TRANSACTIONS = 'MSV3/DELETE_MSV3_TRANSACTIONS',
  ADD_PZN_AVAILABILITY = 'MSV3/ADD_PZN_AVAILABILITY',
}
