/* eslint-disable @typescript-eslint/indent,@typescript-eslint/naming-convention,no-restricted-globals,consistent-return,no-return-assign */
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { toast } from 'react-toastify';
import {
  ArticleI,
  ArticleMatchingI,
  ArticleOptionsTypes,
  ArticleSuggestionI,
  MassImportImportData,
} from '../../../globals/models/data/article.model';
import server, { fileParserApiInstance } from '../../../services/axios.service';
import {
  SetIsArticleCreatedActionI,
  GetArticleSuggestionsByPznActionI,
  SetArticleToEditActionI,
  SetEditModeActionI,
  SetIsLoadingActionI,
  UploadMassImportFileActionI,
  UploadMassImportDataActionI,
  GetArticleMatchingByPznActionI,
} from '../../../globals/models/actions/articleEditor';
import { ArticleEditorActionTypes } from '../types/articleEditor';
import {
  ArticleEditorCreationData,
  MIValidation,
} from '../../../globals/models/components/articleEditor';
import i18next from '../../../i18n';
import {
  MIColumnsIndexes,
  createMIArticlePayload,
  submitMiData,
} from '../../../shared/utils/articleEditor/massImport.utils';
import { DataTableActionTypes } from '../types/dataTable';
import store from '../../index';

export const getArticleSuggestionsFromPzn: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleSuggestionI[] | string }>,
  { type: string; payload: ArticleSuggestionI[] | string },
  string,
  GetArticleSuggestionsByPznActionI
>> = (pzn: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: ArticleEditorActionTypes.SET_IS_LOADING_ARTICLE_SUGGESTIONS,
    payload: true,
  });

  try {
    const {
      data: { product_list },
    } = await server.get<any>(`/marketplace/product/pzn-search/?filter=${pzn}`);

    return dispatch({
      type: ArticleEditorActionTypes.GET_ARTICLES_SUGGESTIONS_BY_PZN,
      payload: product_list as ArticleSuggestionI[],
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setArticleSuggestionsValue: ActionCreator<ThunkAction<
  { type: string; payload: ArticleSuggestionI[] },
  { type: string; payload: ArticleSuggestionI[] },
  string,
  GetArticleSuggestionsByPznActionI
>> = (suggestions: ArticleSuggestionI[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.GET_ARTICLES_SUGGESTIONS_BY_PZN,
    payload: suggestions,
  });
};

export const createOrUpdateArticle: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: boolean | string }>,
  { type: string; payload: boolean | string },
  ArticleEditorCreationData,
  SetIsArticleCreatedActionI
>> = (
  articleData: ArticleEditorCreationData,
  isEditMode: boolean,
  articleId?: string,
  addressIds?: {
    billingAddress: string;
    invoiceAddress: string;
  },
) => async (dispatch: Dispatch) => {
  if (addressIds) {
    Object.assign(articleData, {
      invoice_address_id: addressIds.invoiceAddress,
      delivery_address_id: addressIds.billingAddress,
    });
  }

  try {
    if (window.location.pathname.includes('/application/offers/')) {
      if (isEditMode) {
        await server.put(`/api/v1/articles/${articleId}/`, articleData);
      } else {
        await server.post('/api/v1/articles/', articleData);
      }
    }

    if (window.location.pathname.includes('/application/search/')) {
      if (isEditMode) {
        await server.put(
          `/api/v1/purchase-requests/${articleId}/`,
          articleData,
        );
      } else {
        await server.post('/api/v1/purchase-requests/', articleData);
      }
    }

    return dispatch({
      type: ArticleEditorActionTypes.SET_IS_ARTICLE_CREATED,
      payload: true,
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: i18next.t('ARTICLE_EDITOR_API_ERROR'),
    });
  }
};

export const setIsArticleCreated: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  string,
  SetIsArticleCreatedActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.SET_IS_ARTICLE_CREATED,
    payload: value,
  });
};

export const setArticleToEdit: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleI }>,
  { type: string; payload: ArticleI },
  string,
  SetArticleToEditActionI
>> = (article: ArticleI) => async (dispatch: Dispatch) => {
  try {
    const {
      data: {
        article_data: {
          offer,
          best_offer_auto_accept,
          product: { package_size },
          status_description,
          article_location_country,
        },
      },
    } = await server.get(`/api/v1/articles/${article.id}`);

    dispatch({
      type: ArticleEditorActionTypes.SET_EDIT_MODE,
      payload: true,
    });

    return dispatch({
      type: ArticleEditorActionTypes.SET_ARTICLE_TO_EDIT,
      payload: {
        ...article,
        article_location_country,
        offer: {
          ...offer,
          best_offer_auto_accept,
          package_size: package_size.split(' ')[0],
          status_description,
        },
      },
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

export const setIsEditMode: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  string,
  SetEditModeActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.SET_EDIT_MODE,
    payload: value,
  });
};

export const setIsLoading: ActionCreator<ThunkAction<
  { type: string; payload: boolean },
  { type: string; payload: boolean },
  boolean,
  SetIsLoadingActionI
>> = (value: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.SET_IS_LOADING,
    payload: value,
  });
};

export const uploadMassImportFile: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: MassImportImportData } | string>,
  { type: string; payload: MassImportImportData } | string,
  File,
  UploadMassImportFileActionI
>> = (value: File, isMM: boolean) => async (dispatch: Dispatch) => {
  const form = new FormData();
  form.append('upload', value);
  form.append('isMM', JSON.stringify(isMM));
  try {
    const {
      data: { data },
    } = await fileParserApiInstance.post('/files/parse', form);
    return dispatch({
      type: ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_FILE,
      payload: {
        data,
        fileType: value.name.split('.').slice(-1)[0],
      } as UploadMassImportFileActionI['payload'],
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: i18next.t('GENERAL_API_ERROR'),
    });
  }
};

// export const uploadProductFinderImportFile: ActionCreator<ThunkAction<
//   Promise<
//     { type: string; payload: ProductFinderMassImportImportData } | string
//   >,
//   { type: string; payload: ProductFinderMassImportImportData } | string,
//   File,
//   UploadProductFinderImportFileActionI
// >> = (value: File, isMM: boolean) => async (dispatch: Dispatch) => {
//   const form = new FormData();
//   form.append('upload', value);
//   form.append('isMM', JSON.stringify(isMM));
//   try {
//     const {
//       data: { data },
//     } = await fileParserApiInstance.post('/files/parse', form);
//     return dispatch({
//       type: ArticleEditorActionTypes.UPLOAD_PRODUCT_FINDER_IMPORT_FILE,
//       payload: {
//         data,
//         fileType: value.name.split('.').slice(-1)[0],
//       } as UploadProductFinderImportFileActionI['payload'],
//     });
//   } catch (e) {
//     return dispatch({
//       type: ArticleEditorActionTypes.EDITOR_ERROR,
//       payload: i18next.t('GENERAL_API_ERROR'),
//     });
//   }
// };

export const setMassImportData: ActionCreator<ThunkAction<
  { type: string; payload: MassImportImportData },
  { type: string; payload: MassImportImportData },
  MassImportImportData,
  SetIsLoadingActionI
>> = (
  value: MassImportImportData = {
    headers: [],
    content: [],
    isMM: false,
  },
) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_FILE,
    payload: value,
  });
};

export const createMassImportArticles: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: boolean | string } | undefined>,
  { type: string; payload: boolean | string },
  any,
  UploadMassImportDataActionI
>> = (
  validatedData: MIValidation[][],
  tradeType: 'BUYING' | 'SELLING',
  addressIds?: {
    delivery_address_id: string;
    invoice_address_id: string;
  },
) => (dispatch: Dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ArticleEditorActionTypes.SET_IS_LOADING,
      payload: true,
    });

    dispatch({
      type: ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS,
      payload: {
        totalItems: validatedData.length,
        currentItem: 0,
      },
    });

    const notFoundPzn: string[] = [];
    let uploadCounter = 0;

    const incrementCounter = () => {
      return (uploadCounter += 1);
    };

    const createArticleRequests = validatedData.map(
      (row, index) =>
        new Promise((resolveStorage, rejectStorage) => {
          server
            .get<{ product_list: ArticleSuggestionI[] }>(
              `/marketplace/product/pzn-search/?filter=${
                row[MIColumnsIndexes.PZN].validatedValue
              }`,
            )
            .then(({ data: { product_list } }) => {
              if (product_list.length === 0) {
                dispatch({
                  type: ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS,
                  payload: {
                    totalItems: validatedData.length,
                    currentItem: incrementCounter(),
                  },
                });
                notFoundPzn.push(
                  row[MIColumnsIndexes.PZN].originalValue as string,
                );
                resolveStorage();
              } else {
                const MICreationData = createMIArticlePayload(
                  row,
                  product_list,
                  tradeType,
                  addressIds,
                );

                submitMiData(
                  MICreationData,
                  tradeType,
                  validatedData.length,
                  dispatch,
                  resolveStorage,
                  rejectStorage,
                  incrementCounter,
                );
              }
            })
            .catch((e) => {
              console.error(e);
              dispatch({
                type: ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS,
                payload: {
                  totalItems: validatedData.length,
                  currentItem: incrementCounter(),
                },
              });

              rejectStorage();
            });
        }),
    );

    try {
      if (tradeType === 'BUYING' && !addressIds) {
        throw new Error(
          "AddressDataUndefinedError: 'delivery_adress_id' and 'invoice_adress_id' should be part of the payload when tradeType === 'BUYING'",
        );
      }

      Promise.allSettled(createArticleRequests).then(() => {
        if (notFoundPzn.length !== 0) {
          toast.warn(
            i18next.t('MASS_IMPORT.ERRORS.PZN_NOT_FOUND', {
              pznData: notFoundPzn.join(', '),
            }),
            {
              autoClose: false,
            },
          );
        }

        dispatch({
          type: ArticleEditorActionTypes.SET_IS_LOADING,
          payload: false,
        });

        dispatch({
          type: ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_DATA,
          payload: true,
        });

        resolve({
          type: ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_DATA,
          payload: true,
        });
      });
    } catch (e) {
      if (tradeType === 'BUYING' && !addressIds) {
        console.error(e);
      }
      dispatch({
        type: ArticleEditorActionTypes.EDITOR_ERROR,
        payload: i18next.t('ARTICLE_EDITOR_API_ERROR'),
      });

      reject(e);
    }
  });
};

export const getArticleMatchingFromPzn: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleMatchingI[] | string }>,
  { type: string; payload: ArticleMatchingI[] | string },
  string,
  GetArticleMatchingByPznActionI
>> = (submitData: {
  pzn: string;
  amount: number;
  expiration_date: string;
  price: string;
  batch: string;
}) => async (dispatch: Dispatch) => {
  dispatch({
    type: ArticleEditorActionTypes.SET_IS_GETTING_ARTICLE_MATCHING,
    payload: true,
  });

  let data: ArticleMatchingI[] = [];

  try {
    if (window.location.pathname.includes('/application/offers/')) {
      const { data: matching } = await server.get(
        `/marketplace/matchmaking/purchase_request/${submitData.pzn}/` +
          `?amount=${submitData.amount}&expiration_date=${submitData.expiration_date}` +
          `&price=${submitData.price}&batch=${submitData.batch}`,
      );

      data = matching;
    }
    if (window.location.pathname.includes('/application/search/')) {
      const { data: matching } = await server.get(
        `/marketplace/matchmaking/offer/${submitData.pzn}/` +
          `?amount=${submitData.amount}&expiration_date=${submitData.expiration_date}` +
          `&price=${submitData.price}&batch=${submitData.batch}`,
      );

      data = matching;
    }

    dispatch({
      type: ArticleEditorActionTypes.SET_IS_GETTING_ARTICLE_MATCHING,
      payload: false,
    });

    if (data.length === 0) {
      toast(i18next.t('ARTICLE_MATCHING.NO_MATCHES_FOUND'));
    }

    return dispatch({
      type: ArticleEditorActionTypes.GET_ARTICLE_MATCHING,
      payload: data,
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const setArticleMatching: ActionCreator<ThunkAction<
  { type: string; payload: ArticleMatchingI[] },
  { type: string; payload: ArticleMatchingI[] },
  string,
  GetArticleMatchingByPznActionI
>> = (value: ArticleMatchingI[]) => (dispatch: Dispatch) => {
  return dispatch({
    type: ArticleEditorActionTypes.GET_ARTICLE_MATCHING,
    payload: value,
  });
};

export const addMatchingToShoppingCart: ActionCreator<ThunkAction<
  Promise<{ type: string; payload: ArticleMatchingI[] | string }>,
  { type: string; payload: ArticleMatchingI[] | string },
  string,
  GetArticleMatchingByPznActionI
>> = (id: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: DataTableActionTypes.SET_IS_ADDING_TO_SHOPPING_CART,
    payload: { value: true, iconRef: null },
  });

  try {
    await server.post('/checkout/proposal/mass-options/', {
      data: [{ id }],
    });

    dispatch({
      type: DataTableActionTypes.GET_TRANSACTIONS_NUMBER,
      payload: store.getState().dataTableState.transactionsNumber + 1,
    });

    dispatch({
      type: DataTableActionTypes.SET_IS_ADDING_TO_SHOPPING_CART,
      payload: { value: false, iconRef: null },
    });

    toast.success(i18next.t('ARTICLE_MATCHING.ADD_TO_CART'));

    return dispatch({
      type: ArticleEditorActionTypes.GET_ARTICLE_MATCHING,
      payload: store
        .getState()
        .articleEditorState.articleMatches.filter(
          (el) => el.article_uuid !== id,
        ),
    });
  } catch (e) {
    return dispatch({
      type: ArticleEditorActionTypes.EDITOR_ERROR,
      payload: 'GENERAL_API_ERROR',
    });
  }
};

export const updateArticleMatchingOptionType: ActionCreator<ThunkAction<
  { type: string; payload: ArticleMatchingI[] },
  { type: string; payload: ArticleMatchingI[] },
  string,
  GetArticleMatchingByPznActionI
>> = (id: string, optionType: ArticleOptionsTypes) => (dispatch: Dispatch) => {
  const allArticles = store.getState().articleEditorState.articleMatches;

  const articleIndex = allArticles.findIndex((el) => el.article_uuid === id);

  const editedArticle = allArticles[articleIndex];

  editedArticle.options_type = optionType;

  allArticles[articleIndex] = editedArticle;

  return dispatch({
    type: ArticleEditorActionTypes.GET_ARTICLE_MATCHING,
    payload: allArticles,
  });
};
