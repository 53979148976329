import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextInput from '../../Shared/layouts/TextInput';
import {
  InputChangeState,
  TextInputType,
} from '../../../globals/models/components/textInput';
import {
  ArticleOfferI,
  DataTableModalOptions,
} from '../../../globals/models/components/dataTable';
import {
  setIsTableModalLoading,
  setTableModal,
  submitASubset,
} from '../../../store/actions/creators/dataTable';
import { subsetModalValidationSchema } from '../../../shared/validators/dataTable';

const SubsetModal = ({
  currentModalArticle,
  _submitASubset,
  _setIsTableModalLoading,
  isLoading,
  _setTableModal,
}: SubsetModalProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const activeColor = path.includes('offers') ? 'bg-primary' : 'bg-green-600';
  const inactiveColor = path.includes('offers')
    ? 'bg-primary-disabled'
    : 'bg-green-400';
  const minimumAmount =
    (currentModalArticle?.attributes.min_piece_amount as number) ||
    (currentModalArticle?.attributes.piece_amount as number);

  return (
    <div className="w-full">
      <Formik
        onSubmit={() => {}}
        validateOnChange
        initialValues={{
          articleSubset: currentModalArticle?.attributes.piece_amount as number,
        }}
        validationSchema={subsetModalValidationSchema(
          minimumAmount,
          currentModalArticle?.attributes.piece_amount as number,
        )}
      >
        {(props) => {
          const {
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            values,
          } = props;

          return (
            <>
              <TextInput
                labelKey={t('TABLE_SUBSET_MODAL.QUANTITY_LABEL', {
                  minimumAmount,
                  maximumAmount: currentModalArticle?.attributes
                    .piece_amount as number,
                })}
                forText="articleSubset"
                type={TextInputType.NUMBER}
                isRequired={false}
                onBlur={handleBlur}
                classes={{
                  container: 'pl-4 px-4 pb-5',
                  input: 'border border-gray-500 rounded-sm w-full h-8 mt-2',
                }}
                onChange={(value, event) => {
                  handleChange(event);
                }}
                changeState={
                  touched.articleSubset && errors.articleSubset
                    ? InputChangeState.INVALID
                    : InputChangeState.VALID
                }
                errorMessage={errors.articleSubset}
                value={`${values.articleSubset}`}
                disabled={isLoading}
              />

              <div className="w-full flex flex-row justify-between pt-4 border-t px-4">
                {currentModalArticle && (
                  <button
                    type="button"
                    className="focus:outline-none button mr-4 text-red-600 bg-grey-light hover:bg-red-600 hover:text-white"
                    onClick={() => {
                      _setIsTableModalLoading(false);
                      _setTableModal(null, null);
                    }}
                  >
                    {t('Cancel')}
                  </button>
                )}
                <button
                  type="button"
                  className={`focus:outline-none button ${
                    isValid ? activeColor : inactiveColor
                  }`}
                  onClick={() => {
                    if (currentModalArticle) {
                      _setIsTableModalLoading(true);
                      _submitASubset(
                        currentModalArticle as ArticleOfferI,
                        values.articleSubset,
                      );
                    } else {
                      _setIsTableModalLoading(false);
                      _setTableModal(null, null);
                    }
                  }}
                  disabled={!isValid}
                >
                  {currentModalArticle ? t('Add to shopping cart') : t('Close')}
                  {isLoading && (
                    <CircularProgress
                      style={{ color: 'white' }}
                      size={15}
                      className="ml-2"
                    />
                  )}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

interface SubsetModalProps {
  currentModalArticle: ArticleOfferI | null;
  _submitASubset: (
    {
      attributes: {
        apu_discount,
        aep_discount,
        piece_amount: article_piece_amount,
        price,
      },
      id,
    }: ArticleOfferI,
    chosen_quantity: number,
    chosen_price?: number,
  ) => Promise<{
    type: string;
    payload: { article: ArticleOfferI } | string;
  }>;
  _setIsTableModalLoading: (
    value: boolean,
  ) => { type: string; payload: boolean };
  isLoading: boolean;
  _setTableModal: (
    value: DataTableModalOptions | null,
    title: string | null,
  ) => {
    type: string;
    payload: { page: DataTableModalOptions | null; title: string | null };
  };
}

const mapStateToProps = ({
  dataTableState: {
    currentModal: { article, isLoading },
  },
}: StoreI) => ({
  currentModalArticle: article,
  isLoading,
});

export default connect(mapStateToProps, {
  _submitASubset: submitASubset,
  _setIsTableModalLoading: setIsTableModalLoading,
  _setTableModal: setTableModal,
})(SubsetModal);
