/* eslint-disable @typescript-eslint/indent */
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt, faCogs } from '@fortawesome/free-solid-svg-icons';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { DataTableColumnsConfigI } from '../../../globals/models/components/dataTable';
import { setDataTableColumnsConfig } from '../../../store/actions/creators/dataTable';
import { User } from '../../../globals/models/data/user.model';

interface DataTableColumnsConfigProps {
  columnsConfig: DataTableColumnsConfigI[];
  _setDataTableColumnsConfig: (
    nextConfig: DataTableColumnsConfigI[],
    userId: number,
  ) => Promise<{
    type: string;
    payload: DataTableColumnsConfigI[];
  }>;
  currentUser: User | null;
}

// static translation keys

/**
 * t('TABLE_COLUMN_TIMELINE'),
 * t('TABLE_COLUMN_ARTICLE_NAME'),
 * t('TABLE_COLUMN_EXPIRATION'),
 * t('TABLE_COLUMN_REL_EXPIRATION'),
 * t('TABLE_COLUMN_BATCH'),
 * t('TABLE_COLUMN_MIN_QUANTITY'),
 * t('TABLE_COLUMN_QUANTITY'),
 * t('TABLE_COLUMN_PRICE_PE'),
 * t('AEK_PERCENTAGE'),
 */

const DataTableColumnsConfig = ({
  columnsConfig,
  _setDataTableColumnsConfig,
  currentUser,
}: DataTableColumnsConfigProps) => {
  const [currentOptions, setCurrentOptions] = useState<
    DataTableColumnsConfigI[]
  >(columnsConfig);
  const { t } = useTranslation();

  const onDragEnd = (props: any) => {
    if (props.source.index < props.destination.index) {
      setCurrentOptions([
        ...currentOptions.slice(0, props.source.index),
        ...currentOptions.slice(
          props.source.index + 1,
          props.destination.index + 1,
        ),
        currentOptions[props.source.index],
        ...currentOptions.slice(
          props.destination.index + 1,
          currentOptions.length,
        ),
      ]);
    }

    if (props.source.index > props.destination.index) {
      setCurrentOptions([
        ...currentOptions.slice(0, props.destination.index),
        currentOptions[props.source.index],
        ...currentOptions.slice(props.destination.index, props.source.index),
        ...currentOptions.slice(props.source.index + 1, currentOptions.length),
      ]);
    }
  };

  // The following functions are just for meeting the DragDropContext requirements

  const onBeforeCapture = () => {
    //
  };

  const onBeforeDragStart = () => {
    //
  };

  const onDragStart = () => {
    //
  };
  const onDragUpdate = () => {
    //
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCurrentOptions(columnsConfig);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip
        title={t('TABLE_COLUMN_CONFIG_TOOL_TIP') as string}
        placement="top"
      >
        <button
          type="button"
          className="font-light text-primary general-hover-effect"
          onClick={(e) => handleClick(e)}
        >
          {t('Columns')} &nbsp; <FontAwesomeIcon icon={faCogs} />
        </button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onBeforeDragStart={onBeforeDragStart}
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          <div className="block z-50">
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div
                  className="shadow-lg right-0 w-64 pt-2 bg-white block"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {currentOptions.map((option, index) => (
                    <Draggable
                      draggableId={option.config_id}
                      index={index}
                      key={option.config_id}
                    >
                      {(provided_) => (
                        <span
                          className="border-b relative w-full text-left px-4 overflow-hidden grid grid-cols-8 "
                          ref={provided_.innerRef}
                          {...provided_.draggableProps}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={option.isSelected}
                                onChange={() =>
                                  setCurrentOptions(
                                    currentOptions.map((el) => {
                                      if (el.config_id === option.config_id)
                                        return {
                                          ...option,
                                          isSelected: !option.isSelected,
                                        };
                                      return el;
                                    }),
                                  )
                                }
                                color="primary"
                                className="column-config-checkbox"
                              />
                            }
                            className={`text-sm col-span-7 ${
                              option.isSelected ? '' : 'text-grey-1'
                            }`}
                            label={t(option.displayText)}
                          />
                          <span
                            {...provided_.dragHandleProps}
                            className="col-span-1 text-right pt-3"
                          >
                            <FontAwesomeIcon
                              icon={faArrowsAlt}
                              className="w-1/10 cursor-pointer text-grey-3"
                            />
                          </span>
                        </span>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <span className="flex py-2 grid grid-cols-2 px-2">
                    <button
                      type="button"
                      onClick={() => {
                        // cancel
                        handleClose();
                      }}
                      className="outline-none focus:outline-none text-primary text-lg"
                    >
                      {t('Cancel')}
                    </button>
                    <button
                      type="button"
                      className="text-white bg-primary py-1 rounded-md"
                      onClick={() => {
                        const filteredOptions = currentOptions.map(
                          ({ config_id, isSelected }) => ({
                            config_id,
                            isSelected,
                          }),
                        );
                        _setDataTableColumnsConfig(
                          filteredOptions,
                          currentUser?.user_id as number,
                        );
                      }}
                    >
                      {t('TABLE_COLUMNS_CONFIG_SUBMIT')}
                    </button>
                  </span>
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Popover>
    </div>
  );
};

const mapStateToProps = ({
  dataTableState: { columnsConfig },
  registrationState: { currentUser },
}: StoreI) => ({
  columnsConfig,
  currentUser,
});

export default connect(mapStateToProps, {
  _setDataTableColumnsConfig: setDataTableColumnsConfig,
})(DataTableColumnsConfig);
