import { PAGE_SIZE_CHOICES } from '../shared/constants/dataTable';
import {
  DataTablePageE,
  DataTableShellTabOptionsE,
} from '../globals/models/components/dataTable';

const initialState: StoreI = {
  registrationState: {
    form: {
      type: null,
    },
    tokensStorage: 'sessionStorage',
    currentUser: null,
    errorMessage: null,
    isAuth: false,
    message: null,
    tokens: {
      access: '',
      refresh: '',
    },
    isLoading: false,
  },
  dataTableState: {
    columnsConfig: [],
    errorMessage: null,
    articles: {
      data: [],
    },
    isContentLoading: true,
    pageSize: PAGE_SIZE_CHOICES[0],
    isLayoutLoading: true,
    isAddingToShoppingCart: false,
    loadingIconRef: null,
    transactionsNumber: 0,
    currentModal: {
      article: null,
      page: null,
      isLoading: false,
      title: null,
      maxWidth: undefined,
    },
    selectedRows: [],
    sort: {
      id: '',
      desc: false,
    },
    msv3AvailabilitiesSet: false,
  },
  dataTableShellState: {
    page: DataTablePageE.OFFERS,
    controls: {
      navigation: [
        {
          displayText: DataTableShellTabOptionsE.ALL_OFFERS,
          path: '/application/offers/all',
        },
        {
          displayText: DataTableShellTabOptionsE.MY_OFFERS,
          path: '/application/offers/own',
        },
      ],
      action: {
        displayText: DataTableShellTabOptionsE.CREATE_OFFER,
        path: '/application/offers/-/create',
      },
    },
    tab: DataTableShellTabOptionsE.MY_OFFERS,
    pageIndex: 0,
  },
  sidebarState: {
    filterString: '',
  },
  articleEditorState: {
    articleSuggestions: [],
    articleCreated: false,
    errorMessage: null,
    isEditMode: false,
    articleOnEdit: null,
    isLoading: false,
    isLoadingSuggestions: false,
    massImportData: {
      content: [],
      headers: [],
      isMM: false,
    },
    productFinderImportData: [],
    massImportStatus: {
      currentItem: null,
      totalItems: null,
    },
    articleMatches: [],
    isLoadingMatches: false,
  },
  profileState: {
    addresses: {
      billing: [],
      delivery: [],
      isEditorLoading: false,
      isLayoutLoading: true,
    },
    errorMessage: null,
    isLoading: true,
    userProfile: null,
    messages: {
      data: [],
    },
    isLoadingMessages: true,
    messagesNumber: 0,
    profileSettings: null,
  },
  shoppingCartState: {
    buyingProposals: [],
    sellingProposals: [],
    isContentLoading: true,
    errorMessage: null,
    isLayoutLoading: true,
    iconRef: null,
    isAddingToCart: false,
    loadingIconRef: null,
  },
  adminAreaState: {
    companies: {
      loadedData: { data: [] },
      isLoadingTransactionsToDownload: false,
      isLoading: true,
      breadcrumbs: [],
      currentlyDisplayedCompany: null,
      transactions: {
        data: [],
      },
      transactionsToDownload: {
        data: [],
      },
    },
  },
  modalsState: {
    type: null,
    selectedRows: [],
  },
  msv3State: {
    isLoadingPartners: true,
    isLoadingSinglePartnerRequest: false,
    partnersList: {
      foreign: [],
      own: [],
      all: [],
    },
    currentPZNAvailability: null,
    checkoutResult: {
      errors: {},
      results: {},
    },
    msv3OpenTransactions: [],
    msv3Transactions: [],
  },
};

export default initialState;
