import React from 'react';
import { connect } from 'react-redux';
import { RegistrationFormType } from '../../../globals/models/components/registrationForms';
import LoginPasswordResetModal from '../../Auth/LoginPasswordReset';
import Signup from '../../Auth/Signup';
import { ModalsTypes } from '../../../globals/models/redux';
import SubmitCartDataModal from '../../ShoppingCart/ShoppingCartTable/SubmitCartDataModal';
import { DataTableModalOptions } from '../../../globals/models/components/dataTable';
import TableModal from '../../DataGrid/modals';
import TransactionsExportModal from '../../AdminArea/companies/transactions/TransactionsExportModal';

const ModalsContainer = ({
  registrationFormToDisplay,
  modalType,
  currentTableModalPage,
}: ModalsContainerProps) => {
  return (
    <>
      {(registrationFormToDisplay === RegistrationFormType.LOGIN ||
        registrationFormToDisplay === RegistrationFormType.PASSWORD_RESET ||
        registrationFormToDisplay ===
          RegistrationFormType.PASSWORD_RESET_CONFIRMATION) && (
        <LoginPasswordResetModal />
      )}
      {registrationFormToDisplay === RegistrationFormType.SIGNUP && <Signup />}
      {(modalType === ModalsTypes.SHOPPING_CART_SUBMIT_PURCHASES ||
        modalType === ModalsTypes.SHOPPING_CART_SUBMIT_SALES) && (
        <SubmitCartDataModal />
      )}
      {currentTableModalPage && <TableModal />}
      {modalType === ModalsTypes.ADMIN_TRANSACTIONS_EXPORT && (
        <TransactionsExportModal />
      )}
    </>
  );
};

interface ModalsContainerProps {
  registrationFormToDisplay: RegistrationFormType | null;
  modalType: ModalsTypes | null;
  currentTableModalPage: DataTableModalOptions | null;
}

const mapStateToProps = ({
  registrationState: {
    form: { type },
  },
  modalsState: { type: modalType },
  dataTableState: {
    currentModal: { page },
  },
}: StoreI) => ({
  registrationFormToDisplay: type,
  modalType,
  currentTableModalPage: page,
});

export default connect(mapStateToProps)(ModalsContainer);
