import React from 'react';
import Select from '@material-ui/core/Select';
import Pagination from '@material-ui/lab/Pagination';
import { useTranslation } from 'react-i18next';

const ModalsPaginator: React.FC<ModalsPaginatorProps> = ({
  possiblePageLimits,
  setCurrentPageLimitMeta,
  currentPageLimitMeta,
  pages,
  currentPageMeta,
  setCurrentPageMeta,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-row justify-between mb-6 pl-4 px-4 overflow-x-scroll hide-scrollbar">
      <div className="w-full flex flex-row justify-between pl-6 px-4">
        <div className="flex">
          <div className="pt-2 whitespace-no-wrap mr-2">{`${t(
            'TABLE_ITEMS_PER_PAGE',
          )}`}</div>
          <Select
            name="timeslot"
            id="time-choices"
            className="border rounded-sm w-full h-8 mt-1 px-1"
            defaultValue={possiblePageLimits[0]}
            onChange={({ target: { value } }) => {
              setCurrentPageLimitMeta(value as number);
            }}
            value={currentPageLimitMeta}
            disableUnderline
            native
          >
            {possiblePageLimits.map((el) => (
              <option
                value={el}
                key={el}
                className="hover:bg-grey-4 cursor-pointer"
              >
                {el}
              </option>
            ))}
          </Select>
        </div>
        <div className="pt-2">
          <Pagination
            count={pages}
            onChange={(e, page) => {
              if (page !== currentPageMeta) {
                setCurrentPageMeta(page);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

interface ModalsPaginatorProps {
  possiblePageLimits: number[];
  setCurrentPageLimitMeta: (next: number) => void;
  currentPageLimitMeta: number;
  pages: number;
  currentPageMeta: number;
  setCurrentPageMeta: (next: number) => void;
}

export default ModalsPaginator;
