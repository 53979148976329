import { Dispatch } from 'redux';
import jwt from 'jsonwebtoken';
import { RegistrationActionTypes } from '../../store/actions/types/registration';
import { User } from '../../globals/models/data/user.model';
import { ArticleOfferI } from '../../globals/models/components/dataTable';
import { MSV3DeliverableI } from '../../globals/models/data/msv3.model';

export const setCurrentUserWithDispatch = (
  dispatch: Dispatch,
  user: User,
  isAuth: boolean,
  storage: 'localStorage' | 'sessionStorage',
) => {
  return dispatch({
    type: RegistrationActionTypes.SET_CURRENT_USER,
    payload: { user, isAuth, storage },
  });
};

export const decodeUser = (token: string) => {
  const data = jwt.decode(token) as any;
  return new User(
    data.user_id,
    data.email,
    data.firstName,
    data.lastName,
    data.username,
    data.userRole,
    data.license_renewal_due,
    data.has_supplier_qualification,
    data.msv3_buy_own,
    data.consulting,
  );
};

export const storeTokensInLocalStorage = (
  accessToken: string,
  refreshToken: string,
) => {
  localStorage.setItem('access-token', accessToken);
  localStorage.setItem('refresh-token', refreshToken);
  sessionStorage.removeItem('access-token');
  sessionStorage.removeItem('refresh-token');
};

export const storeTokensInSessionStorage = (
  accessToken: string,
  refreshToken: string,
) => {
  sessionStorage.setItem('access-token', accessToken);
  sessionStorage.setItem('refresh-token', refreshToken);
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
};

export const getMSV3DeliverableSign = (
  offer: ArticleOfferI,
  deliverable?: MSV3DeliverableI,
) => {
  if (!deliverable) {
    return null;
  }

  if (deliverable.amount_deliverable === null) {
    return null;
  }

  if (deliverable.amount_deliverable >= offer.attributes.piece_amount) {
    return '>=';
  }

  return '<';
};
