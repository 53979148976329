/* eslint-disable @typescript-eslint/indent */
import { User } from './data/user.model';
import {
  ArticleOfferI,
  DataTableColumnsConfigI,
  DataTableModalOptions,
  DataTablePageE,
  DataTableShellTabOptionsE,
  PaginatedArticles,
} from './components/dataTable';
import { RegistrationFormType, Tokens } from './components/registrationForms';
import {
  ArticleI,
  ArticleMatchingI,
  ArticleSuggestionI,
  MassImportImportData,
} from './data/article.model';
import { AddressI } from './data/address.model';
import { UserProfile } from './data/userProfile.model';
import { Message } from './data/message.model';
import { Breadcrumbs, PaginatedItems } from './data';
import { Company, Transaction } from './data/company.model';
import {
  MSV3ApiError,
  Msv3PartnerI,
  MSV3PZNAvailability,
  MSV3ServerResponse,
} from './data/msv3.model';
import { UserProfileSettings } from './data/userProfileSettings.model';

export interface RegistrationState {
  form: {
    type: RegistrationFormType | null;
  };
  tokensStorage: 'localStorage' | 'sessionStorage';
  currentUser: User | null;
  errorMessage: string | null;
  isAuth: boolean;
  message: string | null;
  tokens: Tokens;
  isLoading: boolean;
}

export interface DataTableState {
  columnsConfig: DataTableColumnsConfigI[];
  errorMessage: string | null;
  articles: PaginatedArticles;
  isContentLoading: boolean; // for the content inside a rendered table
  pageSize: number;
  isAddingToShoppingCart: boolean;
  isLayoutLoading: boolean; // for the whole tableLayout
  loadingIconRef: number | null;
  transactionsNumber: number;
  currentModal: {
    page: DataTableModalOptions | null;
    article: ArticleOfferI | null;
    isLoading: boolean;
    title: string | null;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  };
  selectedRows: ArticleOfferI[];
  sort?: {
    id: string;
    desc: boolean;
  };
  msv3AvailabilitiesSet: boolean;
}

export interface DataTableShellState {
  page: DataTablePageE;
  controls: {
    navigation: {
      displayText: DataTableShellTabOptionsE;
      path: string;
    }[];
    action: {
      displayText: DataTableShellTabOptionsE;
      path: string;
    };
  };
  tab: DataTableShellTabOptionsE;
  pageIndex?: number;
}

export interface SidebarState {
  filterString: string | null;
}

export interface ArticleEditorState {
  articleSuggestions: ArticleSuggestionI[];
  articleCreated: boolean;
  errorMessage: string | null;
  isEditMode: boolean;
  articleOnEdit: ArticleI | null;
  isLoading: boolean;
  massImportData: MassImportImportData;
  productFinderImportData: [];
  massImportStatus: {
    totalItems: number | null;
    currentItem: number | null;
    fileType?: string;
  };
  isLoadingSuggestions: boolean;
  isLoadingMatches: boolean;
  articleMatches: ArticleMatchingI[];
}

export interface ProfileState {
  addresses: {
    delivery: AddressI[];
    billing: AddressI[];
    isEditorLoading: boolean;
    isLayoutLoading: boolean;
  };
  errorMessage: string | null;
  isLoading: boolean;
  userProfile: UserProfile | null;
  messages: PaginatedItems<{
    type: string;
    id: string;
    attributes: Message;
  }>;
  isLoadingMessages: boolean;
  messagesNumber: number;
  profileSettings: UserProfileSettings | null;
}

export interface ShoppingCartState {
  buyingProposals: ArticleOfferI[];
  sellingProposals: ArticleOfferI[];
  isLayoutLoading: boolean;
  isContentLoading: boolean;
  errorMessage: string | null;
  iconRef: number | null;
  isAddingToCart: boolean;
  loadingIconRef: number | null;
}

export interface AdminAreaState {
  companies: {
    loadedData: PaginatedItems<Company>;
    isLoading: boolean;
    isLoadingTransactionsToDownload: boolean;
    breadcrumbs: Breadcrumbs;
    currentlyDisplayedCompany: {
      display:
        | 'licences'
        | 'offers_purchase_requests'
        | 'successful_transactions';
      data: Company;
    } | null;

    transactions: PaginatedItems<{
      type: string;
      id: string;
      attributes: {
        transaction: Transaction;
      };
    }>;

    transactionsToDownload: PaginatedItems<{
      type: string;
      id: string;
      attributes: {
        transaction: Transaction;
      };
    }>;
  };
}

export enum ModalsTypes {
  SHOPPING_CART_SUBMIT_SALES = 'SHOPPING_CART_SUBMIT_SALES',
  SHOPPING_CART_SUBMIT_PURCHASES = 'SHOPPING_CART_SUBMIT_PURCHASES',
  ADMIN_TRANSACTIONS_EXPORT = 'ADMIN_TRANSACTIONS_EXPORT',
  ARTICLE_MATCHING = 'ARTICLE_MATCHING',
}

export interface ModalsState {
  type: ModalsTypes | null;
  selectedRows: ArticleOfferI[];
}

export interface MSV3State {
  partnersList: {
    own: Msv3PartnerI[];
    foreign: Msv3PartnerI[];
    all: Msv3PartnerI[];
    buy?: Msv3PartnerI[];
  };
  isLoadingPartners: boolean;
  isLoadingSinglePartnerRequest: boolean;
  currentPZNAvailability: MSV3PZNAvailability | null;
  checkoutResult: {
    results: ObjectT<MSV3ServerResponse>;
    errors: ObjectT<MSV3ApiError>;
  };
  msv3OpenTransactions: [];
  msv3Transactions: any[];
}
