/* eslint-disable no-case-declarations,@typescript-eslint/indent */
import { Reducer } from 'redux';

import initialState from '../initialState';
import {
  ArticleEditorActions,
  SetIsArticleCreatedActionI,
  GetArticleSuggestionsByPznActionI,
  SetArticleToEditActionI,
  SetEditModeActionI,
  SetIsLoadingActionI,
  UploadMassImportFileActionI,
  SetIsLoadingSuggestionsActionI,
  SetDataUploadStatusActionI,
  GetArticleMatchingByPznActionI,
  SetIsGettingArticleMatchingByPznActionI,
  AddMSV3deliverablesProductFinderActionI,
} from '../../globals/models/actions/articleEditor';
import { ArticleEditorState } from '../../globals/models/redux';
import { ArticleEditorActionTypes } from '../actions/types/articleEditor';

export const articleEditorReducer: Reducer<
  ArticleEditorState,
  ArticleEditorActions
> = (state = initialState.articleEditorState, action) => {
  switch (action.type) {
    case ArticleEditorActionTypes.GET_ARTICLES_SUGGESTIONS_BY_PZN:
      return {
        ...state,
        articleSuggestions: (action as GetArticleSuggestionsByPznActionI)
          .payload,
        isLoadingSuggestions: false,
      };

    case ArticleEditorActionTypes.SET_IS_ARTICLE_CREATED:
      return {
        ...state,
        articleCreated: (action as SetIsArticleCreatedActionI).payload,
        isLoading: false,
      };

    case ArticleEditorActionTypes.EDITOR_ERROR:
      console.log(action.payload);
      // toast.error(i18next.t(action.payload));
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
      };

    case ArticleEditorActionTypes.SET_ARTICLE_TO_EDIT:
      return {
        ...state,
        articleOnEdit: (action as SetArticleToEditActionI).payload,
      };

    case ArticleEditorActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: (action as SetEditModeActionI).payload,
      };

    case ArticleEditorActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: (action as SetIsLoadingActionI).payload,
      };

    case ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_FILE:
      return {
        ...state,
        isLoading: false,
        massImportData: (action as UploadMassImportFileActionI).payload.data,
        massImportStatus: {
          ...state.massImportStatus,
          fileType: (action as UploadMassImportFileActionI).payload.fileType,
        },
      };

    // case ArticleEditorActionTypes.UPLOAD_PRODUCT_FINDER_IMPORT_FILE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     productFinderImportData: (action as UploadProductFinderImportFileActionI)
    //       .payload.data,
    //     massImportStatus: {
    //       ...state.massImportStatus,
    //       fileType: (action as UploadProductFinderImportFileActionI).payload
    //         .fileType,
    //     },
    //   };

    case ArticleEditorActionTypes.SET_IS_LOADING_ARTICLE_SUGGESTIONS:
      return {
        ...state,
        isLoadingSuggestions: (action as SetIsLoadingSuggestionsActionI)
          .payload,
      };

    case ArticleEditorActionTypes.UPLOAD_MASS_IMPORT_DATA:
      return {
        ...state,
        isLoading: false,
        massImportData: initialState.articleEditorState.massImportData,
      };

    case ArticleEditorActionTypes.SET_DATA_UPLOAD_STATUS:
      return {
        ...state,
        massImportStatus: (action as SetDataUploadStatusActionI).payload,
      };

    case ArticleEditorActionTypes.GET_ARTICLE_MATCHING:
      return {
        ...state,
        articleMatches: (action as GetArticleMatchingByPznActionI).payload,
        isLoadingMatches: false,
      };

    case ArticleEditorActionTypes.SET_IS_GETTING_ARTICLE_MATCHING:
      return {
        ...state,
        isLoadingMatches: (action as SetIsGettingArticleMatchingByPznActionI)
          .payload,
      };

    case ArticleEditorActionTypes.ADD_MSV3_DELIVERABLES:
      return {
        ...state,
        productFinderImportData: (action as AddMSV3deliverablesProductFinderActionI)
          .payload,
      };

    default:
      return state;
  }
};
