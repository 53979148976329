export const ARTICLE_STATUSES = [
  {
    key: 'NO_STATUS',
    value: 'ARTICLE_STATUS_NO_STATUS',
    submitValue: '',
  },
  {
    key: 'OK',
    value: 'ARTICLE_STATUS_OK',
    submitValue: 'Verkaufsfähige Ware',
  },
  {
    key: 'BRUCH',
    value: 'ARTICLE_STATUS_BRUCH',
    submitValue: 'Bruch',
  },
  {
    key: 'KURES_MHD',
    value: 'ARTICLE_STATUS_KURES_MHD',
    submitValue: 'Kurzes Verfallsdatum',
  },
  {
    key: 'AB_MHD',
    value: 'ARTICLE_STATUS_AB_MHD',
    submitValue: 'Abgelaufenes Verfallsdatum',
  },
  {
    key: 'SCHADEN',
    value: 'ARTICLE_STATUS_SCHADEN',
    submitValue: 'Verpackung beschädigt',
  },
];

export const PAYMENT_DEADLINES = [
  {
    key: 'NOT_DEFINED',
    value: 'PAYMENT_DEADLINE_NOT_SPECIFIED',
    submitValue: '',
  },
  {
    key: 'ADVANCE',
    value: 'PAYMENT_DEADLINE_ADVANCE',
    submitValue: 'Vorkasse',
  },
  {
    key: 'DAYS_INSTANT',
    value: 'PAYMENT_DEADLINE_DAYS_INSTANT',
    submitValue: 'Sofort nach Wareneingang',
  },
  {
    key: 'DAYS_7',
    value: 'PAYMENT_DEADLINE_DAYS_7',
    submitValue: '7 Tage nach Wareneingang',
  },
  {
    key: 'DAYS_12',
    value: 'PAYMENT_DEADLINE_DAYS_12',
    submitValue: '14 Tage nach Wareneingang',
  },
];

export const ARTICLE_LOCATION = [
  {
    displayValue: 'COUNTRY_CODE.GERMANY',
    submitValue: 'GERMANY',
  },
  {
    displayValue: 'COUNTRY_CODE.AUSTRIA',
    submitValue: 'AUSTRIA',
  },
];

export const TRAMEDIX_COMMISSION = 0.0075;
