/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect } from 'react';

const useEnterKeyListener = ({
  querySelectorToExecuteClick,
}: {
  querySelectorToExecuteClick: string;
}) => {
  const handlePressEnter = useCallback(() => {
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    function simulateMouseClick(element: Element | null) {
      mouseClickEvents.forEach((mouseEventType) =>
        element?.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          }),
        ),
      );
    }

    const element = document.querySelector(querySelectorToExecuteClick);
    simulateMouseClick(element);
  }, [querySelectorToExecuteClick]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handlePressEnter();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handlePressEnter]);
};

export default useEnterKeyListener;
